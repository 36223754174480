import * as React from 'react';
import { Formik, Field, FormikProps } from 'formik';
import nameof from 'ts-nameof.macro';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { isNullOrEmptyString } from '@helpers/stringHelpers';
import styles from './OrganizationEditor.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { object, number, string } from 'yup';
import "yup-phone";
import i18next from 'i18next';
import ValidationMessage from '@components/ValidationMessage';
import clsx from 'clsx';
import { HttpError } from '@models/shared';
import { submitFormAndShowErrorsAsync } from '@helpers/FormHelper';
import { OrganizationDto, UpdateOrganizationInfo } from '@models/organizations';
import { DateTime } from '@helpers/DateTime';
import LocalizableDatePicker from '@components/LocalizableDatePicker';
import WayToGetAccountingDocSelectField from '@components/inputs/WayToGetAccountingDocSelect/WayToGetAccountingDocSelectField';
import { WayToGetAccountingDoc } from '@models/organizations/WayToGetAccountingDoc';
import uuid from 'react-uuid';
import { InputPhone } from '@components/inputs/InputPhone';


type Model = OrganizationDto & UpdateOrganizationInfo;

export type Props = {
	data: Model;
	isEditMode: boolean;
	onSubmit: (data: Model) => Promise<HttpError | null>;
	onSuccess: () => void;
	onError: (message: string) => void;
	children: (renderEditor: () => JSX.Element, submitButtonClickEvent: () => void) => JSX.Element;
};

const OrganizationEditor = (props: Props) => {
	const { t } = useTranslation();
	const { t: tOrg } = useTranslation('', { keyPrefix: 'organization' });

	const [officeAddressSameAsLegalAddress, setOfficeAddressSameAsLegalAddress] = useState(false);
	const [mailingAddressSameAsLegalAddress, setMailingAddressSameAsLegalAddress] = useState(false);

	const {legalAddress, officeAddress, mailingAddress} = props?.data || {};

	useEffect(() => {
		setOfficeAddressSameAsLegalAddress(legalAddress == officeAddress);
		setMailingAddressSameAsLegalAddress(legalAddress == mailingAddress);
	}, [
		legalAddress, officeAddress, mailingAddress
	]);

	const renderDatePicker = (
		label: JSX.Element | string,
		name: string,
		required: boolean
	) => {
		const domName = name.replace('.', '_');

		const maxDate = DateTime.now(true);
		maxDate.addDays(-1);

		return (
			<label className={clsx(styles.noInputGroup, `control-label`)} htmlFor={domName}>
				{label}
				{required && <sup className="required" />}
					<LocalizableDatePicker
						name={name}
						maxDate={maxDate.toISOString(true)}
						showClearButton={true}
					/>
			</label>
		);
	};

	const renderStateCheckbox = (
		label: JSX.Element | string,
		name: string,
		checked: boolean,
		onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	) => {
		return (
			<div className="form-check">
				<input className="form-check-input" type="checkbox" id={name} checked={checked} onChange={onChange} />
				<label className={`${styles.formLabel} control-label`} htmlFor={name}>
					{label}
				</label>
			</div>
		);
	};

	const renderInput = (
		label: JSX.Element | string,
		name: string,
		required: boolean,
		formikProps: FormikProps<Model>,
		onChange?: (value: string) => void,
		isDisabled = false,
		isMultiline = false

	) => {
		return (
			<label className={clsx(styles.formLabel, `control-label`)} htmlFor={name}>
				{label}
				{required && <sup className="required" />}
				<Field name={name}>
					{({ field }) =>
						props.isEditMode ? (
							<>
								{!isMultiline ? (
									<input
										className={`${styles.formInput} form-control`}
										key={name}
										name={name}
										value={field.value || ''}
										onChange={(x) => {
											field.onChange(x);
											if (onChange) {
												onChange(x.target.value);
											}
										}}
										disabled={isDisabled}
									/>
								) : (
									<textarea
										className={`${styles.formInput} form-control`}
										key={name}
										id={name}
										name={field.name}
										value={field.value || ''}
										onChange={(x) => {
											field.onChange(x);
											if (onChange) {
												onChange(x.target.value);
											}
										}}
										disabled={isDisabled}
										rows={3}
									/>
								)}
								<ValidationMessage name={field.name} errors={formikProps.errors} />
							</>
						) : (
							<div className={styles.formText}>
								{!isNullOrEmptyString(field.value) ? field.value : ' - '}
							</div>
						)
					}
				</Field>
			</label>
		);
	};

	const renderEditor = (formikProps: FormikProps<Model>) => {
		return (
			<Row className={'form'}>
				<Col md={4}>
					<Row>
						<Col>
							<h2>{t('legalInfo')}</h2>
							<hr />
						</Col>
					</Row>

					<Row>
						<Col>
							<label className={clsx(styles.formLabel, `control-label`)}>
								{tOrg('uniqueNumber')}
								<input
									className={clsx(styles.formInput, 'form-control')}
									value={props?.data?.uniqueNumber}
									readOnly={true}
								/>
							</label>
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('vatNumber'),
								nameof.full<Model>((x) => x.vatNumber),
								true,
								formikProps
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('regNumber'),
								nameof.full<Model>((x) => x.regNumber),
								true,
								formikProps
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('fullName'),
								nameof.full<Model>((x) => x.fullName),
								true,
								formikProps
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('shortName'),
								nameof.full<Model>((x) => x.name),
								true,
								formikProps
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('legalAddress'),
								nameof.full<Model>((x) => x.legalAddress),
								true,
								formikProps,
								(value) => {
									if (officeAddressSameAsLegalAddress) {
										formikProps.setFieldValue(
											nameof.full<Model>((x) => x.officeAddress),
											value
										);
									}

									if (mailingAddressSameAsLegalAddress) {
										formikProps.setFieldValue(
											nameof.full<Model>((x) => x.mailingAddress),
											value
										);
									}
								}
							)}
						</Col>
					</Row>

					<Row>
						<Col>
							{renderInput(
								tOrg('mainActivity'),
								nameof.full<Model>((x) => x.mainActivity),
								false,
								formikProps
							)}
						</Col>
					</Row>

					<Row>
						<Col>
							{renderDatePicker(
								tOrg('dateIncorporation'),
								nameof<Model>((x) => x.dateIncorporation),
								true
							)}
						</Col>
					</Row>
				</Col>

				<Col md={4}>
					<Row>
						<Col>
							<h2>{t('contactInfo')}</h2>
							<hr />
						</Col>
					</Row>

					<Row>
						<Col>
							<Field name={nameof.full<Model>((x) => x.areDirectorAndAccountantTheSame)}>
								{({ field }) =>
									renderStateCheckbox(
										t('areDirectorAndAccountantTheSame'),
										'areDirectorAndAccountantTheSame',
										field.value,
										(e) => {
											formikProps.setFieldValue(
												nameof.full<Model>((x) => x.areDirectorAndAccountantTheSame),
												e.target.checked
											);
										}
									)
								}
							</Field>
						</Col>
					</Row>

					<Row>
						<Col>
							{renderInput(
								tOrg('websiteUrl'),
								nameof.full<Model>((x) => x.webSiteUrl),
								false,
								formikProps
							)}
						</Col>
					</Row>
					<Row className='organization-phone-row'>
						<Col>
                            <InputPhone label={tOrg('phone')} name={nameof.full<Model>((x) => x.phone)} required={true} formikProps={formikProps}/>
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								t('email'),
								nameof.full<Model>((x) => x.email),
								true,
								formikProps
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('officeAddress'),
								nameof.full<Model>((x) => x.officeAddress),
								true,
								formikProps,
								null,
								officeAddressSameAsLegalAddress,
								false
							)}
							{renderStateCheckbox(
								t('sameAsLegalAddress'),
								'officeAddressSameAsLegalAddress',
								officeAddressSameAsLegalAddress,
								(e) => {
									setOfficeAddressSameAsLegalAddress(e.target.checked);
									if(e.target.checked){
										formikProps.setFieldValue(
											nameof.full<Model>((x) => x.officeAddress),
											formikProps.values?.legalAddress
										);
									}
								}
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('mailingAddress'),
								nameof.full<Model>((x) => x.mailingAddress),
								true,
								formikProps,
								null,
								mailingAddressSameAsLegalAddress,
								false
							)}
							{renderStateCheckbox(
								t('sameAsLegalAddress'),
								'mailingAddressSameAsLegalAddress',
								mailingAddressSameAsLegalAddress,
								(e) => {
									setMailingAddressSameAsLegalAddress(e.target.checked);
									if(e.target.checked){
										formikProps.setFieldValue(
											nameof.full<Model>((x) => x.mailingAddress),
											formikProps.values?.legalAddress
										);
									}
								}
							)}
						</Col>
					</Row>
				</Col>

				<Col md={4}>
					<Row>
						<Col>
							<h2>{t('financialInfo')}</h2>
							<hr />
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('bankName'),
								nameof.full<Model>((x) => x.bankName),
								true,
								formikProps
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('bik'),
								nameof.full<Model>((x) => x.bic),
								true,
								formikProps
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('checkingAccount'),
								nameof.full<Model>((x) => x.checkingAccount),
								true,
								formikProps
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							{renderInput(
								tOrg('correspondentAccount'),
								nameof.full<Model>((x) => x.correspondentAccount),
								true,
								formikProps
							)}
						</Col>
					</Row>
					<Row>
						<Col>
						<label className={clsx(styles.formLabel, `control-label`)}>
							{tOrg('wayToGetAccountingDocs')}
							<WayToGetAccountingDocSelectField
								name={nameof.full<Model>(
									(x) => x.wayToGetAccountingDocs
								)}
								formikProps={formikProps}
								isEditMode={true}
							/>
						</label>
						</Col>
					</Row>
					{formikProps.values?.wayToGetAccountingDocs === WayToGetAccountingDoc.ByEdm && (
						<>
							<Row>
								<Col>
									{renderInput(
										tOrg('edmName'),
										nameof.full<Model>((x) => x.edmName),
										true,
										formikProps
									)}
								</Col>
							</Row>
							<Row>
								<Col>
									{renderInput(
										tOrg('edmId'),
										nameof.full<Model>((x) => x.edmId),
										true,
										formikProps
									)}
								</Col>
							</Row>
						</>
					)}
				</Col>

			</Row>
		);
	};

	const requiredMsg = t('validation.required');
	const emailReqMsg = t('validation.email');

	const validationSchema = useMemo(
		() =>
			object<Partial<Model>>().shape({
				email: string().nullable().email(emailReqMsg).required(requiredMsg),
				name: string().nullable().required(requiredMsg),
				fullName: string().nullable().required(requiredMsg),
				officeAddress: string().nullable().required(requiredMsg),
				bic: string().nullable().required(requiredMsg),
				bankName: string().nullable().required(requiredMsg),
				checkingAccount: string().nullable().required(requiredMsg),
				correspondentAccount: string().nullable().required(requiredMsg),
				legalAddress: string().nullable().required(requiredMsg),
				mailingAddress: string().nullable().required(requiredMsg),
				mainActivity: string().nullable().required(requiredMsg),
				webSiteUrl: string().nullable().required(requiredMsg),
				phone: string().nullable().required(requiredMsg),
				regNumber: string().nullable().required(requiredMsg),
				dateIncorporation: string().nullable().required(requiredMsg),
				wayToGetAccountingDocs: number().nullable(),
				edmName: string()
					.nullable()
					.when('wayToGetAccountingDocs',
						([x]) => {
						if (x === WayToGetAccountingDoc.ByEdm) {
							return string().nullable().required(requiredMsg);
						}
					}),
				edmId: string()
					.nullable()
					.when('wayToGetAccountingDocs', ([x]) => {
						if (x === WayToGetAccountingDoc.ByEdm) {
							return string().nullable().required(requiredMsg);
						}
					}),
			}),
		[i18next.language]
	);

	return (
		<Formik<Model>
			enableReinitialize
			initialValues={props.data as Model}
			validationSchema={validationSchema}
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onSubmit={async (values, { setSubmitting }) => {
				const result = await submitFormAndShowErrorsAsync(values, props.onSubmit, props.onError);
				if (!result.isError) {
					props.onSuccess();
				}
			}}
		>
			{(formikProps) => {
				return props.children(() => renderEditor(formikProps), formikProps.handleSubmit);
			}}
		</Formik>
	);
};

export default OrganizationEditor;