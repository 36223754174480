import * as React from "react";
import Select from "react-select";
import DropdownIndicator from "@components/select/components/DropdownIndicator";
import { LanguageDto } from '@models/languagesModels';
import SelectStyles from '@components/select/components/SelectStyles';

type Props = {
    availableOptions: LanguageDto[];
    selectedOption: LanguageDto;
    onChange: (value?: LanguageDto) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    placeholder?: any;
    isClearable?: boolean;
};

const LanguageSelect = (props: Props) => {
    const onChange = (value: LanguageDto) => {
        props.onChange(value);
    };

    return <Select
        isSearchable={false}
        components={{DropdownIndicator}}
        options={props.availableOptions}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        onChange={(opt) => {
            const option = opt as LanguageDto;
            onChange(option);
        }}
        value={props.selectedOption}
        placeholder={props.placeholder}
        styles={SelectStyles}
        isMulti={false}
        isClearable={!!props.isClearable}
    />;
};

export default LanguageSelect;