import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import SessionManager from '@root/SessionManager';
import { Loader } from '@components/index';
import { unwrap } from '@helpers/reduxHelpers';
import { toast } from 'react-toastify';
import { fetchOrganization, updateOrganization } from '@store/organizationsStore';
import { UpdateOrganizationInfo } from '@models/organizations';
import OrganizationEditor from '@scenes/accountSettings/organization/components/OrganizationEditor';
import { ContactPermissionType } from '@models/contacts/ContactPermissionType';

const OrganizationPage = () => {
	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	const { organization, isOrganizationFetching, updateInProgress } = useAppSelector((x) => x.organizations);

	useEffect(() => {
		if (SessionManager.user?.organizationId == null) {
			return;
		}
		if (SessionManager.user.organizationId == organization?.id) {
			return;
		}
		dispatch(fetchOrganization({ organizationId: SessionManager.user?.organizationId }));
	}, [SessionManager.user.organizationId]);

	const onSubmitEdit = async (model: UpdateOrganizationInfo) => {
		const result = await dispatch(updateOrganization(model));
		return unwrap(result);
	};

	return (
		<Row>
			<Col>
				{isOrganizationFetching ? (
					<Loader />
				) : (
					<OrganizationEditor
						data={organization}
						isEditMode={SessionManager.user.hasPermissions(
							ContactPermissionType.UpdateOrganizationInfo
						)}
						onSubmit={(x) => onSubmitEdit(x as UpdateOrganizationInfo)}
						onSuccess={() => toast.success(t('changesSaved'))}
						onError={(msg) => toast.error(msg)}
					>
						{(renderEditor, onClickSubmit) => {
							return (
								<>
									{renderEditor()}
									{SessionManager.user.hasPermissions(
										ContactPermissionType.UpdateOrganizationInfo
									) && (
										<div className={'text-center'}>
											<button
												className={'btn btn-primary mt-3'}
												onClick={(e) => {
													e.preventDefault();
													onClickSubmit();
												}}
												disabled={isOrganizationFetching || updateInProgress}
											>
												{t('save')}
											</button>
										</div>
									)}
								</>
							);
						}}
					</OrganizationEditor>
				)}
			</Col>
		</Row>
	);
};

export default OrganizationPage;