import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import AdminMenu from './components/AdminMenu';
import i18n from 'i18next';
import SessionManager from "@root/SessionManager";
import { UserRoles } from "@config/ApiAuthorizationConstants";
import PageHeader from '@components/PageHeader';

const AdminMainPage = () => {
	const { t } = useTranslation();

	const items = useMemo(() => {
		const menuItems = [ {
			name: t('admin.panel.logs'),
			description: t('admin.panel.logsDescription'),
			iconClassName: 'icon-file-text',
		},
			{
				name: t('admin.panel.referenceData'),
				description: t('admin.panel.referenceDataDescription'),
				iconClassName: ' icon-database',
				path: '/admin/dictionaries',
			},
			{
				name: t('admin.panel.modules'),
				description: t('admin.panel.modulesDescription'),
				iconClassName: 'icon-module',
				path: '/admin/settings',
			},
			{
				name: t('notifications.texts.plural'),
				description: t('notifications.settingsDescription'),
				iconClassName: 'icon-module',
				path: '/admin/notifications',
			},
		];

		if(SessionManager.user.hasAnyRole(UserRoles.serviceMode)) {
			menuItems.push({
				name: t('admin.panel.serviceMode'),
				description: t('admin.panel.serviceModeDescription'),
				iconClassName: 'icon-module',
				path: '/admin/service-mode',
			});
		}

		return menuItems;
	}, [i18n.language]);

	return (
		<>
			<PageHeader title={t('admin.panel.title')} />
			<AdminMenu items={items} />
		</>
	);
};

export default AdminMainPage;