import * as React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import s from './styles.module.scss';
import '@icomoon/style.css';
import { fetchSale, loadBookingConfirmation } from '@store/customerApplications/bookingConfirmationStore';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ReactComponent as Info } from '@material-design-icons/svg/round/info.svg';
import i18n from '@i18n';
import Loader from '@components/Loader/index';
import Currency from '@components/Currency';
import Tippy from '@tippyjs/react';
import { Badge, InfoCard } from '@root/components';
import getDate from '@helpers/dateTime/getDate';
import { BookingStatus, WeightCategory } from '@root/enum';
import { defaultCurrencyCode } from '@store/currencyStore';
import cn from 'classnames';
import { UnitOfUse } from '@models/tariffs';
import htmlParse from "html-react-parser";

export interface IProps {
  customerApplicationId: string;
}

const BookingConfirmationForm: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { bookingConfirmation: bc, isFetching, sale } = useAppSelector((x) => x.bookingConfirmation);
  const { selectedCode: currencyCode } = useAppSelector((x) => x.currencies);
  const { availableCurrencies } = useAppSelector((x) => x.currencies);
  const { customerApplication } = useAppSelector((x) => x.customerApplications);
  const baseCurrencyCode = availableCurrencies.find((x) => x.isBaseCurrency)?.code;
  const parsedDescription = htmlParse(`${bc?.productDescription || '-'}`);

  React.useEffect(() => {
    dispatch(
      loadBookingConfirmation({ customerApplicationId: props.customerApplicationId }),
    );
  }, []);

  React.useEffect(() => {
    dispatch(
      fetchSale({ saleId: customerApplication?.saleId, currencyCode }),
    );
  }, [bc]);

  const columnTitles = React.useMemo(() => {
    const i18tColumnNames = [
      'bookingConfirmation.flightNo',
      'bookingConfirmation.originalAirport',
      'bookingConfirmation.destination',
      'bookingConfirmation.departureTime',
      'bookingConfirmation.arrivalTime',
      'bookingConfirmation.dimensions',
      'bookingConfirmation.bookingStatus',
    ];
    return <>{i18tColumnNames.map((x, i) => <span className={s.header} key={i}>{t(x)}</span>)}</>;
  }, [i18n.language]);

  const statusName = (statusId: BookingStatus): string => {
    switch (statusId) {
      case BookingStatus.NN:
        return t('bookingConfirmation.nn');
      case BookingStatus.KK:
        return t('bookingConfirmation.kk');
      case BookingStatus.LL:
        return t('bookingConfirmation.ll');
      case BookingStatus.CN:
        return t('bookingConfirmation.cn');
      default:
        return '';
    }
  };

  const useTariffName = (weightCategoryId: WeightCategory): string => {
    let tariffName: string;

    switch (weightCategoryId) {
      case WeightCategory.Minimal:
        tariffName = t('bookingConfirmation.min');
        break;
      case WeightCategory.Normal:
        tariffName = t('bookingConfirmation.normal');
        break;
      case WeightCategory.Tarif_45_Plus:
        tariffName = '45+';
        break;
      case WeightCategory.Tarif_100_Plus:
        tariffName = '100+';
        break;
      case WeightCategory.Tarif_250_Plus:
        tariffName = '250+';
        break;
      case WeightCategory.Tarif_300_Plus:
        tariffName = '300+';
        break;
      case WeightCategory.Tarif_500_Plus:
        tariffName = '500+';
        break;
      case WeightCategory.Tarif_1000_Plus:
        tariffName = '1000+';
        break;
      case WeightCategory.Tarif_1500_Plus:
        tariffName = '1500+';
        break;
      default:
        tariffName = t('bookingConfirmation.min');
        break;
    }

    return `${t('bookingConfirmation.tariffHeader')} ${tariffName}`;
  };

  return (
    <>
      {isFetching ? <Loader /> : (
        <Alert className={s.alert}>
          {bc ? (
              <div className={s.container}>
                <div className={s.row}>
                  <div className={s.conditions}>
                    <InfoCard fullHeight title={t('bookingConfirmation.conditions')}>
                      <InfoCard description={t('bookingConfirmation.tariffHeader')}>
                        <span>
                          <Badge label={bc?.productName} color='blue' />
                            <div>
                                {parsedDescription || ''}
                            </div>
                        </span>
                      </InfoCard>

                      {bc.restrictions?.length > 0 && (
                        <InfoCard description={t('bookingConfirmation.limits')}>
                          {bc.restrictions.map((v, i) => (
                            <div key={i}>{v.description}</div>
                          ))}
                        </InfoCard>
                      )}
                      {sale?.airlineDescription2 &&
                        <InfoCard expandable
                                  description={t('requestSearchResults.airlinesNoteAndConditions')}>
                          <pre className='preformatted'>
                              {sale?.airlineDescription2}
                            </pre>
                        </InfoCard>
                      }
                      {sale?.airlineDescription &&
                        <InfoCard expandable
                                  description={t('requestSearchResults.generalNotesAndCondition')}>
                            <pre className='preformatted'>
                              {sale?.airlineDescription}
                            </pre>
                        </InfoCard>
                      }
                    </InfoCard>
                  </div>
                  <div className={s.fees}>
                    <InfoCard title={t('bookingConfirmation.tariff')}>
                      <div className={s.feeRow}>
                        <span>{`${useTariffName(bc?.weightCategoryId)}`}</span>
                        <span><Currency amount={bc?.tariffRate}
                                        currencyCode={bc?.currencyCode} />{bc?.weightCategoryId !== 852250010 && (` | ${t('units.kg')}`)}</span>
                      </div>
                      {bc.allIn &&
                        <div className={s.feeRow}>
                          <span>{t('bookingConfirmation.allIn')}</span>
                        </div>
                      }
                      {bc.weightCategoryId !== WeightCategory.Minimal &&
                        <div className={s.feeRow}>
                            <span className={s.feeRowNameTariffBold}>
                              {t('requestSearchResults.totalTariff')}
                            </span>
                          <Currency amount={bc?.tariffRate * sale?.chargeableWeight} currencyCode={sale?.currencyCode} />
                        </div>
                      }
                      {bc.fees.map((x, i) =>
                        <div key={i} className={s.feeRow}>
                                                        <span className={s.feeRowNameTariff}>
                                                        {x.code}
                                                          {x.name && (
                                                            <Tippy content={x.name}>
                                                              <Info className={s.infoIcon} />
                                                            </Tippy>
                                                          )}
                                                        </span>
                          <span>
                                                            <Currency amount={x.total} currencyCode={x.currencyCode} />
                            {' ('}
                            <Currency currencyCode={x.currencyCode} amount={x.value} />
                            {' | '}
                            {t((x.unitOfUseId === UnitOfUse.PhysicalWeight || x.unitOfUseId === UnitOfUse.PaidWeight) ? 'units.kg' : 'units.unit')}
                            {')'}
                                                            </span>
                        </div>,
                      )}
                      <div className={s.feeRow}>
                                                <span className={s.feeRowNameTariffBold}>
                                                    {t('requestSearchResults.totalSurcharges')}
                                                </span>
                        <Currency amount={sale?.surchargesTotal}
                                  currencyCode={sale?.currencyCode} />
                      </div>
                    </InfoCard>
                    <InfoCard title={t('payment.title')}>
                      {sale?.currencyCode !== baseCurrencyCode &&
                        <span>
                                {t('requestSearchResults.paymentTerms', {
                                  exchangeType: sale?.exchangeType,
                                  currencyCode: sale?.currencyCode,
                                  cbPlus: sale?.cbPlusPercent,
                                  paymentOn: sale?.paymentOn,
                                })}
                              </span>
                      }
                      {sale?.isConverted && !(sale?.currencyCode !== baseCurrencyCode) &&
                        <span>{t('requestSearchResults.paymentTermsForBaseCurrency')}</span>
                      }
                      {sale?.currencyCode === defaultCurrencyCode &&
                        <span>{t('requestSearchResults.PaymentIsMadeBasedOnTheIssuedInvoice')}</span>
                      }
                    </InfoCard>
                    {bc.optionalFees && bc.optionalFees.length > 0 &&
                      <InfoCard title={t('bookingConfirmation.optionalFees')}
                                classNames={s.paymentCard}>
                        {bc.optionalFees.map((f, key) =>
                          <div key={key} className={s.feeRow}>
                            {f.value === 0 && f.description &&
                              <span className={s.feeColumn}>{f.description}</span>}
                          </div>,
                        )}
                      </InfoCard>
                    }
                  </div>
                </div>
                {bc?.cargoRoutes && bc?.cargoRoutes.length > 0 &&
                  <InfoCard title={t('bookingConfirmation.flightDetails')}>
                    <div className={s.logContainer}>
                      <div className={s.separateHeader}>{columnTitles}</div>
                      {(bc?.cargoRoutes).map((cr, i) => (
                        <div key={i} className={s.rowRoute}>
                          <div className={s.data}>
                                                        <span
                                                          className={s.header}>{t('bookingConfirmation.flightNo')}</span>
                            <span className={s.column}>
                                  <Badge label={`${cr.airlineCode}${cr.flightNo}`} color='blue' />
                                </span>
                          </div>
                          <div className={s.data}>
                                                        <span
                                                          className={s.header}>{t('bookingConfirmation.originalAirport')}</span>
                            <span className={s.column}>
                                  <Badge label={cr.originAirportCode} color='white' />
                                </span>
                          </div>
                          <div className={s.data}>
                                                        <span
                                                          className={s.header}>{t('bookingConfirmation.destination')}</span>
                            <span className={s.column}>
                                  <Badge label={cr.destinationAirportCode} color='white' />
                                </span>
                          </div>
                          <div className={s.data}>
                                                        <span
                                                          className={s.header}>{t('bookingConfirmation.departureTime')}</span>
                            <span className={s.column}>
                                  {getDate(cr.departureTime)}
                                </span>
                          </div>
                          <div className={s.data}>
                                                        <span
                                                          className={s.header}>{t('bookingConfirmation.arrivalTime')}</span>
                            <span className={s.column}>
                                  {getDate(cr.arrivalTime)}
                                </span>
                          </div>
                          <div className={s.data}>
                                                        <span
                                                          className={s.header}>{t('bookingConfirmation.dimensions')}</span>
                            <span className={cn(s.column, s.dimensions)}>
                                  <Badge label={`${cr?.pieces?.toFixed(0)}${t('units.pc')}`} color='gray' />
                                  <Badge label={`${cr?.weight?.toFixed(1)}${t('units.kg')}`} color='gray' />
                                  <Badge label={`${cr?.volume?.toFixed(3)}${t('units.mc')}`} color='gray' />
                                </span>
                          </div>
                          <div className={s.data}>
                                                        <span
                                                          className={s.header}>{t('bookingConfirmation.bookingStatus')}</span>
                            <span className={s.column}>
                                  <Badge label={statusName(cr.bookingStatusId)} color='white' />
                                </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </InfoCard>
                }
              </div>
            ) :
            t('bookingConfirmation.detailsWillBeSoon')
          }
        </Alert>
      )}
    </>
  );
};

export default BookingConfirmationForm;