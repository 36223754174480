import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationPaths } from '@config/ApiAuthorizationConstants';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

enum ErrorTypes {
  UserNotRegister = 1,
  ContactNotAllowedToLogin = 2,
}

type Props = WithTranslation & RouteComponentProps<RequestProps>;

interface RequestProps {
  email: string;
  errorType: string;
}

class ExternalLoginFailed extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    const email = this.props.match.params.email;
    const errorType = +this.props.match.params.errorType;

    switch (errorType) {
      case ErrorTypes.ContactNotAllowedToLogin:
        return <div>{this.props.t('externalLogin.accessDenied')}</div>;
      case ErrorTypes.UserNotRegister:
        return (
          <div>
            {this.props
              .t('externalLogin.userNotExist')
              .replace('{email}', email)}{' '}
            <Link to={ApplicationPaths.Register}>
              {this.props.t('externalLogin.register')}
            </Link>
          </div>
        );
    }
  }
}

export default withTranslation()(withRouter(ExternalLoginFailed));
