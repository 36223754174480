import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { GetCustomerRequestHistoryItemDto } from '@models/customerRequests/customerRequestHistoryModels';
import CustomerRequestsService from '@services/CustomerRequestsService';

export interface IState {
	isFetching: boolean;
	pagingWrapper: IPagingWrapper<GetCustomerRequestHistoryItemDto>;
}

export const getRequestHistoryData = createAsyncThunk('customerRequestsHistory/getHistoryData', async (nav: StandardNavigation) => {
	const {data: pagingWrapper} = await new CustomerRequestsService().getHistory(nav);
	return pagingWrapper;
})

// Create the slice.
const slice = createSlice({
	name: 'customerRequestsHistory',
	initialState: {
		isFetching: true,
		pagingWrapper: {
			items: [],
			totalCount: 0
		}
	} as IState,
	reducers: {
		setFetching: (state: IState, action: PayloadAction<boolean>) => {
			state.isFetching = action.payload;
		},
		setData: (state: IState, action: PayloadAction<{
			pagingWrapper: IPagingWrapper<GetCustomerRequestHistoryItemDto>
		}>) => {
			state.pagingWrapper = action.payload.pagingWrapper;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getRequestHistoryData.pending,
				state => {
					state.isFetching = true;
					state.pagingWrapper = null;
				})
			.addCase(getRequestHistoryData.rejected,
				state => {
					state.isFetching = false;
				})
			.addCase(getRequestHistoryData.fulfilled,
				(state, action) => {
					state.isFetching = false;
					state.pagingWrapper = action.payload;
				});
	}
});

export const {reducer} = slice;
export const {actions} = slice;
