import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";
import { AdminTopNavBlock } from "@scenes/admin/components/AdminTopNavBlock";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getServiceModeState, updateServiceModeState } from "@store/settingsStore";
import { useAppDispatch, useAppSelector } from "@root/store";
import styles from './index.module.scss';
import { DateTimePicker } from "@scenes/admin/components/DateTimePicker";
import moment from "moment";
import PageHeader from '@components/PageHeader';

type Model = {
	startServiceMode?: Date;
	endServiceMode?: Date;
}

export const ServiceModePage = () => {
	const history = useHistory();
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {startServiceMode, endServiceMode, isFetching} = useAppSelector(appState => appState.settings);
	const [ hasServiceMode, setHasServiceMode ] = useState(false);
	const [ isValid, setValid ] = useState(false);
	const [ start, setStart ] = useState<Date>(null);
	const [ end, setEnd ] = useState<Date>(null);
	const now = new Date();

	useEffect(() => {
		dispatch(getServiceModeState());
	}, []);

	useEffect(() => {
		const hasServiceMode = startServiceMode != null && endServiceMode != null;
		setHasServiceMode(hasServiceMode);
		setStart(startServiceMode != null ? new Date(startServiceMode) : null);
		setEnd(endServiceMode != null ? new Date(endServiceMode) : null);
	}, [ startServiceMode, endServiceMode ]);

	useEffect(() => {
		const hasServiceMode = start != null && end != null;
		const isValid = hasServiceMode && start < end;
		setValid(isValid);
	}, [ start, end ]);

	return <>
		<PageHeader title={t('admin.panel.serviceMode')} />
		<Row>
			<Col>
				<AdminTopNavBlock
					backButtonText={t('admin.backToPanel')}
					onClickBackButton={() => history.push('/admin/panel')}
				/>
			</Col>
		</Row>
		<Row>
			<Col>
				<h3>{t('admin.panel.serviceMode')}</h3>
				{hasServiceMode
					? <>
						{t('admin.panel.serviceModePeriod', {start: moment(start).format('LLL'), end: moment(end).format('LLL')})}
						{end <= now && '. '}
						{end <= now && t('admin.panel.serviceModeExpired')}
					</>
					: t('admin.panel.noServiceMode')}
			</Col>
		</Row>
		<div>
			<Row className={'mt-4'}>
				<Col>
					<div className={styles.dateWithTime}>
						<DateTimePicker
							name={'start'}
							minDate={new Date()}
							value={start}
							onChange={date => setStart(date)}/>
					</div>
					<div className={styles.dateWithTime}>
						<DateTimePicker
							name={'end'}
							minDate={start}
							value={end}
							onChange={date => setEnd(date)}/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col style={{display: "flex", gap: '1rem'}}>
					<button className='btn btn-primary mt-4' type="submit" onClick={(e) => {
						e.preventDefault();
						dispatch(updateServiceModeState({startServiceMode: start, endServiceMode: end}));
					}}
							disabled={isFetching || !isValid}>
						{t('save')}
					</button>
                    {hasServiceMode && <button className='btn btn-danger mt-4' type="button" onClick={(e) => {
                        e.preventDefault();
                        dispatch(updateServiceModeState({startServiceMode: null, endServiceMode: null}));
                    }}
                             disabled={isFetching || !isValid}>
                        {t('admin.panel.serviceModeCancel')}
                    </button>}
				</Col>
			</Row>
		</div>
	</>;
}