import { CreateCustomerRequest, GetCustomerRequestDto } from '@models/customerRequests/customerRequestModels';
import { createAppThunk } from '@helpers/reduxHelpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomerRequestsService from '@services/CustomerRequestsService';
import { CreateCustomerApplication } from '@models/customerApplications/customerApplicationModels';

export type State = {
    isFetching: boolean;
    customerRequest?: GetCustomerRequestDto;
};

export const fetchCustomerRequest = createAppThunk(
    'customerRequests/fetchCustomerRequest',
    async (arg: {id: string}) => {
        const {data} = await new CustomerRequestsService().getSearchRequest(arg.id);
        return data;
    }
);

export const createCustomerRequest = createAppThunk(
    'customerRequests/createCustomerRequest',
    async (arg: CreateCustomerRequest) => {
        const {data} = await new CustomerRequestsService().createSearchRequest(arg);
        return data;
    }
);

export const createQuotationRequest = createAsyncThunk(
	'createQuotationRequest',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async (arg: { model: CreateCustomerApplication }, thunkAPI) => {
		const { data: result } = await new CustomerRequestsService().requestQuotation(arg.model);

		return result;
	}
);

const slice = createSlice({
    name: 'customerRequests',
    initialState: {
        isFetching: false
    } as State,
    reducers: {
        resetCustomerRequest(state){
            state.customerRequest = null;
            state.isFetching = false;
        }
    },
    extraReducers: builder => {

        builder.addCase(
            fetchCustomerRequest.pending,
            (state) => {
                state.isFetching = true;
            });
        builder.addCase(
            fetchCustomerRequest.fulfilled,
            (state, action) => {
                state.isFetching = false
                state.customerRequest = action.payload;
            });
        builder.addCase(
            fetchCustomerRequest.rejected,
            (state, action) => {
                state.isFetching = false
                state.customerRequest = null;
            });
    }
});

export const {reducer} = slice;
export const {actions} = slice;
