import React, { ReactNode } from 'react';
import { ReactComponent as IconNoData } from '@material-design-icons/svg/round/inbox.svg';
import cs from '@assets/styles/common.module.scss';
import s from './style.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface IEmptyProps {
  text?: string;
  description?: string;
  icon?: ReactNode;
}

const Empty: React.FC<IEmptyProps> = ({ icon, text, description }) => {
  const {t} = useTranslation();
  
  return (
    <div className={cn(cs.flex, cs.justifyCenter)}>
      <div className={cn(s.container)}>
        {icon || <IconNoData fill='currentColor' />}
        <div className={cn(cs.flexColumn, cs.alignCenter)}>
          <h1 className={s.header}>{text || t('customerRequestsHistory.nothingFound')}</h1>
          <span className={s.description}>{description || t('changeRequestParams')}</span>
        </div>
      </div>
    </div>
  );
};

export default Empty;