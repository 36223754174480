import React, { PropsWithChildren, ReactNode } from 'react';
import s from './paramCard.module.scss';
import cn from 'classnames';

export interface IInfoCardProps {
  icon: ReactNode;
  paramName: string;
  fullWidth?: boolean;
  classList?: string;
}

const ParamCard: React.FC<PropsWithChildren<IInfoCardProps>> = ({ icon, fullWidth, paramName, classList, children }) => {
  return (
    <div className={cn(s.card, classList, fullWidth && s.fullWidth)}>
      {icon &&
        <div className={s.cardIcon}>
          {icon}
        </div>
      }
      <div className={s.cardContent}>
        <span className={s.cardContentParam}>
          {paramName}
        </span>
        <div className={s.cardContentValue}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ParamCard;
