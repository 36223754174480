import AppModal from '@components/AppModal';
import styles from '@components/Upload.module.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AirlineDictionaryItemDto } from '@models/airlines';
import { UploadImage } from '@scenes/admin/dictionaries/airlines/components/UploadImage';
import { HttpError, UploadProgress } from '@models/shared';
import { toast } from 'react-toastify';
import { Progress } from 'reactstrap';

type Props = {
    isOpen: boolean;
    onUpload: (file: File, uploadProgress: UploadProgress) => Promise<string & HttpError>;
    onClickCloseButton: () => void;
    airline?: AirlineDictionaryItemDto;
};

export enum UploadingMode {
    None,
    Uploading,
    Completed
}

const UploadImageModal = (props: Props) => {
    const [imageUploadMode, setImageUploadMode] = useState<UploadingMode>(UploadingMode.None);
    const [imageUploadProgress, setImageUploadProgress] = useState(0);
    const [fileToUpload, setFileToUpload] = useState<File>(null);
    const { t } = useTranslation();

    const uploadFile = async (file: File) => {
        setImageUploadMode(UploadingMode.Uploading);
        const response = await props.onUpload(file, onUploadProgress);

        if (!response.isError) {
            setImageUploadMode(UploadingMode.Completed);
        } else {
            alert(`Error occured: ${response.message}`);
        }
    };

    useEffect(() => {
        if (fileToUpload == null) {
            return;
        }

        uploadFile(fileToUpload);
    }, [fileToUpload]);

    const onUploadProgress: UploadProgress = (e) => {
        const percents = Math.round((100 * e.loaded) / e.total);
        if (percents == 0) {
            return;
        }

        setImageUploadProgress(25);
        setTimeout(() => {
            setImageUploadProgress(50);
            setTimeout(() => {
                if (percents == 100) {
                    setImageUploadProgress(100);
                    setImageUploadMode(UploadingMode.Completed);
                } else if (percents > 50) {
                    setImageUploadProgress(percents);
                }
            }, 1000);
        }, 2000);
    };


    const RenderImageControl = () => {

        switch (imageUploadMode){
            case UploadingMode.None:
                return <UploadImage
                    imageId={props.airline?.logoId}
                    onChange={file => setFileToUpload(file)} />;

            case UploadingMode.Uploading:
                return <>
                    <div className="text-center">{imageUploadProgress}%</div>
                    <Progress value={imageUploadProgress} />
                </>;

            case UploadingMode.Completed:
                return <>
                    <div className={styles.text}>{t('upload.uploadSuccessful')}</div>
                </>;
        }
    };

    return <AppModal
        isOpen={props.isOpen}
        body={<div className={styles.modalBody}>

            <div className={styles.title}>{t('admin.airlines.uploadLogoModalTitle')}: {props.airline?.name}</div>

            <RenderImageControl/>

        </div>}
        onClickCloseButton={() => {
            if (imageUploadMode == UploadingMode.Uploading) {
                toast.warning(t('upload.uploadingWarning'));
                return;
            }

            props.onClickCloseButton();
        }}
    />;
};

export default UploadImageModal;