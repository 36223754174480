import { AddressDatumDto } from '@models/awbs/awbsModels';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import AddressDataService from '@services/AddressDataService';
import { Col, Row, Table } from 'reactstrap';
import clsx from 'clsx';
import styles from './index.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import { AddressDatumModal } from '@scenes/customerApplication/awb/components/AddressDatumModal';
import ValidationMessage from '@components/ValidationMessage';
import { useTranslation } from 'react-i18next';
import { AppSkeleton, Button } from '@root/components';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
import { ReactComponent as ClearIcon } from '@material-design-icons/svg/round/clear.svg';
import { ReactComponent as AddIcon } from '@material-design-icons/svg/round/add.svg';
import { ReactComponent as EditIcon } from '@material-design-icons/svg/round/edit.svg';
import { Modal } from '@components/Modal';
import Input from '@components/Input';
import { RenderAddressRow } from './components/RenderAddressRow';

interface IAddressControl {
  idFieldName: string;
  onChange?: (model: AddressDatumDto) => void;
  title: string;
  scannedDataFieldName?: string;
  lookupFieldName?: string;
  showAccountNumber?: boolean;
  allowEmptyAccountNumberTitle?: string;
  disabled?: boolean;
}

export const AddressControl = (props: IAddressControl) => {
  const context = useFormikContext<AddressDatumDto>();
  const { errors } = context;
  const [field, _, helpers] = useField(props.idFieldName);
  const [scannedField] = useField(props.scannedDataFieldName);
  const [selectedAddress, setSelectedAddress] = useState<AddressDatumDto>(null);
  const [selectedInListAddress, setSelectedInListAddress] = useState<AddressDatumDto>(null);
  const [addressData, setAddressData] = useState<AddressDatumDto[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [scannedData, setScannedData] = useState('');
  const [addressAsString, setAddressAsString] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    let isCurrent = true;
    const fetchAddress = async (id: string): Promise<AddressDatumDto> => {
      let address = null;

      if (id != null && address?.id != id) {
        const data = await new AddressDataService().get(id);
        address = data.data;
      }

      return address;
    };

    setSelectedAddress(null);

    fetchAddress(field.value)
      .then(address => {
        if (isCurrent) {
          setSelectedAddress(address);
        }
      });

    return () => {
      isCurrent = false;
    };
  }, [field.value]);

  useEffect(() => {
    const scannedValue = props.scannedDataFieldName && scannedField ? (scannedField.value || '') : '';
    if (scannedValue != scannedData)
      setScannedData(scannedValue);
  }, [scannedField.value, props.scannedDataFieldName]);

  useEffect(() => {
    if (selectedAddress == null) {
      return;
    }

    const fields: string[] = [];
    if (selectedAddress.isRequiredAccountNumber) {
      fields.push(selectedAddress.accountNumber);
    }
    fields.push(selectedAddress.name);

    const cityName = selectedAddress.city?.name || selectedAddress.cityName || '';
    if (cityName.length > 0) {
      fields.push(cityName);
    }

    fields.push(selectedAddress.stateProvince || '');
    fields.push(selectedAddress.postCode || '');
    fields.push(selectedAddress.address || '');
    fields.push(selectedAddress.contact || '');
    setAddressAsString(fields.filter(v => (v || '').length > 0).join(', '));

  }, [selectedAddress]);

  useEffect(() => {
    let isCurrent = true;
    searchAddressesDebounced(searchTerm, (data) => {
      if (isCurrent) {
        setAddressData(data);
      }
    });

    return () => {
      isCurrent = false;
    };
  }, [searchTerm]);

  const searchAddressesDebounced = useDebouncedCallback((term: string, callback: (data: AddressDatumDto[]) => void) => {
    setSelectedInListAddress(null);
    const fetchAddresses = async () => {
      const { data } = await new AddressDataService().getItems(term);
      return data;
    };

    fetchAddresses()
      .then((data) => {
        callback(data);
      });
  });
  const updateAddress = (addressDatum: AddressDatumDto) => {
    setSelectedAddress(addressDatum);
    helpers.setValue(addressDatum?.id);
    if (props.lookupFieldName) {
      context.setFieldValue(props.lookupFieldName, addressDatum)
        .then(() => {
          context.validateForm();
        });
    }
  };

  const onAddressClose = (addressDatum: AddressDatumDto) => {
    setIsModalOpen(false);
    if (addressDatum != null) {
      searchAddressesDebounced(searchTerm, (data) => {
        setAddressData(data);
        setSelectedInListAddress(data.find(item => item.id === addressDatum.id));
      });
    }
  };

  const onClose = () => {
    setSelectedInListAddress(null);
    setIsSearchOpen(false);
  };

  return (<div data-cy={props.idFieldName}>
    <h3>{props.title}</h3>
    <Row>
      <Col>
        <div className={styles.lookupField} data-cy={'address-select'}>
          <Input
            disabled
            placeholder={props.title}
            value={selectedAddress?.name || ''}
          />
          {!props.disabled &&
            <Button
              type='fill'
              variant='primary'
              icon={<SearchIcon fill='currentColor' />}
              onClick={() => {
                setSelectedInListAddress(selectedAddress);
                setIsSearchOpen(true);
              }} />}
          {!props.disabled && selectedAddress &&
            <Button
              type='fill'
              variant='secondary'
              icon={<ClearIcon fill='currentColor' />}
              onClick={() => {
                updateAddress(null);
              }} />}
        </div>
        <ValidationMessage name={field.name} errors={errors} />
        {props.lookupFieldName && errors[props.lookupFieldName] &&
          <div>
            <span className='validationMessage'>{t('validation.invalidAddress')}</span>
          </div>
        }
      </Col>
    </Row>
    <Row>
      <Col>
        {(scannedData || addressAsString) &&
          <Input
            type='textarea'
            value={scannedData || addressAsString}
            data-cy={'scanned-data'}
          />
        }
      </Col>
    </Row>
    {isSearchOpen &&
      <Modal
        wide
        header='Наименование и адрес получателя'
        onConfirm={() => {
          updateAddress(selectedInListAddress);
          setSelectedInListAddress(null);
          setIsSearchOpen(false);
        }}
        disabled={selectedInListAddress == null}
        confirmText={t('select')}
        open={true}
        onClose={onClose}>
        <div className={styles.addressSearch}>
          <Input
            value={searchTerm}
            placeholder='Поиск'
            onChange={(event) => setSearchTerm(event.target.value)} />

          <div className={styles.toolbar}>
            <Button
              type='fill'
              variant='primary'
              onClick={() => {
                setSelectedInListAddress(null);
                setIsModalOpen(true);
              }}
              icon={<AddIcon fill='currentColor' />}
              title={t('add')}
            >
              {t('add')}
            </Button>
            {selectedInListAddress !== null &&
              <Button
                type='fill'
                variant='secondary'
                disabled={selectedInListAddress == null}
                onClick={() => setIsModalOpen(true)}
                title={t('awb.edit')}
                icon={<EditIcon fill='currentColor' />}
              >
                {t('awb.edit')}
              </Button>
            }
          </div>
          {addressData.length > 0 ?
            (
              <div className={styles.addressesTable}>
                <Table
                  hover
                  size='sm'
                  responsive
                >
                  <thead>
                  <tr>
                    <th>Название</th>
                    <th>ИНН</th>
                    <th>Дополнительная информация</th>
                  </tr>
                  </thead>
                  <tbody>

                  {addressData.map(item =>
                    <RenderAddressRow
                      key={item.id}
                      addressDatum={item}
                      isSelected={selectedInListAddress?.id == item.id}
                      onSelectionChange={() => setSelectedInListAddress(selectedInListAddress?.id == item.id ? null : item)}
                    />)}
                  </tbody>
                </Table>
              </div>
            )
            : (<AppSkeleton count={10} mode='row' />)
          }

          <Input value={scannedData} />
        </div>
      </Modal>}
    {isModalOpen &&
      <AddressDatumModal
        id={selectedInListAddress?.id || field.value}
        scannedData={selectedInListAddress != null ? '' : scannedData}
        showAccountNumber={props.showAccountNumber}
        allowEmptyAccountNumberTitle={props.allowEmptyAccountNumberTitle}
        title="Новый адрес"
        onClose={(addressDatum) => onAddressClose(addressDatum)}
      />
    }
  </div>);
};
