import React from 'react';
import cn from 'classnames';
import styles from './loader.module.scss';

interface ILoaderProps {
	className?: string;
}

const Loader: React.FC<ILoaderProps> = ({ className }) => {
	return (
		<div className={cn(styles.loaderWrap, className)}>
			<div className={styles.loader} />
		</div>
	);
};

export default Loader;
