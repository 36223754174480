import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@root/store';
import { getTariffsDictionary } from '@store/tariffsStore';

import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { TariffDictionaryItem } from '@models/tariffs';
import { Col, Row } from 'reactstrap';
import dictionaryStyles from '../../components/DictionaryStyles.module.scss';
import clsx from 'clsx';
import {
    FilterConnection,
    FilterOperator,
    FilterValue,
    FilterValueCondition,
} from '@models/entityNavigation/filtering';
import debounce from 'awesome-debounce-promise';
import nameof from 'ts-nameof.macro';
import { PropertySorter } from '@models/entityNavigation/sorting';
import Paginator from '@components/paginator/Paginator';
import { Loader } from '@components/index';

import TariffItem from '@scenes/admin/dictionaries/tariffs/components/TariffItem';

import { useTranslation } from 'react-i18next';
import PageHeader from '@components/PageHeader';

type NavState = {
    navigation: StandardNavigation;
    codeFilterValue: FilterValue;
    sorter: PropertySorter;
};

const createNavState = (): NavState => {
    const navigation = new StandardNavigation();

    const fcnOr = new FilterConnection(FilterOperator.Or);

    const codeFilterValue = new FilterValue(
        nameof.full<TariffDictionaryItem>((x) => x.code),
        FilterValueCondition.Contains
    );

    fcnOr.values.push(codeFilterValue);

    navigation.filters.push(fcnOr);

    // Create sorters.

    const sorter = new PropertySorter();

    navigation.sorters.push(sorter);

    return {
        navigation,
        codeFilterValue,
        sorter,
    };
};

let navState: NavState = null;

const TarrifsPage = (): JSX.Element => {
    const { isFetching, tariffs } = useAppSelector((x) => x.tariffs);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (navState === null) {
            navState = createNavState();
        }

        dispatch(getTariffsDictionary(navState.navigation));
    }, []);

    const getDebounced = debounce(
        (navigation: StandardNavigation, resetPaging: boolean) => {
            if (resetPaging) {
                navigation.pagingFilter.pageNumber = 0;
            }
            dispatch(getTariffsDictionary(navigation));
        },
        800
    );

    const onSearchByTerm = (term: string): void => {
        term = term?.trim() ?? null;

        navState.codeFilterValue.value = term;
        getDebounced(navState.navigation, true);
    };

    const onChangePage = (pageNumber: number): void => {
        navState.navigation.pagingFilter.pageNumber = pageNumber;
        getDebounced(navState.navigation, false);
    };

    const { t } = useTranslation();

    return (
        <>
            <PageHeader title={t('admin.dictionaries.tariffs')} backUrl="/admin/dictionaries" />
            <Row className="mb-3">
                <Col md={12}>
                    <input
                        type="text"
                        className={clsx('form-control', dictionaryStyles.termFilter)}
                        placeholder={t('admin.tariffs.search')}
                        onKeyUp={(e): void =>
                            onSearchByTerm(e.currentTarget.value)
                        }
                    />
                </Col>
            </Row>

            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <Row>
                        <Col>
                            <table
                                className={clsx(
                                    'table table-hover table-responsive-sm',
                                    dictionaryStyles.table
                                )}
                            >
                                <thead>
                                    <tr>
                                        <th>{t('admin.tariffs.code')}</th>
                                        <th>{t('admin.tariffs.type')}</th>
                                        <th>{t('admin.tariffs.departureAirportCode')}</th>
                                        <th>{t('admin.tariffs.destinationAirportCode')}</th>
                                        <th>{t('admin.tariffs.airlineCode')}</th>
                                        <th>{t('admin.tariffs.currencyCode')}</th>
                                        <th>{t('admin.tariffs.product')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tariffs?.items?.map((tariff, index) => (
                                        <TariffItem
                                            key={index}
                                            tariff={tariff}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Paginator
                                totalResults={tariffs?.totalCount}
                                limitPerPage={
                                    navState?.navigation?.pagingFilter.pageSize
                                }
                                currentPage={
                                    navState?.navigation?.pagingFilter
                                        .pageNumber + 1
                                }
                                onChangePage={(p): void => onChangePage(p)}
                                pageNeighbours={4}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default TarrifsPage;
