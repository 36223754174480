import nameof from 'ts-nameof.macro';
import { TableRow } from './models';
import { objectValuesAreEmptyCheck } from '@helpers/objectHelpers';

export const defaultRowValidatorFactory = <TModel>(...keysToCheck: string[]) => (model: TableRow<TModel>): boolean => {
    for (const item of keysToCheck) {
        const keyValue = model[item];
        if (keyValue == null || keyValue.toString()?.trim() == '') {
            return false;
        }
    }
    return true;
};

export const defaultRowEmptyCheck = <TModel>(model: TableRow<TModel>): boolean => {

    const excludedKeys = [
        nameof<TableRow<TModel>>(x => x.id),
        nameof<TableRow<TModel>>(x => x.isValid),
        nameof<TableRow<TModel>>(x => x.isEmpty)];

    return objectValuesAreEmptyCheck(model, x => excludedKeys.indexOf(x) == - 1);

    // const keys = Object.keys(model).filter(x => excludedKeys.indexOf(x) == -1);
    //
    // let result = '';
    //
    // keys.forEach(key => {
    // 	result += (model[key] == null ? '' : model[key]);
    // });
    //
    // return result == null || result.toString().trim() == '';
};