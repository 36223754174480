import { createAppThunk } from '@helpers/reduxHelpers';
import { createSlice } from '@reduxjs/toolkit';
import { GetRoutesSchedules, RoutesScheduleDto } from '@models/routesModels';
import RoutesService from '@services/RoutesService';

type State = {
    routesSchedules: RoutesScheduleDto[];
    isFetching: boolean;
}

export const appendRoutesSchedules = createAppThunk(
    'routes/appendRoutesSchedules',
    async (arg: GetRoutesSchedules) => {
        const {data} = await new RoutesService().get(arg);
        return data;
    }
);

const slice = createSlice({
    name: 'routes',
    initialState: {
        routesSchedules: [],
        isFetching: true
    } as State,
    reducers: {
        resetRoutes(state) {
           state.routesSchedules = [];
           state.isFetching = true;
        }
    },
    extraReducers: builder => {
        builder.addCase(
            appendRoutesSchedules.pending,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (state, action) => {
                state.isFetching = true;
            });
        builder.addCase(
            appendRoutesSchedules.fulfilled,
            (state, action) => {
                state.routesSchedules = [...state.routesSchedules, ...action.payload];
                state.isFetching = false;
            });
    }
});

export const {reducer} = slice;
export const {actions} = slice;