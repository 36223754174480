import { FormikProps, isString } from 'formik';
import { CreateCustomerRequestModel } from '@models/customerRequests/formModels';
import nameof from 'ts-nameof.macro';
import { nullIfNan } from '@helpers/Numbers';
import { sumBy } from 'lodash';
import { CargoDimensionDto } from '@models/customerRequests/customerRequestModels';

export const getTotalWeight = (cargoDimensions: CargoDimensionDto[]) => {
    return cargoDimensions.reduce((sum, current) => {
        //const amount = +current.amount;
        const weightVal = isString(current.weight) ? (current.weight as string).replace(/,/, '.') : current.weight;
        //const weight = +weightVal;
        if (current.isTotalWeight)
            sum += ((+weightVal) * (+current.amount));
        else
            sum += (+weightVal);
        return sum;
    }, 0);
};

export const getTotalVolume = (cargoDimensions: CargoDimensionDto[]) => {
    return cargoDimensions.reduce((sum, current) => {
        const amount = +current.amount;
        const lengthVal = isString(current.length) ? (current.length as string).replace(/,/, '.') : current.length;
        const length = +lengthVal;
        const heightVal = isString(current.height) ? (current.height as string).replace(/,/, '.') : current.height;
        const height = +heightVal;
        const widthVal = isString(current.width) ? (current.width as string).replace(/,/, '.') : current.width;
        const width = +widthVal;

        const coef = 0.000001; //cm3 to m3

        return sum + length * height * width * coef * amount;
    }, 0);
};

export function recalculateCargoDetails(formikProps: FormikProps<CreateCustomerRequestModel>, isCalcTotalWeight = false, isCalcTotalVolume = false) {
    if (!formikProps.values.hasCargoDimensions)
        return;

    formikProps.setFieldValue(
        nameof.full<CreateCustomerRequestModel>(x => x.totalPlaces),
        nullIfNan(sumBy(formikProps.values.cargoDimensions, x => x.amount)),
    );

    if (!formikProps.values.useTotalWeight || formikProps.values.hasCargoDimensions) {
        let totalWeight: number | string;

        if (isCalcTotalWeight) {
            totalWeight = nullIfNan(getTotalWeight(formikProps?.values?.cargoDimensions)).toFixed(2);
            formikProps.setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.totalWeight), totalWeight);
        }
    }

    if (isCalcTotalVolume) {
        const totalVolume = nullIfNan(getTotalVolume(formikProps?.values?.cargoDimensions)).toFixed(2);
        formikProps.setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.totalVolume), totalVolume);
    }
}