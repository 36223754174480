import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface CarouselProps {
	items: ReactElement[];
}

const Carousel: React.FC<CarouselProps> = (
	{
		items
	}
) => {

	const carouselItem = useRef<HTMLDivElement>(null);
	const slides = useRef<HTMLDivElement>(null);
	const [pagesCount, setPagesCount] = useState(0);
	const [slidePerPage, setSlidePerPage] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);
	const [slideOffset, setSlideOffset] = useState(0);
	const [slideWidth, setSlideWidth] = useState(0);

	const recalculateSizes = (): void => {
		let totalWidth = 0;
		const clientWidth = carouselItem.current.clientWidth;

		if (slides.current.children.length > 0) {
			const newSlideWidth = (slides.current.children[0] as HTMLDivElement).clientWidth;
			setSlideWidth(newSlideWidth);
			totalWidth = newSlideWidth * items.length;
			setSlidePerPage(clientWidth / newSlideWidth);
		}

		const rawPageCount = totalWidth / clientWidth;
		const count = Math.round(rawPageCount + .5);
		setPagesCount(count);
	};

	useEffect(() => {
		recalculateSizes();
	}, [items]);

	useEffect(() => {
		let left = slideWidth * slidePerPage * pageIndex;
		left = Math.trunc(left / slideWidth) * slideWidth;

		left = Math.min(left, slideWidth * items.length - slideWidth * slidePerPage);
		setSlideOffset(-left);
	}, [pageIndex]);

	useEffect(() => {
		let timer = null;
		const onResize = (): void => {
			if (timer) {
				clearTimeout(timer);
			}

			timer = setTimeout(() => recalculateSizes(), 300);
		};

		window.addEventListener('resize', onResize);

		return (): void => window.removeEventListener('resize', onResize);
	});

	return <div className={styles.carousel} ref={carouselItem}>
		<div className={styles.slides} ref={slides} style={{ left: slideOffset + 'px' }}>
			{items.map((slide, index) => <div key={index} className={styles.slide}>{slide}</div>)}
		</div>
		<div className={styles.buttons}>
			{Array.from(Array(pagesCount).keys())
				.map(index => <div key={index} className={classNames(styles.page, index == pageIndex ? styles.active : '')}
								   onClick={(): void => setPageIndex(index)} />)}
		</div>
	</div>;
};

export default Carousel;
