import { createSlice } from '@reduxjs/toolkit';
import { createAppThunk } from '@helpers/reduxHelpers';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { UserDictionaryItem } from '@models/users';
import { ChangePasswordModel } from '@models/accounts/changePasswordModels';
import AccountService from '@services/AccountService';

export type State = {
    accounts?: IPagingWrapper<UserDictionaryItem>;
    isFetching: boolean;
};

export const getAccounts = createAppThunk(
    'accounts/getAccounts',
    async (navigation: StandardNavigation) => {
        const { data } = await new AccountService().fetchDictionary(navigation);
        return data;
    }
);

export const changePassword = createAppThunk(
	'accounts/changePassword',
	async (arg: ChangePasswordModel) => {
		const service = new AccountService();
		const { data } = await service.changePassword(arg);
		return data;
	}
);

const slice = createSlice({
    name: 'accounts',
    initialState: {
        airlines: null,
        isFetching: true,
    } as State,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAccounts.fulfilled, (state, action) => {
            state.isFetching = false;
            state.accounts = action.payload;
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(getAccounts.pending, (state, action) => {
            state.isFetching = true;
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.isFetching = false;
        });
    },
});

export const { reducer } = slice;
export const { actions } = slice;