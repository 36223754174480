import React from 'react';

import styles from './ContactCircle.module.scss';
import ContactCanLoginSvg from '@assets/svg/contactCanLogin.svg';
import ContactCanNotLoginSvg from '@assets/svg/contactCanNotLogin.svg';

import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

type Props = {
    initials: string;
    isBig: boolean;
    canLogin?: boolean;
	inactive: boolean;
};

function getIcon(canLogin?: boolean, inactive?: boolean) {
    if (canLogin == null && inactive == null)
		return null;

    return canLogin && (inactive == false || inactive == null) ? ContactCanLoginSvg : ContactCanNotLoginSvg;
}

const ContactCircle = (props: Props): JSX.Element => {
    const { initials, isBig, canLogin, inactive } = props;
    const icon = getIcon(canLogin, inactive);

    const { t: tContact } = useTranslation('', {keyPrefix: 'contact'});

    return (
        <div className={styles.circle + ` ${isBig ? styles.big : ''}`}>
            <div className={styles.initials}>{initials}</div>
            {icon && (
                <Tippy
                    content={
						inactive == true ? tContact('deactivated') : (
                        canLogin
                            ? tContact('canLogin')
                            : tContact('canNotLogin')
						)
                    }
                >
                    <img className={styles.icon} src={icon} />
                </Tippy>
            )}
        </div>
    );
};

export default ContactCircle;