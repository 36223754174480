import clsx from 'clsx';
import React, { ReactNode } from 'react';
import s from './TracingPage.module.scss';
import { BookingStatus } from '@models/enums';
import { isEqual } from '@helpers/statusHelper';
import { statuses } from '@scenes/booking/components/Status/types';
import StatusLoader from '@scenes/booking/components/StatusLoader';
import { useStatuses } from '@scenes/booking/components/Status/useStatuses';
import { ReactComponent as RequestSvg } from '@material-design-icons/svg/round/assignment.svg';
import { ReactComponent as ClosingSvg } from '@material-design-icons/svg/round/location_on.svg';
import { ReactComponent as BookedSvg } from '@material-design-icons/svg/round/event_available.svg';
import { ReactComponent as ExecutionSvg } from '@material-design-icons/svg/round/flight.svg';

const getStatusIcon = (status: BookingStatus): ReactNode => {
    switch (status) {
        case BookingStatus.Request:
            return <RequestSvg fill='currentColor' />;
        case BookingStatus.Booking:
            return <BookedSvg fill='currentColor' />;
        case BookingStatus.Execution:
            return <ExecutionSvg fill='currentColor' />;
        case BookingStatus.Closing:
            return <ClosingSvg fill='currentColor' />;
    }
};

const Status = () => {
    const bookingStatus = useStatuses();

    return (
        <div>
            <div className={s.bookingStatuses}>
                {statuses.map((status, index) =>
                    <div key={index} className={clsx(
                        s.itemWrapper,
                        isEqual(status.status, bookingStatus.status) && s.itemActive,
                        status.status > bookingStatus.status && s.itemFuture)}>
                        {getStatusIcon(status.status)}
                        {
                            status.alternativeTitle
                                ? (
                                    <span>{(bookingStatus.delivered || !isEqual(status.status, bookingStatus.status) ? status.title : status.alternativeTitle)}</span>)
                                : (<span>{status.title}</span>)
                        }
                        {status.status === bookingStatus.status && status.status !== BookingStatus.Closing &&
                            <StatusLoader />
                        }
                    </div>,
                )}
            </div>
        </div>
    );
};

export default Status;