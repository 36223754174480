/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import styles from './ContactUs.module.scss';
import MoonIcon from '@components/MoonIcon';
import { apiHandleErrors } from '@core/AuthorizedHttpClient';

export interface Category {
  id: string;
  valueRes: string;
  icon: string;
  value: string;
}

const ContactUs = (): JSX.Element => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useHistory();

  const categories: Category[] = [
    {
      id: 'cat1',
      valueRes: 'airline',
      icon: 'icon-airplane',
      value: 'Airline',
    },
    {
      id: 'cat2',
      valueRes: 'contactUs.gssa',
      icon: 'icon-dashboard',
      value: 'GSSA',
    },
    {
      id: 'cat3',
      valueRes: 'contactUs.forwarder',
      icon: 'icon-box',
      value: 'Freight forwarder',
    },
    {
      id: 'cat4',
      valueRes: 'contactUs.airport',
      icon: 'icon-airport',
      value: 'Airport',
    },
    {
      id: 'cat5',
      valueRes: 'contactUs.press',
      icon: 'icon-camera',
      value: 'Press',
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contactUs = (event: any): void => {
    event.preventDefault();
    const formData: FormData = new FormData(event.target);

    // reset error text
    setError('');

    // add Loading indicator
    setLoading(true);

    fetch(`api/ContactUs/contactus`, {
      body: formData,
      method: 'POST',
    })
      .then(apiHandleErrors)
      .then((_) => navigate.push('/'))
      .catch((error) => {
        setLoading(false);
        setError(t('contactUs.error'));
        console.error('Contact us error', error.stack);
      });
  };

  const goBack = (event): void => {
    event.preventDefault();
    navigate.push('/');
  };

  return (
    <section>
      <Container fluid={true} className={styles.container}>
        <Row>
          <Col>
            <h3>{t('contactUs.mainHeader')}</h3>
            <h2>{t('contactUs.header')}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form
              className={styles.contactForm}
              onSubmit={(e) => contactUs(e)}
            >
              <fieldset>
                <legend>{t('contactUs.selectLbl')}</legend>
                <div className={styles.radioGroup}>
                  {categories.map((cat) => (
                    <div
                      key={cat.id}
                      className={styles.formRadio}
                    >
                      <input
                        id={cat.id}
                        type='radio'
                        name='Category'
                        value={cat.value}
                        required
                      />
                      <Label
                        for={cat.id}
                        className={styles.catLabel}
                      >
                        <MoonIcon
                          withBorder
                          icon={`${cat.icon} ${styles.catIcon}`}
                        />
                        <span>{t(cat.valueRes)}</span>
                      </Label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <FormGroup className={styles.formLabelGroup}>
                <Input
                  type='text'
                  name='FullName'
                  id='fullNameFld'
                  placeholder={t('contactUs.name')}
                  required
                />
                <Label for='fullNameFld'>
                  {t('contactUs.name')}
                </Label>
              </FormGroup>
              <FormGroup className={styles.formLabelGroup}>
                <Input
                  type='email'
                  name='Email'
                  id='emailFld'
                  placeholder={t('contactUs.email')}
                  required
                />
                <Label for='emailFld'>
                  {t('contactUs.email')}
                </Label>
              </FormGroup>
              <FormGroup className={styles.formLabelGroup}>
                <Input
                  type='tel'
                  name='Phone'
                  id='phoneFld'
                  placeholder={t('contactUs.phone')}
                  required
                />
                <Label for='phoneFld'>
                  {t('contactUs.phone')}
                </Label>
              </FormGroup>
              <FormGroup className={styles.formLabelGroup}>
                <Input
                  type='text'
                  name='Company'
                  id='companyFld'
                  placeholder={t('contactUs.company')}
                  required
                />
                <Label for='companyFld'>
                  {t('contactUs.company')}
                </Label>
              </FormGroup>
              <FormGroup className={styles.formLabelGroup}>
                <Input
                  type='textarea'
                  name='Message'
                  id='messageFld'
                  required
                />
                <Label for='messageFld'>
                  {t('contactUs.message')}
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>{t('contactUs.policy')}</Label>&nbsp;
                <Link to={'/privacy_policy'}>
                  {t('welcomePage.policy')}
                </Link>
              </FormGroup>
              <FormGroup>
                {error && (
                  <Alert color='danger' className='w-100'>
                    {error}
                  </Alert>
                )}
              </FormGroup>
              <FormGroup>
                <button
                  type='submit'
                  className={`btn-primary ${styles.contactBtn}`}
                  disabled={loading}
                >
                  <MoonIcon icon='icon-message moon-1x mr-2' />
                  <span>{t('contactUs.contactUs')}</span>
                </button>
                {loading && (
                  <span className={styles.spinner} />
                )}
                <button
                  type='reset'
                  className={`btn-secondary ${styles.contactBtn}`}
                  onClick={goBack}
                >
                  <span>{t('contactUs.cancel')}</span>
                </button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;