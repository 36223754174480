import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { HttpError } from '@models/shared';
import {
	CreateNotificationSettings,
	NotificationSettingsEntryDto,
	UpdateNotificationSettings,
} from '@models/admin/notificationsModels';

export default class NotificationsService {
	public fetchAll = () => {
		return AuthorizedHttpClient.get<NotificationSettingsEntryDto[] & HttpError>('api/notifications');
	};

	public create = (cmd: CreateNotificationSettings) => {
		return AuthorizedHttpClient.post<string & HttpError>('api/notifications', cmd);
	};

	public update = (cmd: UpdateNotificationSettings) => {
		return AuthorizedHttpClient.put<HttpError>('api/notifications', cmd);
	};

	public delete = (id: string) => {
		return AuthorizedHttpClient.delete<HttpError>(`api/notifications/${id}`);
	};
}