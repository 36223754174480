/* eslint-disable prefer-const */
export const Util = {
    rand: (min, max) => {
      return Math.random() * ( max - min ) + min;
    },
    randInt: (min, max) => {
      return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
    },
    norm: ( val, min, max ) => {
          return ( val - min ) / ( max - min );
    },
    lerp: (norm, min, max) => {
      return ( max - min ) * norm + min;
    },
    map: (val, sMin, sMax, dMin, dMax) => {
        return Util.lerp( Util.norm(val, sMin, sMax), dMin, dMax);
    },
    clamp: (val, min, max) => {
        return Math.min(Math.max(val, Math.min(min, max)), Math.max(min, max));
    },
    distance: (p1, p2) => {
        const dx = p1.x - p2.x,
            dy = p1.y - p2.y;
        return Math.sqrt( dx * dx + dy * dy );
    },
    angle: (p1, p2) => {
        return Math.atan2(p1.y - p2.y, p1.x - p2.x);
    },
    inRange: (val, min, max) => {
        return val >= Math.min(min, max) && val <= Math.max(min, max);
    },
    pointInRect: (x, y, rect) => {
        return Util.inRange(x, rect.x, rect.x + rect.width) &&
            Util.inRange(y, rect.y, rect.y + rect.height);
    },
    pointInArc: (p, a) => {
        return Util.distance(p, a) <= a.radius;
    },
    setProps: (obj, props) => {
        for(let k in props) {
            obj[k] = props[k];
        }
    },
    multicurve: (points, ctx) => {
        let p0, p1, midx, midy;
        ctx.moveTo(points[0].x, points[0].y);
        for(let i = 1; i < points.length - 2; i += 1) {
            p0 = points[i];
            p1 = points[i + 1];
            midx = (p0.x + p1.x) / 2;
            midy = (p0.y + p1.y) / 2;
            ctx.quadraticCurveTo(p0.x, p0.y, midx, midy);
        }
        p0 = points[points.length - 2];
        p1 = points[points.length - 1];
        ctx.quadraticCurveTo(p0.x, p0.y, p1.x, p1.y);
    }
};
