import { createSlice } from '@reduxjs/toolkit';
import HouseAirwaybillsService from '@services/HouseAirwaybillsService';
import { CreateHouseAirwaybill, UpdateHouseAirwaybill, FhlDto, AirwaybillDto } from '@models/awbs/awbsModels';
import { UploadProgress } from '@models/shared';
import { createAppThunk } from '@helpers/reduxHelpers';

export type State = {
    isFetching: boolean;
    houseAirwaybill?: AirwaybillDto;
    fhlCode?: string;
};

export const uploadAndRecognizeHawbImage = createAppThunk(
    'houseAirwaybills/uploadAndRecognizeHawbImage',
    async (arg: { parentAirwaybillId: string; file: File; onUploadProgress: UploadProgress }) => {
        const { data } = await new HouseAirwaybillsService().recognizeImage(
            arg.parentAirwaybillId,
            arg.file,
            arg.onUploadProgress
        );
        return data;
    }
);

export const createHawb = createAppThunk(
    'houseAirwaybills/createHawb',
    async (arg: { model: CreateHouseAirwaybill }) => {
        const { data } = await new HouseAirwaybillsService().create(arg.model);
        return data;
    }
);

export const updateHawb = createAppThunk(
    'houseAirwaybills/updateHawb',
    async (arg: { model: UpdateHouseAirwaybill }) => {
        const { data } = await new HouseAirwaybillsService().update(arg.model);
        return data;
    }
);

export const getHawbById = createAppThunk('houseAirwaybills/getHawbById', async (arg: string) => {
    const { data } = await new HouseAirwaybillsService().get(arg);
    return data;
});

export const removeHawbById = createAppThunk('houseAirwaybills/removeHawbById', async (arg: string) => {
    await new HouseAirwaybillsService().remove(arg);
});

export const getFhlCode = createAppThunk('houseAirwaybills/getFhlCode', async (arg: string) => {
    const { data } = await new HouseAirwaybillsService().getFhlCode(arg);
    return data;
});

export const importFromFhl = createAppThunk('houseAirwaybills/importFromFhl', async (arg: { model: FhlDto }) => {
    const { data } = await new HouseAirwaybillsService().importFromFhl(arg.model);
    return data;
});

const slice = createSlice({
    name: 'houseAirwaybills',
    initialState: {
        isFetching: true,
    } as State,
    reducers: {},
    extraReducers: (builder) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(getHawbById.pending, (state, action) => {
            state.isFetching = true;
            state.houseAirwaybill = null;
        });

        builder.addCase(getHawbById.fulfilled, (state, action) => {
            state.isFetching = false;
            state.houseAirwaybill = action.payload;
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(getHawbById.rejected, (state, action) => {
            state.isFetching = false;
            state.houseAirwaybill = null;
        });

        builder.addCase(getFhlCode.fulfilled, (state, action) => {
            state.fhlCode = action.payload;
        });
    },
});

export const { reducer } = slice;
export const { actions } = slice;
