export enum FilterValueCondition
{
    Equals,
    Contains,
    MoreThan,
    LessThan,
    MoreOrEqualsThan,
    LessOrEqualsThan
}

export enum FilterOperator
{
    And,
    Or
}

export class FilterValue {

    constructor(        
        public path: string,
        public condition: FilterValueCondition,
        public value?: string
    ) { }
    
}

export class FilterConnection {
    
    constructor(
        public operator: FilterOperator
    ) {
    }

    public connections: FilterConnection[] = [];
    public values: FilterValue[] = [];
}

export class PagingFilter {
    constructor(
        public pageNumber: number,
        public pageSize: number
    ) {
    }
}