import styles from './index.module.scss';
import { AddressTokenDto, TokenType } from '@models/awbs/awbsModels';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cs from '../../../../common.module.scss';
import cn from 'classnames';

interface Props {
  onClick?: () => void;
  token: AddressTokenDto;
}

export const AddressParsedToken = ({ onClick, token }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cn(cs.flex, cs.gap_5, styles.AddressToken, cs.flexAlignCenter)} style={{width: "unset"}} onClick={onClick}>
      <div className={cn(styles.indicator, styles[TokenType[token.tokenType]])} />
			<div title={t(`awb.tokenType.${TokenType[token.tokenType]}`)}>
        {token.value}
      </div>
    </div>
  );
};