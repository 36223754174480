import {components} from "react-select";
import styles from "@components/select/Select.module.scss";
import React from "react";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <i className={`${styles.icon__arrow} icon-chevron-down ${props.selectProps.menuIsOpen ? "rotate-180" : ""}`}/>
            </components.DropdownIndicator>
        )
    );
};

export default DropdownIndicator;