import { Field, FormikProps } from 'formik';
import React from 'react';
import Select from './Select';
import ValidationMessage from '@components/ValidationMessage';
import styles from './Select.module.scss';

const SelectField = <TObject, TFieldValue>(props: {
	name: string;
	isEditMode: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formikProps: FormikProps<any>;
	availableObjects: TObject[];
	fieldValueSelector: (obj: TObject) => TFieldValue;
	placeholder: string;
	optionLabelSelector: (selectedObject: TObject) => JSX.Element;
	selectedLabelSelector?: (selectedObject: TObject) => JSX.Element;
	onChange?: (selectedObjects: TObject[], selectedValues: TFieldValue[]) => void;
	isMulti?: boolean;
	disabledSelector?: (obj: TObject) => boolean;
	fixedSelector?: (obj: TObject) => boolean;
}) => {

	const joinElements = (separator: JSX.Element | string, elements: JSX.Element[]) => {
		const res = [];
		for(let i= 0; i < elements.length; i++){
			res.push(elements[i]);
			if(i != elements.length - 1){
				res.push(separator);
			}
		}
		return res;
	}

	return (
		<Field name={props.name}>
			{({ field }) => {
				let val = field.value;
				if (!props.isMulti) {
					if (!Array.isArray(val)) {
						val = val != null ? [val] : [];
					}
				}

				return props.isEditMode ? (
					<>
						<Select<TObject, TFieldValue>
							isMulti={props.isMulti}
							values={val}
							availableObjects={props.availableObjects}
							onChange={(objects, values) => {
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								let o: any = objects,
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									v: any = values;

								if (!props.isMulti) {
									o = o[0] || null;
									v = v[0] || null;
								}

								props.formikProps.setFieldValue(props.name, v);
								props.formikProps.setFieldTouched(props.name, true);
								if (props.onChange) {
									props.onChange(o, v);
								}
							}}
							optionLabelSelector={props.optionLabelSelector}
							selectedLabelSelector={props.selectedLabelSelector}
							placeholder={props.placeholder}
							valueSelector={props.fieldValueSelector}
							disabledSelector={props.disabledSelector}
							fixedSelector={props.fixedSelector}
						/>
						<ValidationMessage name={field.name} errors={props.formikProps.errors} />
					</>
				) : (
					<div className={styles.formText}>
						{Array.isArray(field.value)
							? field.value?.length > 0
								? joinElements(', ', props.availableObjects
										.filter((x) => field.value.includes(props.fieldValueSelector(x)))
										.map(props.selectedLabelSelector))
								: ' - '
							: props.availableObjects
									.filter((x) => props.fieldValueSelector(x) == field.value)
									.map(props.selectedLabelSelector)[0] || ' - '}
					</div>
				);
			}}
		</Field>
	);
};

export default SelectField;