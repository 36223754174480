import {
    CreateCustomerRequest,
	GetSaleDto,
} from '@models/customerRequests/customerRequestModels';
import { GetCustomerRequestDto } from '@models/customerRequests/customerRequestModels';
import { HttpError } from '@models/shared';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import {StandardNavigation} from '@models/entityNavigation/StandardNavigation';
import {IPagingWrapper} from '@models/entityNavigation/IPagingWrapper';
import {GetCustomerRequestHistoryItemDto} from '@models/customerRequests/customerRequestHistoryModels';
import { CreateCustomerApplication } from '@models/customerApplications/customerApplicationModels';
import { AxiosResponse } from 'axios';

export default class CustomerRequestsService {

    public createSearchRequest = async (createCustomerRequest: CreateCustomerRequest) => {
        return await AuthorizedHttpClient.post<string & HttpError>('/api/customerRequests', createCustomerRequest);
    }

    public getSearchRequest = async (searchRequestId: string) => {
        return await AuthorizedHttpClient.get<GetCustomerRequestDto & HttpError>(`/api/customerRequests?id=${searchRequestId}`);
    }

    public getSales = async (searchRequestId: string) => {
        return await AuthorizedHttpClient.get<GetSaleDto[]>(`api/results/getAirlines?requestId=${searchRequestId}`);
    }

    public getHistory = async (nav: StandardNavigation) => {
        return await AuthorizedHttpClient.post<IPagingWrapper<GetCustomerRequestHistoryItemDto> & HttpError>(`api/customerRequests/history`, nav);
    }

    public requestQuotation = async (model: CreateCustomerApplication) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return await AuthorizedHttpClient.post<any & HttpError>('/api/customerRequests/requestQuotation', model);
	}

    public async sendClientOffer(requestId: string, withTariffSChedule: boolean, saleIDs: string[], emails: string[]): Promise<AxiosResponse<void>> {
		return await AuthorizedHttpClient.post('api/customerRequests/sendClientOffer', {
            RequestId: requestId,
            WithTariffSChedule: withTariffSChedule,
            SaleIDs: saleIDs,
            Emails: emails
        });
	}
}
