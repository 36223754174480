import { welcomeImages } from '@helpers/tenantsHelper';

export const getImageFromLogoId = (logoId: string): string => {
  switch (logoId) {
    case 'FFCargo':
      return welcomeImages.ffCargoLogo;
    case 'Trasko':
      return welcomeImages.traskoLogo;
    case 'Abipa':
      return welcomeImages.abipaLogo;
    case 'Ata':
      return welcomeImages.ataLogo;
    case 'CargoWorld':
      return welcomeImages.cargoWorldLogo;
    case 'SkyCargo':
      return welcomeImages.skyCargoLogo;
    default:
      return null;
  }
};