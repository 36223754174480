import AuthorizedHttpClient from "@core/AuthorizedHttpClient";
import { AddressDatumDto, AddressInfoDto, AddressTokenDto } from "@models/awbs/awbsModels";

export default class AddressDataService {
	public get(id: string) {
		return AuthorizedHttpClient.get<AddressDatumDto>(`api/AddressData/${id}`);
	}

	public getItems(search: string) {
		return AuthorizedHttpClient.get<AddressDatumDto[]>(`api/AddressData/list/${search}`);
	}

	public parseAddress(address: string) {
		return AuthorizedHttpClient.post<AddressTokenDto[]>('api/AddressData/parse', { address: address });
	}

	public createAddress(model: AddressDatumDto) {
		return AuthorizedHttpClient.post<string>('api/AddressData/create', model);
	}

	public updateAddress(model: AddressDatumDto) {
		return AuthorizedHttpClient.post<string>('api/AddressData/update', model);
	}

	getRecognizedAddresses(id: string) {
		return AuthorizedHttpClient.get<AddressInfoDto>(`api/AddressData/recognized-addresses-by-awb/${id}`);
	}
}