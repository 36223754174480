import * as React from 'react';
import { Field, FormikProps } from 'formik';
import clsx from 'clsx';
import ValidationMessage from '@components/ValidationMessage';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ru from 'react-phone-number-input/locale/ru.json';
import en from 'react-phone-number-input/locale/en.json';
import i18n from '@i18n';
import { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props<TModel> {
    label?: JSX.Element | string;
    name: string;
    required: boolean;
    formikProps: FormikProps<TModel>;
    isDisabled?: boolean;
    inputClassName?: string;
    placeholder?: string;
    hideExt?: boolean;
    autofocus?: boolean;
}

export interface InputPhoneProps {
    name: string;
    isDisabled?: boolean;
    value?: string;
    onChange: (value: string) => void;
    errors?: any;
    inputClassName?: string;
    placeholder?: string;
    hideExt?: boolean;
    autofocus?: boolean;
}

export function InputPhoneControl({ name, value, onChange, isDisabled, errors, inputClassName, hideExt, autofocus }: InputPhoneProps) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneExt, setPhoneExt] = useState('');
    const { t } = useTranslation();
    const refCtrl = useRef(null);

    useEffect(() => {
        if (value == null) {
            setPhoneNumber('');
            setPhoneExt('');
            return;
        }

        if (value == [phoneNumber, phoneExt].join('*')) {
            return;
        }

        const phoneParts = value.split('*');
        setPhoneNumber(phoneParts[0] || '');
        if (phoneParts.length > 1) {
            setPhoneExt(phoneParts[1] || '');
        }
    }, [value]);

    const updateNumber = (phone: string, ext: string) => {
        setPhoneNumber(phone);
        if (phone == '') {
            ext = '';
        }

        setPhoneExt(ext);
        onChange([phone, ext].filter(f => f.length > 0).join('*'));
    };

    useEffect(() => {
        if (refCtrl.current == null) {
            return;
        }

        refCtrl.current.focus();
    }, []);

    return <>
        <div className={styles.inputPhoneContainer}>
            <PhoneInput
                ref={refCtrl}
                className={clsx('form-control', inputClassName)}
                labels={i18n.language == 'en' ? en : ru}
                key={name}
                id={name}
                name={name}
                value={phoneNumber}
                onChange={value => {
                    updateNumber(value || '', phoneExt);
                }}
                disabled={isDisabled}
                international
                countryCallingCodeEditable={false}
                limitMaxLength={true}
            />
            {!hideExt && <span className={styles.extLabel}>{t('inputPhone.extLabel')}</span>}
            {!hideExt && <input
                type="tel"
                disabled={phoneNumber == ''}
                className={clsx('form-control', styles.extInput, inputClassName)}
                maxLength={5}
                value={phoneExt}
                onChange={e => {
                    updateNumber(phoneNumber, e.target.value || '');
                }}
            />}
        </div>
        <ValidationMessage name={name} errors={errors} />
    </>;
}

export function InputPhone<TModel>({ label, required, name, formikProps, isDisabled, inputClassName, placeholder, hideExt, autofocus }: Props<TModel>) {
    return (
        <label className={clsx('control-label')} htmlFor={name}>
            {label}
            {required && <sup className="required" />}
            <Field name={name}>
                {({ field }) =>
                    <InputPhoneControl
                        name={field.name}
                        onChange={value => formikProps.setFieldValue(name, value || '')}
                        errors={formikProps.errors}
                        isDisabled={isDisabled}
                        value={field.value || ''}
                        inputClassName={inputClassName}
                        placeholder={placeholder}
                        hideExt={hideExt}
                        autofocus={autofocus}
                    />
                }
            </Field>
        </label>
    );
}
