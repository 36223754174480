import * as React from 'react';
import { useState } from 'react';
import { ReactComponent as IconOpen } from '@material-design-icons/svg/round/settings.svg';
import { ReactComponent as IconRefresh } from '@material-design-icons/svg/round/refresh.svg';
import { IGetCustomerApplicationHistoryDto } from '@models/customerApplications/customerApplicationHistoryModels';
import styles from '../style.module.scss';
import listStyles from '@assets/list.module.scss';
import routePopoverStyles from '@assets/routePopover.module.scss';
import { Collapse, Popover, PopoverBody } from 'reactstrap';
import { isoUtcDateToLocalString, isoUtcDateToShortLocalString } from '@helpers/dateHelpers';
import AirlineLogo from '@components/AirlineLogo';
import Price from '@components/Price';
import { ReactComponent as Airplane } from '@assets/svg/legacy/airplane.svg';
import { ReactComponent as ArrowRightShort } from '@assets/svg/legacy/arrow_right_short.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import SessionManager from '../../../SessionManager';
import { UserRoles } from '@config/ApiAuthorizationConstants';
import { Badge, Button, PageHeader, ParamCard } from '@root/components';
import cn from 'classnames';
import i18n from '@i18n';
import scenesCS from '../../common.module.scss';
import cs from "@assets/styles/common.module.scss";

interface Props {
    data: IGetCustomerApplicationHistoryDto;
}

const CustomerApplicationHistoryItem: React.FC<Props> = ({ data }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [popovers, setPopovers] = useState<Map<string, boolean>>(new Map());
    const { t } = useTranslation();
    const history = useHistory();

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const togglePopover = (id: string, isOpen: boolean): void => {
        setTimeout(() => {
            popovers[id] = isOpen;
            setPopovers(popovers);
        }, 300);
    };

    const i18tColumnNames = [
        'number',
        'customerApplicationHistory.items',
        'customerApplicationHistory.itemLength',
        'customerApplicationHistory.itemWidth',
        'customerApplicationHistory.itemHeight',
        'customerApplicationHistory.itemWeight',
    ];

    const columnTitles = React.useMemo(() => {
        return <>{i18tColumnNames.map((x, i) => <span className={scenesCS.header} key={i}>{t(x)}</span>)}</>;
    }, [i18n.language]);

    return (
        <>
            {/* Route popover. */}
            <Popover
                placement='bottom'
                target={`direction-${data?.id}`}
                isOpen={popovers[`direction-${data?.id}`]}
            >
                <PopoverBody className={routePopoverStyles.popover}>
                    <div className={routePopoverStyles.header}>
                        <div className={routePopoverStyles.airplane}>
                            <Airplane />
                        </div>
                        <div className={routePopoverStyles.codes}>
                            <span>{data?.departureAirport?.code}</span>
                            <ArrowRightShort />
                            <span>{data?.destinationAirport?.code}</span>
                        </div>
                    </div>

                    <div className={routePopoverStyles.info}>
                        <div>
                            <b>{t('customerApplicationHistory.departure')}</b>
                            <div>
                                {data?.departureAirport?.displayName},{' '}
                                {data?.departureAirport?.cityName},{' '}
                                {data?.departureAirport?.countryName}
                            </div>
                        </div>
                        <div>
                            <b>{t('customerApplicationHistory.destination')}</b>
                            <div>
                                {data?.destinationAirport?.displayName},{' '}
                                {data?.destinationAirport?.cityName},{' '}
                                {data?.destinationAirport?.countryName}
                            </div>
                        </div>
                    </div>
                </PopoverBody>
            </Popover>

            {/* Expected shipping date popover. */}
            <Popover
                placement='top'
                target={`expectedShippingDate-${data?.id}`}
                isOpen={popovers[`expectedShippingDate-${data?.id}`]}
            >
                <PopoverBody>{t('customerApplicationHistory.expectedShippingDate')}</PopoverBody>
            </Popover>

            <div onClick={() => {
                toggleOpen();
            }}
                 id={data?.id}
                 className={cn(scenesCS.itemCard, isOpen && listStyles.head__active)}>

                <div className={styles.logo}>
                    <AirlineLogo
                        image={data?.airline?.logo}
                        name={data?.airline?.name}
                    />
                </div>
                <div className={styles.props}>
                    <div className={styles.itemCardColumn}
                         id={`expectedShippingDate-${data?.id}`}
                         onMouseEnter={(): void => togglePopover(`expectedShippingDate-${data?.id}`, true)}
                         onMouseLeave={(): void => togglePopover(`expectedShippingDate-${data?.id}`, false)}
                    >
                            <h6>{t('sorting.dateCreated')}</h6>
                            <span>{isoUtcDateToShortLocalString(data.createTime)}</span>
                    </div>
                    <div className={styles.itemCardColumn}>
                        {data.airwaybillNumber &&
                            <Badge label={data.airwaybillNumber} color='blue' />
                        }
                    </div>
                    <div className={styles.itemCardColumn}
                         onMouseEnter={(): void => togglePopover(`direction-${data?.id}`, true)}
                         onMouseLeave={(): void => togglePopover(`direction-${data?.id}`, false)}
                    >
                                    <span id={`direction-${data?.id}`}>
                                        <span className='pr-2'>{data?.departureAirport?.code}</span>
                                        <i className={clsx(listStyles.arrowRightIcon, `icon-arrow_right_long`)} />
                                        <span className='pl-2'>{data?.destinationAirport?.code}</span>
                                    </span>
                    </div>

                    <div className={styles.itemCardColumn}>
                        <span className={styles.vertical}>
                          <Price
                              price={data?.totalPrice}
                              costPerUnit={data?.costPerUnit}
                              priceCurrencyCode={data?.currencyCode}
                              unitCurrencyCode={data?.unitCurrencyCode}
                          />
                        </span>
                    </div>
                    <div className={styles.itemCardColumn}>
                        {SessionManager.user.hasAnyRole(UserRoles.superuser, UserRoles.tenantAdmin) &&
                            <Badge label={data?.organizationName} color='blue' />
                        }
                    </div>
                    <div className={cn(styles.colButtons, styles.itemCardColumn)}>
                        <Button
                            title={t('customerApplicationHistory.openBookingForm')}
                            icon={<IconOpen fill='currentColor' />}
                            type='fill'
                            variant='primary'
                            onClick={() => history.push(`/booking/${data?.id}`)} />
                        <Button
                            title={t('customerApplicationHistory.repeatSearch')}
                            icon={<IconRefresh fill='currentColor' />}
                            type='fill'
                            variant='white'
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(`request/${data?.customerRequestId}`);
                            }} />
                    </div>
                </div>
            </div>

            <Collapse isOpen={isOpen}>
                <div className={cn(scenesCS.collapseData, cs.card)}>
                    <div className={scenesCS.generalInfo}>
                        <PageHeader size='subHeader' title={t('customerApplicationHistory.general')} />
                        <div className={cn(cs.flex, cs.gap10)}>
                            <ParamCard classList={styles.fullWidthCard} paramName={t('customerApplicationHistory.dateCreated')} icon={null}>
                                {data?.createTime && isoUtcDateToLocalString(data?.createTime)}
                            </ParamCard>
                            <ParamCard classList={styles.fullWidthCard} paramName={t('customerApplicationHistory.actualShippingDate')} icon={null}>
                                {data?.dateStartFact && isoUtcDateToShortLocalString(data?.dateStartFact) || '-'}
                            </ParamCard>
                            <ParamCard classList={styles.fullWidthCard} paramName={t('customerApplicationHistory.expectedArrivalDate')} icon={null}>
                                {data?.dateEndPlan && isoUtcDateToShortLocalString(data?.dateEndPlan) || '-'}
                            </ParamCard>
                            <ParamCard classList={styles.fullWidthCard} paramName={t('customerApplicationHistory.actualArrivalDate')} icon={null}>
                                {data?.dateEndFact && isoUtcDateToShortLocalString(data?.dateEndFact) || '-'}
                            </ParamCard>
                            <ParamCard classList={styles.fullWidthCard} paramName={t('customerApplicationHistory.awbNumber')} icon={null}>
                                {data?.airwaybillNumber ?? '-'}
                            </ParamCard>
                            <ParamCard classList={styles.fullWidthCard} paramName={t('customerApplicationHistory.totalItems')} icon={null}>
                                {data?.cargoTotalAmount}
                            </ParamCard>
                            <ParamCard classList={styles.fullWidthCard} paramName={t('customerApplicationHistory.totalWeight')} icon={null}>
                                {data?.cargoTotalWeight} {t('units.kg')}
                            </ParamCard>
                            <ParamCard classList={styles.fullWidthCard} paramName={t('customerApplicationHistory.totalVolume')} icon={null}>
                                {data?.cargoTotalVolume} {t('units.m3')}
                            </ParamCard>
                            <ParamCard classList={styles.fullWidthCard} paramName={t('customerApplicationHistory.requestNo')} icon={null}>
                                {data?.customerRequestNo ?? '-'}
                            </ParamCard>
                        </div>
                    </div>
                    <div className={scenesCS.dimensions}>
                        <PageHeader size='subHeader' title={t('customerApplicationHistory.dimensionsOfEachPackage')} />
                        {data?.cargoes &&
                            <div className={scenesCS.cargoes}>
                                <div className={scenesCS.separateHeader}>{columnTitles}</div>
                                {data?.cargoes.map((x, i) =>
                                    <div className={scenesCS.rowCargo} key={i}>
                                        <div className={scenesCS.data}>
                                                <span className={scenesCS.header}>
                                                    {t(i18tColumnNames[0])}
                                                </span>
                                            <span className={scenesCS.column}>
                                                    <span>{i + 1}.</span>
                                                </span>
                                        </div>
                                        <div className={scenesCS.data}>
                                                <span className={scenesCS.header}>
                                                    {t(i18tColumnNames[1])}
                                                </span>
                                            <span className={scenesCS.column}>
                                            <span>{x.amount}</span>
                                                </span>
                                        </div>
                                        <div className={scenesCS.data}>
                                                <span className={scenesCS.header}>
                                                    {t(i18tColumnNames[2])}
                                                </span>
                                            <span className={scenesCS.column}>
                                            <span>{x.length} {t('units.cm')}</span>
                                                </span>
                                        </div>
                                        <div className={scenesCS.data}>
                                                <span className={scenesCS.header}>
                                                    {t(i18tColumnNames[3])}
                                                </span>
                                            <span className={scenesCS.column}>
                                            <span>{x.width} {t('units.cm')}</span>
                                                </span>
                                        </div>
                                        <div className={scenesCS.data}>
                                                <span className={scenesCS.header}>
                                                    {t(i18tColumnNames[4])}
                                                </span>
                                            <span className={scenesCS.column}>
                                            <span>{x.height} {t('units.cm')}</span>
                                                </span>
                                        </div>
                                        <div className={scenesCS.data}>
                                                <span className={scenesCS.header}>
                                                    {t(i18tColumnNames[5])}
                                                </span>
                                            <span className={scenesCS.column}>
                                            <span>{x.weight} {t('units.kg')}</span>
                                                </span>
                                        </div>
                                    </div>,
                                )}
                            </div>
                        }
                    </div>
                </div>
            </Collapse>
        </>
    );
};

export default CustomerApplicationHistoryItem;