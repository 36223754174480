import React, {forwardRef} from "react";
import {useTranslation} from "react-i18next";
import {DateTime} from "@helpers/DateTime";
import styles from "./tile.module.scss";
import clsx from "clsx";
import {TileStatus} from "../types";

type CalendarTileProps = {
    date: string,
    resultCount: number,
    status: TileStatus,
    onClick: () => void;
};

const Tile = forwardRef<HTMLDivElement, CalendarTileProps>(({
                                                                resultCount,
                                                                date,
                                                                onClick,
                                                                status
                                                            }: CalendarTileProps, ref) => {

    const {t} = useTranslation();

    const d = new DateTime(date);
    d.resetTime();

    let textActiveStatusClassName = null;
    let tileClassName = null;
    switch (status) {
        case TileStatus.Disabled:
            tileClassName = styles.tileDisabled;
            break;
        case TileStatus.Enabled:
            break;
        case TileStatus.Loading:
            tileClassName = styles.tileLoading;
            break;
        case TileStatus.Active:
            tileClassName = styles.tileActive;
            textActiveStatusClassName = styles.textActive;
            break;
    }

    return (
        <div
            className={clsx(styles.tile, tileClassName, status == TileStatus.Active ? 'active' : null)}
            onClick={() => status === TileStatus.Enabled && onClick()}
            ref={ref}
        >
            <span className={clsx(styles.day, textActiveStatusClassName)}>{d.format('D')}</span>
            <span className={clsx(styles.dayOfWeek, textActiveStatusClassName)}>{d.format('ddd')}</span>
            <div className={clsx(styles.month, textActiveStatusClassName)}>{d.format('MMMM')}</div>
            {resultCount != null ? (<>
                <div className={styles.divider}/>
                {resultCount > 0 && (
                    <>
                        <span className={clsx(styles.resultCountNumber, textActiveStatusClassName)}>{resultCount}</span>
                        <span className={clsx(styles.resultCountText, textActiveStatusClassName)}>
                        {" "}{t('requestSearchResults.results', {count: (resultCount % 10)})?.toLocaleLowerCase()}
                    </span>
                    </>
                )}
            </>) : (status == TileStatus.Loading ? <div className={styles.loader}/> : null)}
        </div>
    );
});
Tile.displayName = 'Tile';
export default Tile;