import React, { useEffect, useRef, useState } from 'react';
import { welcomeImages } from '@helpers/tenantsHelper';
import s from './index.module.scss';
import classNames from 'classnames';
import cn from 'classnames';
import { LOGIN_REDIRECT_URL } from '@config/ApiAuthorizationConstants';
import { listFirst, listSecond, listThird } from './types';
import { Button } from '@root/components';
import { useHistory } from 'react-router';
import { ReactComponent as IconPlane } from '@material-design-icons/svg/round/flight.svg';
import DemoForm from '@scenes/welcome/FFCargoWelcomePage/components/demoForm';
import { SectionContent } from '@scenes/welcome/FFCargoWelcomePage/components/SectionContent';
import { MapAnimated } from '@scenes/welcome/FFCargoWelcomePage/components/MapAnimated';
import { Video } from '@scenes/welcome/FFCargoWelcomePage/components/Video';
import { HowStart } from '@scenes/welcome/FFCargoWelcomePage/components/HowStart';
import { Reviews } from '@scenes/welcome/FFCargoWelcomePage/components/Reviews';

const cx = classNames.bind(s);

const FFCargoWelcomePage = (): JSX.Element => {
  const [animateText, setAnimateText] = useState(false);
  const [animateVideo, setAnimateVideo] = useState(false);
  const [animateAirPlan, setAnimateAirPlan] = useState(false);
  const history = useHistory();
  const circle = useRef(null);
  const searchSection = useRef(null);
  const placeSection = useRef(null);
  const docsSection = useRef(null);
  const heroSection = useRef(null);
  const videoSection = useRef(null);
  const airPlanImage = useRef(null);

  const animateVideoClass = cx({
    [s['animate']]: true,
    [s['animate-active']]: animateVideo,
  });

  const animateAircraftClass = cx({
    [s['animate']]: true,
    [s['animate-active']]: animateAirPlan,
  });

  const fadeInTextClass = cx({
    [s['animate']]: true,
    [s['fade']]: true,
    [s['animate-active']]: animateText,
  });

  const getTop = (el: HTMLElement): number => {
    const rect = el.getBoundingClientRect(),
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop;
  };


  useEffect(() => {
    setAnimateText(true);
  });

  useEffect(() => {
    const scrollListener = (): void => {
      const sections = [searchSection.current, placeSection.current, docsSection.current];
      sections.forEach((aminItem) => {
        const animItemHeight = aminItem.offsetHeight;
        const animItemTopOffset = getTop(aminItem);
        const animStart = 1.5;

        let animItemPoint = window.innerHeight - animItemHeight / animStart;
        if (animItemHeight > window.innerHeight) {
          animItemPoint = window.innerHeight - window.innerHeight / animStart;
        }
        if (window.pageYOffset > animItemTopOffset - animItemPoint && window.pageYOffset < animItemTopOffset + animItemHeight) {
          const imagePos = aminItem.querySelector(`.${s['lazy-img']}`);

          const rect = circle.current;
          rect.style.width = imagePos.offsetHeight * aminItem.getAttribute('data-width') + 'px';
          if (aminItem.getAttribute('data-pos')) {
            rect.style.left = imagePos.offsetLeft + imagePos.offsetWidth - rect.offsetWidth * 0.8 + 'px';
            rect.style.top = imagePos.offsetTop - rect.offsetHeight / 6 + 'px';
          } else {
            rect.style.left = imagePos.offsetLeft + 'px';
            rect.style.top = imagePos.offsetTop + 'px';
          }
        }
      });
    };

    const cleanupFunction = (): void => window.removeEventListener('scroll', scrollListener);
    window.addEventListener('scroll', scrollListener);

    return cleanupFunction;
  });

  useEffect(() => {
    const videoObserver = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            return;
          }

          setAnimateVideo(true);
          videoObserver.unobserve(videoSection.current);
        });
      },
      {
        threshold: 0.8,
      },
    );

    videoObserver.observe(videoSection.current);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            return;
          }

          if (entry.target == heroSection.current) {
            setAnimateText(true);
            observer.unobserve(heroSection.current);
            return;
          }

          if (entry.target == airPlanImage.current) {
            observer.unobserve(airPlanImage.current);
            setAnimateAirPlan(true);
          }
        });
      },
      {
        threshold: 0.1,
      },
    );

    const animatedBlocks = [heroSection.current, airPlanImage.current];
    animatedBlocks.forEach((section) => observer.observe(section));

    return (): void => {
      videoObserver.disconnect();
      observer.disconnect();
    };
  });

  return (
    <div className={s.welcome}>
      <MapAnimated />
      <div className={cn(s.section, s.bgGrey)}>
        <div className={s.sectionCont}>
          <div className={s.video}>
            <div className={classNames(s['title-line'], s['content-center'], s.column, s['over-map'])} ref={heroSection}>
              <h1 className={classNames(s['t-xl'], s['t-center'], fadeInTextClass)} data-delay='400'>
                <span className={s.badge}>Онлайн поиск</span> и бронирование грузовых авиаперевозок
              </h1>
              <h4 className={classNames(s['t-sub'], s['t-center'], fadeInTextClass)} data-delay='400'>
                На платформе Cargobooking365.com</h4>
              <p className={classNames(s['text-gray'], s['text-l'], s['t-center'], fadeInTextClass)} data-delay='400'>
                100+ транспортных компаний присоединились к нам, получив
                возможность быстрее находить лучшие варианты перевозки своих грузов.
                <strong className={s.row}>Присоединяйтесь и вы уже сегодня!</strong>
              </p>
              <div className={classNames(s['buttons-line'], s['content-center'], fadeInTextClass)} data-delay='400'>
                <Button onClick={() => history.push('#about')} type='fill' variant='primary'>Узнать больше</Button>
                <Button onClick={() => history.push('#demo')} type='outline' variant='primary'>Запросить демо</Button>
                <Button onClick={() => history.push('/auth/login')} type='outline' variant='primary'>Начать</Button>
              </div>
            </div>
            <div className={classNames(s['hero-animation'], s['will-start'], animateVideoClass)} ref={videoSection}>
              <div className={s.anchor} id='about' />
              <Video />
            </div>
          </div>
        </div>
      </div>

      <div className={s['rect']} ref={circle}>
        <svg viewBox='0 0 296 296' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='148' cy='148' r='148' fill='#3796F6' />
        </svg>
      </div>


      <div className={s.section} data-width='1' ref={searchSection} data-circle-class-name={s['first-section']}>
        <div className={s.sectionCont}>
          <SectionContent
            advantagesList={listFirst}
            coloredHeader='Легко заполнить'
            header='быстро найти'
            text='Оцените стоимость вашей транспортировки, указав пункты отправления и назначения, тип и характеристики груза'
            image={welcomeImages.search}
            imagePosition='right'
          />
        </div>
      </div>


      <div className={cn(s.section, s.bgGrey)} data-width='0.5' data-pos='right' ref={placeSection}
           data-circle-class-name={s['second-section']}>
        <div className={s.sectionCont}>
          <SectionContent
            advantagesList={listSecond}
            coloredHeader='Вся информация'
            header='на одном экране'
            text='Предложения авиакомпаний с актуальными тарифами и условиями перевозки груза'
            image={welcomeImages.place}
          />
        </div>
      </div>


      <div className={s.section} data-width='0.5' ref={docsSection} data-circle-class-name={s['last-section']}>
        <div className={s.sectionCont}>
          <SectionContent
            advantagesList={listThird}
            coloredHeader='Электронный'
            header='документооборот'
            text='Создание и обработка основных документов по перевозке в удобном веб-интерфейсе'
            image={welcomeImages.docs}
            imagePosition='right'
          />
        </div>
      </div>

      <div className={cn(s.section, s.bgGrey)}>
        <div className={s.sectionCont}>
          <Reviews />
        </div>
      </div>
      <div className={cn(s.section)}>
        <div className={s.sectionCont}>
          <HowStart />
        </div>
      </div>
      <div className={cn(s.section, s.letsStart)}>
        <div className={s.sectionCont}>
          <DemoForm />
        </div>
      </div>
      <footer className={s.footer} ref={airPlanImage}>
        <div className={s.container}>
          <div className={s['footer-content']}>
            <div
              className={classNames(s['title-line'], s['content-center'], s.column, s['line-small'])}>
              <h2 className={classNames(s['t-l'], s['t-center'])}>Уже зарегистрированы? Начните
                прямо сейчас!</h2>

              <Button
                icon={<IconPlane />}
                onClick={() => history.push(LOGIN_REDIRECT_URL)}
                type='fill'
                variant='white'
              >
                Перейти
              </Button>
            </div>
          </div>
        </div>
        <div className={classNames(s['air-plane'], animateAircraftClass)}>
          <div className={s['lazy-img']}>
            <img src={welcomeImages.airPlan} alt='' />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FFCargoWelcomePage;
