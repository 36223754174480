import * as React from 'react';
import { Switch, Route, RouteProps } from 'react-router';
import { hot } from 'react-hot-loader';
import WelcomeLayout from '@layouts/WelcomeLayout';
import WelcomePage from '@scenes/welcome/WelcomePage';
import PrivacyPolicy from '@scenes/welcome/PrivacyPolicy';
import TermsOfUse from '@scenes/welcome/TermsOfUse';
import ContactUs from '@scenes/welcome/ContactUs';
import { SearchForm, SearchPage } from './scenes';
import './custom.scss';
import { ApplicationPaths } from '@config/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from '@scenes/auth/ApiAuthorizationRoutes';
import AdminRoutes from '@scenes/admin/AdminRoutes';
import CreateCustomerApplicationPage from '@scenes/customerApplication/CreateCustomerApplicationPage';
import CustomerApplicationHistoryPage from '@scenes/customerApplicationHistory';
import GuestLayout from '@layouts/GuestLayout';
import 'react-dates/initialize';
import CustomerRequestsHistoryPage from '@scenes/customerRequestHistory';
import AccountSettingsMainPage from '@scenes/accountSettings/AccountSettingsMainPage';
import AccreditationListPage from '@scenes/accreditationList/AccreditationListPage';
import useAccreditationListRedirection from '@helpers/routingHelpers';
import { isCargoTenant, isMainTenant } from '@helpers/tenantsHelper';
import FFCargoWelcomePage from '@scenes/welcome/FFCargoWelcomePage';
import NotFound from '@components/NotFound';
import AwbRoutes from '@scenes/awb/AwbRoutes';
import { useEffect } from "react";
import AdminLayout from '@layouts/AdminLayout';
import Booking from '@scenes/booking';
import Request from '@scenes/request';

function App(): React.ReactElement {
    useAccreditationListRedirection();
    const currentHost = window.location.hostname;

    useEffect(() => {
        document.title = isMainTenant
            ? 'Services with Cargobooking365.com - Making Air Cargo Booking Instant and Simple'
            : 'FFCargo Services with Cargobooking365.com - Making Air Cargo Booking Instant and Simple';
    }, [isMainTenant]);

    const AppRoute = (props: RouteProps) => {
        const {component, ...rest} = props;
        useAccreditationListRedirection();
        return <Route {...rest} component={component}/>;
    }

    return (
        <Switch>
            <WelcomeLayout path="/" exact component={isCargoTenant ? FFCargoWelcomePage : WelcomePage}/>
            <WelcomeLayout path="/welcome-test" exact component={FFCargoWelcomePage}/>
            <AppRoute path="/contact_us" component={ContactUs}/>
            <WelcomeLayout path="/welcome" component={FFCargoWelcomePage}/>
            <WelcomeLayout path="/privacy_policy" component={PrivacyPolicy}/>
            <WelcomeLayout path="/terms_of_use" component={TermsOfUse}/>
            <AppRoute path="/awb" component={AwbRoutes}/>
            <AppRoute path="/admin" component={AdminRoutes}/>
            <GuestLayout path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes}/>
            <AdminLayout path="/request/search" component={SearchPage}/>
            <AdminLayout
                path="/request/:requestId?"
                // TODO убрать условие после тестирования новой страницы на проде
                component={currentHost.includes("sandbox") || currentHost.includes("localhost") || currentHost.includes("testing") ? Request : SearchForm}/>
            <AdminLayout
                path="/customerApplication/new/:requestId/:saleId/:routeStartDate?/:routeId?"
                component={CreateCustomerApplicationPage}
            />
            <AdminLayout path="/bookingHistory" component={CustomerApplicationHistoryPage}/>
            <AdminLayout path="/requestsHistory" component={CustomerRequestsHistoryPage}/>
            <AdminLayout path="/booking/:id" component={Booking}/>
            <AdminLayout path="/account" component={AccountSettingsMainPage}/>
            <AdminLayout path="/account/employees/:id" component={AccountSettingsMainPage}/>
            <AdminLayout path="/accreditation" component={AccreditationListPage}/>
            <WelcomeLayout path="*" exact component={NotFound}/>
        </Switch>
    );
}

export default hot(module)(App);