import React, { useState } from 'react';

import {
    OrganizationDictionaryItem,
    OrganizationHierarchyLevel,
} from '@models/organizations';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import styles from '@scenes/admin/dictionaries/organizations/components/OrganizationItem.module.scss';
import Tippy from '@tippyjs/react';

const OrganizationHierarchyLevelDisplayName = (props: {
    hierarchyLevel: OrganizationHierarchyLevel;
}): JSX.Element => {
    const { t } = useTranslation();

    const getDisplayName = (
        hierarchyLevel: OrganizationHierarchyLevel
    ): string => {
        switch (hierarchyLevel) {
            case OrganizationHierarchyLevel.Branch:
                return t('admin.organizations.branch');
            case OrganizationHierarchyLevel.Office:
                return t('admin.organizations.office');
            case OrganizationHierarchyLevel.ParentOrganization:
                return t('admin.organizations.parent');
            case OrganizationHierarchyLevel.Related:
                return t('admin.organizations.related');
            case OrganizationHierarchyLevel.SubsidiaryOrganization:
                return t('admin.organizations.subsidiary');
            default:
                return '';
        }
    };

    return <span>{getDisplayName(props.hierarchyLevel)}</span>;
};

type Props = {
    organization: OrganizationDictionaryItem;
	onChangeAccreditationListFilled: (isFilled: boolean) => Promise<void>;
};

const OrganizationItem = (props: Props): JSX.Element => {
    const { organization } = props;

    const history = useHistory();

    const goToUsersTable = (): void => {
        history.push(`/admin/dictionaries/users/${organization.id}`);
    };

    const { t } = useTranslation();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isEditEnabled, setEditEnabled] = useState(true);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const renderStateCheckbox = (
		name: string,
		checked: boolean,
		onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
		disabled?: boolean
	) => {
		return <input disabled={disabled} type="checkbox" id={name} checked={checked} onChange={onChange} />;
	};

    return (
        <Tippy content={t('admin.organizations.goToUsersTable')}>
            <tr className={styles.link}>
                <td onClick={goToUsersTable}>{organization.name}</td>
                <td onClick={goToUsersTable}>{organization.email}</td>
                <td onClick={goToUsersTable}>{organization.phone}</td>
                <td onClick={goToUsersTable}>{organization.uniqueNumber}</td>
                <td onClick={goToUsersTable}>{organization.vatNumber}</td>
                <td onClick={goToUsersTable}>{organization.regNumber}</td>
                <td onClick={goToUsersTable}>
                    <OrganizationHierarchyLevelDisplayName
                        hierarchyLevel={organization.hierarchyLevel}
                    />
                </td>
				{/* <td>
					{renderStateCheckbox(
						`${organization.id}_isAccredListFilled`,
						props.organization.isAccreditationListFilled,
						async (e) => {
							e.stopPropagation();
							setEditEnabled(false);
							await props.onChangeAccreditationListFilled(e.target.checked);
							setEditEnabled(true);
						},
						!isEditEnabled
					)}
				</td> */}
            </tr>
        </Tippy>
    );
};

export default OrganizationItem;