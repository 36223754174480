import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useClipboard } from 'use-clipboard-copy';
import clsx from 'clsx';
import { FwbVersion } from '@models/awbs/awbsModels';
import AirwaybillsService from '@services/AirwaybillsService';

type Props = {
    airwaybillId: string;
    onSendFwbCompleted?: () => void;
}

const FwbPage = (props: Props) => {
    const clipboard = useClipboard({ copiedTimeout: 1000 });
    const { t } = useTranslation();
    const [version, setVersion] = useState<FwbVersion>(FwbVersion.None);
    const [fwbCode, setFwbCode] = useState('');
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        let isCurrent = true;
        const fetchFwbCode = async () => {
            const { data } = await new AirwaybillsService().getFwbCode(props.airwaybillId);
            return data;
        };

        setIsFetching(true);
        fetchFwbCode()
            .then(newFwbCode => {
                if (isCurrent) {
                    setIsFetching(false);
                    setFwbCode(newFwbCode);
                }
            });

        return () => {
            isCurrent = false;
        };
    }, [props.airwaybillId]);

    useEffect(() => {
        if (fwbCode == null) {
            return;
        }

        let newVersion = FwbVersion.Version17;

        if (fwbCode.startsWith('FWB/16')) {
            newVersion = FwbVersion.Version16;
        }

        if (newVersion != version) {
            setVersion(newVersion);
        }
    }, [fwbCode]);

    const sendFwbCode = () => {
        let isCurrent = true;
        const sendFwbAsync = async () => {
            await new AirwaybillsService().sendFwb(props.airwaybillId, fwbCode);
        }

        sendFwbAsync()
            .then(() => {
                if (isCurrent && props.onSendFwbCompleted) {
                    props.onSendFwbCompleted();
                }
            });

        return () => {
            isCurrent = false;
        };
    };

    const onVersionChange = (version: FwbVersion) => {
        let isCurrent = true;

        setVersion(version);
        const changeVersionAsync = async () => {
            const { data } = await new AirwaybillsService().changeFwbVersion(props.airwaybillId, version);
            return data;
        };

        changeVersionAsync()
            .then(newFwbCode => {
                if (isCurrent) {
                    setFwbCode(newFwbCode);
                }
            });

        return () => {
            isCurrent = false;
        };
    };

    return <>
        <h2>{t('awb.fwb')}</h2>
        <Row className="mb-3">
            <Col>
                <textarea disabled={true} ref={clipboard.target} className="form-control" style={{ height: 200 }}
                          value={fwbCode} />
            </Col>
        </Row>
        <Row>
            <Col style={{ display: 'flex', gap: 10 }}>
                <span>{t('awb.fwbVersion')}</span>
                <label><input style={{ marginRight: 10 }}
                              disabled={isFetching}
                              type="radio"
                              value="16"
                              checked={version === FwbVersion.Version16}
                              onChange={() => onVersionChange(FwbVersion.Version16)} />16</label>
                <label><input style={{ marginRight: 10 }}
                              disabled={isFetching}
                              type="radio"
                              value="17"
                              checked={version === FwbVersion.Version17}
                              onChange={() => onVersionChange(FwbVersion.Version17)} />17</label>
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                <button
                    disabled={isFetching}
                    onClick={clipboard.copy}
                    className={clsx('btn', !clipboard.copied ? 'btn-primary' : 'btn-secondary')}>
                    {clipboard.copied ? t('awb.copied') : <><i
                        className="icon-inline icon-copy" /> {t('awb.copyCode')}</>}
                </button>
                {'  '}
                <button className="btn btn-primary"
                        disabled={isFetching}
                        onClick={sendFwbCode}>
                    <i className="icon-inline icon-mail" /> {t('awb.send')}
                </button>
            </Col>
        </Row>
    </>;
};

export default FwbPage;