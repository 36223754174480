export interface LocationDto {
    id?: string | undefined;
    name?: string | undefined;
    codeIata?: string | undefined;
    parentId?: string | undefined;
    languageId?: string | undefined;
    type?: LocationType | undefined;
    parent?: LocationDto | undefined;
    children?: LocationDto[];
}

export enum LocationType
{
    Airport,
    City,
    Country
}