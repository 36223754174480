import React from "react";
import Select from "react-select";
import { GetAirlinesForApplicationsByTerm } from "@models/customerApplications/customerApplicationHistoryModels";
import DropdownIndicator from "@components/select/components/DropdownIndicator";
import SelectStyles from "@components/select/components/SelectStyles";
import AirlinesService from '@services/AirlinesService';
import { da } from 'date-fns/locale';

export type Props = {
	onChange(airlineIds: string[]): void;
	loadingMessage?: string;
	noOptionsMessage?: string;
	placeholder?: string;
	isMulti?: boolean;
    mode?: "all" | "awb only";
}

export type State = {
	isFetching: boolean
	availableOptions: Option[];
	selectedOptions: Option[];
}

export type Option = {
	value: string;
	label: string;
}

class AirlineSelect extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = {
			isFetching: false,
			availableOptions: [],
			selectedOptions: []
		}
	}

	nav: GetAirlinesForApplicationsByTerm = new GetAirlinesForApplicationsByTerm();
	private selectedIds: string[] = [];

	public reset = () => {
		this.setState({ selectedOptions: [] });
	}

	public setValue(val: string[]) {
		this.selectedIds = [];

		if (val == null || val.length == 0) {
			this.reset();
			return;
		}

		if (this.state.availableOptions.length === 0)
			this.selectedIds = val;
		else
			this.setState({ selectedOptions: this.state.availableOptions.filter(option => val.indexOf(option.value) > -1) })
	}

	componentDidMount() {
		this.search("");
	}

	private search = async (_: string) => {
		this.setState({ isFetching: true })

		const airlinesService = new AirlinesService();
		const { data } = this.props.mode === "awb only" ? await airlinesService.getAwbAirlines() : await airlinesService.getAllAirlines();

		const options: Option[] = data.map(x => ({ value: x.id, label: x.name }));
		let selectedOptions: Option[] = [];
		if (this.selectedIds.length > 0) {
			selectedOptions = options.filter(option => this.selectedIds.indexOf(option.value) > -1);
			this.selectedIds = [];
		}

		this.setState({ isFetching: false, availableOptions: options, selectedOptions: selectedOptions });
	}

	private onChange = (options: Option | Option[]) => {
		let airlineIds = [];
		if (options != null) {
			if (Array.isArray(options)) {
				airlineIds = options.map(x => x.value);
			} else {
				airlineIds.push(options.value);
			}
		}
		this.props.onChange(airlineIds);
	}

	render() {
		return <>
			<Select
				components={{ DropdownIndicator }}
				styles={SelectStyles}
				isLoading={this.state.isFetching}
				isClearable={true}
				options={this.state.availableOptions}
				value={this.state.selectedOptions || []}
				isMulti={this.props.isMulti}
				inputId="airlineIds"
				getOptionLabel={option => option.label}
				getOptionValue={option => option.value}
				placeholder={this.props.placeholder}
				loadingMessage={() => this.props.loadingMessage}
				noOptionsMessage={() => this.props.noOptionsMessage}
				onChange={selectedOptions => {
					this.setState({ selectedOptions: selectedOptions as Option[] })
					this.onChange(selectedOptions as Option[]);
				}}
			/>
		</>;
	}
}

export default AirlineSelect;