/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/ban-types
export const objectValuesAreEmptyCheck = (object: object, keyFilter?: (key: string) => boolean, additionalKeys?: string[]) => {
	let keys = Object.keys(object);
	if(keyFilter){
		keys = keys.filter(x => keyFilter(x));
	}
	if(additionalKeys && additionalKeys.length > 0){
		keys = keys.concat(additionalKeys);
	}
	for (let i = 0; i < keys.length; i++){
		if(!objectValueEmpty(object, keys[i])){
			return false;
		}
	}
	return true;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const objectValueEmpty = (object: object, key: string): boolean => {
	const value = (object[key] == null ? null : object[key]);
	return value == null || value.toString().trim() == '';
};