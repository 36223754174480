import { AirlineDictionaryItemDto } from '@models/airlines';
import AppModal from '@components/AppModal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { UploadImage } from '@scenes/admin/dictionaries/airlines/components/UploadImage';
import styles from './AirlineEditorModal.module.scss';
import { FwbVersion } from '@models/awbs/awbsModels';
import { useAppDispatch, useAppSelector } from '@root/store';
import { fetchSpecialHandlingCodes } from '@store/cargoes/specialHandlingCodesStore';
import { GetSpecialHandlingCodeDto } from '@models/cargoes';
import Input from '@components/Input';

interface IProps {
    model: AirlineDictionaryItemDto;
    onChange: (updatedModel: AirlineDictionaryItemDto, updatedImage?: File) => Promise<void>
    onCancel: () => void;
}

export const AirlineEditorModal = (props: IProps) => {
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const { specialHandlingCodes, currentVisibility } = useAppSelector((x) => x.specialHandlingCodes);
    const [eAwbShCodes, setEAwbShCodes] = useState<GetSpecialHandlingCodeDto[]>([]);
    const [securityShCodes, setSecurityShCodes] = useState<GetSpecialHandlingCodeDto[]>([]);
    const [editedModel, setEditedModel] = useState<AirlineDictionaryItemDto>(props.model);
    const [updatedImage, setUpdatedImage] = useState<File>(null);

    const eAwbCodes = ['EAP', 'EAW'];
    const securityCodes = ['NSC', 'SCO', 'SPX', 'SHR'];

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (specialHandlingCodes.length == 0 || currentVisibility !== null) {
            dispatch(fetchSpecialHandlingCodes({ visibilityType: null }));
        }
    }, []);

    useEffect(() => {
        if (specialHandlingCodes == null || specialHandlingCodes.length == 0) {
            setEAwbShCodes([]);
            setSecurityShCodes([]);
            return;
        }

        function addShCode(specialHandlingCode: GetSpecialHandlingCodeDto, codes: string[], shCodes: GetSpecialHandlingCodeDto[]) {
            if (codes.includes(specialHandlingCode.code)) {
                shCodes.push(specialHandlingCode);
                return true;
            }

            return false;
        }

        const eCodes = [{ id: '', code: '', name: t('awb.notSpecified') }];
        const sCodes = [{ id: '', code: '', name: t('awb.notSpecified') }];

        for (const specialHandlingCode of specialHandlingCodes) {
            if (addShCode(specialHandlingCode, eAwbCodes, eCodes)) {
                continue;
            }

            addShCode(specialHandlingCode, securityCodes, sCodes);
        }

        setEAwbShCodes(eCodes);
        setSecurityShCodes(sCodes);
    }, [specialHandlingCodes]);

    const updateDataAsync = async () => {
        setIsSaving(true);

        try {
            await props.onChange(editedModel, updatedImage);
            setIsSaving(false);
        } catch (e) {
            setErrorMessage(e as string);
        }
    };

    return <AppModal
        isOpen={true}
        size={'lg'}
        onClickCloseButton={() => props.onCancel()}
        body={<div className={styles.inputForm}>
            <div>
                <UploadImage
                    disabled={isSaving}
                    dropZoneClassName={styles.imageDropZone}
                    imageId={editedModel.logoId}
                    onChange={file => {
                        setUpdatedImage(file);
                    }} />
            </div>
            <div>
                <h6>{t('admin.airlines.name')}</h6>
            </div>
            <Input
                disabled={isSaving}
                type={'text'}
                placeholder={t('admin.airlines.name')}
                value={editedModel.name}
                onChange={text => setEditedModel({ ...editedModel, name: text.target.value })}
            />
            <div>
                <h6>{t('admin.airlines.fwbVersion')}</h6>
            </div>
            <div className={styles.radioControl}>
                <label>
                    <input
                        disabled={isSaving}
                        style={{ marginRight: 10 }}
                           type="radio"
                           value="16"
                           checked={editedModel.defaultFwbVersion === FwbVersion.Version16}
                           onChange={() => setEditedModel({
                               ...editedModel,
                               defaultFwbVersion: FwbVersion.Version16
                           })} />16</label>
                <label>
                    <input
                        disabled={isSaving}
                        style={{ marginRight: 10 }}
                              type="radio"
                              value="17"
                              checked={editedModel.defaultFwbVersion === FwbVersion.Version17}
                              onChange={() => setEditedModel({
                                  ...editedModel,
                                  defaultFwbVersion: FwbVersion.Version17
                              })} />17</label>

            </div>
            <div>
                <h6>{t('admin.airlines.eAwb')}</h6>
            </div>
            <div className={styles.radioControl}>
                {eAwbShCodes.map(shc => <label key={shc.id}>
                    <input
                        disabled={isSaving}
                        style={{ marginRight: 10 }}
                        type="radio"
                        value={shc.id}
                        checked={editedModel.defaultEAwbShcId === shc.id}
                        onChange={() => setEditedModel({
                            ...editedModel,
                            defaultEAwbShcId: shc.id
                        })} />{shc.code ? shc.code + ' - ' : ''}{shc.name}</label>)}
            </div>
            <div>
                <h6>{t('admin.airlines.security')}</h6>
            </div>
            <div className={styles.radioControl}>
                {securityShCodes.map(shc => <label key={shc.id}>
                    <input
                        disabled={isSaving}
                        style={{ marginRight: 10 }}
                        type="radio"
                        value={shc.id}
                        checked={editedModel.defaultSecurityShcId === shc.id}
                        onChange={() => setEditedModel({
                            ...editedModel,
                            defaultSecurityShcId: shc.id
                        })} />{shc.code ? shc.code + ' - ' : ''}{shc.name}</label>)}
            </div>
        </div>}
        footer={
            <div className={styles.buttons}>
                <Button
                    disabled={isSaving}
                    type={'button'}
                    onClick={() => updateDataAsync()}>
                    {t('save')}
                </Button>
                <Button
                    disabled={isSaving}
                    type={'button'}
                    onClick={() => props.onCancel()}>
                    {t('cancel')}
                </Button>
                {errorMessage && <div>{errorMessage}</div>}
                {isSaving && <div>{t('admin.airlines.dataSaving')}</div>}
            </div>
        }
    />;
};