import React, { useMemo } from 'react';
import i18n from '@i18n';
import DatePicker from 'reactstrap-date-picker';
import { useField } from 'formik';
import { localDateToUtcDate } from '@helpers/dateHelpers';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { KnownLocales } from './types';

type Props = {
    name: string;
    autoComplete?: string;
    minDate?: string;
    maxDate?: string;
    showClearButton?: boolean;
    disabled?: boolean;
    label?: string;
};

const LocalizableDatePicker = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(props.name);

    const { placeholder, monthLabels, dayLabels, dateFormat } = useMemo(() => {
        return {
            placeholder: KnownLocales[i18n.language].placeholder,
            monthLabels: KnownLocales[i18n.language].monthLabels,
            dayLabels: KnownLocales[i18n.language].dayLabels,
            dateFormat: KnownLocales[i18n.language].dateFormat,
        };
    }, [i18n.language]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { minDate, maxDate, showClearButton, autoComplete } = props;

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {props.label &&
                <Label for={props.name}>
                    {t('request.plannedDepartDate')}
                </Label>
            }
            <DatePicker
                disabled={props.disabled}
                id={props.name.replace('.', '_')}
                autoComplete={autoComplete || 'off'}
                showClearButton={showClearButton}
                placeholder={placeholder}
                dayLabels={dayLabels}
                monthLabels={monthLabels}
                value={field.value}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(value: string): void => {
                    const date = value != null ? localDateToUtcDate(new Date(value)).toISOString() : null;
                    helpers.setValue(date);
                }}
                dateFormat={dateFormat}
            />
            {meta?.error && <div><span className='validationMessage'>{meta.error}</span></div>}
        </div>
    );
};

export default LocalizableDatePicker;