import { IStorage } from '@models/storage.model'

class LocalStorageService implements IStorage {
    getItem<T>(key: string): T {
        return JSON.parse(localStorage.getItem(key));
    }
    setItem<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
    removeItem(key: string): void {
        localStorage.removeItem(key);
    }
}

export const localStorageService = new LocalStorageService();