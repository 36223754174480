import AuthorizedHttpClient from '@core/AuthorizedHttpClient';

export default class FilesService {
    public getImageUrl = (fileId: string) => {
        return `api/files/images/${fileId}`;
    }
	public getAttachmentUrl = (fileId: string) => {
		return `api/files/attachments/${fileId}`;
	}
	public getAttachment = async (fileId: string) => {
		const {data} = await AuthorizedHttpClient.get<Blob>(this.getAttachmentUrl(fileId), { responseType: 'blob' });
		return data;
	}
}