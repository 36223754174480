import { createAppThunk } from '@helpers/reduxHelpers';
import { createSlice } from '@reduxjs/toolkit';
import { ShcNavigation } from '@models/entityNavigation/StandardNavigation';
import { GetSpecialHandlingCodeDto, VisibilityType } from '@models/cargoes';
import CargoesService from '@services/CargoesService';

export type State = {
	isFetching: boolean;
	specialHandlingCodes: GetSpecialHandlingCodeDto[];
    currentVisibility?: VisibilityType;
};

export const fetchSpecialHandlingCodes = createAppThunk('specialHandlingCodes/fetchSpecialHandlingCodes', async (arg: {visibilityType: VisibilityType}) => {
	const nav = new ShcNavigation();
    nav.visibility = arg.visibilityType;
    nav.pagingFilter.pageSize = 100; // Load all codes.
	const { data } = await new CargoesService().getCodes(nav);

	const items = [...data.items];
	const genItem = items.find((x) => x.name.indexOf('GEN') > -1);
	if (genItem) {
		return [genItem, ...items.filter(x => x != genItem)];
	}

	return data.items;
});

const slice = createSlice({
	name: 'specialHandlingCodes',
	initialState: {
		isFetching: false,
		specialHandlingCodes: [],
	} as State,
	reducers: {},
	extraReducers: (builder) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchSpecialHandlingCodes.pending, (state, action) => {
			state.isFetching = true;
		});
		builder.addCase(fetchSpecialHandlingCodes.fulfilled, (state, action) => {
			state.isFetching = false;
			state.specialHandlingCodes = action.payload;
            state.currentVisibility = action.meta.arg.visibilityType;
		});
	},
});

export const { reducer } = slice;
export const { actions } = slice;
