export function validateAirwaybillNumber(value: string) {
	const normalizedValueStr = value?.replace(/[\s+_-]/gm, '');
	const awbNumberValidLength = 11;
	const normalizedValue = parseInt(normalizedValueStr);

	if (normalizedValueStr?.length == awbNumberValidLength && normalizedValue > 0) {
		const numStr = normalizedValueStr.substr(3, 7);
		if (numStr?.length == 7) {
			const num = parseInt(numStr);
			if (num > 0) {
				const valueToCheck = num - Math.trunc(num / 7) * 7;
				const checkableValue = parseInt(normalizedValueStr[normalizedValueStr.length - 1]);
				return checkableValue == valueToCheck;
			}
		}
	}

	return false;
}

export const airwaybillNumberMask = '999-99999999';

export function isNullOrEmptyString(value: string) {
	return value == null || value.trim() == '';
}

export function normalizeIataPrefixForAirwaybillNumber(iataPrefix: string) {
	let result = iataPrefix;

	if (result.length < 3) {
		const countOfZerosToAddPrepend = 3 - result.length;
		new Array(countOfZerosToAddPrepend).forEach(() => {
			result = '0' + result;
		});
	}

	return result;
}

export function isWhiteSpace(str?: string): boolean {
	return str === undefined || str === null || str.match(/^ *$/) !== null;
}

export type ExceptionWithCode = {
	i18nPath: string;
	args?: string[];
};

export function tryGetExceptionWithCode(message: string): ExceptionWithCode | null {
	if (isNullOrEmptyString(message) || !message.startsWith('__EXCEPTION')) {
		return null;
	}

	const splitted = message.split('::');

	const exceptionName = splitted[1];
	const exceptionCodeString = splitted[2];
	const exceptionMessageArgs = splitted[3];

	return {
		i18nPath: `exceptions.${exceptionName}.${exceptionCodeString}`,
		args: exceptionMessageArgs?.split('|'),
	};
}

export function tryGetIdentityErrors(errors: { code: string; description: string }[]) {
	if (errors?.length == null || errors.length == 0) {
		return null;
	}

	return errors.map((x) => {
		const args = [];

		if (x.code == 'PasswordTooShort') {
			const matches = x.description.match(/\d+/g);
			if (matches?.length > 0) {
				args.push(...matches);
			}
		}

		return {
			i18nPath: `identityCodes.${x.code}`,
			args,
		};
	});
}

export function getExtensionFromFileName(fileName: string) {
	const rx = /(?:\.([^.]+))?$/;
	const results = rx.exec(fileName) || null;
	if (results) {
		return results[results.length - 1];
	}
	return null;
}

export function formatBytes(a, b = 2) {
	if (0 === a) return '0 Bytes';
	const c = 0 > b ? 0 : b,
		d = Math.floor(Math.log(a) / Math.log(1024));
	return (
		parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
		' ' +
		['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
	);
}

export function getAvatarInitials(firstName: string, lastName: string) {
	if (isNullOrEmptyString(firstName)) {
		return lastName.substr(0, 2);
	}
	if (isNullOrEmptyString(lastName)) {
		return firstName.substr(0, 2);
	}
	return `${firstName[0]}${lastName[0]}`.toUpperCase();
}

export const randomString = (length: number) => {
	let rnd = '';
	while (rnd.length < length) rnd += Math.random().toString(36).substring(2);
	return rnd.substring(0, 6);
};
