import * as React from 'react';
import styles from './AdminMenu.module.scss';
import { NavLink } from 'react-router-dom';

type AdminMenuProps = {
	items: MenuItem[];
}

type MenuItem = {
	name: string;
	description?: string;
	iconClassName: string;
	path?: string;
};

const AdminMenuItem = (props: AdminMenuProps) => {

	const renderItems = (items: MenuItem[]) => {
		return items.map((x, i) => <NavLink key={i} to={x.path || '#'}>
			<a
				className={styles.tile}
				href={x.path || '#'}
			>
				<div className={styles.tileIconWrapper}>
					<span className={`${styles.tileIcon} moon-icon ${x.iconClassName}`}/>
				</div>
				<div className={styles.tileText}>
					<div className={styles.tileTitle}>{x.name}</div>
					<div className={styles.tileDescription}>{x.description}</div>
				</div>
			</a>
		</NavLink>);
	};

	return <div className={styles.tiles}>{renderItems(props.items)}</div>
}

export default AdminMenuItem;