import * as React from "react";
import styles from "./Switcher.module.scss";
import { useTranslation } from "react-i18next";

type ISwitchProps = React.InputHTMLAttributes<HTMLInputElement> & {
    texton: string;
    textoff: string;
}

const Switcher = (props: ISwitchProps): JSX.Element => {
    const { checked, texton, textoff } = props;
    const { t } = useTranslation();

    return (
        <label className={styles.switch}>
            <input type="checkbox" {...props} />
            <span className={`${styles.slider} ${checked ? "" : styles.no}`}>
                {t(checked ? texton : textoff)}
            </span>
        </label>
    );
};

export default Switcher;
