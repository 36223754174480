const cargoBookingTenant = 'cargobooking';
const ffcargoTenant = 'ffcargo';

interface TenantsTextResources {
	welcomePageMainHeader: string;
	welcomePageHeader: string;
	welcomePageCopyright: string;
	loginHeader: string;
	contactUsMainHeader: string;
	requestHeader: string;
}

class CargoBookingEnTextResources implements TenantsTextResources {
	welcomePageMainHeader = 'Cargobooking365.com';
	welcomePageHeader = 'Making Air Cargo Booking Instant and Simple';
	welcomePageCopyright = '© cargobooking365com ltd 2022';
	loginHeader = 'CargoBooking easy Way for your Cargo';
	contactUsMainHeader = '';
	requestHeader = 'Indicate the route for your shipment';
}

class CargoBookingRuTextResources implements TenantsTextResources {
	welcomePageMainHeader = 'Cargobooking365.com';
	welcomePageHeader = 'Making Air Cargo Booking Instant and Simple';
	welcomePageCopyright = '© cargobooking365com ltd 2022';
	loginHeader = 'CargoBooking easy Way for your Cargo';
	contactUsMainHeader = '';
	requestHeader = 'Укажите маршрут для Вашего груза';
}

class FFcargoEnTextResources implements TenantsTextResources {
	welcomePageMainHeader = 'FF Cargo Services';
	welcomePageHeader = 'Making Air Cargo Booking Instant and Simple';
	welcomePageCopyright = '© cargobooking365com ltd';
	loginHeader = 'Cargobooking365.com. Easy way for your Cargo';
	contactUsMainHeader = '';
	requestHeader = 'Indicate the route for your exported shipment';
}

class FFcargoRuTextResources implements TenantsTextResources {
	welcomePageMainHeader = 'FF Cargo Services';
	welcomePageHeader = 'Making Air Cargo Booking Instant and Simple';
	welcomePageCopyright = '© cargobooking365com ltd';
	loginHeader = 'Cargobooking365.com. Easy way for your Cargo';
	contactUsMainHeader = '';
	requestHeader = 'Укажите маршрут для Вашего экспортного груза';
}

type TenantsTextResourcesDictionary = {
	[key: string]: {
		ru: TenantsTextResources;
		en: TenantsTextResources;
	};
};

const translations = {
	[cargoBookingTenant]: {
		ru: new CargoBookingRuTextResources(),
		en: new CargoBookingEnTextResources()
	},
	[ffcargoTenant]: {
		ru: new FFcargoRuTextResources(),
		en: new FFcargoEnTextResources()
	}
} as TenantsTextResourcesDictionary;

const titles = {
	[cargoBookingTenant]: 'CargoBooking365',
	[ffcargoTenant]: 'FF Cargo Services'
} as {
	[key: string]: string;
};

const { REACT_APP_TENANT_ID } = process.env;

const resources = require.context(`@assets/themes/${process.env.REACT_APP_TENANT_ID}`, true);

export const logo = resources('./' + 'logo.png');

export const miniLogo = resources('./' + 'miniLogo.png');

export const background = resources('./' + 'background.png');

export let welcomeImages = {
	ataLogo: '',
	abipaLogo: '',
	ffCargoLogo: '',
	cargoWorldLogo: '',
	step4: '',
	goIcon: '',
	videoSideBar: '',
	videoBase: '',
	airPlan: '',
	traskoLogo: '',
	step2: '',
	videoSecondRow: '',
	skyCargoLogo: '',
	step3: '',
	step1: '',
	search: '',
	videoRowOpen: '',
	docs: '',
	videoMenu: '',
	videoFirstRow: '',
	videoButton: '',
	place: '',
	mapImages: [],
};

export const title = titles[REACT_APP_TENANT_ID];

export const TenantRuResources = translations[REACT_APP_TENANT_ID].ru;

export const TenantEnResources = translations[REACT_APP_TENANT_ID].en;

export const isMainTenant = REACT_APP_TENANT_ID === cargoBookingTenant;
export const isCargoTenant = REACT_APP_TENANT_ID === ffcargoTenant;

const cbCtx = require.context(`@assets/themes/cargobooking`, true);
export const poweredByLogo = cbCtx(`./logo.png`);

if (isCargoTenant) {
	const welcomeImage = (imageName: string): string => resources(`./welcome/${imageName}`);

	welcomeImages = {
		abipaLogo: welcomeImage('abipa-logo.png'),
		ataLogo: welcomeImage('ata-logo.png'),
		cargoWorldLogo: welcomeImage( 'ecargo-logo.png'),
		skyCargoLogo: welcomeImage('skycargo-logo.png'),
		videoBase: welcomeImage('base.png'),
		videoMenu: welcomeImage('menu.png'),
		videoSideBar: welcomeImage('sidebar.png'),
		videoRowOpen: welcomeImage('row-open.png'),
		videoFirstRow: welcomeImage('row-1.png'),
		videoSecondRow: welcomeImage('row-2.png'),
		videoButton: welcomeImage('button.png'),
		search: welcomeImage('search-img.png'),
		place: welcomeImage('place-img.png'),
		docs: welcomeImage('docs-img.png'),
		ffCargoLogo: welcomeImage('ffcargo-logo.svg'),
		traskoLogo: welcomeImage('trasko-logo.svg'),
		step1: welcomeImage('step-1.svg'),
		step2: welcomeImage('step-2.svg'),
		step3: welcomeImage('step-3.svg'),
		step4: welcomeImage('step-4.svg'),
		goIcon: welcomeImage('go-icon.svg'),
		airPlan: welcomeImage('air-plan.png'),
		mapImages: [
			welcomeImage('map/map-0.png'),
			welcomeImage('map/map-1.svg'),
			welcomeImage('map/map-2.svg'),
			welcomeImage('map/map-3.svg'),
			welcomeImage('map/map-4.svg'),
			welcomeImage('map/map-5.svg'),
		]
	};
}
