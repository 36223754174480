import { AirwaybillDto } from "@models/awbs/awbsModels";
import AppModal from "@components/AppModal";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { useTranslation } from "react-i18next";
import { CargoDimensionModel } from "@models/shared";
import EditableTable, { TableField, TableFieldType } from "@components/EditableTable";
import i18n from "i18next";
import { objectValuesAreEmptyCheck } from "@helpers/objectHelpers";
import { isString } from 'formik';
import styles from './CargoInfoEditModel.module.scss';
import ValidationMessage from '@components/ValidationMessage';

type Model = Pick<AirwaybillDto, 'cargoDescription' | 'cargoDimensions' | 'harmonizedCommodityCodes' | 'cargoVolume'>;

const CargoInfoEditModal = (props: {
	model: Model;
	onSave: (model: Model) => void;
	onCancel: () => void;
	isOpen: boolean;
}) => {
	const { t } = useTranslation();
	const { isOpen, onCancel, model } = props;
	const [values, setValues] = useState(model);
	const [errors, setErrors] = useState({});
	const [isVolumeDisabled, setVolumeDisabled] = useState(false);

	const checkValidationError = (name: string, value: any) => {
		const obj = {};
		if (name === nameof<Model>(m => m.cargoDescription)) {
			if (value == null || value.trim() == '' || value.trim().length == 0) {
				obj[name] = t('validation.required');
			} else {
				obj[name] = null;
			}
			return obj;
		}

		if (name === nameof<Model>(m => m.harmonizedCommodityCodes)) {
			if (value != null) {
				obj[name] = null;
				if (value.length > 12) {
					obj[name] = t('validation.tooMuchHSCodes');
				} else if (value.filter(v => v.length > 0 && v.length < 6).length > 0) {
					obj[name] = t('validation.tooShortHSCodes');
				} else if (value.filter(v => v.length > 18).length > 0) {
					obj[name] = t('validation.tooLongHSCodes');
				}
			}
			return obj;
		}

		if (name === nameof<Model>(x => x.cargoDimensions)) {
			setVolumeDisabled(value != null && value.length > 0);
		}

		return obj;
	}

	const validate = (name: string, value: any) => {
		const obj = checkValidationError(name, value);

		setErrors(obj);
	};

	useEffect(() => {
		// Validate on init.
		Object.keys(model).forEach(key => validate(key, model[key]));

		setValues(model);
	}, [model]);

	const convertToNumber = (value: string | number) =>
		+((isString(value))
			? (value as string).replace(/,/, ".")
			: value);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onChangeInput = (name: string, value: any) => {
		const obj = {};
		obj[name] = value;

		if (name == nameof<Model>(x => x.cargoDimensions) && value.length > 0) {
			let volume = 0;
			const dimensions: CargoDimensionModel[] = value;
			const coef = 0.000001; //cm3 to m3
			for (const dimension of dimensions) {
				const itemVolume = convertToNumber(dimension.amount) * convertToNumber(dimension.height)
					* convertToNumber(dimension.length) * convertToNumber(dimension.width)
					* coef;
				volume += itemVolume;
			}

			obj[nameof<Model>(x => x.cargoVolume)] = Math.round(volume * 1000) / 1000;
			setVolumeDisabled(true);
		}

		setValues({ ...values, ...obj });
		validate(name, value);
	};

	const getHarmonizedCodesForInput = (codes: string[]) => {
		return codes?.join(' ');
	};

	const getHarmonizedCodesForModel = (codesStr: string): string[] => {
		const result = codesStr
			.replace(',', ' ')
			.split(' ');

		return result;
	};

	const onSave = (values: Model) => {
		const cargoDimensions =
			values.cargoDimensions.map(x => ({
				length: x.length,
				amount: x.amount,
				height: x.height,
				width: x.width,
				weight: x.weight
			})).filter(x => !objectValuesAreEmptyCheck(x));

		cargoDimensions.forEach(model => {
			Object.keys(model).forEach(key => {
				const val = model[key];
				model[key] = val != null ? parseFloat(val) : 0;
			});
		});

		props.onSave({
			cargoDimensions,
			cargoDescription: values.cargoDescription,
			harmonizedCommodityCodes: values.harmonizedCommodityCodes,
			cargoVolume: values.cargoVolume
		});
	};

	const fields: TableField[] = useMemo(() => {
		return [{
			columnName: t('request.items'),
			placeholder: t('units.unit'),
			propertyPath: nameof<CargoDimensionModel>(x => x.amount),
			required: true,
			type: TableFieldType.Integer
		},
			{
				columnName: t('request.itemLength'),
				placeholder: t('units.cm'),
				propertyPath: nameof<CargoDimensionModel>(x => x.length),
				required: true,
				type: TableFieldType.Float
			},
			{
				columnName: t('request.itemWidth'),
				placeholder: t('units.cm'),
				propertyPath: nameof<CargoDimensionModel>(x => x.width),
				required: true,
				type: TableFieldType.Float
			},
			{
				columnName: t('request.itemHeight'),
				placeholder: t('units.cm'),
				propertyPath: nameof<CargoDimensionModel>(x => x.height),
				required: true,
				type: TableFieldType.Float
			},
			{
				columnName: t('request.itemWeight'),
				placeholder: t('units.kg'),
				propertyPath: nameof<CargoDimensionModel>(x => x.weight),
				type: TableFieldType.Float
			}
		]
	}, [i18n.language])

	return <AppModal
		onClickCloseButton={() => {
			onCancel();
		}}
        size={'lg'}
		isOpen={isOpen}
		body={<>
			<h4>{t('awb.cargoInfo')}</h4>
			<Row className={'mt-4'}>
				<Col>
					<label className={styles.textAreaLabel}>
						{t('awb.description')}
						<textarea
							style={{ minHeight: '100px' }}
							className={'form-control input-required'}
							required={true}
							value={values.cargoDescription}
							onChange={(e) => onChangeInput(nameof<Model>(x => x.cargoDescription), e.target.value)}
						/>
					</label>
                    <ValidationMessage name={nameof<Model>(x => x.cargoDescription)} errors={errors}/>
				</Col>
            </Row>
            <Row className={'mt-4'}>
				<Col>
					<label className={styles.textAreaLabel}>
						{t('harmonizedCommodityCodes')}
						<textarea
							style={{ minHeight: '100px' }}
							className={'form-control'}
							value={getHarmonizedCodesForInput(values.harmonizedCommodityCodes)}
							onChange={(e) => onChangeInput(nameof<Model>(x => x.harmonizedCommodityCodes), getHarmonizedCodesForModel(e.target.value))}
						/>
					</label>
                    <ValidationMessage name={nameof<Model>(x => x.harmonizedCommodityCodes)} errors={errors}/>
				</Col>
			</Row>
			<Row className={'mt-4'}>
				<Col md={4}>
					<label className={styles.volumeDimension}>{t('customerApplication.form.cargoVolume')}
						<input className={'form-control'}
							   key={'cargoVolume'}
							   value={values.cargoVolume || ''}
                               type={'number'}
							   onChange={e => onChangeInput(nameof<Model>(x => x.cargoVolume), e.target.value.replace(',','.'))}
							   disabled={isVolumeDisabled}/>
					</label>
					<span>{t('customerApplication.form.meters')}</span>
				</Col>
			</Row>
			<Row>
				<Col className={'mt-3'}>
					<EditableTable<CargoDimensionModel>
						modelFactory={() => ({ amount: null, height: null, weight: null, width: null, length: null })}
						initialValues={model.cargoDimensions}
						onChange={(values) => onChangeInput(nameof<Model>(x => x.cargoDimensions), values.filter(x => x.isValid && !x.isEmpty))}
						fields={fields}
						maxEmptyRowSize={4}
					/>
				</Col>
			</Row>
		</>}
		footer={<div className={'w-100'}>
			<button className={'btn btn-danger d-block float-left px-4'} onClick={(e) => {
				e.preventDefault();
				onCancel();
			}}>{t('cancel')}</button>
			{Object.keys(errors).some(key => errors[key] != null) == false &&
				<button className={'btn btn-primary btn-success btn-green d-block float-right px-5'} onClick={(e) => {
					e.preventDefault();
					onSave(values);
				}}>{t('ok')}</button>}
		</div>}
	/>
};

export default CargoInfoEditModal;