import s from '@scenes/welcome/FFCargoWelcomePage/index.module.scss';
import classNames from 'classnames';
import cn from 'classnames';
import Carousel from '@components/Carousel';
import React, { useEffect } from 'react';
import { getImageFromLogoId } from '@scenes/welcome/FFCargoWelcomePage/helper';
import { useAppDispatch, useAppSelector } from '@root/store';
import { fetchLatestReviews } from '@store/reviewStore';

export const Reviews = () => {
  const dispatch = useAppDispatch();
  const { latestReviews, isFetched } = useAppSelector(appState => appState.latestReviews);

  const reviewCards = latestReviews
    .map(review => (
      <div key={review.logoId} className={cn(s.reviewCard, s.card)}>
        <div className={s['review-logo']}>
          <img src={getImageFromLogoId(review.logoId)} alt='' />
        </div>
        <div className={s['review-content']}>
          <h4 className={s['review-title']}>{review.highlightText}</h4>
          {
            review.reviewBody
              .split('\n')
              .map((text, index) =>
                <p key={index}
                   className={classNames(s['text-l'], s['review-text'])}>{text}</p>)
          }
          <span className={s.quote} />
        </div>
        <div className={s['review-author']}>
          <p className={s['author-name']}><strong>{review.author}</strong></p>
          <div className={s['text-s']}>{review.authorPost}
          </div>
        </div>
      </div>));

  useEffect(() => {
    if (!isFetched) {
      dispatch(fetchLatestReviews());
    }
  }, []);

  return (
      <>
        <div className={classNames(s['title-line'], s['content-center'], s.column)}>
          <h2 className={classNames(s['t-l'], s['t-center'])}>Отзывы о платформе</h2>
          <p className={classNames(s['text-l'], s['t-center'])}>Опыт применения Cargobooking365.com</p>
        </div>
        <Carousel items={reviewCards} />
      </>
  );
};