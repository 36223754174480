import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

const defaultValues = [5, 10, 20, 50, 100];

export const ItemsPerPage = (
	props: {
		onChange: ((value: number) => void);
		valuesToDisplay?: number[];
		value: number;
	}
) => {

	const {t} = useTranslation();

	const [value, setValue] = useState(props.value || defaultValues[0]);

	const valuesToDisplay = useMemo(() => {

		let result: number[] = props.valuesToDisplay;

		if(result == null || result.length == 0){
			result = defaultValues;
		}

		if(!result.includes(value)){
			result.push(props.value);
		}

		result = result.sort((a, b) => a - b);

		return result;

	}, [props.valuesToDisplay, props.value]);

	const onChange = (newValue: number) => {
		props.onChange(newValue);
		setValue(newValue);
	};

	return <div className='d-inline-block ml-2'>
		<label>
			{t('paging.onPage')}{'   '}
			<select
				name='ItemsPerPage'
				className='form-control ml-1'
				style={{width: '80px', display: 'inline-block'}}
				value={value}
				onChange={e => {
					e.preventDefault();
					onChange(parseInt(e.target.value));
				}}
			>
				{valuesToDisplay.map((x, i) => <option key={i} value={x}>{x}</option>)}
			</select>
		</label>
	</div>
};