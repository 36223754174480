/* eslint-disable @typescript-eslint/no-explicit-any */
import { Util } from './utility';
import { Plane } from './Plane';

export class Port {
    public x;
    public y;
    private approachingCount = 0;

    constructor (cw: number, ch: number) {
        this.x = Util.rand(cw * 0.1, cw * 0.9);
        this.y = Util.rand(ch * 0.1, ch * 0.9);
        // while( !this.validSpacing() ) {
        //     this.x = Util.rand(cw * 0.1, cw * 0.9);
        //     this.y = Util.rand(ch * 0.1, ch * 0.9);
        // }
    }

    init = (cw: number, ch: number, ports: Port[], portSpacingDist: number): void => {
        while( !this.validSpacing(ports, portSpacingDist) ) {
            this.x = Util.rand(cw * 0.1, cw * 0.9);
            this.y = Util.rand(ch * 0.1, ch * 0.9);
        }
    }

    validSpacing = (ports: Port[], portSpacingDist: number): boolean => {
        let spaced = true,
            i: number = ports.length;
        while( i-- ) {
          const otherPort: Port = ports[i];
          if( Util.distance( otherPort, this ) < portSpacingDist ) {
            spaced = false;
            break;
          }
        }
        return spaced;
    }

    update = (i: number, planes: Plane[]): void => {
        let j: number = planes.length;
        this.approachingCount = 0;
        while( j-- ) {
          const plane: Plane = planes[j];
          if(plane.destIndex === i && plane.approaching) {
            this.approachingCount++;
          }
        }
    }

    render = (i: number, et: number, ctx: any): void => {
        ctx.beginPath();
        ctx.arc( this.x, this.y, 3 + ( this.approachingCount + 5 ), 0, Math.PI * 4 );
        ctx.fillStyle = 'hsla(210, 91%, 59%, ' + ( 0.05 + Math.sin( et / 20 ) * 0.1 ) + ')';
        ctx.fill();

        ctx.fillStyle = '#fff';
    }
}
