import {IAirport} from '@models/customerRequests/customerRequestModels';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import {AxiosResponse} from 'axios';
import { StandardNavigation } from "@models/entityNavigation/StandardNavigation";
import { IPagingWrapper } from "@models/entityNavigation/IPagingWrapper";
import { HttpError } from "@models/shared";
import { AirportDictionaryItemDto } from "@models/airports/AirportDictionaryItemDto";
import {
	FilterConnection,
	FilterOperator,
	FilterValue,
	FilterValueCondition
} from "@models/entityNavigation/filtering";
import nameof from "ts-nameof.macro";

export default class AirportsService {

    public searchByTerm = (term: string): Promise<AxiosResponse<IAirport[]>> =>
        AuthorizedHttpClient.get<IAirport[]>(`api/airport/search/${term}`);

	public findByCodeIata = (codeIata: string[]) => {
		const navState = new StandardNavigation();
		const fcnOr = new FilterConnection(FilterOperator.Or);
		for (const code of codeIata) {
			fcnOr.values.push(new FilterValue(
				nameof.full<AirportDictionaryItemDto>((x) => x.codeIata),
				FilterValueCondition.Equals,
				code
			));
		}

		navState.filters.push(fcnOr);

		return this.get(navState);
	}

	public get = (nav: StandardNavigation) => {
		return AuthorizedHttpClient.post<IPagingWrapper<AirportDictionaryItemDto> & HttpError>(
			`api/airport/dictionary`,
			nav
		);
	}

}