/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import '@icomoon/style.css';

export function getCssPropertyValue(cssSelectorName, cssPropertyName): number | string {
    const element = document.createElement(`i`);
    element.className = `${cssSelectorName} hidden`;
    document.body.appendChild(element);
    const val = window.getComputedStyle(
        element, ':before'
    ).getPropertyValue(cssPropertyName);
    element.remove();
    return val;
}

export function getIconSymbol(cssSelectorName: string): string {
    const val = getCssPropertyValue(cssSelectorName, 'content');
    if (val != null) {
        return val.toString().replace("\"", "").replace("\"", "");
    }
}