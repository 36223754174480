import {TableRow} from "./models";

export enum TableActionType {
	Init = 'Init',
	Add = 'Add',
	Remove = 'Remove',
	ChangeValue = 'ChangeValue'
}

type InitAction<TModel> = {
	type: TableActionType.Init;
	payload: { models: TableRow<TModel>[] };
};

type AddAction<TModel> = {
	type: TableActionType.Add;
	payload: { model: TableRow<TModel> };
};

type RemoveAction = {
	type: TableActionType.Remove;
	payload: { id: number };
};

type ChangeValueAction = {
	type: TableActionType.ChangeValue;
	payload: { newValue: string, name: string, id: number };
};

export const buildReducer = function<TModel>(
	isRowEmpty: ((row: TableRow<TModel>) => boolean),
	isRowValid: ((row: TableRow<TModel>) => boolean),
) {
	return function (state: TableRow<TModel>[], action: InitAction<TModel> | AddAction<TModel> | RemoveAction | ChangeValueAction): TableRow<TModel>[] {

		const sortFn = (a: TableRow<TModel>, b: TableRow<TModel>) => a.id - b.id;

		switch (action.type) {
			case TableActionType.Init:
				return action.payload.models;
			case TableActionType.Add:
				return [...state, action.payload.model];
			case TableActionType.Remove:
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				return state.filter((x, i) => x.id != action.payload.id);
			case TableActionType.ChangeValue:
				const row = {...state.find(x => x.id == action.payload.id)};
				row[action.payload.name] = action.payload.newValue;
				row.isValid = isRowEmpty(row) || isRowValid(row);
				row.isEmpty = isRowEmpty(row);
				return [...state.filter(x => x.id !== action.payload.id), row].sort(sortFn);
			default:
				return state;
		}
	}
};