import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import s from './infoCard.module.scss';
import { ReactComponent as ChevronIcon } from '@material-design-icons/svg/round/chevron_right.svg';
import Button from '@components/Button';
import Loader from '@components/Loader/index';
import cn from 'classnames';

export type InfoCardType = {
  title?: string;
  description?: string;
  expandable?: boolean;
  color?: 'default' | 'primary';
  fullHeight?: boolean;
  classNames?: string;
};
type InfoCardProps = InfoCardType & { loading?: boolean; withoutExpanded?: boolean };

const InfoCard:
  React.FC<PropsWithChildren<InfoCardProps>> = ({
                                                  children,
                                                  title,
                                                  description,
                                                  color,
                                                  fullHeight,
                                                  withoutExpanded,
                                                  loading,
                                                  expandable,
                                                  classNames,
                                                }) => {
  const memories = JSON.parse(window.localStorage.getItem('cards'));
  const defaultExpand = withoutExpanded || (memories && memories[title]);
  const [expand, setExpand] = useState<boolean>(defaultExpand);
  const [overflow, setOverflow] = useState<'visible' | 'hidden'>(
    defaultExpand ? 'visible' : 'hidden',
  );
  const ref = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    if (expand) setOverflow('hidden');
    else
      setTimeout(() => {
        setOverflow('visible');
      }, 100);
    setExpand(!expand);
  };
  useEffect(() => {
    window.localStorage.setItem('cards', JSON.stringify({ ...memories, [title]: expand }));
  }, [expand]);

  return (
    <div className={cn([s.card, [s[color]], fullHeight && s.h100])}
         style={{ ...(expandable && { height: `max-content` }), overflow }}>
      <div className={s.header}>
        <div className={s.titleWrap}>
          {title &&
            <h5
              className={s.cardTitle}
              style={{ cursor: expandable ? 'pointer' : 'initial' }}
              onClick={expandable ? toggleExpand : undefined}
            >
              {title}
            </h5>
          }
          {description &&
            <span className={s.description}>{description}</span>
          }
          {loading && <Loader className={s.loader} />}
        </div>
        {expandable && (
          <Button
            className={cn([s.expandButton, { [s.expandButtonOpen]: expand }])}
            icon={<ChevronIcon fill='currentColor' />}
            onClick={toggleExpand}
            type='text'
            variant='secondary'
          />
        )}
      </div>

      {(expand || !expandable) && (
        <div ref={ref} className={cn(s.content, fullHeight && s.h100, classNames)}>
          {children}
        </div>
      )}
    </div>
  );
};

export default InfoCard;
