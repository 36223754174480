import React, { useMemo } from 'react';
import Select, { components, OptionProps, StylesConfig } from 'react-select';
import SelectStyles from '@components/select/components/SelectStyles';
import styles from './Select.module.scss';
import DropdownIndicator from '@components/select/components/DropdownIndicator';
import { OptionTypeBase } from 'react-select/src/types';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';

export type Props<T> = {
    onChange(options: T[]): void;
    availableOptions: T[];
    optionLabel?: (option: T) => JSX.Element;
    selectedLabel?: (option: T) => JSX.Element;
    value: T[];
    checkboxes?: boolean;
    disabledSelector?: (option: T) => boolean;
    fixedSelector?: (option: T) => boolean;
    formatOptionLabel?: (option: T) => JSX.Element;
    label?: string;
} & Omit<
    OptionTypeBase,
    | 'options'
    | 'value'
    | 'getOptionLabel'
    | 'getOptionValue'
    | 'loadOptions'
    | 'onChange'
    | 'defaultOptions'
    | 'defaultValue'
    | 'loadingOptions'
    | 'noOptionsMessage'
    | 'label'
>;

export default function StaticSelect<T>(props: Props<T>) {
    const selectedLabel = props.selectedLabel || props.optionLabel || props.formatOptionLabel;

    const disabledOptions = useMemo(() => {
        if (!props.disabledSelector) {
            return [];
        }

        return props.availableOptions.filter(props.disabledSelector);
    }, [props.availableOptions, props.disabledSelector]);

    const fixedOptions = useMemo(() => {
        if (!props.fixedSelector) {
            return [];
        }

        return props.availableOptions.filter(props.fixedSelector);
    }, [props.availableOptions, props.fixedSelector]);

    const customStyles = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const s: Partial<StylesConfig> = { ...SelectStyles };


        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const dynamicStyles: Partial<StylesConfig> = {
            option: (base, state) => {

                const disabled = fixedOptions.includes(state.data) || disabledOptions.includes(state.data);

                const pointerEvents = disabled ? 'none' : 'all';

                if (props.checkboxes == true) {
                    return {
                        ...base,
                        backgroundColor: disabled ? '#bcbcbc' : 'white',
                        color: 'black',
                        opacity: disabled ? '0.8' : '1',
                        pointerEvents,
                    };
                } else {
                    return { ...base, pointerEvents };
                }
            },
            multiValue: (base, state) => {
                return fixedOptions.includes(state.data)
                    ? { ...SelectStyles.multiValue(base, state), backgroundColor: '#8f8f8f' }
                    : { ...base, ...SelectStyles.multiValue(base, state) };
            },
            multiValueLabel: (base, state) => {
                return fixedOptions.includes(state.data)
                    ? {
                        ...SelectStyles.multiValueLabel(base, state),
                        color: 'white',
                        fontWeight: 'normal',
                        paddingRight: 6,
                    }
                    : { ...base, ...SelectStyles.multiValueLabel(base, state) };
            },
            multiValueRemove: (base, state) => {
                return fixedOptions.includes(state.data) ? { ...base, display: 'none' } : { ...base };
            },
        };

        return { ...s, ...dynamicStyles };
    }, [props.checkboxes, fixedOptions]);

    const MultiValue = (providedProps) => {
        return (
            components.MultiValue && (
                <components.MultiValue {...providedProps} isDisabled={disabledOptions.includes(providedProps.data)}>
                    {selectedLabel(providedProps.data)}
                </components.MultiValue>
            )
        );
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Option = ({ ...providedProps }: OptionProps<T>) => {
        const optionLabel = props.optionLabel || props.formatOptionLabel;

        if (props.checkboxes == true) {
            return (
                components.Option && (
                    <components.Option {...providedProps} isDisabled={disabledOptions.includes(providedProps.data)}>
                        <div className={styles.checkboxWrapper}>
                            <input
                                type='checkbox'
                                className='form-control'
                                defaultChecked={providedProps.isSelected}
                                disabled={disabledOptions.includes(providedProps.data)}
                            />
                        </div>
                        {' '}
                        {optionLabel(providedProps.data)}
                    </components.Option>
                )
            );
        }

        return (
            components.Option && (
                <components.Option {...providedProps} isDisabled={disabledOptions.includes(providedProps.data)}>
                    {optionLabel(providedProps.data)}
                </components.Option>
            )
        );
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const SingleValue = ({ ...providedProps }: OptionProps<T>) => {
        return (
            components.SingleValue && (
                <components.SingleValue {...providedProps}>{selectedLabel(providedProps.data)}</components.SingleValue>
            )
        );
    };

    const { t } = useTranslation();

    const onChange = (options: T[]) => {
        if (options == null) {
            options = [];
        } else if (!Array.isArray(options)) {
            // Normalize for TS signature.
            options = [options];
        }

        props.onChange(options);
    };

    const selectProps = { ...props };
    delete selectProps.onChange;
    delete selectProps.value;
    delete selectProps.label;

    if (props.checkboxes == true) {
        selectProps.closeMenuOnSelect = false;
        selectProps.hideSelectedOptions = false;
    }

    // Normalize value.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let val: any = props.value;
    if (!props.isMulti && Array.isArray(val)) {
        val = val[0];
    } else if (props.isMulti && Array.isArray(val)) {
        const fixedOptionsInVal = val.filter((x) => fixedOptions.includes(x));
        val = [...fixedOptionsInVal, ...val.filter((x) => !fixedOptions.includes(x))];
    }

    if (val == undefined) {
        val = null;
    }

    return (
        <div className={styles.container}>
            {props.label &&
                <Label>{props.label}</Label>
            }
            <Select
                components={{ DropdownIndicator, Option, SingleValue, MultiValue, ...props.components }}
                styles={{ ...selectProps.styles, ...customStyles }}
                options={props.availableOptions}
                value={val}
                getOptionValue={(x) => JSON.stringify(x)}
                defaultOptions={props.availableOptions}
                loadingMessage={() => t('options.loadingOptions')}
                noOptionsMessage={() => t('options.noOptions')}
                onChange={(newSelectedOptions) => {
                    let opts = newSelectedOptions;
                    if (opts == null) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        opts = [];
                    } else if (!Array.isArray(opts)) {
                        // Normalize TS signature for single value.
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        opts = [opts as any];
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange(opts as any);
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...(selectProps as any)}
                placeholder={props.placeholder}
                isClearable={fixedOptions.length > 0 ? (props.isClearable == null || props.isClearable == true) : false}
            />
        </div>
    );
}