/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOGIN_REDIRECT_URL } from '@config/ApiAuthorizationConstants';
import SessionManager from '../SessionManager';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class AuthorizeRoute extends Component<any> {
  render() {
    const authenticated = SessionManager.isAuthenticated();
    const { component: Component, ...rest } = this.props;

    return <Route {...rest} component={(props) => {
      return authenticated
        ? <Component {...props} />
        : <Redirect to={{ pathname: LOGIN_REDIRECT_URL }} />;
    }} />;
  }
}