import React from 'react';
import { ContactListItemDto } from '@models/contacts';
import ContactCircle from './ContactCircle';
import styles from './ContactCard.module.scss';

import PhoneSvg from '@assets/svg/phone.svg';
import MailSvg from '@assets/svg/mail.svg';
import { getAvatarInitials, isNullOrEmptyString } from '@helpers/stringHelpers';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

type ContactCardProps = {
	data: ContactListItemDto;
};

const ContactCard = (props: ContactCardProps): JSX.Element => {
	const { data } = props;
	const { t } = useTranslation();
	return (
		<div className={clsx(styles.card, data.inactive ? styles.inactive : null)}>
			<NavLink to={`/account#?tabId=employees&id=${data.id}`}>
				<div className={styles.editBtn}>
					<span>
						<i className="icon-edit" />
					</span>
				</div>
			</NavLink>

			<div className={styles.info}>
				<div style={{ marginRight: '20px' }}>
					<ContactCircle
						initials={getAvatarInitials(data.firstName, data.lastName)}
						canLogin={data.canLogin}
						inactive={data.inactive}
						isBig={false}
					/>
				</div>
				<div>
					<div className={styles.name}>{`${data.firstName} ${data.lastName}`}</div>
					<div className={styles.job}>
						{data.jobTitle}
					</div>
				</div>
			</div>
			<div className={styles.contacts}>
				<div>
					<div className={styles.imgWrapper}>
						<img src={MailSvg} />
					</div>
					<span>
						<a href={`mailto:${data.email}`}>{data.email}</a>
					</span>
				</div>

				{!isNullOrEmptyString(data.phone) && (
					<div>
						<div className={styles.imgWrapper}>
							<img src={PhoneSvg} />
						</div>
						<span>
							<a href={`tel:${data.phone}`}>{data.phone}</a>
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default ContactCard;