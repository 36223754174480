import { RoutesScheduleDto } from '@models/routesModels';
import {difference, min, max} from 'lodash';
import { DateTime } from '@helpers/DateTime';

export function getInitialDatesToLoadSchedules(date: DateTime, today: DateTime): DateTime[] {

    const todayWithResettedTime = today.clone();
    todayWithResettedTime.resetTime();

    const result = date.clone();
    result.resetTime();

    const daysNext = 4;
    let daysBack = 4;

    if (result == today) {
        daysBack = 0;
    } else {
        const daysBackFromSelectedTillToday = result.diffDaysCount(todayWithResettedTime);
        if (daysBack > daysBackFromSelectedTillToday) {
            daysBack = daysBackFromSelectedTillToday;
        }
    }

    if (daysBack != 0) {
        result.addDays(-daysBack);
    }

    const daysToLoad = daysBack + daysNext + 2;

    return result.arrangeDates(daysToLoad);
}

export function getNewDatesToLoadSchedules
(newDate: DateTime, existingRoutesSchedules: RoutesScheduleDto[], today: DateTime): DateTime[] {

    const newDateWithResettedTime = newDate.clone();
    newDateWithResettedTime.resetTime();

    const todayWithResettedTime = today.clone();
    todayWithResettedTime.resetTime();

    const threshold = 5;
    const daysToLoadAfterReachThreshold = 4;

    const loadedDatesFromRoutes = existingRoutesSchedules.map(x => new DateTime(x.dateStart));
    const minFromLoaded = min(loadedDatesFromRoutes);
    const maxFromLoaded = max(loadedDatesFromRoutes);

    if (newDateWithResettedTime == todayWithResettedTime) {
        return [];
    }

    const minDaysDiff = newDateWithResettedTime.diffDaysCount(minFromLoaded);
    const maxDaysDiff = maxFromLoaded.diffDaysCount(newDateWithResettedTime);

    let daysOffset = 0;
    let startDayForBuildingDates: DateTime = null;

    if (maxDaysDiff < threshold) {
        startDayForBuildingDates = maxFromLoaded.clone();
        startDayForBuildingDates.addDays(1);
        daysOffset = daysToLoadAfterReachThreshold;
    } else if (minDaysDiff < threshold) {
        startDayForBuildingDates = minFromLoaded.clone();
        //startDayForBuildingDates.subtract(2, 'day');
        daysOffset = -daysToLoadAfterReachThreshold;
    } else {
        return [];
    }

    const newDAys = daysOffset != 0 ?
        difference(
            startDayForBuildingDates
                .arrangeDates(daysOffset)
                .filter(x => x >= todayWithResettedTime).map(x => x.toISOString()),
            existingRoutesSchedules.map(x => x.dateStart),
        )
        : [];
    
    return newDAys.map(x => new DateTime(x));
}