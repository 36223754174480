import {createBrowserHistory} from "history";

const getBrowserHistory = () => {
    
    const baseUrl = document
        .getElementsByTagName('base')[0]
        .getAttribute('href') as string;

    return createBrowserHistory({ basename: baseUrl });
};

export default getBrowserHistory;