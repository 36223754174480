import { TokenType } from '@models/awbs/awbsModels';

export interface TokenInfo {
  value: TokenType;
  labelKey: string;
}

export enum EditState {
  None,
  Confirming,
  Confirmed,
  Closed
}

export enum CityEditState {
  None,
  NeedToCreate,
  Creating,
  Saving
}

export const tokenTypes: TokenInfo[] = [
  {
    value: TokenType.Unknown,
    labelKey: 'awb.unknown',
  },
  {
    value: TokenType.Address,
    labelKey: 'awb.address',
  },
  {
    value: TokenType.AccountNumber,
    labelKey: 'awb.accountNumber',
  },
  {
    value: TokenType.Name,
    labelKey: 'awb.name',
  },
  {
    value: TokenType.PostalCode,
    labelKey: 'awb.postCode',
  },
  {
    value: TokenType.Place,
    labelKey: 'awb.place',
  },
  {
    value: TokenType.Phone,
    labelKey: 'awb.contact',
  },
];