import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styles from "./Paginator.module.scss";
import { Button } from '@root/components';
import {ReactComponent as IconLeft} from '@material-design-icons/svg/round/chevron_left.svg';
import {ReactComponent as IconRight} from '@material-design-icons/svg/round/chevron_right.svg';
import cs from '@assets/styles/common.module.scss';
import cn from 'classnames';

export type Props = {
    totalResults: number;
    limitPerPage: number;
    currentPage: number;
    pageNeighbours?: number;
    onChangePage: (pageNum: number) => void;
} & WithTranslation;

export type State = {
    pageNeighbours: number,
    totalPages: number,
    currentPage: number
};

enum PageDirection {
    Left = "LEFT",
    Right = "RIGHT"
}

class Paginator extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = this.recalculatePages(props);
    }

    private recalculatePages = (props: Props): {
		currentPage: number,
		pageNeighbours: number,
		totalPages: number
	} => {
        const { totalResults = null, limitPerPage, pageNeighbours = 0 } = this.props;

        const neighbours = Math.max(0, Math.min(pageNeighbours, 2));

        const totalPages = Math.ceil(totalResults / limitPerPage);

        return  {
            currentPage: props.currentPage,
            pageNeighbours: neighbours,
            totalPages
        };
    }

    private range = (from: number, to: number, step = 1): number[] => {

        let i = from;

        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }

        return range;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
        this.setState({...this.recalculatePages(nextProps)})
    }

    private fetchPageNumbers = () => {

        const totalPages = this.state.totalPages;
        const currentPage = this.state.currentPage;
        const pageNeighbours = this.state.pageNeighbours;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (this.state.pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {


            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let pages: any[] = this.range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = this.range(startPage - spillOffset, startPage - 1);
                    pages = [PageDirection.Left, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = this.range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, PageDirection.Right];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [PageDirection.Left, ...pages, PageDirection.Right];
                    break;
                }
            }

            return [1, ...pages, totalPages];

        }

        return this.range(1, totalPages);
    }

    private gotoPage = page => {

        const currentPage = Math.max(1, Math.min(page, this.state.totalPages));

        const paginationData = {
            currentPage,
            totalPages: this.state.totalPages,
            pageLimit: this.props.limitPerPage,
            totalRecords: this.props.totalResults
        };

        this.setState({
                currentPage
            },
            () => this.props.onChangePage(paginationData.currentPage - 1)
        );
    }

    private handleClick = page => {
        this.gotoPage(page);
    }

    private handleMoveLeft = () => {
        this.gotoPage(this.state.currentPage - (this.state.pageNeighbours * 2) - 1);
    }

    private handleMoveRight = () => {
        this.gotoPage(this.state.currentPage + (this.state.pageNeighbours * 2) + 1);
    }

    render() {

        if (!this.props.totalResults || this.state.totalPages === 1)
            return null;

        const { currentPage } = this.state;
        const pages = this.fetchPageNumbers();

        return (
                <div className={cn(cs.justFlex)}>
                    <ul className={styles.paginator} style={{marginTop: "revert"}}>
                        <li key={"back"}>
                            <Button
                              type="text"
                              variant="primary"
                              icon={<IconLeft fill="currentColor" />}
                              onClick={() => this.gotoPage(this.state.currentPage - 1)}
                            />
                        </li>

                        { pages.map((page, index) => {
                            if (page === PageDirection.Left) return (
                                <li key={index}>
                                    <Button
                                      type="text"
                                      variant="white"
                                      onClick={this.handleMoveLeft}
                                    >
                                        ...
                                    </Button>
                                </li>
                            );

                            if (page === PageDirection.Right) return (
                                <li key={index}>
                                    <Button
                                      type="text"
                                      variant="white"
                                      onClick={this.handleMoveRight}
                                    >
                                        ...
                                    </Button>
                                </li>
                            );

                            return (
                                <li key={index}>
                                    <Button
                                      disabled={page === currentPage}
                                      type={currentPage === page ? "fill" : "text"}
                                      variant={currentPage === page ? "primary" : "white"}
                                      onClick={() => this.handleClick(page)}
                                    >
                                        {page}
                                    </Button>
                                </li>
                            );

                        }) }

                        <li key={"front"}>
                            <Button
                              type="text"
                              variant="primary"
                              icon={<IconRight fill="currentColor" />}
                              onClick={() => this.gotoPage(this.state.currentPage + 1)}
                            />
                        </li>

                    </ul>
                </div>
            
        );

    }
}

export default withTranslation()(Paginator);