import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { LOGIN_REDIRECT_URL } from '@config/ApiAuthorizationConstants';
import i18n from 'i18next';
import AccountService from '@services/AccountService';
import SessionManager from '../../SessionManager';
import { IUser } from '@models/accounts/IUser';

type LoginProps = RouteComponentProps<LoginRouteParam>;

type State = {
    error?: string;
};

interface LoginRouteParam {
    requestId: string;
}
class ExLoginCallback extends React.Component<LoginProps, State> {
    translate = i18n.t; // useTranslation();

    constructor(props) {
        super(props);

        this.state = {};
    }

    navigateToReturnUrl(returnUrl: string): void {
        this.props.history.replace(returnUrl);
    }

    private externalLoginCallback = async (
        requestId: string
    ): Promise<void> => {
        const accountService = new AccountService();
        const getTokenResult = await accountService.getMyTokenByExternalAuth(
            requestId
        );

        SessionManager.setUserAsync(getTokenResult.data as IUser);
        this.navigateToReturnUrl('/request');
    };

    componentDidMount() {
        const requestId = this.props.match.params.requestId;

        if (!requestId) {
            this.navigateToReturnUrl(LOGIN_REDIRECT_URL);
        }

        this.externalLoginCallback(requestId).catch((error) => {
            this.setState({
                error: error.response.data.message,
            });
        });
    }

    render(): JSX.Element {
        return <div>{this.state?.error}</div>;
    }
}

export default withRouter(ExLoginCallback);