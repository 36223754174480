import { useTranslation } from 'react-i18next';
import { ReactComponent as IconOpen } from '@material-design-icons/svg/round/visibility.svg';
import { ReactComponent as IconRefresh } from '@material-design-icons/svg/round/refresh.svg';
import { useHistory } from 'react-router';
import {
  AirportDto,
  GetCustomerRequestHistoryItemDto,
  GetCustomerRequestHistoryItemDto_LocationDto,
} from '@models/customerRequests/customerRequestHistoryModels';
import * as React from 'react';
import { useState } from 'react';
import listStyles from '@assets/list.module.scss';
import routePopoverStyles from '@assets/routePopover.module.scss';
import { Collapse, Popover, PopoverBody } from 'reactstrap';
import { isoUtcDateToLocalString, isoUtcDateToShortLocalString } from '@helpers/dateHelpers';
import { ReactComponent as Airplane } from '@assets/svg/legacy/airplane.svg';
import { ReactComponent as ArrowRightShort } from '@assets/svg/legacy/arrow_right_short.svg';
import { LocationDto } from '@models/locations';
import { splitCode } from '@helpers/specialHandlingCodesHelpers';
import clsx from 'clsx';
import Button from '@components/Button';
import cn from 'classnames';
import { Badge, PageHeader, ParamCard } from '@root/components';
import cs from '@assets/styles/common.module.scss';
import scenesCS from '../../common.module.scss';
import styles from '../styles.module.scss';
import i18n from '@i18n';
import moment from 'moment';

type Props = {
  data: GetCustomerRequestHistoryItemDto;
};

const getCode = (airport: AirportDto, location: LocationDto): string => {
  if (airport) {
    return airport.codeIata;
  }
  return location?.codeIata ?? '-';
};

const getDisplayName = (airport: AirportDto, location: GetCustomerRequestHistoryItemDto_LocationDto): string => {
  if (airport) {
    return `${airport.name}, ${airport.location?.cityName}, ${airport.location?.countryName}`;
  }
  return `${location?.cityName}, ${location?.countryName}`;
};

const CustomerRequestsHistoryItem = ({ data }: Props) => {
  const [isOpen, toggleOpen] = useState(false);
  const [showRoutePopover, toggleRoutePopover] = useState(false);
  const [showExpectedShippingDatePopover, toggleExpectedShippingDatePopover] = useState(false);
  
  const routePopoverId = `route-${data.id}`;
  const expectedShippingDatePopoverId = `date-${data.id}`;

  const { t } = useTranslation();

  const history = useHistory();

  const i18tColumnNames = [
    'customerRequestsHistory.items',
    'customerRequestsHistory.itemLength',
    'customerRequestsHistory.itemWidth',
    'customerRequestsHistory.itemHeight',
    'customerRequestsHistory.itemWeight',
  ];

  const columnTitles = React.useMemo(() => {
    return <>{i18tColumnNames.map((x, i) => <span className={scenesCS.header} key={i}>{t(x)}</span>)}</>;
  }, [i18n.language]);

  return (
    <>
      <div
        id={data?.id}
        className={cn(scenesCS.itemCard)}
        onClick={() => {
          toggleOpen(!isOpen);
        }}
      >
        <div className={cs.flex}
             id={expectedShippingDatePopoverId}
             onMouseEnter={(): void => toggleExpectedShippingDatePopover(true)}
             onMouseLeave={(): void => toggleExpectedShippingDatePopover(false)}
        >
          {isoUtcDateToShortLocalString(data.createTime)}
        </div>
        <div className={cs.flex}
             id={expectedShippingDatePopoverId}
             onMouseEnter={(): void => toggleExpectedShippingDatePopover(true)}
             onMouseLeave={(): void => toggleExpectedShippingDatePopover(false)}
        >
          {isoUtcDateToShortLocalString(data.dateStartPlan)}
        </div>
        <div className={cs.flex}
             onMouseEnter={(): void => toggleRoutePopover(true)}
             onMouseLeave={(): void => toggleRoutePopover(false)}
        >
							<span id={routePopoverId}>
								<span className='pr-2'>{getCode(data.sourceAirport, data.sourceLocation)}</span>
								<i className={clsx(listStyles.arrowRightIcon, `icon-arrow_right_long`)} />
								<span className='pl-2'>{getCode(data.targetAirport, data.targetLocation)}</span>
							</span>
        </div>

        <div className={cs.flex}>
          <Badge label={data.specialHandlingCodes?.map((x) => splitCode(x.name).code).join(', ') || '-'}
                 color='blue' />
        </div>

        <div className={cs.flex}>
          <Badge label={data.natureOfCargo?.name} color='white' />
        </div>

        <div className={cn(cs.flex, cs.gap10)}>
          <Badge label={`${data.cargoTotalAmount} ${t('units.pc')}`} color='blue' />
          <Badge label={`${data.cargoTotalWeight} ${t('units.kg')}`} color='blue' />
          <Badge label={`${data.cargoTotalVolume} ${t('units.m3')}`} color='blue' />
        </div>

        <div className={cn(styles.colButtons, cs.flex)}>
          <Button
            title={t('customerRequestsHistory.repeatSearch')}
            icon={<IconRefresh fill='currentColor' />}
            type='outline'
            variant='white'
            onClick={() => history.push(`request/${data.id}`)} />
          <Button
            title={t('customerRequestsHistory.viewSearchResults')}
            icon={<IconOpen fill='currentColor' />}
            type='outline'
            variant='white'
            onClick={() => history.push(`request/search?id=${data.id}`)} />
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        <div
          className={cn(cs.flexColumn, scenesCS.collapseData, cs.card, cs.gap20, isOpen && [scenesCS.bordered, scenesCS.moreShadow])}>
          {data &&
            <div className={cn(cs.flexColumn, cs.gap10, scenesCS)}>
              <PageHeader size='subHeader' title={t('customerRequestsHistory.general')} />
              <div className={cn(cs.flex, cs.gap10)}>
                <ParamCard classList={styles.fullWidthCard} paramName={t('request.requestDate')}
                           icon={null}>
                  {data.createTime ? isoUtcDateToLocalString(data.createTime) : null}
                </ParamCard>
                <ParamCard classList={styles.fullWidthCard} paramName={t('customerRequestsHistory.totalItems')}
                           icon={null}>
                  {data.cargoTotalAmount}
                </ParamCard>
                <ParamCard classList={styles.fullWidthCard} paramName={t('customerRequestsHistory.totalWeight')}
                           icon={null}>
                  {data.cargoTotalWeight}{t('units.kg')}
                </ParamCard>
                <ParamCard classList={styles.fullWidthCard} paramName={t('customerRequestsHistory.totalVolume')}
                           icon={null}>
                  {data.cargoTotalVolume} {t('units.m3')}
                </ParamCard>
                {data.cargoDetails &&
                  <ParamCard classList={styles.fullWidthCard} paramName={t('request.cargoDetails')}
                             icon={null}>
                    {data.cargoDetails}
                  </ParamCard>
                }
                <ParamCard classList={styles.fullWidthCard} paramName={t('requestSearchResults.requestNumber')}
                           icon={null}>
                  {data.number}
                </ParamCard>
              </div>
            </div>
          }
          {data?.cargoes && data?.cargoes.length > 0 &&
            <div className={cn(scenesCS.cargoes, cs.gap10)}>
              <PageHeader size='subHeader' title={t('customerRequestsHistory.dimensionsOfEachPackage')} />
              <div className={scenesCS.separateHeader}>{columnTitles}</div>
              {data?.cargoes.map((x, i) =>
                <div className={scenesCS.rowCargo} key={i}>
                  <div className={scenesCS.data}>
                      <span className={scenesCS.header}>
                        {t(columnTitles[0])}
                      </span>
                    <span className={scenesCS.column}>
                        <span>{x.amount}</span>
                      </span>
                  </div>
                  <div className={scenesCS.data}>
                      <span className={scenesCS.header}>
                        {t(columnTitles[1])}
                      </span>
                    <span className={scenesCS.column}>
                        <span>{x.length} {t('units.cm')}</span>
                      </span>
                  </div>
                  <div className={scenesCS.data}>
                      <span className={scenesCS.header}>
                        {t(columnTitles[2])}
                      </span>
                    <span className={scenesCS.column}>
                        <span>{x.width} {t('units.cm')}</span>
                      </span>
                  </div>
                  <div className={scenesCS.data}>
                      <span className={scenesCS.header}>
                        {t(columnTitles[3])}
                      </span>
                    <span className={scenesCS.column}>
                        <span>{x.height} {t('units.cm')}</span>
                      </span>
                  </div>
                  <div className={scenesCS.data}>
                      <span className={scenesCS.header}>
                        {t(columnTitles[4])}
                      </span>
                    <span className={scenesCS.column}>
                        <span>{x.weight} {t('units.kg')}</span>
                      </span>
                  </div>
                </div>,
              )}
            </div>
          }
        </div>
      </Collapse>

      {/* Route popover. */}
      <Popover placement='bottom' target={routePopoverId} isOpen={showRoutePopover}>
        <PopoverBody className={routePopoverStyles.popover}>
          <div className={routePopoverStyles.header}>
            <div className={routePopoverStyles.airplane}>
              <Airplane />
            </div>
            <div className={routePopoverStyles.codes}>
              <span>{getCode(data.sourceAirport, data.sourceLocation)}</span>
              <ArrowRightShort />
              <span>{getCode(data.targetAirport, data.targetLocation)}</span>
            </div>
          </div>

          <div className={routePopoverStyles.info}>
            <div>
              <b>{t('customerRequestsHistory.source')}</b>
              <div>{getDisplayName(data.sourceAirport, data.sourceLocation)}</div>
            </div>
            <div>
              <b>{t('customerRequestsHistory.target')}</b>
              <div>{getDisplayName(data.targetAirport, data.targetLocation)}</div>
            </div>
          </div>
        </PopoverBody>
      </Popover>

      {/* Expected shipping date popover. */}
      <Popover placement='top' target={expectedShippingDatePopoverId} isOpen={showExpectedShippingDatePopover}>
        <PopoverBody>{t('customerRequestsHistory.expectedShippingDate')}</PopoverBody>
      </Popover>
    </>
  );
};

export default CustomerRequestsHistoryItem;