import { ReactComponent as UploadCloudSvg } from '@assets/svg/upload-cloud.svg';
import clsx from 'clsx';
import styles from '@components/Upload.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import FilesService from '@services/FilesService';

type Props = {
    imageId?: string;
    onChange?: (file: File) => void;
    dropZoneClassName?: string;
    disabled?: boolean;
};

export const UploadImage = (props: Props) => {
    const [fileToUpload, setFileToUpload] = useState<File>(null);
    const [localImage, setLocalImage] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.imageId == null && fileToUpload == null) {
            setLocalImage(null);
            return;
        }

        if (fileToUpload != null) {
            const fr = new FileReader();
            props.onChange(fileToUpload);
            fr.onload = function() {
                setLocalImage(fr.result);
            };
            fr.readAsDataURL(fileToUpload);
            return;
        }

        const filesService = new FilesService();

        setLocalImage(filesService.getImageUrl(props.imageId));
    }, [props.imageId, fileToUpload]);

    const onDrop = useCallback(_ => {
        // Do something with the files
    }, []);

    const dropzoneOpts: DropzoneOptions = {
        disabled: props.disabled,
        onDrop,
        accept: ['image/jpeg', 'image/png', 'image/gif'],
        multiple: false,
        onDropAccepted: (files: File[]) => setFileToUpload(files[0]),
        onDropRejected: _ => alert(t('upload.cantUploadFile'))
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOpts);

    return <div
        className={clsx(styles.uploadField, isDragActive ? styles.uploadFieldDraggable : null, props.dropZoneClassName)} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={styles.imgWrapper}>
            {
                localImage
                    ? <img
                        className={styles.image}
                        src={localImage}
                        width={'30%'}
                        alt={''} />
                    : <UploadCloudSvg />
            }

            <div>
            {isDragActive ?
                <>
                    <div className={clsx(styles.text, isDragActive ? styles.textDraggable : null)}>
                        {t('upload.dropFilesHere')}
                    </div>
                </> :
                <>
                    <div className={styles.fileFormats}>
                        .jpg .png .gif
                    </div>
                    <div className={styles.text}>
                        {t('upload.dragAndDropFileHere')}
                    </div>
                </>}
            </div>

        </div>
    </div>;
};