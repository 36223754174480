import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, FormGroup, Alert } from 'reactstrap';
import loginStyle from './Login.module.scss';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import HttpClient from "@core/HttpClient";

type State = {
    success: boolean;
    errors: string[];
    submitting: boolean;
};

type Props = RouteComponentProps & WithTranslation;

class ResetPasswordRequest extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            success: false,
            errors: [],
            submitting: false,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async submitForm(event: any): Promise<void> {
        event.preventDefault();
        const data = new FormData(event.target);

        this.setState({
            errors: [],
            submitting: true,
            success: false,
        });

        try {
            await HttpClient.post('api/Account/ResetPasswordRequest', {
                email: data.get('email'),
            });
            this.setState({
                success: true,
            });
        } catch (error) {
            const errors =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                error.response && error.response.status === 400
                    ? ['loginErrors.notFoundUserMsg']
                    : ['loginErrors.somethingWrong'];
            this.setState({
                success: false,
                errors: errors,
            });
        } finally {
            this.setState({
                submitting: false,
            });
        }
    }

    navigateToReturnUrl(returnUrl: string): void {
        this.props.history.replace(returnUrl);
    }

    render() {
        return (
            <div className="simple-layout-content-container">
                <Form className={loginStyle.form} onSubmit={this.submitForm}>
                    <FormGroup row>
                        <h1 className="header-30">
                            {this.props.t('resetPasswordRequest.header')}
                        </h1>
                    </FormGroup>
                    <FormGroup row>
                        <p className="paragraph">
                            {this.props.t('resetPasswordRequest.subHeader')}
                        </p>
                    </FormGroup>
                    <FormGroup row>
                        <Input
                            type="email"
                            placeholder={this.props.t(
                                'resetPasswordRequest.email'
                            )}
                            className="w-100 input-text-primary"
                            required
                            name="email"
                        />
                    </FormGroup>
                    <FormGroup row>
                        <Button
                            color="primary"
                            className="w-100"
                            type="submit"
                            disabled={this.state.submitting}
                        >
                            {this.props.t('resetPasswordRequest.reset')}
                        </Button>
                    </FormGroup>
                    <FormGroup row>
                        {this.state.success && (
                            <Alert color="success" className="w-100">
                                {this.props.t(
                                    'resetPasswordRequest.successResetMsg'
                                )}
                            </Alert>
                        )}
                        {!this.state.success &&
                            this.state.errors.map((error, index) => (
                                <Alert
                                    key={index}
                                    color="danger"
                                    className="w-100"
                                >
                                    {this.props.t(error)}
                                </Alert>
                            ))}
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

export default connect()(withTranslation()(withRouter(ResetPasswordRequest)));
