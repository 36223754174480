import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import Button from 'reactstrap/lib/Button';
import { UnitModes } from '@root/enum';

interface IMultiSwitchOption {
    labelRes: string;
    value: UnitModes;
}

interface IMultiSwitchProps {
    options: IMultiSwitchOption[];
    defaultValue?: string;
    onChange: (val: UnitModes) => void;
}

const MultiSwitch = (props: IMultiSwitchProps): JSX.Element => {
    const { options, defaultValue, onChange } = props;
    const { t } = useTranslation();
    const [selected, setSelected] = React.useState<string>(defaultValue);

    const onButtonChange = (newValue: UnitModes): void => {
        setSelected(newValue);
        onChange(newValue);
    };

    return (
        <ButtonGroup>
            {options?.map((opt) => (
                <Button
                    key={opt.value}
                    color='primary'
                    outline
                    onClick={() => onButtonChange(opt.value)}
                    active={selected === opt.value}
                >
                    {t(opt.labelRes)}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default MultiSwitch;