import * as React from 'react';

import { Field, FieldArray, FieldProps, FormikProps } from 'formik';

import {Container, Row, Col, Input, Label} from 'reactstrap';

import styles from './CargoDimensions.module.scss';

import InputNumber from '@components/InputNumber';

import MoonIcon from '@components/MoonIcon';
import { useTranslation } from 'react-i18next';

import { getIn } from 'formik';

import nameof from "ts-nameof.macro";
import { CreateCustomerRequestModel } from '@models/customerRequests/formModels';
import { nullIfNan } from '@helpers/Numbers';
import FieldError from './FieldError';
import {CargoDimensionDto} from "@models/customerRequests/customerRequestModels";

interface Props {
    formikProps: FormikProps<CreateCustomerRequestModel>;
}

const CargoDimensions = (props: Props): JSX.Element => {

    const { t } = useTranslation();

    const {formikProps} = props;

    return (
        <FieldArray
            name={nameof.full<CreateCustomerRequestModel>(x => x.cargoDimensions)}
            render={({ push, remove }): JSX.Element => (
                <>
                    {formikProps.values.cargoDimensions.map((cargoDimension, index) =>
						{
							const fieldPath = `${nameof.full<CreateCustomerRequestModel>(x => x.cargoDimensions)}[${index}]`;

							return <Container key={index} className={styles.cargoRow}>
								<Row>
									<Col className={styles.alignBottom}>
										<Field name={`${fieldPath}.${nameof<CargoDimensionDto>(x => x.amount)}`}>
											{({ field, form }: FieldProps) =>
												<InputNumber
													id={field.name}
													label={t('request.items')}
													hasError={
														getIn(form.errors, field.name) && getIn(form.touched, field.name)
													}
													{...field}
													onBlur={form.setFieldTouched}
													onChange={(name, value) => {
														// eslint-disable-next-line @typescript-eslint/no-explicit-any
														let val: any = value;
														if(val != null){
															val = nullIfNan(parseInt(val)) || '';
														}
														formikProps.setFieldValue(field.name, val);
													}}
												/>
											}
										</Field>
									</Col>
									<Col className={styles.alignBottom}>
										<Field
											name={`${fieldPath}.length`}
										>
											{({field, form}: FieldProps) => (
												<div className='inputControl'>
													<Label for={field.name}>{t('request.itemLength')}</Label>
													<Input
														placeholder={t('units.cm')}
														className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
														{...field}
													/>
													<FieldError
														error={form.errors[field.name] as string}
														isTouched={form.touched[field.name] as boolean}
													/>
												</div>
											)}
										</Field>
									</Col>
									<Col className={styles.alignBottom}>
										<Field name={`${fieldPath}.width`}>
											{({field, form}: FieldProps) => (
												<div className='inputControl'>
													<Label for={field.name}>{t('request.itemWidth')}</Label>
													<Input
														placeholder={t('units.cm')}
														className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
														{...field}
													/>
													<FieldError
														error={form.errors[field.name] as string}
														isTouched={form.touched[field.name] as boolean}
													/>
												</div>
											)}
										</Field>
									</Col>
									<Col className={styles.alignBottom}>
										<Field name={`${fieldPath}.height`}>
											{({field, form}: FieldProps) => (
												<div className='inputControl'>
													<Label for={field.name}>{t('request.itemHeight')}</Label>
													<Input
														placeholder={t('units.cm')}
														className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
														{...field}
													/>
													<FieldError
														error={form.errors[field.name] as string}
														isTouched={form.touched[field.name] as boolean}
													/>
												</div>
											)}
										</Field>
									</Col>
									<Col className={styles.alignBottom}>
										<Field name={`${fieldPath}.weight`}>
											{({field, form}: FieldProps) => (
												<div className='inputControl'>
													<Label for={field.name}>{t('request.itemWeight')}</Label>
													<Input
														placeholder={t('units.kg')}
														disabled={formikProps.values.useTotalWeight}
														className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
														{...field}
													/>
													<FieldError
														error={form.errors[field.name] as string}
														isTouched={form.touched[field.name] as boolean}
													/>
												</div>
											)}
										</Field>
									</Col>
								</Row>
								{index !== 0 && (
									<button
										type="button"
										className={styles.removeBtn}
										onClick={(): void => {
											remove(index);
										}}
									>
										<MoonIcon icon="icon-trash" className="moon-1x" />
									</button>
								)}
							</Container>;
						}
                    )}
                    <button
                        className={styles.plusBtn}
                        type="button"
                        onClick={(): void => {
                            push({
                                amount: 1,
                                weight: 0,
                                height: 0,
                                length: 0,
                                width: 0
                            } as CargoDimensionDto);
                        }}
                    >
                        <MoonIcon icon="icon-plus" className="moon-1x" />
                    </button>
                </>
            )}
        />
    );
};

export default CargoDimensions;