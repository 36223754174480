import { useAppDispatch, useAppSelector } from '@root/store';
import { getAirlines, uploadAirlineLogo, changeFwbVersion } from '@store/airlinesStore';
import React, { useEffect, useMemo, useState } from 'react';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { AirlineDictionaryItemDto } from '@models/airlines';
import { Col, Row } from 'reactstrap';
import dictionaryStyles from '../../components/DictionaryStyles.module.scss';
import clsx from 'clsx';
import AirlineTrItem from '@scenes/admin/dictionaries/airlines/components/AirlineTrItem';
import { useTranslation } from 'react-i18next';
import UploadImageModal from '@scenes/admin/dictionaries/airlines/components/UploadImageModal';
import { HttpError, UploadProgress } from '@models/shared';
import {
	FilterConnection,
	FilterOperator,
	FilterValue,
	FilterValueCondition,
} from '@models/entityNavigation/filtering';
import debounce from 'awesome-debounce-promise';
import SortSelect, { createSortSelectValue, SortSelectValue } from '@components/select/SortSelect';
import nameof from 'ts-nameof.macro';
import { PropertySorter, SortDirection } from '@models/entityNavigation/sorting';
import styles from './AirlinesPage.module.scss';
import Paginator from '@components/paginator/Paginator';
import { Loader } from '@components/index';
import i18n from 'i18next';
import PageHeader from '@components/PageHeader';

const createNavState = () => {
    const nav = new StandardNavigation();

    const fcnOr = new FilterConnection(FilterOperator.Or);

    const nameFilterValue = new FilterValue(
        nameof.full<AirlineDictionaryItemDto>((x) => x.name),
        FilterValueCondition.Contains
    );
    const codeIataFilterValue = new FilterValue(
        nameof.full<AirlineDictionaryItemDto>((x) => x.codeIata),
        FilterValueCondition.Contains
    );
    const codeIcaoFilterValue = new FilterValue(
        nameof.full<AirlineDictionaryItemDto>((x) => x.codeIcao),
        FilterValueCondition.Contains
    );
    const iataPrefixFilterValue = new FilterValue(
        nameof.full<AirlineDictionaryItemDto>((x) => x.iataPrefix),
        FilterValueCondition.Contains
    );
    const codeIso2CountryFilterValue = new FilterValue(
        nameof.full<AirlineDictionaryItemDto>((x) => x.codeIso2Country),
        FilterValueCondition.Contains
    );

    fcnOr.values.push(
        nameFilterValue,
        codeIataFilterValue,
        codeIcaoFilterValue,
        iataPrefixFilterValue,
        codeIso2CountryFilterValue
    );

    nav.filters.push(fcnOr);

    // Create sorters.

    const sorter = new PropertySorter();

    nav.sorters.push(sorter);

    return {
        nav,
        nameFilterValue,
        codeIataFilterValue,
        codeIcaoFilterValue,
        iataPrefixFilterValue,
        codeIso2CountryFilterValue,
        sorter,
    };
};

let navState: ReturnType<typeof createNavState> = null;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AirlinesPage = () => {
    const { airlines, isFetching } = useAppSelector((x) => x.airlines);

    const [isUploadImageModalOpened, toggleUploadImageModal] = useState(false);
    const [
        selectedAirline,
        setSelectedAirline,
    ] = useState<AirlineDictionaryItemDto>(null);

    const dispatch = useAppDispatch();

    const getDebounced = debounce(
        (nav: StandardNavigation, resetPaging: boolean) => {
            if (resetPaging) {
                nav.pagingFilter.pageNumber = 0;
            }
            dispatch(getAirlines(nav));
        },
        800
    );

    useEffect(() => {
        if (navState == null) {
            navState = createNavState();
            dispatch(getAirlines(navState.nav));
        }

        return () => {
            setSelectedAirline(null);
            navState = null;
        };
    }, [navState?.nav]);

    const onClickUploadLogo = (airline: AirlineDictionaryItemDto) => {
        setSelectedAirline(airline);
        toggleUploadImageModal(true);
    };

    const onChangeFwbVersion = (airline: AirlineDictionaryItemDto, fwbVersion: number) => {
        dispatch(changeFwbVersion({airlineId: airline.id, fwbVersion: fwbVersion}))
    };

    const uploadFile = async (file: File, onUploadProgress: UploadProgress) => {
        return new Promise<string & HttpError>((resolve, reject) => {
            dispatch(uploadAirlineLogo({
                        airlineId: selectedAirline.id,
                        file,
                        onUploadProgress,
                    }))
                .then(value => resolve(value.payload as string & HttpError))
                .catch(reject);
        });
    };

    const onSearchByTerm = (term: string) => {
        term = term?.trim();

        let val = null;

        if (term != null && term != '') {
            val = term;
        }

        navState.nameFilterValue.value = val;
        navState.codeIataFilterValue.value = val;
        navState.codeIcaoFilterValue.value = val;
        navState.codeIso2CountryFilterValue.value = val;
        navState.iataPrefixFilterValue.value = val;

        getDebounced(navState.nav, true);
    };

    const onChangePage = (pageNum: number) => {
        navState.nav.pagingFilter.pageNumber = pageNum;
        getDebounced(navState.nav, false);
    };

    const { t } = useTranslation();

    const availableSortProperties = useMemo(() => [
		{
			label: t('sorting.name'),
			propertyName: nameof.full<AirlineDictionaryItemDto>((x) => x.name),
		},
	], [i18n.language]);

    const [sorting, setSorting] = useState(createSortSelectValue(availableSortProperties[0], SortDirection.Ascending));

	const onChangeSort = (val?: SortSelectValue) => {
		setSorting(val);
		navState.sorter.path = val?.propertyName;
		navState.sorter.sortDirection = val?.sortDirection;
		getDebounced(navState.nav, false);
	};

	return (
        <>
            <PageHeader title={t('admin.airlines.airlines')} backUrl="/admin/dictionaries" />
            <Row className="mb-3">
                <Col md={8}>
                    <input
                        type="text"
                        className={clsx('form-control', styles.termFilter)}
                        placeholder={t('admin.airlines.search')}
                        onKeyUp={(e) => onSearchByTerm(e.currentTarget.value)}
                    />
                </Col>
                <Col md={4}>
                    <SortSelect
                        availableProperties={availableSortProperties}
                        onChange={(value) => {
                            onChangeSort(value);
                        }}
						value={sorting}
                    />
                </Col>
            </Row>

            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <Row>
                        <Col>
                            <table
                                className={clsx(
                                    'table table-hover table-responsive-sm',
                                    dictionaryStyles.table
                                )}
                            >
                                <thead>
                                    <tr>
                                        <th key={'img'}>
                                            {t('admin.airlines.logo')}
                                        </th>
                                        <th key={'name'}>
                                            {t('admin.airlines.name')}
                                        </th>
                                        <th key={'codeIata'}>IATA</th>
                                        <th key={'codeIcao'}>ICAO</th>
                                        <th key={'iataPrefix'}>
                                            {t('admin.airlines.iataPrefix')}
                                        </th>
                                        <th key={'codeIso2Country'}>
                                            {t(
                                                'admin.airlines.iso2CountryCode'
                                            )}
                                        </th>
                                        <th>
                                        {t('admin.airlines.fwbVersion')}
                                        </th>
                                        <th>
                                        {t('admin.airlines.eAwb')}
                                        </th>
                                        <th>
                                        {t('admin.airlines.security')}
                                        </th>
                                        <th key={'manage'}/>
                                    </tr>
                                </thead>
                                <tbody>
                                    {airlines?.items?.map((x) => (
                                        <AirlineTrItem
                                            key={x.id}
                                            model={x}
                                            onClickUploadLogoImage={onClickUploadLogo}
                                            onChangeFwbVersion={fwbVersion => onChangeFwbVersion(x, fwbVersion)}
                                            onUpdate={() => {
                                                dispatch(getAirlines(navState.nav))
                                            }}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Paginator
                                totalResults={airlines?.totalCount}
                                limitPerPage={
                                    navState?.nav?.pagingFilter.pageSize
                                }
                                currentPage={
                                    navState?.nav?.pagingFilter.pageNumber + 1
                                }
                                onChangePage={(p) => onChangePage(p)}
                                pageNeighbours={4}
                            />
                        </Col>
                    </Row>
                </>
            )}

            <UploadImageModal
                isOpen={isUploadImageModalOpened}
                onUpload={uploadFile}
                onClickCloseButton={() => toggleUploadImageModal(false)}
                airline={selectedAirline}
            />
        </>
    );
};

export default AirlinesPage;