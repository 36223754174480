import { useField } from "formik";
import Async from "react-select/async";
import DropdownIndicator from "@components/select/components/DropdownIndicator";
import nameof from "ts-nameof.macro";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AirlineDto } from "@models/customerRequests/customerRequestModels";
import AirlinesService from "@services/AirlinesService";
import { useDebouncedCallback } from "use-debounce";
import { OptionsType } from "react-select";
import { OptionTypeBase } from "react-select/src/types";
import { StandardNavigation } from "@models/entityNavigation/StandardNavigation";
import {
	FilterConnection,
	FilterOperator,
	FilterValue,
	FilterValueCondition
} from "@models/entityNavigation/filtering";
import { AirlineDictionaryItemDto } from "@models/airlines";
import { PropertySorter } from "@models/entityNavigation/sorting";

interface Props {
	disabled?: boolean;
	name: string;
    onChange: (airline: AirlineDto) => void;
}

export const AirlineField = (props: Props) => {
	const [ nameField, meta, helpers ] = useField(props.name);

	const {t} = useTranslation();
	const [ airlines, setAirlines ] = useState<AirlineDto[]>([]);
	const [ firstCarrier, setFirstCarrier ] = useState<AirlineDto>(null);

	const fetchAirlinesAsync = async () => {
		const airlineService = new AirlinesService();
		const {data: airlines} = await airlineService.getAllAirlines();
		return airlines;
	}

	useEffect(() => {
		let isCurrent = true;

		fetchAirlinesAsync()
			.then(airlines => {
				if (!isCurrent) {
					return;
				}

				setAirlines(airlines);
			});

		return () => {
			isCurrent = false;
		};
	}, []);

	useEffect(() => {
		if (nameField.value == null) {
			return;
		}

		const firstCarrier = airlines.find(a => a.id == nameField.value);
		setFirstCarrier(firstCarrier);
	}, [ nameField ]);

	const createNavState = () => {
		const nav = new StandardNavigation();

		const fcnOr = new FilterConnection(FilterOperator.Or);

		const nameFilterValue = new FilterValue(
			nameof.full<AirlineDictionaryItemDto>((x) => x.name),
			FilterValueCondition.Contains
		);
		const codeIataFilterValue = new FilterValue(
			nameof.full<AirlineDictionaryItemDto>((x) => x.codeIata),
			FilterValueCondition.Contains
		);
		const codeIcaoFilterValue = new FilterValue(
			nameof.full<AirlineDictionaryItemDto>((x) => x.codeIcao),
			FilterValueCondition.Contains
		);
		const iataPrefixFilterValue = new FilterValue(
			nameof.full<AirlineDictionaryItemDto>((x) => x.iataPrefix),
			FilterValueCondition.Contains
		);
		const codeIso2CountryFilterValue = new FilterValue(
			nameof.full<AirlineDictionaryItemDto>((x) => x.codeIso2Country),
			FilterValueCondition.Contains
		);

		fcnOr.values.push(
			nameFilterValue,
			codeIataFilterValue,
			codeIcaoFilterValue,
			iataPrefixFilterValue,
			codeIso2CountryFilterValue
		);

		nav.filters.push(fcnOr);

		// Create sorters.

		const sorter = new PropertySorter();

		nav.sorters.push(sorter);

		return {
			nav,
			nameFilterValue,
			codeIataFilterValue,
			codeIcaoFilterValue,
			iataPrefixFilterValue,
			codeIso2CountryFilterValue,
			sorter,
		};
	};

	const searchDebounced = useDebouncedCallback((term, callback: (options: OptionsType<OptionTypeBase>) => void) => {
		const fetchTerms = async () => {
			const navState = createNavState();
			navState.nameFilterValue.value = term;
			navState.codeIataFilterValue.value = term;
			navState.codeIcaoFilterValue.value = term;
			navState.codeIso2CountryFilterValue.value = term;
			navState.iataPrefixFilterValue.value = term;
			const {data} = await new AirlinesService().get(navState.nav);
			callback(data.items);
		};

		fetchTerms();
	});

	const searchFirstCarrier = (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
		if (inputValue != null && inputValue.length >= 2) {
			searchDebounced(inputValue, callback);
		}
	};

	return <>
		<Async
			isDisabled={props.disabled}
			options={airlines}
			value={firstCarrier}
			isClearable
			components={{DropdownIndicator}}
			loadOptions={searchFirstCarrier}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.id}
			onChange={value => {
                const airlineId = value?.id;
                helpers.setValue(airlineId);
				const airlineDto = airlines.find(a => a.id == airlineId);
				setFirstCarrier(airlineDto);
                props.onChange(airlineDto);
			}}
			placeholder={t('awb.carrier')}
		/>
		{meta.error && <div><span className="validationMessage">{meta.error}</span></div>}
	</>;
}