export enum WeightCategory {
  Minimal = 852250010,
  Normal = 852250009,
  Tarif_45_Plus = 852250000,
  Tarif_100_Plus = 852250001,
  Tarif_250_Plus = 852250003,
  Tarif_300_Plus = 852250002,
  Tarif_500_Plus = 852250004,
  Tarif_1000_Plus = 852250005,
  Tarif_1500_Plus = 557050001
}