import { StaffDto } from '@models/demoBooking/StaffDto';
import { createAppThunk } from '@helpers/reduxHelpers';
import DemoBookingService from '@services/DemoBookingService';
import { createSlice } from '@reduxjs/toolkit';

export type State = {
	staffMap: Record<string, StaffDto[]>;
	isFetched: boolean;
	isFetching: boolean;
}

export const getStaffs = createAppThunk('demo-booking-services/staffs',
	async (serviceId: string) => {
		const { data } = await new DemoBookingService().getStaffs(serviceId);
		return data;
	});

const slice = createSlice({
	name: 'demo-booking-services-staff',
	initialState: {
		services: [],
		staffMap: {},
		isFetching: false,
		isFetched: false
	} as State,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getStaffs.pending, (state, action) => {
			const serviceId = action.meta.arg;
			state.staffMap[serviceId] = [];
			state.isFetched = false;
			state.isFetching = true;
		});

		builder.addCase(getStaffs.fulfilled, (state, action) => {
			const serviceId = action.meta.arg;
			state.staffMap[serviceId] = action.payload;
			state.isFetched = true;
			state.isFetching = false;
		});

		builder.addCase(getStaffs.rejected, (state, action) => {
			const serviceId = action.meta.arg;
			state.staffMap[serviceId] = [];
			state.isFetched = false;
			state.isFetching = false;
		});
	}
});

export const { reducer } = slice;
export const { actions } = slice;