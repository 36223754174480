import React from 'react';
import { UserDictionaryItem } from '@models/users';

type Props = {
    user: UserDictionaryItem;
};

const UserItem = (props: Props): JSX.Element => {
    const { user } = props;

    return (
        <tr>
            <td>{user.userName}</td>
            <td>{user.email}</td>
            <td>{user.jobTitle}</td>
            <td>{user.organizationName}</td>
            <td>{user.contactBackOfficeId}</td>
        </tr>
    );
};

export default UserItem;
