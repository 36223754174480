export enum SettingCategory {
    UserInterface = 1,
    Email = 2,
}

export type SettingsEntryDto = {
    id?: string | undefined;
    category?: SettingCategory | undefined;
    key?: string | undefined;
    value?: string | undefined;
    languageId?: string | undefined;
    inactive?: boolean | undefined;
    isPublic?: boolean;
    comment?: string;
}

export type CreateSettingsEntry = {
    category?: SettingCategory | undefined;
    key?: string | undefined;
    value?: string | undefined;
    languageId?: string | undefined;
    inactive?: boolean | undefined;
	isPublic?: boolean;
	comment?: string;
}

export type UpdateSettingsEntry = {
    id?: string | undefined;
    category?: SettingCategory | undefined;
    key?: string | undefined;
    value?: string | undefined;
    languageId?: string | undefined;
    inactive?: boolean | undefined;
	isPublic?: boolean;
	comment?: string;
}

export type ServiceStateEntry = {
	startServiceMode?: Date;
	endServiceMode?: Date;
}