import {createAppThunk} from '@helpers/reduxHelpers';
import {createSlice} from '@reduxjs/toolkit';
import { GetSaleDto, SalesFilteringEntities, SaleStatus } from '@models/customerRequests/customerRequestModels';
import SalesService from '@services/SalesService';
import {GetSales, GetSalesFilteringEntities} from '@models/sales';

type State = {
    sales: GetSaleDto[];
    isSalesFetching: boolean;
    filteringEntities: SalesFilteringEntities;
    isFilteringEntitiesFetching: boolean;
}

const getStatus = (s: string): SaleStatus => {
    switch (s) {
        case '1':
            return SaleStatus.Available;
        case '2':
            return SaleStatus.UponRequest;
        case '3':
            return SaleStatus.Embargo;
        case '4':
            return SaleStatus.Restrictions;
        case '5':
            return SaleStatus.AdHoc;
        case '6':
            return SaleStatus.NotAvailable;
        case '7':
            return SaleStatus.Promo;
    }
};

export const fetchFilteringEntities = createAppThunk(
    'sales/fetchFilteringEntities',
    async (arg: GetSalesFilteringEntities) => {
        arg.filter.statuses = arg.filter.statuses.map(s => getStatus(s.toString()));
        const {data} = await new SalesService().getFilteringEntities(arg);
        return data;
    }
);

export const fetchSales = createAppThunk(
    'sales/fetchSales',
    async (arg: GetSales) => {
        arg.filter.statuses = arg.filter.statuses.map(s => getStatus(s.toString()));
        const {data} = await new SalesService().getSales(arg);
        return data;
    }
);

const slice = createSlice({
    name: "routes",
    initialState: {
        sales: [],
        isSalesFetching: true,
        filteringEntities: {
            airlines: [],
            products: [],
            statuses: [],
            departures: [],
            destinations: []
        },
        isFilteringEntitiesFetching: true
    } as State,
    reducers: {
        resetSales(state) {
            state.sales = [];
            state.isSalesFetching = true;
        },
        resetFilteringEntities(state) {
            state.filteringEntities = {
                airlines: [],
                products: [],
                statuses: [],
                departures: [],
                destinations: []
            };
            state.isFilteringEntitiesFetching = true;
        }
    },
    extraReducers: builder => {

        builder.addCase(fetchSales.pending, (state) => {
            state.isSalesFetching = true;
        });
        builder.addCase(
            fetchSales.fulfilled,
            (state, action) => {
                state.sales = action.payload;
                state.isSalesFetching = false;
            });

        builder.addCase(
            fetchFilteringEntities.pending, (state) => {
                state.isFilteringEntitiesFetching = true;
            });
        builder.addCase(fetchFilteringEntities.fulfilled, (state, action) => {
            state.filteringEntities = action.payload;
            state.isFilteringEntitiesFetching = false;
        });
    }
});

export const {reducer} = slice;
export const {actions} = slice;