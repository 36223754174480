/* eslint-disable @typescript-eslint/no-explicit-any */
import { Util } from './utility';
import { Port } from './Port';

export class Plane {
  public approaching: boolean;
  public destIndex: number;

  private originIndex: number;
  private origin: Port;
  private dest: Port;
  private path;
  private x;
  private y;
  private vx;
  private vy;
  private ox;
  private oy;
  private vmax;
  private accel;
  private decel;
  private angle;
  private holding;
  private speed;

  constructor (ports: Port[]) {
    this.originIndex = Util.randInt(0, ports.length - 1);
    this.origin = ports[ this.originIndex ];
    this.path = [];
    this.x = this.origin.x;
    this.y = this.origin.y;
    this.vx = Util.rand( -0.35, 0.35 );
    this.vy = Util.rand( -0.35, 0.35 );
    this.vmax = 1;
    this.accel = 0.01;
    this.decel = 0.96;
    this.angle = 0;
    this.approaching = false;
    this.holding = false;
    this.setDest(ports);
  }

  setDest = (ports: Port[]): void => {
    if (this.destIndex !== undefined && ports) {
      this.originIndex = this.destIndex;
      this.origin = ports[ this.originIndex ];
    }
    this.destIndex = Util.randInt(0, ports.length - 1);
    while( this.destIndex === this.originIndex ) {
      this.destIndex = Util.randInt(0, ports.length - 1);
    }
    this.dest = ports[ this.destIndex ];
    this.approaching = false;
    this.holding = false;
  }

  update = (i: number, tick: number, opt: any, dt, cw: number, ch: number, ports: Port[]): void => {
    this.ox = this.x;
    this.oy = this.y;
    if( tick % opt.pathSpacing === 0 ) {
      this.path.push( { x: this.x, y: this.y } );
    }
    if( this.path.length > opt.pathCount ) {
      this.path.shift();
    }

    this.angle = Util.angle(this.dest, this);
    this.speed = ( Math.abs( this.vx ) + Math.abs( this.vy ) ) / 2;

    if( !Util.pointInRect( this.x, this.y, { x: 0, y: 0, width: cw, height: ch } ) ) {
      this.vx *= this.decel;
      this.vy *= this.decel;
    }

    if( this.speed > 0.1 ) {
      if( Util.distance( this.dest, this ) < opt.approachDist ) {
        this.vx *= this.decel;
        this.vy *= this.decel;
        this.approaching = true;
     }
    }

    if( Util.distance( this.dest, this ) < opt.holdingDist ) {
      this.holding = true;
      this.setDest(ports);
    }

    this.vx += Math.cos( this.angle ) * this.accel;
    this.vy += Math.sin( this.angle ) * this.accel;
    if( this.speed > this.vmax ) {
      this.vx *= this.decel;
      this.vy *= this.decel;
    }

    this.x += this.vx * dt;
    this.y += this.vy * dt;
  }

  render = (i: number, opt: any, ctx: any, tick: number): void => {
    if( this.approaching ) {
      ctx.strokeStyle = 'rgba(55, 150, 246, 0.2)';
    } else {
      ctx.strokeStyle = 'hsla(180, 80%, 50%, 1)';
    }

    ctx.beginPath();
    ctx.moveTo( this.x, this.y );
    const angle2 = Util.angle( { x: this.ox, y: this.oy }, this );
    ctx.lineWidth = 2;
    ctx.lineTo(
      this.x - Math.cos( angle2 ) * ( 3 + this.speed * 2 ),
      this.y - Math.sin( angle2 ) * ( 3 + this.speed * 2 )
    );
    ctx.stroke();

    const pathLength: number = this.path.length;
    if( pathLength > 1) {
      ctx.strokeStyle = 'hsla(210, 91%, 59%, 0.1)';
      ctx.lineWidth = 1;
      ctx.beginPath();

      let x2: number, y2: number;
      if( pathLength >= opt.pathCount ) {
        const angle3 = Util.angle( this.path[ 1 ], this.path[ 0 ] ),
            dx = this.path[ 0 ].x - this.path[ 1 ].x,
            dy = this.path[ 0 ].y - this.path[ 1 ].y,
            dist = Math.sqrt( dx * dx + dy * dy );
        x2 = this.path[ 0 ].x + Math.cos( angle3 ) * ( dist * ( ( tick % opt.pathSpacing ) / opt.pathSpacing ) );
        y2 = this.path[ 0 ].y + Math.sin( angle3 ) * ( dist * ( ( tick % opt.pathSpacing ) / opt.pathSpacing ) );
      } else {
        x2 = this.path[ 0 ].x;
        y2 = this.path[ 0 ].y;
      }

      ctx.moveTo( x2, y2 );
      for( let i = 1; i < pathLength; i++ ) {
        const point = this.path[ i ];
        ctx.lineTo( point.x, point.y );
      }
      ctx.lineTo( this.x, this.y );
      ctx.stroke();
    }
  }
}
