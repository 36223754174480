import { GetSaleDto, SalesFilteringEntities, SaleStatus } from '@models/customerRequests/customerRequestModels';
import { AxiosResponse } from 'axios';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { HttpError } from '@models/shared';
import { GetSales, GetSalesFilteringEntities, GetSalesPdfDocument, SendSalesDocumentToEmail } from '@models/sales';

export default class SalesService {
    public getSale = async (saleId: string, currencyCode?: string) => {
        if (saleId) {
            let url = `api/sales/${saleId}`;
            url += currencyCode ? `?currencyCode=${currencyCode}` : '';
            return await AuthorizedHttpClient.get<GetSaleDto & HttpError>(url);
        }
    };

    public getFilteringEntities = async (m: GetSalesFilteringEntities) => {
        return await AuthorizedHttpClient.post<SalesFilteringEntities>('api/sales/filteringEntities', m);
    };

    public getSales = async (m: GetSales) => {
        return await AuthorizedHttpClient.post<GetSaleDto[] & HttpError>('api/sales', m);
    };

    public downloadDocument = async (model: GetSalesPdfDocument, departureName: string, destinationName: string, requestNumber: string) => {
        const { data } = await AuthorizedHttpClient.post(`api/sales/pdf`, model, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.setAttribute('download', `${departureName}_${destinationName}_${requestNumber}.pdf`);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
    };

    public downloadHtml = async (model: GetSalesPdfDocument, departureName: string, destinationName: string, requestNumber: string) => {
        const { data } = await AuthorizedHttpClient.post(`api/sales/html`, model);
        return data;
    };

    public sendSalesDocumentToEmail = async (model: SendSalesDocumentToEmail): Promise<AxiosResponse> => {
        return await AuthorizedHttpClient.post('api/sales/email', model);
    };

    public askDiscount = async (saleId: string, comments: string): Promise<AxiosResponse> => {
        return await AuthorizedHttpClient.post('api/sales/discount', {
            saleId,
            comments,
        });
    };
}