import * as React from 'react';
import { useTranslation } from 'react-i18next';
import s from './ColumnTitles.module.scss';
import { PropsWithChildren } from 'react';

export type Props = {
  i18tColumnNames: string[];
};

const ColumnTitles: React.FC<PropsWithChildren<Props>> = ({ i18tColumnNames }) => {
  const { t } = useTranslation();

  return (
    <div className={s.headerTitles}>
      {
        i18tColumnNames.map((x, i) => <span className={s.headerTitlesCol} key={i}>{t(x)}</span>)
      }
    </div>
  );
};

export default ColumnTitles;