import React from 'react';
import NumberFormat from 'react-number-format';
import s from './currency.module.scss';
import { KnownCurrencies } from '@models/KnownCurrencies';

interface Props {
  currencyCode: string;
  amount: number;
}

const Currency: React.FC<Props> = ({ currencyCode, amount }) => {
  const prefix = KnownCurrencies[currencyCode]?.sign || currencyCode;
  return (
    <span className={s.text}>
			{
        amount > 0 ?
          <NumberFormat
            decimalScale={2}
            value={amount}
            displayType={'text'}
            thousandSeparator={' '}
            prefix={prefix}
          /> : 'X'
      }
        </span>
  );
};

export default Currency;
