import { AddressDatumDto } from '@models/awbs/awbsModels';
import React, { useEffect, useRef } from 'react';
import s from './RenderAddressRow.module.scss';
import cn from 'classnames';

export const RenderAddressRow = (props: {
  addressDatum: AddressDatumDto;
  isSelected: boolean;
  onSelectionChange: () => void;
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (props.isSelected && props.addressDatum.id && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

  }, [props.isSelected]);

  return (
    <tr
      key={props.addressDatum.id}
      className={cn(props.isSelected ? s.selected : null)}
      ref={ref}
      onClick={() => props.onSelectionChange()}
    >
      <td style={{maxWidth: "500px"}}>
        {props.addressDatum.name}
      </td>
      <td>
        {props.addressDatum.accountNumber}
      </td>
      <td>
        {props.addressDatum.scannedRawData}
      </td>
    </tr>
  );
};