import React, {PropsWithChildren} from 'react';
import s from "./propCard.module.scss";

interface IPropCard {
  label: string;
  value?: string;
}

const PropCard: React.FC<PropsWithChildren<IPropCard>> = ({children, label, value}) => {
  return (
    <div className={s.container}>
      <div className={s.labelProp}>{label}</div>
      <div className={s.content}>{children ? children : value}</div>
    </div>
  );
};

export default PropCard;