import clsx from 'clsx';
import styles from './index.module.scss';
import nameof from 'ts-nameof.macro';
import { AddressDatumDto, TokenType } from '@models/awbs/awbsModels';
import i18next, { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@root/store';
import {
    changeParsedTokenType,
    clearParsedTokens,
    createAddressDatum,
    createOrUpdateAddress,
    getParticipantContactInfoById,
    parseAddress,
    setParsedData,
    updateAddressDatum,
} from '@store/addressDataStore';
import { useDispatch } from 'react-redux';
import { AddressParsedToken } from '@scenes/customerApplication/awb/components/AddressParsedToken';
import LocationsService from '@services/LocationsService';
import { LocationDto, LocationType } from '@models/locations';
import { ValidationError } from 'yup';
import { useAddressValidation } from '@scenes/customerApplication/awb/components/validations/addressValidation';
import { AxiosResponse } from 'axios';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useDebouncedCallback } from 'use-debounce';
import { OptionTypeBase, ValueType } from 'react-select';
import { CountrySelect } from '@scenes/customerApplication/awb/components/CountrySelect';
import { AppSkeleton, Button, TextArea, ValidationError as Validation } from '@root/components';
import { Modal } from '@components/Modal';
import Input from '@components/Input';
import { CityEditState, EditState, tokenTypes } from './types';
import cs from '../../../../common.module.scss';
import cn from 'classnames';
import { ReactComponent as IconSpellCheck } from '@material-design-icons/svg/round/spellcheck.svg';

interface Props {
    id?: string;
    title: string;
    scannedData?: string;
    showAccountNumber?: boolean;
    allowEmptyAccountNumberTitle?: string;
    onClose: (addressDatum: AddressDatumDto) => void;
}

export const AddressDatumModal = ({
                                      id,
                                      scannedData,
                                      showAccountNumber,
                                      title,
                                      allowEmptyAccountNumberTitle,
                                      onClose,
                                  }: Props) => {
    const { addressDatum, parsedTokens, isParsing } = useAppSelector(x => x.addressData);
    const [editedAddress, setEditedAddress] = useState<AddressDatumDto>(null);
    const [editState, setEditState] = useState(EditState.None);
    const [currentTokenIndex, setCurrentTokenIndex] = useState<number>(-1);
    const [city, setCity] = useState<LocationDto>(null);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const [message, setMessage] = useState('');
    const [fieldState, setFieldState] = useState<Record<string, EditState>>({});
    const [allowEmptyInn, setAllowEmptyInn] = useState(false);
    const [rawData, setRawData] = useState(scannedData);
    const [cityName, setCityName] = useState('');
    const [cityEditState, setCityEditState] = useState(CityEditState.None);
    const [cities, setCities] = useState<LocationDto[]>([]);
    //const [selectedCity, setSelectedCity] = useState<LocationDto>(null);
    const [addressAsString, setAddressAsString] = useState('');
    const [lang, setLang] = useState("");
    const dispatch = useDispatch();

    const schema = useAddressValidation(showAccountNumber);
    const validateAsync = async (addressDatum: AddressDatumDto) => {
        try {
            await schema.validate(addressDatum, { abortEarly: false });
            setValidationErrors([]);
        } catch (e) {
            const error = e as ValidationError;
            setValidationErrors(error.inner);
        }
    };

    const fetchCity = async (cityId: string) => {
        if (cityId == city?.id) {
            return city;
        }

        let cityItem: LocationDto = null;

        if (cityId != null) {
            const data = await new LocationsService().getLocationById(cityId, /[a-zA-Z]+/.test(city?.name || "") ? "en" : "ru");
            if (!data.data.isError) {
                cityItem = data.data;
            }
        }

        return cityItem;
    };

    const initCity = async () => {
        //i18n.changeLanguage("en");
        const city = await fetchCity(editedAddress.cityId);
        //i18n.changeLanguage("en");
        setCity(city);
        if (city !== null || editedAddress.cityName != null) {
            setCity(city || { id: null, name: editedAddress.cityName });
        }

        await validateAsync(editedAddress);
    };

    const initializeEmptyFields = () => {
        const newValues = {};
        const newFieldState: Record<string, EditState> = {};

        for (const key of Object.keys(addressDatum)) {
            const textLength = editedAddress[key]?.length || 0;
            if (textLength == 0 && addressDatum[key] != null) {
                newValues[key] = addressDatum[key];
                newFieldState[key] = EditState.Confirmed;
            }
        }

        setEditedAddress({ ...editedAddress, ...newValues });
        setFieldState({ ...fieldState, ...newFieldState });
    };

    const processAddressDatum = () => {
        switch (editState) {
            case EditState.None:
                // setAllowEmptyInn(addressDatum != null && (addressDatum.accountNumber || '') == '');
                setEditedAddress(addressDatum);
                break;

            case EditState.Confirming:
                initializeEmptyFields();
                break;

            case EditState.Confirmed:
                dispatch(clearParsedTokens());
                onClose(addressDatum);
                break;
        }
    };

    useEffect(() => {
        let current = true;
        if (current) {
            processAddressDatum();
        }

        return () => {
            current = false;
        };
    }, [addressDatum]);

    useEffect(() => {
        setCity(null);
        setEditedAddress(null);
        dispatch(clearParsedTokens());
        dispatch(id != null ? getParticipantContactInfoById(id) : createAddressDatum());
    }, [id]);

    useEffect(() => {
        if (editedAddress == null) {
            setCity(null);
            return;
        }

        setAllowEmptyInn(editedAddress.isRequiredAccountNumber === false);
        initCity()
            .then(() => {
                const fields: string[] = [];
                if (editedAddress.isRequiredAccountNumber) {
                    fields.push(editedAddress.accountNumber);
                }
                fields.push(editedAddress.name || '');

                const cityName = city?.name || editedAddress.cityName || '';
                if (cityName.length > 0) {
                    fields.push(cityName);
                }

                fields.push(editedAddress.stateProvince || '');
                fields.push(editedAddress.postCode || '');
                fields.push(editedAddress.address || '');
                fields.push(editedAddress.contact || '');
                setAddressAsString(fields.filter(v => v.length > 0).join(', '));
            });
    }, [editedAddress]);

    useEffect(() => {
        if (parsedTokens.length > 0) {
            setMessage('');
        }
    }, [parsedTokens]);

    useEffect(() => {
        validateAsync(editedAddress);
    }, [allowEmptyInn]);

    const renderTokens = () => {
        const onChangeInput = async (info) => {
            let id = null;

            if (info.value == TokenType.Place) {
                const { data: search } = await new LocationsService().getLocations(info.labelKey, LocationType.City);
                if (search.length > 0) {
                    id = search[0].id;
                }
            }

            dispatch(changeParsedTokenType({
                tokenIndex: currentTokenIndex,
                tokenType: info.value,
                id: id,
            }));

            setFieldState({});
            setEditState(EditState.None);
            setCurrentTokenIndex(-1);
        };

        if (currentTokenIndex >= 0) {
            return (
                <div className={cs.flexColumn}>
                    <div className={cs.flex}>
                        <AddressParsedToken token={parsedTokens[currentTokenIndex]}
                                            onClick={() => setCurrentTokenIndex(-1)} />
                    </div>
                    <div className={cn(cs.flexColumn, cs.gap_10)}>
                        {tokenTypes.filter(info => info.value != TokenType.AccountNumber || showAccountNumber).map((info, idx) =>
                            <Input
                                key={idx}
                                label={t(info.labelKey)}
                                type={'radio'}
                                name={'token-type'}
                                value={info.value}
                                defaultChecked={info.value === parsedTokens[currentTokenIndex].tokenType}
                                onChange={() => onChangeInput(info)}
                            />,
                        )}
                    </div>
                </div>
            );
        }

        return parsedTokens?.map((token, idx) =>
            <AddressParsedToken onClick={() => setCurrentTokenIndex(idx)} key={idx} token={token} />,
        );
    };

    const parse = () => {
        dispatch(parseAddress(rawData));
    };

    const getValidationError = (fieldName: string) => {
        if (validationErrors == null) {
            return null;
        }

        const [validationError] = validationErrors.filter(value => value.path == fieldName);
        if (validationError == null) {
            return null;
        }

        return validationError.message;
    };

    const renderConfirmBlock = (fieldName: string) => {
        if (addressDatum == null || fieldState[fieldName] == EditState.Confirmed) {
            return null;
        }

        const newValue = addressDatum[fieldName];

        if (newValue == null || editState != EditState.Confirming || editedAddress[fieldName] == newValue) {
            return null;
        }

        const oldValue = { [fieldName]: editedAddress[fieldName] };

        const replaceField = () => {
            setFieldState({ ...fieldState, [fieldName]: EditState.Confirmed });
            setMessage('');
            setEditedAddress({ ...editedAddress, [fieldName]: newValue });
        };

        const skipField = () => {
            setFieldState({ ...fieldState, [fieldName]: EditState.Confirmed });
            setMessage('');
            updateAddressDatum({ ...addressDatum, ...oldValue });
        };

        return (
            <div className={cn(cs.flex, cs.gap_5)}>
                <Button
                    type='outline'
                    size='small'
                    variant='primary'
                    onClick={replaceField}
                >
                    {t('awb.replaceField')}
                </Button>
                <Button
                    type='outline'
                    size='small'
                    variant='secondary'
                    onClick={skipField}
                >
                    {t('awb.skipField')}
                </Button>
            </div>
        );
    };

    const CreateCityBlock = () => {
        if (cityEditState == CityEditState.None) {
            return null;
        }

        const onCreateBtnClick = () => {
            switch (cityEditState) {
                case CityEditState.NeedToCreate:
                    setCityEditState(CityEditState.Creating);
                    setEditedAddress({
                        ...editedAddress,
                        [nameof<AddressDatumDto>(m => m.cityId)]: null,
                    });
                    break;

                case CityEditState.Creating:
                    setEditedAddress({
                        ...editedAddress,
                        [nameof<AddressDatumDto>(m => m.cityName)]: cityName,
                    });
                    setCityName('');
                    break;
            }
        };

        return (
            <div className={cn(cs.flex, cs.gap_5)}>
                <Button
                    type='outline'
                    size='small'
                    variant='primary'
                    disabled={cityEditState == CityEditState.Saving || editedAddress.cityName == ''}
                    onClick={e => {
                        e.preventDefault();
                        onCreateBtnClick();
                    }}
                >
                    {t('awb.create')}
                </Button>
                <Button
                    type='outline'
                    size='small'
                    variant='secondary'
                    disabled={cityEditState == CityEditState.Saving}
                    onClick={e => {
                        e.preventDefault();
                        setCityEditState(CityEditState.None);
                    }}
                >
                    {t('awb.cancel')}
                </Button>
            </div>
        );
    };

    const fetchCitiesDebounced = useDebouncedCallback((term: string, resolve: (options: LocationDto[]) => void) => {
        new LocationsService()
            .getLocations(term, LocationType.City)
            .then((data: AxiosResponse<LocationDto[]>) => {
                let locations = editedAddress.cityId == null
                    ? data.data
                    : data.data.filter(location => location.id == editedAddress.cityId);

                if (editedAddress.countryId != null) {
                    locations = locations.filter(l => l.parentId == editedAddress.countryId);
                }

                if (locations.length == 0) {
                    setCityName(term);
                }

                setCities(locations);
                resolve(locations);
            });
    }, 400);

    const fetchCities = (term: string) =>
        new Promise<LocationDto[]>(resolve => {
            if (term.length < 3) return;
            fetchCitiesDebounced(term, resolve);
        });

    const saveHandle = () => {
        setEditState(EditState.Confirmed);

        const newValue = {
            ...editedAddress,
            accountNumber: allowEmptyInn ? '' : editedAddress.accountNumber,
        };
        dispatch(createOrUpdateAddress(newValue));
    };

    const cancelHandle = () => {
        setFieldState({});
        setEditState(EditState.Closed);
        onClose(null);
    };

    const onCreateOptionHandle = (inputValue: string) => {
        const value: LocationDto = { id: null, name: inputValue, type: LocationType.City };
        setCities([...cities, value]);
        setCity(value);
        setEditedAddress({
            ...editedAddress,
            [nameof<AddressDatumDto>(m => m.cityName)]: inputValue,
            [nameof<AddressDatumDto>(m => m.cityId)]: null,
        });
    };

    function onChangeSelectedCity(value: ValueType<LocationDto, false>) {
        setCity(value);
        setEditedAddress({
            ...editedAddress,
            [nameof<AddressDatumDto>(m => m.cityId)]: value?.id,
        });
    }

    const checkChange = (e) => {
        setAllowEmptyInn(e.currentTarget.checked);
        setEditedAddress({
            ...editedAddress,
            [nameof<AddressDatumDto>(m => m.isRequiredAccountNumber)]: !e.currentTarget.checked,
        });
    };

    const handleApply = () => {
        setCityName('');
        setEditState(EditState.Confirming);
        dispatch(setParsedData(rawData));
    };

    return (
        <Modal header={title} open={true} onClose={cancelHandle} onConfirm={saveHandle}>
            <div className={cn(cs.flexColumn, cs.gap_10)} style={{ width: '500px' }}>
                {showAccountNumber &&
                    <div className={cn(cs.flex, cs.gap_10)}>
                        <div className={cn(cs.flexColumn, cs.gap_5)}>
                            <Input
                                indicatorColor='warning'
                                label={t('awb.accountNumber')}
                                disabled={allowEmptyInn}
                                className={styles.flexControl}
                                name={nameof<AddressDatumDto>(m => m.accountNumber)}
                                placeholder={t(allowEmptyAccountNumberTitle || (allowEmptyInn ? 'awb.allowEmptyAccountNumber' : 'awb.accountNumber'))}
                                value={allowEmptyInn ? '' : editedAddress?.accountNumber ?? ''}
                                validationError={getValidationError(nameof<AddressDatumDto>(m => m.accountNumber))}
                                onChange={e => setEditedAddress({
                                    ...editedAddress,
                                    [nameof<AddressDatumDto>(m => m.accountNumber)]: e.target.value,
                                })}
                            />
                            {renderConfirmBlock(nameof<AddressDatumDto>(m => m.accountNumber))}
                            <div className={clsx('form-check', styles.Name)}>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='no-account-number'
                                    checked={allowEmptyInn}
                                    onChange={(e) => checkChange(e)}
                                />
                                <label className={'control-label'} htmlFor='no-account-number'>
                                    {allowEmptyAccountNumberTitle || t('awb.allowEmptyAccountNumber')}
                                </label>
                            </div>
                        </div>
                        <div className={cn(cs.flexColumn, styles.Name, cs.gap_5)}>
                            <Input
                                indicatorColor='success'
                                labelAsPlaceholder
                                className={styles.flexControl}
                                name={nameof<AddressDatumDto>(m => m.name)}
                                placeholder={t('awb.name')}
                                value={editedAddress?.name ?? ''}
                                onChange={e => setEditedAddress({
                                    ...editedAddress,
                                    [nameof<AddressDatumDto>(m => m.name)]: e.target.value,
                                })}
                                validationError={getValidationError(nameof<AddressDatumDto>(m => m.name))}
                            />
                            {renderConfirmBlock(nameof<AddressDatumDto>(m => m.name))}
                        </div>
                    </div>
                }
                <div className={cn(cs.flex, styles.Place, cs.gap_10)}>
                    <div className={cn(cs.flex)}>
                        <div className={cs.flexColumn}>
                            <CountrySelect countryId={editedAddress?.countryId} onChange={value => {
                                setCity(null);
                                setEditedAddress({
                                    ...editedAddress,
                                    [nameof<AddressDatumDto>(m => m.countryId)]: value?.id,
                                    [nameof<AddressDatumDto>(m => m.cityId)]: null,
                                });
                            }} />
                            <Validation>
                                {getValidationError(nameof<AddressDatumDto>(m => m.countryId))}
                            </Validation>
                        </div>
                    </div>
                    <div className={cn(cs.flex)}>
                        <div className={cn(cs.flexColumn, cs.gap_5)}>
                            <AsyncCreatableSelect<LocationDto, false>
                                placeholder={t('awb.place')}
                                cacheOptions
                                loadOptions={(inputValue) => fetchCities(inputValue)}
                                formatOptionLabel={(option) => (option as OptionTypeBase).label || option.name}
                                isClearable
                                value={city}
                                onChange={(value) => onChangeSelectedCity(value)}
                                loadingMessage={({ inputValue }) => inputValue.length < 3 ? t('options.specifyAtLeast3Chars') : t('options.loadingOptionsTemplate', { itemType: t('awb.itemType') })}
                                formatCreateLabel={inputValue => i18next.t('awb.setUnknownCity', { city: inputValue })}
                                onCreateOption={inputValue => onCreateOptionHandle(inputValue)}
                            />
                            {renderConfirmBlock(nameof<AddressDatumDto>(m => m.cityId))}
                            <CreateCityBlock />
                            <Validation>
                                {getValidationError(nameof<AddressDatumDto>(m => m.cityId))}
                            </Validation>
                        </div>
                    </div>
                </div>
                <div className={cn(cs.flex, cs.gap_10)}>
                    <div className={cn(cs.flexColumn, cs.gap_5)}>
                        <Input
                            labelAsPlaceholder
                            name={nameof<AddressDatumDto>(m => m.stateProvince)}
                            placeholder={t('awb.stateProvince')}
                            value={editedAddress?.stateProvince ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.stateProvince)]: e.target.value,
                            })}
                        />
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.stateProvince))}
                    </div>
                    <div className={cn(cs.flexColumn, cs.gap_5)}>
                        <Input
                            indicatorColor='error'
                            labelAsPlaceholder
                            name={nameof<AddressDatumDto>(m => m.postCode)}
                            placeholder={t('awb.postCode')}
                            value={editedAddress?.postCode ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.postCode)]: e.target.value,
                            })}
                            validationError={getValidationError(nameof<AddressDatumDto>(m => m.postCode))}
                        />
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.postCode))}
                    </div>
                </div>
                <div className={cn(cs.flex)}>
                    <Input
                        indicatorColor='primary'
                        labelAsPlaceholder
                        className={styles.flexControl}
                        name={nameof<AddressDatumDto>(m => m.address)}
                        placeholder={t('awb.address')}
                        value={editedAddress?.address ?? ''}
                        onChange={e => setEditedAddress({
                            ...editedAddress,
                            [nameof<AddressDatumDto>(m => m.address)]: e.target.value,
                        })}
                        validationError={getValidationError(nameof<AddressDatumDto>(m => m.address))}
                    />
                </div>
                <div className={cn(cs.flex, cs.gap_10)}>
                    <div className={cn(cs.flexColumn, cs.gap_5)}>
                        <Input
                            indicatorColor='info'
                            labelAsPlaceholder
                            name={nameof<AddressDatumDto>(m => m.contact)}
                            placeholder={t('awb.contact')}
                            value={editedAddress?.contact ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.contact)]: e.target.value,
                            })}
                        />
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.contact))}
                    </div>
                    <div className={cn(cs.flex, cs.gap_5)}>
                        <Input
                            indicatorColor='info'
                            labelAsPlaceholder
                            name={nameof<AddressDatumDto>(m => m.extraContact)}
                            placeholder={t('awb.contact')}
                            value={editedAddress?.extraContact ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.extraContact)]: e.target.value,
                            })}
                        />
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.extraContact))}
                    </div>
                </div>
                <div className={cn(cs.flex)}>
                    {!scannedData || parsedTokens?.length == 0
                        ? (!isParsing && <TextArea
                                className={styles.rawData}
                                onChange={e => setRawData(e)}
                                value={rawData || addressAsString}
                            />
                        ) : (
                            <div
                                className={cn(cs.flexColumn, cs.gap_10, cs.flexWrap, cs.flexAlignCenter, styles.parsedCard)}>
                                <div className={cn(cs.flex, cs.flexJustifySpaceBetween, cs.flexAlignCenter)}>
                                    <h5 className={styles.parsedCardHeader}>{t('awb.parsed')}</h5>
                                    {parsedTokens?.filter(t => t.tokenType != TokenType.Unknown).length > 0 && editState == EditState.None &&
                                        <Button
                                            icon={<IconSpellCheck fill='currentColor' />}
                                            size='small'
                                            type='text'
                                            variant='white'
                                            onClick={handleApply}
                                        >
                                            {t('awb.apply')}
                                        </Button>
                                    }
                                </div>
                                <div className={cn(cs.flex, cs.flexWrap, cs.gap_5)}>
                                    {renderTokens()}
                                </div>
                            </div>
                        )
                    }
                </div>
                {message &&
                    <div className={cs.flex}>{message}</div>
                }
                {isParsing &&
                    <AppSkeleton count={2} />
                }
                <div className={cn(cs.flex, cs.gap_10, cs.flexJustifyFlexEnd)}>
                    {rawData && editState == EditState.None && parsedTokens?.length == 0 &&
                        <Button
                            type='outline'
                            variant='primary'
                            disabled={isParsing}
                            onClick={() => {
                                setMessage(t('awb.parsing'));
                                parse();
                            }}
                        >
                            {t('request.parse')}
                        </Button>
                    }
                </div>
            </div>
        </Modal>
    );
};
