import moment from "moment";

export function localDateToUtcDate(localDate: Date): Date {
    return new Date(
        Date.UTC(
            localDate.getFullYear(),
            localDate.getMonth(),
            localDate.getDate()
        )
    );
}

export function toDDMMYYYYformat(date: Date): string {
    return date
        ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
        : '';
}

export function isoUtcDateToShortLocalString(isoUtcDate: string): string {
    return moment(isoUtcDate).local().format('DD.MM.YYYY');
}

export function isoUtcDateToLocalString(isoUtcDate: string): string {
    return moment(isoUtcDate).local().format('DD.MM.YYYY HH:mm');
}