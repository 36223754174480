import s from '@scenes/welcome/FFCargoWelcomePage/index.module.scss';
import classNames from 'classnames';
import React, { useState } from 'react';
import { ModalDialog } from '@components/ModalDialog';
import { welcomeImages } from '@helpers/tenantsHelper';

export const Video = () => {
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  return (
    <>
      <div className={classNames(s['animate-zone'], s['lazy-img'])}>
        <img src={welcomeImages.videoBase} alt='' className={s['img-base']} />
        <img src={welcomeImages.videoMenu} alt='' className={s['img-menu']} />
        <img src={welcomeImages.videoSideBar} alt='' className={s['img-sidebar']} />
        <img src={welcomeImages.videoSecondRow} alt='' className={s['img-row-2']} />
        <img src={welcomeImages.videoFirstRow} alt='' className={s['img-row-1']} />
        <img src={welcomeImages.videoRowOpen} alt='' className={s['img-rowopen']} />
        <img src={welcomeImages.videoButton} alt='' className={s['img-button']} />
      </div>
      <button className={s['play-video']} data-modal-open='video' onClick={() => setShowVideoPopup(true)}>
        <div className={s['button-inner']}>
          <div className={classNames(s['button-content'], s['content-center'])}>
            <div className={s['button-text']}>Посмотреть видео</div>
            <div className={classNames(s['button-icon'], s['content-center'])}>
              <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 24'>
                <path fillRule='evenodd' clipRule='evenodd'
                      d='m1.498 23.769 12.194-11.032a.954.954 0 0 0 .308-.705.954.954 0 0 0-.308-.706L1.495.228A.879.879 0 0 0 .529.085.94.94 0 0 0 0 .935v22.127a.94.94 0 0 0 .53.854.879.879 0 0 0 .969-.147Z' />
              </svg>
            </div>
          </div>
        </div>
      </button>
      <ModalDialog isBodyLocked={true}
                   show={showVideoPopup}
                   contentClassName={s['video-modal']}
                   onClose={() => setShowVideoPopup(false)}>
        <div className={classNames(s['m-video'], s['content-center'])}>
          <iframe src='https://www.youtube.com/embed/xQgAY4R5o2s?autoplay=1'
                  title='YouTube video player' frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen={true} />
        </div>
      </ModalDialog>
    </>
  );
};