import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { fetchContact, updateContact } from '@store/contactsStore';
import SessionManager from '@root/SessionManager';
import { Loader } from '@components/index';
import PersonalInfoEditor, { EditorMode } from '@scenes/accountSettings/components/PersonalInfoEditor';
import LanguageEditor from '@scenes/accountSettings/personal/components/LanguageEditor';
import { UpdateContact } from '@models/contacts';
import { unwrap } from '@helpers/reduxHelpers';
import { toast } from 'react-toastify';
import ChangePassword from '@scenes/accountSettings/personal/ChangePassword';
import { ContactPermissionType } from '@models/contacts/ContactPermissionType';

const PersonalPage = () => {
	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { contact, isContactFetching, isContactEditFetching, isContactPagerFetching } = useAppSelector(
		(x) => x.contacts
	);

	useEffect(() => {
		if (SessionManager.user?.contactId == null) {
			return;
		}
		if (SessionManager.user.contactId == contact?.id) {
			return;
		}
		dispatch(fetchContact({ id: SessionManager.user.contactId }));
	}, [SessionManager.user?.contactId]);

	const onSubmitEditPersonalInfo = async (model: UpdateContact) => {
		const result = await dispatch(updateContact(model));
		return unwrap(result);
	};

	return (
		<>
			<Row>
				<Col md={6} sm={12}>
					<Row>
						<Col>
							<h2>{t('personalInfo')}</h2>
							<hr />
						</Col>
					</Row>

					<Row>
						<Col className={'mb-3'}>
							{isContactFetching ? (
								<Loader />
							) : (
								<PersonalInfoEditor
									data={contact}
									mode={SessionManager.user.hasPermissions(
										ContactPermissionType.UpdateContact
									) ? EditorMode.Edit : EditorMode.View}
									showCanLogin={false}
									onSubmit={onSubmitEditPersonalInfo}
									onSuccess={() => toast.success(t('changesSaved'))}
									onError={(msg) => toast.error(msg)}
								>
									{(renderEditor, onClickSubmit) => {
										return (
											<>
												{renderEditor()}
												{SessionManager.user.hasPermissions(
													ContactPermissionType.UpdateContact
												) && (
													<button
														className={'btn btn-primary mt-4'}
														onClick={(e) => {
															e.preventDefault();
															onClickSubmit();
														}}
														disabled={isContactFetching || isContactEditFetching}
													>
														{t('save')}
													</button>
												)}
											</>
										);
									}}
								</PersonalInfoEditor>
							)}
						</Col>
					</Row>
				</Col>

				<Col md={6} sm={12}>
					<Row>
						<Col>
							<h2>{t('preferences')}</h2>
							<hr />
						</Col>
					</Row>
					<Row>
						<Col className={'mb-3'}>
							<LanguageEditor />
						</Col>
					</Row>
					<Row>
						<Col>
							<h2>{t('changePasswordText')}</h2>
							<hr />
						</Col>
					</Row>
					<Row>
						<Col className={'mb-3'}>
							{isContactFetching ? <Loader /> : <ChangePassword contact={contact} />}
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default PersonalPage;