import s from '../../index.module.scss';
import { ListAnimated } from '@scenes/welcome/FFCargoWelcomePage/components/ListAnimated';
import { AdvantageListItem } from '@scenes/welcome/FFCargoWelcomePage/types';
import React from 'react';
import cn from 'classnames';

interface ISectionContent {
  advantagesList: AdvantageListItem[];
  coloredHeader: string;
  header: string;
  text: string;
  image: string;
  imagePosition?: 'right';
}

export const SectionContent: React.FC<ISectionContent> = (
  {
    imagePosition,
    image,
    advantagesList,
    header,
    coloredHeader,
    text,
  }) => {
  return (
    <div className={cn(s.sectionContainer, imagePosition !== "right" && s.sectionContainerReversed)}>
      <div className={s.sectionContainerText}>
        <h2 className={s['t-l']}>{coloredHeader} <span className={s.badge}>{header}</span></h2>
        <p className={s['text-l']}>{text}</p>
        <ListAnimated items={advantagesList} />
      </div>
      <div className={cn(s.sectionContainerImage, s['lazy-img'], imagePosition === "right" && s['right-crop'])}>
        <img src={image} alt='' />
      </div>
    </div>
  );
};