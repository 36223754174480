import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppSelector } from '@root/store';
import { OrganizationAccessLevel } from '@models/organizations';

const useAccreditationListRedirection = () => {
	const history = useHistory();
	const routerState = useAppSelector(x => x.router);
	const {organizationSettings, isOrganizationSettingsFetching} = useAppSelector(x => x.organizationSettingsStore);

	useEffect(() => {

		const accreditationPath = '/accreditation';

		if (history.location.pathname == accreditationPath || history.location.pathname == '/account') {
			return;
		}
		if (organizationSettings == null) {
			return;
		}
		if (organizationSettings.accessLevel !== OrganizationAccessLevel.Granted &&
			organizationSettings.accessLevel !== OrganizationAccessLevel.GrantedNoBooking) {
			history.replace(accreditationPath);
		}

	}, [
		history.location.pathname,
		routerState?.location?.pathname,
		organizationSettings,
		isOrganizationSettingsFetching
	]);
};

export default useAccreditationListRedirection;