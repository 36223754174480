import React, { useEffect, useState } from 'react';

import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { getAccounts } from '@store/accountsStore';

import {
    FilterConnection,
    FilterOperator,
    FilterValue,
    FilterValueCondition,
    PagingFilter,
} from '@models/entityNavigation/filtering';
import { UserDictionaryItem } from '@models/users';
import nameof from 'ts-nameof.macro';

import { useAppDispatch, useAppSelector } from '@root/store';
import { Col, Row } from 'reactstrap';
import dictionaryStyles from '../../components/DictionaryStyles.module.scss';
import clsx from 'clsx';
import debounce from 'awesome-debounce-promise';
import Paginator from '@components/paginator/Paginator';
import { Loader } from '@components/index';
import { OrganizationDictionaryItem } from '@models/organizations';

import OrganizationsSelect from '@scenes/admin/dictionaries/users/components/OrganizationsSelect';
import UserItem from '@scenes/admin/dictionaries/users/components/UserItem';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import PageHeader from '@components/PageHeader';

const createTermConnection = (term?: string): FilterConnection => {
    const termFilterConnection = new FilterConnection(FilterOperator.Or);

    const userNameFilterValue = new FilterValue(
        nameof.full<UserDictionaryItem>((x) => x.userName),
        FilterValueCondition.Contains,
        term
    );

    const emailFilterValue = new FilterValue(
        nameof.full<UserDictionaryItem>((x) => x.email),
        FilterValueCondition.Contains,
        term
    );

    // const jobTitleFilterValue = new FilterValue(
    //     nameof.full<UserDictionaryItem>((x) => x.jobPositionType),
    //     FilterValueCondition.Contains,
    //     term
    // );

    const organizatonNameFilterValue = new FilterValue(
        'organization.name',
        FilterValueCondition.Contains,
        term
    );

    termFilterConnection.values.push(userNameFilterValue);
    termFilterConnection.values.push(emailFilterValue);
    //termFilterConnection.values.push(jobTitleFilterValue);
    termFilterConnection.values.push(organizatonNameFilterValue);

    return termFilterConnection;
};

const createOrganizationsConnection = (
    organizations?: OrganizationDictionaryItem[]
): FilterConnection => {
    if (organizations && organizations.length !== 0) {
        const organizationsFilterConnection = new FilterConnection(
            FilterOperator.Or
        );

        const organizationsFilterValues = organizations.map(
            (organization) =>
                new FilterValue(
                    nameof.full<UserDictionaryItem>((x) => x.organizationId),
                    FilterValueCondition.Equals,
                    organization.id
                )
        );

        organizationsFilterConnection.values = organizationsFilterValues;

        return organizationsFilterConnection;
    }

    return null;
};

const createPagingFilter = (pageNumber?: number): PagingFilter => {
    return new PagingFilter(pageNumber ?? 0, 10);
};

const createNavigation = (
    term?: string,
    organizations?: OrganizationDictionaryItem[],
    pageNumber?: number
): StandardNavigation => {
    const navigation = new StandardNavigation();

    navigation.pagingFilter = createPagingFilter(pageNumber);

    const termFilterConnection = createTermConnection(term);

    const organizationsFilterConnection = createOrganizationsConnection(
        organizations
    );

    if (organizationsFilterConnection !== null) {
        const combinedConnection = new FilterConnection(FilterOperator.And);

        combinedConnection.connections.push(termFilterConnection);
        combinedConnection.connections.push(organizationsFilterConnection);

        navigation.filters.push(combinedConnection);

        return navigation;
    }

    navigation.filters.push(termFilterConnection);

    return navigation;
};

const UsersPage = (): JSX.Element => {
    const { isFetching, accounts } = useAppSelector((x) => x.accounts);

    const { organizations: storedOrganizations } = useAppSelector(
        (x) => x.organizations
    );

    const { organizationId } = useParams<{ organizationId: string }>();

    let initialOrganizations: OrganizationDictionaryItem[] = [];

    if (organizationId) {
        initialOrganizations = storedOrganizations?.items
            ? [
                  storedOrganizations.items.find(
                      (organization) => organization.id == organizationId
                  ),
              ]
            : [];
    }

    const [organizations, setOrganizations] = useState<
        OrganizationDictionaryItem[]
    >(initialOrganizations);

    const [term, setTerm] = useState('');

    const [navigation, setNavigation] = useState<StandardNavigation>(
        createNavigation(term, organizations, 0)
    );

    const debouncedTermChanged = debounce((term: string) => {
        setTerm(term);
        setNavigation(createNavigation(term, organizations, 0));
    }, 800);

    const onSearchByTerm = (term: string): void => {
        term = term?.trim() ?? '';
        debouncedTermChanged(term);
    };

    const onChangePage = (pageNumber: number): void => {
        setNavigation(createNavigation(term, organizations, pageNumber));
    };

    const onChangeOrganizations = (
        organizations: OrganizationDictionaryItem[]
    ): void => {
        setOrganizations(organizations);
        setNavigation(createNavigation(term, organizations, 0));
    };

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAccounts(navigation));
    }, [navigation]);

    const { t } = useTranslation();

    return (
        <>
            <PageHeader title={t('admin.dictionaries.users')} backUrl="/admin/dictionaries" />
            <Row className="mb-3">
                <Col md={4}>
                    <input
                        type="text"
                        className={clsx(
                            'form-control',
                            dictionaryStyles.termFilter
                        )}
                        placeholder={t('admin.users.search')}
                        onKeyUp={(e): void =>
                            onSearchByTerm(e.currentTarget.value)
                        }
                    />
                </Col>
                <Col md={8}>
                    <OrganizationsSelect
                        initialOrganizations={initialOrganizations}
                        onChangeOrganizations={onChangeOrganizations}
                    />
                </Col>
            </Row>

            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <Row>
                        <Col>
                            <table
                                className={clsx(
                                    'table table-hover table-responsive-sm',
                                    dictionaryStyles.table
                                )}
                            >
                                <thead>
                                    <tr>
                                        <th>{t('admin.users.userName')}</th>
                                        <th>{t('admin.users.email')}</th>
                                        <th>{t('jobPosition')}</th>
                                        <th>{t('admin.users.organization')}</th>
                                        <th>{t('admin.users.contactId')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accounts?.items?.map((user) => (
                                        <UserItem key={user.id} user={user} />
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Paginator
                                totalResults={accounts?.totalCount}
                                limitPerPage={navigation?.pagingFilter.pageSize}
                                currentPage={
                                    navigation?.pagingFilter.pageNumber + 1
                                }
                                onChangePage={(pageNumber): void =>
                                    onChangePage(pageNumber)
                                }
                                pageNeighbours={4}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default UsersPage;