export enum WayToGetAccountingDoc {
	None = 0,
    ByEmail = 1,
    ByEdm = 2
}

export interface WayToGetAccountingDocDto {
	key: string,
	value: number
}
