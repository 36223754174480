import * as React from 'react';
import { HTMLInputTypeAttribute, useEffect, useMemo, useState } from 'react';
import { Formik, useField, useFormikContext } from 'formik';
import nameof from 'ts-nameof.macro';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styles from './AccreditationListEditor.module.scss';
import clsx from 'clsx';
import { HttpError } from '@models/shared';
import { submitFormAndShowErrorsAsync } from '@helpers/FormHelper';
import { AccreditationModel, OrganizationInfoDto } from '@models/organizations/accreditationModels';
import LocalizableDatePicker from '@components/LocalizableDatePicker';
import { DateTime } from '@helpers/DateTime';
import { InteractionType, InteractionTypeDto } from '@models/contacts/InteractionType';
import i18next from 'i18next';
import WayToGetAccountingDocSelectField
    from '@components/inputs/WayToGetAccountingDocSelect/WayToGetAccountingDocSelectField';
import { WayToGetAccountingDoc } from '@models/organizations/WayToGetAccountingDoc';
import { InteractionContacts } from '@scenes/accreditationList/components/InteractionContacts';
import { array, boolean, number, object, Schema, string, ValidationError } from 'yup';
import { createContactShape } from '@models/validationSchemas/contact';
import { useAppDispatch, useAppSelector } from '@root/store';
import { fetchOrganizationSettings } from '@store/organizationSettingsStore';
import FileUploader, { AttachedFile } from '@components/FileUploader';
import FilesService from '@services/FilesService';
import { InputPhone } from '@components/inputs/InputPhone';

export type Props = {
    data: AccreditationModel;
    isUpdating: boolean;
    organizationUniqueNumber: string;
    interactionTypes: InteractionTypeDto[];
    onSubmit: (data: AccreditationModel) => Promise<HttpError | null>;
    onSuccess: () => void;
    onError: (message: string) => void;
};

const StateCheckbox = (props: {
    label?: JSX.Element | string;
    name: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                id={props.name}
                checked={props.checked}
                onChange={props.onChange}
            />
            {props.label && (
                <label className="control-label" htmlFor={props.name}>
                    {props.label}
                </label>
            )}
        </div>
    );
};

const Input = (props: {
    label: JSX.Element | string;
    name: string;
    required: boolean;
    isDisabled?: boolean;
    type?: HTMLInputTypeAttribute;
}) => {
    const [field, meta] = useField(props.name);
    const name = props.name.replace('.', '_');

    return (
        <label className={clsx(styles.formLabel, `control-label`)} htmlFor={name}>
            {props.label}
            {props.required && <sup className="required" />}
            <input
                type={props.type || 'text'}
                className={clsx(styles.formInput, 'form-control')}
                id={name}
                name={field.name}
                value={field.value || ''}
                onChange={x => {
                    field.onChange(x);
                }}
                disabled={props.isDisabled}
                onBlur={field.onBlur}
            />
            {meta.error && <div><span className="validationMessage">{meta.error}</span></div>}
        </label>
    );
};


function AccreditationListEditor(props: Props) {
    const { t } = useTranslation();
    const { t: tOrg } = useTranslation('', { keyPrefix: 'organization' });
    const { organizationSettings } = useAppSelector((x) => x.organizationSettingsStore);

    const [officeAddressSameAsLegalAddress, setOfficeAddressSameAsLegalAddress] = useState(false);
    const [mailingAddressSameAsLegalAddress, setMailingAddressSameAsLegalAddress] = useState(false);
    const [columnWidth, setColumnWidth] = useState(4);
    const [attachedFiles, setAttachedFiles] = useState<AttachedFile[]>([]);

    const { legalAddress, officeAddress, mailingAddress } = props.data?.organization || {
        legalAddress: '',
        officeAddress: '',
        mailingAddress: ''
    };

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchOrganizationSettings);
    }, []);

    useEffect(() => {
        setOfficeAddressSameAsLegalAddress(legalAddress == officeAddress);
        setMailingAddressSameAsLegalAddress(legalAddress == mailingAddress);
    }, [legalAddress, officeAddress, mailingAddress]);

    useEffect(() => {
        setColumnWidth(organizationSettings == null || organizationSettings.hasDynamicsRegistration !== false ? 4 : 3);
    }, [organizationSettings]);

    const renderDatePicker = (
        label: JSX.Element | string,
        name: string,
        required: boolean
    ) => {
        const domName = name.replace('.', '_');

        const maxDate = DateTime.now(true);
        maxDate.addDays(- 1);

        return (
            <label className={clsx(styles.noInputGroup, `control-label`)} htmlFor={domName}>
                {label}
                {required && <sup className="required" />}
                <LocalizableDatePicker
                    name={name}
                    maxDate={maxDate.toISOString(true)}
                    showClearButton={true}
                />
            </label>);
    };

    const validationSchema = useMemo(() => {
        const contactShape = createContactShape();
        const requiredMsg = t('validation.required');
        const interactionSchema = object<Record<string, string>>()
            .shape({})
            .test('test',
                'Error',
                async (interactionMap: Record<string, string[]>, context) => {
                    const errors: ValidationError[] = [];

                    for (const typeKey of Object.keys(interactionMap)) {
                        const contactIds = interactionMap[typeKey].filter(c => c != null);

                        if (contactIds.length == 0) {
                            switch (typeKey) {
                                case InteractionType[InteractionType.Director]:
                                    errors.push(new ValidationError(t('accreditation.contacts.errors.ShouldContainDirectorInteractionType'), null, `interactionMap.${typeKey}[0].id`));
                                    break;

                                case InteractionType[InteractionType.Commercial]:
                                    errors.push(new ValidationError(t('accreditation.contacts.errors.ShouldContainCommercialInteractionType'), null, `interactionMap.${typeKey}[0].id`));
                                    break;

                                case InteractionType[InteractionType.Operational]:
                                    errors.push(new ValidationError(t('accreditation.contacts.errors.ShouldContainOperationalInteractionType'), null, `interactionMap.${typeKey}[0].id`));
                                    break;

                                case InteractionType[InteractionType.Payments]:
                                    errors.push(new ValidationError(t('accreditation.contacts.errors.ShouldContainPaymentsInteractionType'), null, `interactionMap.${typeKey}[0].id`));
                                    break;
                            }
                        }

                        for (let i = 0; i < contactIds.length; i ++) {
                            const contactId = contactIds[i];

                            const contact = context.parent.contacts.find(c => c.id == contactId);
                            try {
                                await contactShape.validate(contact, { abortEarly: false });
                            } catch (e) {
                                const error = e as ValidationError;
                                const validationErrors = error.path == null && error.inner?.length > 0
                                    ? error.inner
                                    : [error];

                                for (const validationError of validationErrors) {
                                    errors.push(new ValidationError(validationError.message, null, `${context.path}.${typeKey}.${i}.${validationError.path}`));
                                }
                            }
                        }
                    }

                    return errors.length > 0 ? context.createError({ message: () => errors }) : true;
                });

        const validationRules: { [key in keyof OrganizationInfoDto]: Schema } = {
            name: string().nullable().required(requiredMsg),
            fullName: string().nullable().required(requiredMsg),
            officeAddress: string().nullable().required(requiredMsg),
            bic: string().nullable().required(requiredMsg),
            bankName: string().nullable().required(requiredMsg),
            checkingAccount: string().nullable().required(requiredMsg),
            correspondentAccount: string().nullable().required(requiredMsg),
            legalAddress: string().nullable().required(requiredMsg),
            mailingAddress: string().nullable().required(requiredMsg),
            webSiteUrl: string().nullable(),
            regNumber: string().nullable().required(requiredMsg),
            dateIncorporation: string().nullable().required(requiredMsg),
            vatNumber: string().nullable().required(requiredMsg),
            phone: string().nullable().required(requiredMsg),
            wayToGetAccountingDocs: number().nullable(),
            edmName: string()
                .nullable()
                .when('wayToGetAccountingDocs', ([x]) => {
                    if (x === WayToGetAccountingDoc.ByEdm) {
                        return string().nullable().required(requiredMsg);
                    }
                }),
            edmId: string()
                .nullable()
                .when('wayToGetAccountingDocs', ([x]) => {
                    if (x === WayToGetAccountingDoc.ByEdm) {
                        return string().nullable().required(requiredMsg);
                    }
                }),
            employeesCount: number()
                .nullable()
                .test('check-dynamics-registration-employees-count',
                    requiredMsg,
                    (value, context) => {
                        return context.parent?.hasDynamicsRegistration !== false || value != null && value > 0;
                    }),
            currentCarriers: string()
                .nullable()
                .test('check-dynamics-registration-current-carriers',
                    requiredMsg,
                    (value, context) => {
                        return context.parent?.hasDynamicsRegistration !== false || value != null && value.trim().length > 0;
                    }),
            mainDirections: string()
                .nullable()
                .test('check-dynamics-registration-main-directions',
                    requiredMsg,
                    (value, context) => {
                        return context.parent?.hasDynamicsRegistration !== false || value != null && value.trim().length > 0;
                    }),
            expectedVolumes: string()
                .nullable()
                .test('check-dynamics-registration-expected-volumes',
                    requiredMsg,
                    (value, context) => {
                        return context.parent?.hasDynamicsRegistration !== false || value != null && value.trim().length > 0;
                    })
        };

        return object<AccreditationModel>().shape({
            organization: object().shape(validationRules),
            interactionMap: interactionSchema,
            attachedFiles: array().nullable()
                .test('check-dynamics-registration-attached-files',
                    requiredMsg,
                    (value, context) => {
                        return context.parent?.organization?.hasDynamicsRegistration !== false || attachedFiles.length > 0;
                    }),
            saveOnly: boolean().nullable()
        });
    }, [i18next.language, organizationSettings, props.data, attachedFiles]);

    const AddressField = (props: {
        label: string,
        name: string,
        sameAsLegalAddress: boolean,
        onChangeSameAsLegalAddress: (value: boolean) => void
    }) => {
        const { values, setFieldValue } = useFormikContext<AccreditationModel>();

        useEffect(() => {
            if (props.sameAsLegalAddress) {
                setFieldValue(props.name, values?.organization?.legalAddress);
            }
        }, [values?.organization?.legalAddress]);

        return <>
            <Input
                label={props.label}
                name={props.name}
                required={true}
                isDisabled={props.sameAsLegalAddress}
            />
            <StateCheckbox
                label={t('sameAsLegalAddress')}
                name={props.name.replace('.', '_') + 'SameAsLegalAddress'}
                checked={props.sameAsLegalAddress}
                onChange={(e) => {
                    props.onChangeSameAsLegalAddress(e.target.checked);
                    if (!e.target.checked) {
                        return;
                    }

                    setFieldValue(props.name, values?.organization?.legalAddress);
                }}
            />
        </>;
    };

    const saveDataAsync = (values: AccreditationModel) => {
        values.attachedFiles = attachedFiles;
        return submitFormAndShowErrorsAsync(values, props.onSubmit, props.onError, false);
    };

    return (
        <Formik<AccreditationModel>
            enableReinitialize
            initialValues={props.data}
            validationSchema={validationSchema}
            onSubmit={async (values, formikHelpers) => {
                try {
                    const result = await saveDataAsync(values);

                    if (!result.isError) {
                        if (values.saveOnly) {
                            return;
                        }

                        props.onSuccess();
                        return;
                    }

                    const setError = (errors: any, path: string[], messages: string[]) => {
                        let result = errors;
                        let p: string | number = path.shift();
                        const match = /^\[(\d+)]$/gm.exec(p);
                        if (match != null && match.length > 0) {
                            if (!Array.isArray(result)) {
                                p = + match[1];
                                result = [];
                            }
                        }

                        if (path.length > 0)
                            result[p] = setError(errors[p] || {}, path, messages);
                        else {
                            result[p] = messages.map(m => t(`accreditation.contacts.errors.${m}`));
                        }

                        return result;
                    };

                    let errors = {};

                    for (const field of result.fields) {
                        const path = field.name.replace('\[', '.[').split('.').filter(s => s != '');
                        errors = setError(errors, path, field.messages);
                    }

                    setTimeout(() => formikHelpers.setErrors(errors), 10);
                } catch (e) {
                    console.log(e);
                }
            }}
        >
            {(formikProps) => {
                return (
                    <>
                        <Row>
                            <Col>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h2 className={'mb-2'}>{t('organizationText')}</h2>
                                        <Row>
                                            <Col>
                                                <h5>{t('legalInfo')}</h5>
                                            </Col>
                                            <Col>
                                                <h5>{t('financialInfo')}</h5>
                                            </Col>
                                            <Col>
                                                <h5>{t('contactInfo')}</h5>
                                            </Col>
                                            {columnWidth == 3 &&
                                                <Col>
                                                    <h5>{t('additionalInfo.title')}</h5>
                                                </Col>}
                                        </Row>
                                        <Row className={'form'}>
                                            <Col md={columnWidth}>
                                                <Row>
                                                    <Col>
                                                        <hr />
                                                        <label className={clsx(styles.formLabel, `control-label`)}
                                                               htmlFor={tOrg('uniqueNumber')}>
                                                            {tOrg('uniqueNumber')}
                                                            <input
                                                                className={clsx(styles.formInput, 'form-control')}
                                                                value={props.organizationUniqueNumber}
                                                                readOnly={true}
                                                            />
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={tOrg('regNumber')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.regNumber)}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={tOrg('vatNumber')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.vatNumber)}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={tOrg('fullName')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.fullName)}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={tOrg('shortName')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.name)}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={tOrg('legalAddress')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.legalAddress)}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {renderDatePicker(
                                                            tOrg('dateIncorporation'),
                                                            nameof.full<AccreditationModel>((x) => x.organization.dateIncorporation),
                                                            true
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col md={columnWidth}>
                                                <Row>
                                                    <Col>
                                                        <hr />
                                                        <Input
                                                            label={tOrg('bankName')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.bankName)}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={tOrg('bik')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.bic)}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={tOrg('checkingAccount')}
                                                            name={nameof.full<AccreditationModel>(
                                                                (x) => x.organization.checkingAccount
                                                            )}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={tOrg('correspondentAccount')}
                                                            name={nameof.full<AccreditationModel>(
                                                                (x) => x.organization.correspondentAccount
                                                            )}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <label className={clsx(styles.formLabel, `control-label`)}>
                                                            {tOrg('wayToGetAccountingDocs')}
                                                            <WayToGetAccountingDocSelectField
                                                                name={nameof.full<AccreditationModel>(
                                                                    (x) => x.organization.wayToGetAccountingDocs
                                                                )}
                                                                formikProps={formikProps}
                                                                isEditMode={true}
                                                            />
                                                        </label>
                                                    </Col>
                                                </Row>
                                                {formikProps.values?.organization?.wayToGetAccountingDocs === WayToGetAccountingDoc.ByEdm && (
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <Input
                                                                    label={tOrg('edmName')}
                                                                    name={nameof.full<AccreditationModel>(
                                                                        (x) => x.organization.edmName
                                                                    )}
                                                                    required={true}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Input
                                                                    label={tOrg('edmId')}
                                                                    name={nameof.full<AccreditationModel>(
                                                                        (x) => x.organization.edmId
                                                                    )}
                                                                    required={true}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </Col>

                                            <Col md={columnWidth}>
                                                <Row>
                                                    <Col>
                                                        <hr />
                                                        <Input
                                                            label={tOrg('websiteUrl')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.webSiteUrl)}
                                                            required={false}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="organization-phone-row">
                                                    <Col>
                                                        <InputPhone
                                                            label={tOrg('phone')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.phone)}
                                                            required={false}
                                                            formikProps={formikProps}
                                                            hideExt />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <AddressField
                                                            label={tOrg('officeAddress')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.officeAddress)}
                                                            sameAsLegalAddress={officeAddressSameAsLegalAddress}
                                                            onChangeSameAsLegalAddress={setOfficeAddressSameAsLegalAddress}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <AddressField
                                                            label={tOrg('mailingAddress')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.mailingAddress)}
                                                            sameAsLegalAddress={mailingAddressSameAsLegalAddress}
                                                            onChangeSameAsLegalAddress={setMailingAddressSameAsLegalAddress} />
                                                    </Col>
                                                </Row>
                                            </Col>

                                            {columnWidth == 3 && <Col md={3} data-cy="additional-info">
                                                <Row>
                                                    <Col>
                                                        <hr />
                                                        <Input
                                                            type="number"
                                                            label={t('additionalInfo.employeesCount')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.employeesCount)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={t('additionalInfo.currentCarriers')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.currentCarriers)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={t('additionalInfo.mainDirections')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.mainDirections)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label={t('additionalInfo.expectedVolumes')}
                                                            name={nameof.full<AccreditationModel>((x) => x.organization.expectedVolumes)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <label className={`${styles.formLabel} control-label`}
                                                               htmlFor="form__additionalEmail">
                                                            {t('awb.attachOrganizationDocuments')}
                                                        </label>
                                                        <FileUploader
                                                            maxFileCount={5}
                                                            value={attachedFiles}
                                                            onChange={(files) => setAttachedFiles(files)}
                                                            downloadPreviewBlob={(file) => {
                                                                return new FilesService().getAttachment(file.id);
                                                            }}
                                                        />
                                                        {formikProps.touched && formikProps.errors['attachedFiles'] &&
                                                            <div><span
                                                                className="validationMessage">{t('validation.required')}</span>
                                                            </div>}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h2>{t('employees')}</h2>
                                        <i><h5 className={'mb-2'}>{t('accreditation.employeesSubHeader')}</h5></i>

                                        <Row>
                                            <Col>
                                                <div>
                                                    <table className={clsx('table', styles.interactionTypesTable)}
                                                           style={{ minWidth: '1000px' }}>
                                                        <thead>
                                                        <tr>
                                                            <th className={styles.interactionTypesHeader}>{t('accreditation.contacts.interactionTypesText')}</th>
                                                            <th>{t('accreditation.contacts.employee')}</th>
                                                            <th>{t('accreditation.contacts.jobPosition')}</th>
                                                            <th>{t('accreditation.contacts.email')}</th>
                                                            <th>{t('phone')}</th>
                                                            <th>{t('mobilePhone')}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <InteractionContacts
                                                            formikProps={formikProps}
                                                        />
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'} style={{ display: 'none' }}>
                                    <Col>
                                        <span>{JSON.stringify(formikProps.errors)}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className={'text-center mt-3'}>
                            {t('accreditation.thanks')}
                        </div>
                        <div className={clsx(styles.buttonsFooter, 'text-center mt-3')}>
                            <button
                                data-cy="save-accreditation-button"
                                type="button"
                                className={'btn btn-primary'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveDataAsync({ ...formikProps.values, saveOnly: true });
                                }}
                                disabled={props.isUpdating}
                            >
                                {t('save')}
                            </button>
                            <button
                                data-cy="save-accreditation-button"
                                type="button"
                                className={'btn btn-primary'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={props.isUpdating}
                            >
                                {t('awb.send')}
                            </button>
                        </div>
                    </>
                );
            }}
        </Formik>
    );
}

export default AccreditationListEditor;