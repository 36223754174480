import React, {useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '@store/index';
import {useHistory} from 'react-router';
import {Col, Container, Row} from 'reactstrap';
import {CreateCustomerRequestQs, GetSaleDto, SaleFilterDto} from '@models/customerRequests/customerRequestModels';
import SaveSvg from '@assets/svg/save.svg';
import MailSvg from '@assets/svg/mail-dark.svg';
import SaleInfo from './components/SaleInfo';
import CustomerRequestInfo from './components/CustomerRequestInfo';
import LoadingSpinner from '@components/LoadingSpinner';
import styles from './Search.module.scss';
import {CalendarData, FiltersComponent, SearchCalendar} from './components';
import {
    actions as customerRequestsActions,
    createCustomerRequest,
    fetchCustomerRequest,
} from '@store/customerRequests/customerRequestsStore';
import {unwrapResult} from '@reduxjs/toolkit';
import {actions as routesActions, appendRoutesSchedules} from '@store/routesStore';
import {flatten, max} from 'lodash';
import Tippy from '@tippyjs/react';
import SendSearchResultsToEmailModal
    from '@scenes/customerRequest/searchResults/components/SendSearchResultsToEmailModal';
import AskDiscountModal from '@scenes/customerRequest/searchResults/components/AskDiscountModal';
import SessionManager from '@root/SessionManager';
import {getInitialDatesToLoadSchedules, getNewDatesToLoadSchedules} from '@helpers/schedulesHelpers';
import SalesService from '@services/SalesService';
import {actions as salesActions, fetchFilteringEntities, fetchSales} from '@store/salesStore';
import {isNullOrEmptyString} from '@helpers/stringHelpers';
import {DateTime} from '@helpers/DateTime';
import {RouteDto} from '@models/routesModels';
import StaticSelect from '@components/select/StaticSelect';
import {CurrencyDto} from '@models/currency';
import * as currencyStore from '@store/currencyStore';
import {fetchOrganization} from '@store/organizationsStore';
import i18n from 'i18next';
import SortSelect, {createSortSelectValue, SortSelectValue} from '@components/select/SortSelect';
import nameof from 'ts-nameof.macro';
import {PropertySorter, SortDirection} from '@models/entityNavigation/sorting';
import orderBy from 'lodash/orderBy';
import {OrganizationAccessLevel} from '@models/organizations';
import {getServiceModeState} from "@store/settingsStore";
import PageHeader from "@components/PageHeader";
import CustomerRequestsService from '@services/CustomerRequestsService';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import SendEmailToClientFromDynamicsModal from './components/SendEmailToClientFromDynamicsModal';

const createFilter = (noConnections: boolean): SaleFilterDto => {
    return {
        noConnections,
        airlineIds: [],
        productIds: [],
        departureIds: [],
        destinationIds: [],
        statuses: [],
    };
};

type SaleToView = {
    sale: GetSaleDto;
    routes: RouteDto[];
    hasRouteForSelectedDate: boolean;
};

const SearchPage = (): JSX.Element => {
    const {startServiceMode, endServiceMode} = useAppSelector(appState => appState.settings);
    const {selectedCode: currencyCode, availableCurrencies} = useAppSelector((x) => x.currencies);
    const {customerRequest, isFetching: isCustomerRequestFetching} = useAppSelector((x) => x.customerRequests);
    const {sales, isSalesFetching} = useAppSelector((x) => x.sales);
    const {routesSchedules: schedules, isFetching: isSchedulesFetching} = useAppSelector((x) => x.routes);
    const {organization, isOrganizationFetching} = useAppSelector((x) => x.organizations);
    const [isServiceModeActive, setIsServiceModeActive] = useState(false);
    const baseCurrencyCode = availableCurrencies.find((x) => x.isBaseCurrency)?.code;
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const createDefaultFilter = () => createFilter(false);
    const [selectedDate, setSelectedDate] = useState<string>(null);
    const [isFetchingSalesDocument, setFetchingSalesDocument] = useState(false);
    const [isFetchingSalesHtml, setFetchingSalesHtml] = useState(false);
    const [filter, setFilter] = useState<SaleFilterDto>(createDefaultFilter());
    const [isFistTimeFetching, setFirstTimeFetching] = useState(true);
    const history = useHistory();
    const isGlobalFetching = isCustomerRequestFetching || isFistTimeFetching;
    const noResultsTotal = !isGlobalFetching && sales.length == 0;
    const createCustomerRequestCmd = useMemo(() => {
        if (isNullOrEmptyString(window.location.search)) {
            return null;
        }

        const qs = window.location.search.slice(1);

        return CreateCustomerRequestQs.fromQueryString(qs).toModel();
    }, [window.location.search]);

    const availableSortProperties = useMemo(
        () => [
            {
                label: t('price'),
                propertyName: nameof.full<SaleToView>((x) => x.sale.total),
                shortPropertyName: nameof<SaleToView>((x) => x.sale.total),
            },
            {
                label: t('airline'),
                propertyName: nameof.full<SaleToView>((x) => x.sale.airlineName),
                shortPropertyName: nameof<SaleToView>((x) => x.sale.airlineName),
            },
        ],
        [i18n.language]
    );
    useEffect(() => {
        dispatch(getServiceModeState());
    }, []);

    useEffect(() => {
        const now = new Date();
        const start = startServiceMode != null ? new Date(startServiceMode) : null;
        const end = endServiceMode != null ? new Date(endServiceMode) : null;
        setIsServiceModeActive(start != null && end != null && (start <= now && end >= now))
    }, [startServiceMode, endServiceMode]);

    useEffect(() => {
        if (organization != null || isOrganizationFetching == true) {
            return;
        }
        dispatch(fetchOrganization({organizationId: SessionManager.user.organizationId}));
    }, [organization, isOrganizationFetching]);

    const salesWithExpirationDate = useMemo(() => {
        return sales.map((x) => ({expirationDate: new DateTime(x.expirationDate), sale: x}));
    }, [sales]);

    const [sorting, setSorting] = useState<SortSelectValue>(
        createSortSelectValue(availableSortProperties[0], SortDirection.Ascending)
    );

    const salesForSelectedDay: SaleToView[] = useMemo(() => {
        if (selectedDate == null) {
            return [];
        }

        const selectedDateTime = new DateTime(selectedDate);
        selectedDateTime.resetTime();

        // We should get the schedules which equals or greatest than start date.
        const schedulesForSelectedDay = schedules
            .map((x) => ({startDateTime: new DateTime(x.dateStart), schedule: x}))
            .filter((x) => x.startDateTime >= selectedDateTime);

        const arr = salesWithExpirationDate
            // TODO .filter((x) => x.expirationDate >= selectedDateTime)
            .map((s) => {
                const routes = flatten(schedulesForSelectedDay.map((x) => x.schedule.routes));
                const routesForSale = routes.filter((x) => x.saleId == s.sale.id);

                const hasRouteForSelectedDate =
                    flatten(
                        schedulesForSelectedDay
                            .filter((x) => x.startDateTime.toISOString() == selectedDateTime.toISOString())
                            .map((x) => x.schedule.routes)
                    ).find((x) => x.saleId == s.sale.id) != null;

                return {routes: routesForSale, sale: s.sale, hasRouteForSelectedDate};
            });

        let results = [];

        const sortDirection = sorting.sortDirection === SortDirection.Ascending ? "asc" : "desc";

        const getTotal = (sale: GetSaleDto) => sale.isConverted ? sale.convertedTotal : sale.total;

        const maxTotal = sortDirection == "asc" ?
            arr.reduce((maxValue, item) => {
                const total = getTotal(item.sale);
                return maxValue < total ? total : maxValue;
            }, 0) + 1
            : 0;

        const isSortingByTotal = sorting.propertyName === nameof.full<SaleToView>((x) => x.sale.total);

        const arrayToOrder =
            arr.map(x => {
                let total: number | string;
                total = getTotal(x.sale);
                if (isSortingByTotal) {
                    if (total == 0) {
                        // move items with 0 total to the end of list
                        total = sortDirection == "asc" ? maxTotal : 0;
                    }
                } else {
                    const valuesToOrder = sortDirection == "asc" ? ['z', 'a'] : ['a', 'z'];
                    total = valuesToOrder[total == 0 ? 0 : 1];
                }

                const itemToSort = {
                    total: total,
                    readonly: x.sale.readonly,
                    rest: x
                };

                if (!isSortingByTotal) {
                    itemToSort[sorting.shortPropertyName] = x.sale[sorting.shortPropertyName];
                }

                return itemToSort;
            });

        const columnsToOrder = ["readonly", "total"];
        const columnsSortingDirections: ("asc" | "desc")[] = ["asc", sortDirection];

        if (!isSortingByTotal) {
            columnsToOrder.push(sorting.shortPropertyName);
            columnsSortingDirections.push(sortDirection);
        }

        results = orderBy(
            arrayToOrder,
            columnsToOrder, // move ReadOnly sales to the end
            columnsSortingDirections
        ).map(x => x.rest);

        return results;
    }, [selectedDate, salesWithExpirationDate, schedules, sorting]);

    const maxSaleExpirationDate = useMemo(() => {
        return max(sales.map((x) => new DateTime(x.expirationDate)));
    }, [sales]);

    const noResultsForSelectedDay = !isGlobalFetching && salesForSelectedDay.length == 0;
    const today = useMemo(() => DateTime.now(true), []);

    const propertySorter = useMemo(() => {
        const sorter = new PropertySorter();
        if (sorting.propertyName) {
            sorter.path = sorting.propertyName;
        }
        if (sorting.sortDirection) {
            sorter.sortDirection = sorting.sortDirection;
        }
        return sorter;
    }, [sorting?.propertyName, sorting?.sortDirection])

    const [withTariffSchedule, setWithTariffSchedule] = useState(false);
    const sendClientOffer = (withSchedule: boolean): void => {
        setWithTariffSchedule(withSchedule);
        setSendEmailToClientModalOpened(true);
    }

    const downloadSalesDocument = (): void => {
        setFetchingSalesDocument(true);

        new SalesService().downloadDocument({
                customerRequestId: customerRequest.id,
                currencyCode,
                baseCurrencyCode,
                selectedDate,
                filter,
                sorter: propertySorter
            },
            customerRequest.departureLocation.codeIata,
            customerRequest.destinationLocation.codeIata,
            customerRequest.number).finally(() => {
            setFetchingSalesDocument(false);
        });
    };

    const downloadSalesHtml = (): void => {
        setFetchingSalesHtml(true);
        const type = 'text/html';
        const downloadHtml = () => new SalesService().downloadHtml({
                customerRequestId: customerRequest.id,
                currencyCode,
                baseCurrencyCode,
                selectedDate,
                filter,
                sorter: propertySorter
            },
            customerRequest.departureLocation.codeIata,
            customerRequest.destinationLocation.codeIata,
            customerRequest.number)

        const getHtmlBlobAsync = async () => {
            const text = await downloadHtml();

            return new Blob([text], {type});
        };

        if ('clipboard' in navigator) {
            navigator.clipboard.write([new ClipboardItem({[type]: getHtmlBlobAsync()})])
                .finally(() => {
                    setFetchingSalesHtml(false);
                });
        } else {
            downloadHtml()
                .then(text => {
                    const listener = (e) => {
                        e.clipboardData.setData("text/html", text);
                        e.clipboardData.setData('text/plain', text);
                    };

                    document.addEventListener("copy", listener);
                    document.execCommand("copy");
                    document.removeEventListener("copy", listener);
                })
                .finally(() => {
                    setFetchingSalesHtml(false);
                });
        }
    };

    const onSubmitSale = (sale: GetSaleDto, requestQuotationMode: boolean, route: RouteDto) => {
        let url = `/customerApplication/new/${customerRequest.id}/${sale.id}`;
        if (route) {
            url += `/${new DateTime(route.dateStart).toDateString()}/${route.routeId}`;
        }
        url += window.location.search;
        url += `&requestQuotation=${requestQuotationMode === true ? 'true' : 'false'}`;
        history.push(url);
    };

    const [saleForDiscountId, setSaleForDiscountId] = useState<string>();
    const [askDiscountModalOpened, setAskDiscountModalOpened] = useState(false);
    const onAskDiscount = (sale: GetSaleDto) => {
        setSaleForDiscountId(sale.id); // save Sale ID
        setAskDiscountModalOpened(true); // show Modal
    };

    const calendarData: CalendarData[] = useMemo(() => {
        if (schedules == null || schedules.length == 0) {
            return [];
        }

        return schedules.map((x) => ({
            date: x.dateStart,
            resultCount: x.routes.filter((z) => sales.find((s) => s.id == z.saleId) != null).length,
        }));
    }, [schedules, sales]);

    useEffect(() => {
        if (createCustomerRequestCmd == null) {
            return;
        }

        dispatch(routesActions.resetRoutes());
        dispatch(salesActions.resetSales());
        dispatch(salesActions.resetFilteringEntities());
        dispatch(customerRequestsActions.resetCustomerRequest());

        setSelectedDate(new DateTime(createCustomerRequestCmd.dateStartPlan).toISOString());

        const getRequestId = createCustomerRequestCmd.id
            ? Promise.resolve(createCustomerRequestCmd.id)
            : dispatch(createCustomerRequest(createCustomerRequestCmd)).then((res) => unwrapResult(res));
        getRequestId.then((id) => {
            setFilter(createDefaultFilter());

            dispatch(fetchCustomerRequest({id}));

            const requestDate = new DateTime(createCustomerRequestCmd.dateStartPlan);
            requestDate.resetTime();
            const d = requestDate < today ? today : requestDate;

            const initialDates = getInitialDatesToLoadSchedules(d, today);

            dispatch(
                appendRoutesSchedules({
                    customerRequestId: id,
                    departureDates: initialDates.map((x) => x.toISOString()),
                })
            );

            dispatch(
                fetchSales({
                    customerRequestId: id,
                    filter,
                    currencyCode,
                })
            );

            dispatch(fetchFilteringEntities({customerRequestId: id, filter}));
        });

        return () => {
            dispatch(customerRequestsActions.resetCustomerRequest());
        };
    }, [createCustomerRequestCmd]);

    useEffect(() => {
        return () => {
            dispatch(routesActions.resetRoutes());
            dispatch(salesActions.resetSales());
            dispatch(salesActions.resetFilteringEntities());
            dispatch(customerRequestsActions.resetCustomerRequest());
        };
    }, []);

    useEffect(() => {
        if (!isFistTimeFetching) {
            return;
        }

        if (!isSalesFetching && !isSchedulesFetching) {
            setFirstTimeFetching(false);
        }
    }, [sales, schedules]);

    const onChangeSelectedDate = (newDate: string) => {
        setSelectedDate(newDate);

        const datesToLoad = getNewDatesToLoadSchedules(new DateTime(newDate), schedules, today).filter(
            (x) => x < maxSaleExpirationDate
        );

        if (datesToLoad.length > 0 && !isSchedulesFetching) {
            dispatch(
                appendRoutesSchedules({
                    customerRequestId: customerRequest.id,
                    departureDates: datesToLoad.map((x) => x.toISOString()),
                })
            );
        }
    };

    const [emailSendModalOpened, setEmailSendModalOpened] = useState(false);

    const [sendEmailToClientModalOpened, setSendEmailToClientModalOpened] = useState(false);

    const [buttonsDropdownOpen, setButtonsDropdownOpen] = useState(false);

    if (isServiceModeActive) {
        return <h3>{t('request.serviceModeActive')}</h3>
    }

    return (
        <>
            <Container fluid={true} className={`${styles.searchResults}`}>
                <Row className="h-100">
                    <Col md={12} lg={12} xl={9} className={styles.mainContent}>
                        <PageHeader title={t('requestSearchResults.searchResults')}
                                    additionalInfo={!isGlobalFetching && `${t('requestSearchResults.found')}: ${sales.length}`}/>

                        {!isGlobalFetching && (
                            <>
                                <div className={styles.controls}>
                                    <div className={styles.buttons}>
                                        {sales.length > 0 && (
                                            <Dropdown isOpen={buttonsDropdownOpen}
                                                    toggle={() => setButtonsDropdownOpen((prevState) => !prevState)}
                                                    className={styles.sendButtons}>
                                                <DropdownToggle caret className={styles.dropHeader}>
                                                    <img src={MailSvg}/>
                                                    {t('requestSearchResults.sendHeader')}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => setEmailSendModalOpened(true)} className={styles.dropItem}>
                                                        {t('requestSearchResults.sendFile')}
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => sendClientOffer(false)} className={styles.dropItem}>
                                                        {t('requestSearchResults.sendEmail')}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        )}
                                        
                                        {sales.length > 0 && (
                                            <Tippy content={t('requestSearchResults.sendSchedule')}>
                                                <button
                                                    style={{display: 'flex'}}
                                                    type="button"
                                                    onClick={() => sendClientOffer(true)}
                                                >
                                                    <img src={MailSvg}/>
                                                    {t('requestSearchResults.sendSchedule')}
                                                </button>
                                            </Tippy>
                                        )}

                                        {isFetchingSalesDocument ? (
                                            <div className={styles.loader}/>
                                        ) : (
                                            <Tippy content={t('requestSearchResults.downloadSalesDocument')}>
                                                <button
                                                    style={{display: sales.length == 0 ? `none` : 'flex'}}
                                                    type="button"
                                                    disabled={isFetchingSalesDocument || isFetchingSalesHtml}
                                                    onClick={downloadSalesDocument}
                                                >
                                                    <img src={SaveSvg}/>
                                                    {t('requestSearchResults.downloadFile')}
                                                </button>
                                            </Tippy>
                                        )}

                                        {isFetchingSalesHtml ? (
                                            <div className={styles.loader}/>
                                        ) : (
                                            <Tippy content={t('requestSearchResults.copySalesHtml')}>
                                                <button
                                                    style={{display: sales.length == 0 ? `none` : 'flex'}}
                                                    type="button"
                                                    disabled={isFetchingSalesDocument || isFetchingSalesHtml}
                                                    onClick={downloadSalesHtml}
                                                >
                                                    <img src={SaveSvg}/>
                                                    {t('requestSearchResults.copySales')}
                                                </button>
                                            </Tippy>
                                        )}
                                    </div>
                                    <div className={styles.filter}>
                                        <div style={{width: 90}} className="mr-2">
                                            <StaticSelect<CurrencyDto>
                                                value={availableCurrencies.filter((x) => x.code == currencyCode)}
                                                availableOptions={availableCurrencies}
                                                onChange={(x) => {
                                                    dispatch(
                                                        currencyStore.actionCreators.updateSelectedCurrency(x[0]?.code)
                                                    );
                                                    window.location.reload();
                                                }}
                                                optionLabel={(x) => <>{x.name}</>}
                                                selectedLabel={(x) => <>{x.name}</>}
                                                isSearchable={false}
                                            />
                                        </div>
                                        <div style={{width: 180}} className="mr-2" data-order>
                                            <SortSelect
                                                availableProperties={availableSortProperties}
                                                onChange={(value) => setSorting(value)}
                                                value={sorting}
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {!isGlobalFetching && !noResultsTotal && (
                            <Row>
                                <Col>
                                    <SearchCalendar
                                        value={selectedDate}
                                        data={calendarData}
                                        onChange={(date) => {
                                            onChangeSelectedDate(date);
                                        }}
                                        daysToRenderNext={20}
                                        disabledFromDate={maxSaleExpirationDate}
                                    />
                                </Col>
                            </Row>
                        )}

                        {isGlobalFetching && (
                            <LoadingSpinner searchText={t('requestSearchResults.searchText')}/>
                        )}

                        {noResultsTotal || noResultsForSelectedDay ? (
                            <p className="text-center my-5 pre-line">
                                <Trans
                                    i18nKey={'requestSearchResults.nothingFound'}
                                    values={{email: SessionManager.settings['SalesOperatorEmail']}}
                                    components={[<a key={0}>x</a>]}
                                />
                            </p>
                        ) : null}

                        {!isGlobalFetching && salesForSelectedDay.length > 0 && (
                            <Row>
                                <Col>

                                    <div className={styles.table}>
                                        <div className={styles.tableHeader}>
                                            <div>{t('airline')}</div>
                                            <div>{t('requestSearchResults.product')}</div>
                                            <div>{t('requestSearchResults.status')}</div>
                                            <div
                                                className={styles.routeHeader}>{t('requestSearchResults.route')}</div>
                                            <div>{t('requestSearchResults.feeTableHeader')}</div>
                                        </div>
                                        {salesForSelectedDay.map((s, i) => (
                                            <SaleInfo
                                                key={i}
                                                selectedDate={selectedDate}
                                                sale={s.sale}
                                                routes={s.routes}
                                                hasGenCode={(customerRequest?.specialHandlingCodes || []).some(
                                                    (shc) => shc.name.indexOf('GEN') === 0
                                                )}
                                                isStackable={customerRequest?.isCargoStacked === true}
                                                userCanBook={organization?.accessLevel !== OrganizationAccessLevel.GrantedNoBooking}
                                                hasRouteForSelectedDate={s.hasRouteForSelectedDate}
                                                hasCustomerApplication={customerRequest.hasCustomerApplication}
                                                onSubmit={(requestQuotationMode, route) => onSubmitSale(s.sale, requestQuotationMode, route)}
                                                onAskDiscount={() => onAskDiscount(s.sale)}
                                            />
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                    <Col md={12} lg={12} xl={3} className={styles.sidebar}>
                        <CustomerRequestInfo customerRequest={customerRequest}/>
                        <div
                            style={{
                                pointerEvents: isCustomerRequestFetching ? 'none' : 'auto',
                                opacity: isCustomerRequestFetching ? '0.4' : '1',
                            }}
                        >
                            <FiltersComponent/>
                        </div>
                    </Col>
                </Row>
            </Container>

            <SendSearchResultsToEmailModal
                model={{
                    email: SessionManager.user.email,
                    customerRequestId: customerRequest?.id,
                    currencyCode,
                    baseCurrencyCode,
                    selectedDate,
                    filter,
                    sorter: propertySorter
                }}
                isOpened={emailSendModalOpened}
                onClose={() => setEmailSendModalOpened(false)}
            />

            <SendEmailToClientFromDynamicsModal
                model={{
                    RequestId: customerRequest?.id,
                    WithTariffSchedule: withTariffSchedule,
                    SaleIDs: (sales || []).map(x => x.id),
                    MyEmail: SessionManager.user.email
                }}
                isOpened={sendEmailToClientModalOpened}
                onClose={() => setSendEmailToClientModalOpened(false)}
            />

            <AskDiscountModal
                saleId={saleForDiscountId}
                isOpened={askDiscountModalOpened}
                onClose={() => setAskDiscountModalOpened(false)}
            />
        </>
    );
};

export default SearchPage;