import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { GetSpecialHandlingCodeDto, VisibilityType } from '@models/cargoes';
import { fetchSpecialHandlingCodes } from '@store/cargoes/specialHandlingCodesStore';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Row } from 'reactstrap';
import { useField } from 'formik';
import nameof from 'ts-nameof.macro';
import { AirwaybillDto } from '@models/awbs/awbsModels';
import Select from '@components/inputs/Select/Select';
import styles from './index.module.scss';
import { OtherShcModal } from '@scenes/customerApplication/awb/components/OtherShcModal';

export const ShcDetails = () => {
    const { specialHandlingCodes, currentVisibility } = useAppSelector((x) => x.specialHandlingCodes);
    const [eAwbShCodes, setEAwbShCodes] = useState<GetSpecialHandlingCodeDto[]>([]);
    const [securityShCodes, setSecurityShCodes] = useState<GetSpecialHandlingCodeDto[]>([]);
    const [staticShCodes, setstaticShCodes] = useState<GetSpecialHandlingCodeDto[]>([]);
    const [otherShCodes, setOtherShCodes] = useState<GetSpecialHandlingCodeDto[]>([]);
    const [allIdsExceptOthers, setAllIdsExceptOthers] = useState<string[]>([]);
    const [isOtherCodesModalOpen, setIsOtherCodesModalOpen] = useState(false);
    const eAwbCodes = ['EAP', 'EAW'];
    const securityCodes = ['NSC', 'SCO', 'SPX', 'SHR'];
    const staticCodes = ['PER', 'XPS', 'COL', 'AVI'];

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (specialHandlingCodes.length == 0 || currentVisibility != VisibilityType.InAirwayBill) {
            dispatch(fetchSpecialHandlingCodes({ visibilityType: VisibilityType.InAirwayBill }));
        }
    }, []);

    useEffect(() => {
        if (specialHandlingCodes == null || specialHandlingCodes.length == 0) {
            setEAwbShCodes([]);
            setSecurityShCodes([]);
            setstaticShCodes([]);
            setOtherShCodes([]);
            return;
        }

        function addShCode(specialHandlingCode: GetSpecialHandlingCodeDto, codes: string[], shCodes: GetSpecialHandlingCodeDto[]) {
            if (codes.includes(specialHandlingCode.code)) {
                shCodes.push(specialHandlingCode);
                return true;
            }

            return false;
        }

        const eCodes = [{ id: '', code: '', name: t('awb.notSpecified') }];
        const sCodes = [{ id: '', code: '', name: t('awb.notSpecified') }];
        const stCodes: GetSpecialHandlingCodeDto[] = [];
        const otherCodes: GetSpecialHandlingCodeDto[] = [];
        const allExceptOthers: string[] = [];

        for (const specialHandlingCode of specialHandlingCodes) {
            if (addShCode(specialHandlingCode, eAwbCodes, eCodes)) {
                allExceptOthers.push(specialHandlingCode.id);
                continue;
            }

            if (addShCode(specialHandlingCode, securityCodes, sCodes)) {
                allExceptOthers.push(specialHandlingCode.id);
                continue;
            }

            if (addShCode(specialHandlingCode, staticCodes, stCodes)) {
                allExceptOthers.push(specialHandlingCode.id);
                continue;
            }

            otherCodes.push(specialHandlingCode);
        }

        setEAwbShCodes(eCodes);
        setSecurityShCodes(sCodes);
        setstaticShCodes(stCodes);
        setOtherShCodes(otherCodes);
        setAllIdsExceptOthers(allExceptOthers);
    }, [specialHandlingCodes]);

    const [field, meta, helpers] = useField(nameof<AirwaybillDto>(a => a.shcCodes));

    if (specialHandlingCodes.length == 0) {
        return null;
    }


    const replaceAwb = (newShcId: string, allowedCodes: GetSpecialHandlingCodeDto[]) => {
        const shcCodes = field.value?.filter((id: string) => allowedCodes.find(c => c.id == id) == null) || [];

        if (newShcId != '') {
            shcCodes.push(newShcId);
        }

        helpers.setValue(shcCodes);
    };

    const toggleStaticCode = (shcId: string, isSelected: boolean) => {
        const shcCodes = field.value?.filter((id: string) => shcId != id) || [];
        if (isSelected) {
            shcCodes.push(shcId);
        }

        helpers.setValue(shcCodes);
    };

    return <>
        <Row>
            <Col>
                <Select<GetSpecialHandlingCodeDto, string>
                    onChange={options => replaceAwb(options[0].id, eAwbShCodes)}
                    isMulti={false}
                    availableObjects={eAwbShCodes}
                    values={field.value?.filter(id => eAwbShCodes.find(e => e.id == id) != null)}
                    optionLabelSelector={selectedObject => <>{(selectedObject.id == '' ? '' : `${selectedObject.code} - `)}{selectedObject.name}</>}
                    placeholder={t('awb.eAwb')}
                    valueSelector={obj => obj.id}
                />
            </Col>
            <Col>
                <Select<GetSpecialHandlingCodeDto, string>
                    onChange={options => replaceAwb(options[0].id, securityShCodes)}
                    isMulti={false}
                    availableObjects={securityShCodes}
                    values={field.value?.filter(id => securityShCodes.find(e => e.id == id) != null)}
                    optionLabelSelector={selectedObject => <>{(selectedObject.id == '' ? '' : `${selectedObject.code} - `)}{selectedObject.name}</>}
                    placeholder={t('awb.security')}
                    valueSelector={obj => obj.id}
                />
            </Col>
        </Row>
        <div className={styles.staticCodes}>
            {staticShCodes?.map((shCode, idx) => <div key={shCode.id} className={styles.staticCode}>
                <Input
                    id={`shCode-${idx}`}
                    type={'checkbox'}
                    checked={field.value?.find(id => shCode.id == id) != null}
                    onChange={(e) => toggleStaticCode(shCode.id, e.target.checked)}
                />
                <label htmlFor={`shCode-${idx}`}>{shCode.code} - {shCode.name}</label>
            </div>)}
        </div>
        <div className={styles.otherCodes}>
            <div className={styles.otherCodesInput}>
                {otherShCodes
                    .filter(shc => field.value?.includes(shc.id))
                    .map(shc => <span key={shc.code}>{shc.code}</span>)}
            </div>
            <Button
                className={styles.searchCodes}
                onClick={() => setIsOtherCodesModalOpen(true)}
            />
        </div>
        <OtherShcModal
            isOpen={isOtherCodesModalOpen}
            setIsOpen={setIsOtherCodesModalOpen}
            otherShCodes={otherShCodes}
            selectedShcIds={field.value}
            onChange={ids => {
                helpers.setValue([...(field.value || []).filter(id => allIdsExceptOthers.includes(id)), ...ids]);
            }} />
    </>;
};