import { KeyValuePair } from '@core/models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function enumToKeyValuePairArray(enumType: any): KeyValuePair<number>[] {
	const results: KeyValuePair<number>[] = [];
	for (const enumMember in enumType) {
		const isValueProperty = parseInt(enumMember, 10) >= 0
		if (isValueProperty) {
			results.push({ key: enumType[enumMember], value: parseInt(enumMember) });
		}
	}
	return results;
}