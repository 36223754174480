import React, { ReactNode } from 'react';
import cn from 'classnames';
import s from './badge.module.scss';

export type ChipColors = 'pink' | 'orange' | 'blue' | 'yellow' | 'red' | 'green' | 'gray' | 'white';

export interface IChip {
	icon?: React.ReactNode;
	label?: string | number | ReactNode;
	color?: ChipColors;
	className?: string;
	crossed?: boolean;
	size?: "small" | "large";
}

const Badge: React.FC<IChip> = ({ icon, label, size, color = 'pink', className, crossed }) => {
	if (!label) return null;
	return (
		<div
			className={cn([
				s.chip,
				{ [s.bgBlue]: color === 'blue' },
				{ [s.bgOrange]: color === 'orange' },
				{ [s.bgPink]: color === 'pink' },
				{ [s.bgYellow]: color === 'yellow' },
				{ [s.bgRed]: color === 'red' },
				{ [s.bgGreen]: color === 'green' },
				{ [s.bgGray]: color === 'gray' },
				{ [s.bgWhite]: color === 'white' },
				{ [s.small]: size === 'small' },
				{ [s.large]: size === 'large' },
				className,
			])}
		>
			{icon && <span className={label ? s.chipIcon : undefined}>{icon}</span>}
			{label && (
				<span title={label.toString()} className={cn([{ [s.crossed]: crossed }])}>
					{label}
				</span>
			)}
		</div>
	);
};

export default Badge;
