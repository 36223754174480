import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import SettingsEntry from '@scenes/admin/components/SettingsEntry';
import { createSettingsEntry, deleteSettingsEntry, fetchSettings, updateSettingsEntry } from '@store/settingsStore';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { fetchLanguages } from '@store/languagesStore';
import { CreateSettingsEntry, SettingsEntryDto } from '@models/admin/settingsModels';
import AppModal from '@components/AppModal';
import { AdminTopNavBlock } from '@scenes/admin/components/AdminTopNavBlock';
import { useHistory } from 'react-router';
import { unwrap } from '@helpers/reduxHelpers';
import PageHeader from '@components/PageHeader';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SettingsPage = (props) => {

  const { models } = useAppSelector(x => x.settings);
  const { languages } = useAppSelector(x => x.languages);

  const [isDeleteModalOpen, toggleDeleteModalOpen] = useState(false);
  const [modelToDelete, setModelToDelete] = useState<SettingsEntryDto>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchLanguages());
    dispatch(fetchSettings());
  }, []);

  const { t } = useTranslation();

  const onAdd = async (model: CreateSettingsEntry) => {
    const r = unwrap(await dispatch(createSettingsEntry(model)));
    return !(r.isError == true);
  };

  const onUpdate = async (model: SettingsEntryDto) => {
    const r = unwrap(await dispatch(updateSettingsEntry(model)));
    return !(r.isError == true);
  };

  const onDelete = (model: SettingsEntryDto) => {
    setModelToDelete(model);
    toggleDeleteModalOpen(true);
  };

  const submitDelete = () => {
    return dispatch(deleteSettingsEntry(modelToDelete.id))
      .then(x => {
        toggleDeleteModalOpen(false);
        setModelToDelete(null);
        return x;
      });
  };

  const history = useHistory();

  return <>
    <PageHeader title={t('admin.settings.settings')} />
    <Row>
      <Col>
        <AdminTopNavBlock
          backButtonText={t('admin.backToPanel')}
          onClickBackButton={() => history.push('/admin/panel')}
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <table className='table table-hover'>
          <thead>
          <tr>
            <th key={'isPublic'}>{t('admin.settings.isPublic')}</th>
            <th key={'key'}>{t('admin.settings.key')}</th>
            <th key={'value'}>{t('admin.settings.value')}</th>
            <th key={'language'} style={{ minWidth: 170 }}>{t('admin.settings.language')}</th>
            <th key={'category'}>{t('admin.settings.category')}</th>
            <th style={{ minWidth: 130 }} />
          </tr>
          </thead>
          <tbody>
          {
            models
              .map((x, i) =>
                <SettingsEntry
                  key={i}
                  model={x}
                  onSave={onUpdate}
                  onDelete={onDelete}
                  availableLanguages={languages}
                  isNew={false}
                  otherEntries={models}
                />,
              )
          }
          {
            <SettingsEntry
              key={'new'}
              model={{}}
              availableLanguages={languages}
              onSave={onAdd}
              isNew={true}
              otherEntries={models}
            />
          }
          </tbody>
        </table>
      </Col>

    </Row>

    <AppModal isOpen={isDeleteModalOpen}
              onClickCloseButton={() => toggleDeleteModalOpen(false)}
              body={<>
                <h4>{t('admin.settings.deleteModalMessage').replace('{0}', modelToDelete?.key)}</h4>
                {t('admin.settings.areYouSure')}
              </>}
              footer={<div className='text-center w-100'>
                <a
                  className='btn btn-primary'
                  onClick={e => {
                    e.preventDefault();
                    submitDelete();
                  }}
                >{t('admin.settings.yes')}</a>
                {'   '}
                <a
                  className='btn btn-primary'
                  onClick={e => {
                    e.preventDefault();
                    toggleDeleteModalOpen(false);
                    setModelToDelete(null);
                  }}
                >{t('admin.settings.cancel')}</a>
              </div>}
    />

  </>;
};

export default SettingsPage;