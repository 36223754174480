import { SenderEmailModelDto } from "@models/settings/SenderEmailModel";
import { createAppThunk } from "@helpers/reduxHelpers";
import SenderEmailService from "@services/SenderEmailService";
import { createSlice } from "@reduxjs/toolkit";

export type State = {
	emails: SenderEmailModelDto[];
	isFetching: boolean;
}

export const fetchSenderEmails = createAppThunk('sender-emails/get', async () => {
	const {data} = await new SenderEmailService().getSenderEmails();

	return data;
})

const slice = createSlice({
	name: 'senderEmails',
	initialState: {
		isFetching: false,
		emails: []
	} as State,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchSenderEmails.pending, state => {
				state.isFetching = true;
				state.emails = [];
			})
			.addCase(fetchSenderEmails.fulfilled,
				(state, action) => {
					state.isFetching = false;
					state.emails = action.payload;
				});
	}
});

export const { reducer } = slice;
export const { actions } = slice;
