import React from 'react';
import { TariffDictionaryItem, TariffType } from '@models/tariffs';
import { useTranslation } from 'react-i18next';

type Props = {
    tariff: TariffDictionaryItem;
};

const TariffTypeDisplayName = (props: { type: TariffType }): JSX.Element => {
    const { type } = props;

    const { t } = useTranslation();

    const getName = (type: TariffType): string => {
        switch (type) {
            case TariffType.BUY:
                return t('admin.tariffs.buy');
            case TariffType.SELL:
                return t('admin.tariffs.sell');
            case TariffType.BUY_SELL:
                return t('admin.tariffs.buySell');
            case TariffType.None:
                return t('admin.tariffs.none');
            default:
                return '';
        }
    };

    return <span>{getName(type)}</span>;
};

const TariffItem = (props: Props): JSX.Element => {
    const { tariff } = props;

    return (
        <tr>
            <td>{tariff.code}</td>
            <td>
                <TariffTypeDisplayName type={tariff.type} />
            </td>
            <td>{tariff.departureAirportCode}</td>
            <td>{tariff.destinationAirportCode}</td>
            <td>{tariff.airlineCode}</td>
            <td>{tariff.currencyCode}</td>
            <td>{tariff.productName}</td>
        </tr>
    );
};

export default TariffItem;
