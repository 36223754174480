import s from './style.module.scss';
import React from 'react';
import { AdvantageListItem } from '../../types';
import { Badge } from '@root/components';

interface IListAnimated {
  items: AdvantageListItem[];
}

export const ListAnimated: React.FC<IListAnimated> = ({ items }) => {
  return (
    <ul className={s.list}>
      {items.map((item, index) =>
        <li key={index}>
          {item.isStrong ? <strong>{item.text}</strong> : item.text}
          {item.isSoon && <Badge className="ml-2" color='blue' label='Скоро' />}
        </li>,
      )}
    </ul>
  );
};