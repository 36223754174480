import * as React from 'react';
import './MoonIcon.css';
import '@icomoon/style.css';

type IconProps = {
    icon: string;
    className?: string;
    withBorder?: boolean;
};

const MoonIcon = ({ icon, className, withBorder }: IconProps): JSX.Element => {
    return (
        <span className={`moon-icon ${icon} ${className || ""} ${withBorder ? "moon-icon-with-border" : ""}`} />
    );
};

export default MoonIcon;
