import { createSlice } from '@reduxjs/toolkit';
import { createAppThunk } from '@helpers/reduxHelpers';
import {
	ContactDto,
	ContactListItemDto, ContactPermissionListItemDto,
	CreateContact,
	GetContacts, ToggleContactActivation,
	UpdateContact,
	UpdateContactsPermissions
} from '@models/contacts';
import ContactsService from '@services/ContactsService';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { InteractionType, InteractionTypeDto } from '@models/contacts/InteractionType';
//import { JobPositionTypeDto } from '@models/contacts/JobPositionType';
import { ContactPermissionTypeDto } from '@models/contacts/ContactPermissionType';
import { HttpError } from '@models/shared';

export type State = {
	contact?: ContactDto;
	interactionTypes: InteractionTypeDto[];
	//jobPositionTypes: JobPositionTypeDto[];
	contacts: IPagingWrapper<ContactListItemDto>;
	isContactFetching: boolean;
	isInteractionTypesFetching: boolean;
	//isJobPositionTypesFetching: boolean;
	isContactPagerFetching: boolean;
	isContactEditFetching: boolean;

	isPermissionsUpdating: boolean;
	isPermissionsFetching: boolean;
	permissions: ContactPermissionListItemDto[];

	isPermissionTypesFetching: boolean;
	contactPermissionTypes: ContactPermissionTypeDto[];

	isColleageEmailsFetching: boolean;
	colleagueEmailsWithOperationalInteractionType: string[];
};

export const fetchContact = createAppThunk('contacts/fetchContact', async (arg: { id: string }) => {
	const { data } = await new ContactsService().fetchOne(arg.id);
	return data;
});

export const fetchContactPager = createAppThunk('contacts/fetchContactPager', async (arg: GetContacts) => {
	const { data } = await new ContactsService().fetchPager(arg);
	return data;
});

export const updateContact = createAppThunk('contacts/updateContact', async (arg: UpdateContact) => {
	const { data } = await new ContactsService().update(arg);
	return data;
});

export const createContact = createAppThunk('contacts/createContact', async (arg: CreateContact) => {
	const { data } = await new ContactsService().create(arg);
	return data;
});

export const fetchInteractionTypes = createAppThunk('contacts/fetchInteractionTypes', async () => {
	const {data} = await new ContactsService().fetchInteractionTypes();
	return data;
});

export const fetchContactPermissionTypes = createAppThunk(
	'contacts/fetchContactPermissionTypes',
	async () => {
		const { data } = await new ContactsService().fetchContactPermissionTypes();
		return data;
	}
);

export const fetchContactPermissionsMap = createAppThunk(
	'contacts/fetchContactPermissionsMap',
	async (arg: { organizationId: string }) => {
		const { data } = await new ContactsService().fetchContactPermissionsMap(arg.organizationId);
		return data;
	}
);

export const fetchColleaguesByInteractionType = createAppThunk(
	'contacts/fetchColleaguesByInteractionType',
	async (arg: { iType: InteractionType }) => {
		const { data } = await new ContactsService().fetchColleaguesByInteractionType(arg.iType);
		return data;
	}
);

export const updateContactPermissionsMap = createAppThunk(
	'contacts/updateContactPermissionsMap',
	async (arg: UpdateContactsPermissions) => {
		const { data } = await new ContactsService().updateContactPermissionsMap(arg);
		return data;
	}
);

export const toggleContactActivation = createAppThunk(
	'contacts/toggleContactActivation',
	async (arg: ToggleContactActivation) => {
		const { data } = await new ContactsService().toggleActivation(arg);
		return data;
	}
);

const slice = createSlice({
	name: 'contacts',
	initialState: {
		contact: null,
		contacts: { items: null, totalCount: 0 },
		//jobPositionTypes: [],
		interactionTypes: [],
		isContactFetching: false,
		isContactEditFetching: false,
		isInteractionTypesFetching: false,
		//isJobPositionTypesFetching: false,
		isContactPagerFetching: false,
		isPermissionsUpdating: false,
		isPermissionsFetching: false,
		permissions: [],
		isPermissionTypesFetching: false,
		contactPermissionTypes: [],
		colleagueEmailsWithOperationalInteractionType: [],
		isColleageEmailsFetching: false
	} as State,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchContact.fulfilled, (state, action) => {
			state.contact = action.payload as ContactDto;
			state.isContactFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchContact.rejected, (state, action) => {
			state.contact = null;
			state.isContactFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchContact.pending, (state, action) => {
			state.contact = null;
			state.isContactFetching = true;
		});

		builder.addCase(fetchContactPager.fulfilled, (state, action) => {
			state.contacts = action.payload as IPagingWrapper<ContactListItemDto>;
			state.isContactPagerFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchContactPager.rejected, (state, action) => {
			state.isContactPagerFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchContactPager.pending, (state, action) => {
			state.isContactPagerFetching = true;
		});

		builder.addCase(updateContact.fulfilled, (state, action) => {
			state.isContactEditFetching = false;

			state.contact = { ...state.contact, ...action.meta.arg };

			const contactId = action.meta.arg.id;

			const contactFromList = state.contacts.items.find((x) => x.id == contactId);
			if (!contactFromList) {
				return;
			}

			const newContactFromList = { ...contactFromList, ...action.meta.arg };

			state.contacts.items = state.contacts.items
				.filter((x) => x.id != contactId)
				.concat([newContactFromList as ContactListItemDto]);
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(updateContact.rejected, (state, action) => {
			state.isContactEditFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(updateContact.pending, (state, action) => {
			state.isContactEditFetching = true;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(createContact.fulfilled, (state, action) => {
			state.contact = null;
			state.isContactEditFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(createContact.rejected, (state, action) => {
			state.isContactEditFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(createContact.pending, (state, action) => {
			state.isContactEditFetching = true;
		});

		builder.addCase(fetchInteractionTypes.fulfilled, (state, action) => {
			state.interactionTypes = action.payload;
			state.isInteractionTypesFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchInteractionTypes.rejected, (state, action) => {
			state.isInteractionTypesFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchInteractionTypes.pending, (state, action) => {
			state.isInteractionTypesFetching = true;
		});

		// builder.addCase(fetchJobPositionTypes.fulfilled, (state, action) => {
		// 	state.jobPositionTypes = action.payload;
		// 	state.isJobPositionTypesFetching = false;
		// });
		// // eslint-disable-next-line @typescript-eslint/no-unused-vars
		// builder.addCase(fetchJobPositionTypes.rejected, (state, action) => {
		// 	state.isJobPositionTypesFetching = false;
		// });
		// // eslint-disable-next-line @typescript-eslint/no-unused-vars
		// builder.addCase(fetchJobPositionTypes.pending, (state, action) => {
		// 	state.isJobPositionTypesFetching = true;
		// });

		builder.addCase(fetchContactPermissionsMap.fulfilled, (state, action) => {
			state.permissions = action.payload;
			state.isPermissionsFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchContactPermissionsMap.rejected, (state, action) => {
			state.isPermissionsFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchContactPermissionsMap.pending, (state, action) => {
			state.isPermissionsFetching = true;
		});

		builder.addCase(updateContactPermissionsMap.fulfilled, (state, action) => {
			state.permissions.forEach(x => {
				action.meta.arg[x.id] = x.permissions
			});
			state.isPermissionsUpdating = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(updateContactPermissionsMap.rejected, (state, action) => {
			state.isPermissionsUpdating = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(updateContactPermissionsMap.pending, (state, action) => {
			state.isPermissionsUpdating = true;
		});

		builder.addCase(fetchContactPermissionTypes.fulfilled, (state, action) => {
			state.contactPermissionTypes = action.payload;
			state.isPermissionTypesFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchContactPermissionTypes.rejected, (state, action) => {
			state.isPermissionTypesFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchContactPermissionTypes.pending, (state, action) => {
			state.isPermissionTypesFetching = true;
		});

		builder.addCase(toggleContactActivation.fulfilled, (state, action) => {
			const id = action.meta.arg.id;
			const doInactive = !action.meta.arg.isActivated;

			if(state.contact?.id == id){
				state.contact.inactive = doInactive;
			}

			const contact = state.contacts?.items?.find(x => x.id == id);
			if(contact){
				contact.inactive = doInactive;
			}

			state.isContactEditFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(toggleContactActivation.rejected, (state, action) => {
			state.isContactEditFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(toggleContactActivation.pending, (state, action) => {
			state.isContactEditFetching = true;
		});

		//
		builder.addCase(fetchColleaguesByInteractionType.fulfilled, (state, action) => {
			state.colleagueEmailsWithOperationalInteractionType = action.payload;
			state.isColleageEmailsFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchColleaguesByInteractionType.rejected, (state, action) => {
			state.isColleageEmailsFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchColleaguesByInteractionType.pending, (state, action) => {
			state.isColleageEmailsFetching = true;
		});
	},
});

export const { reducer } = slice;
export const { actions } = slice;