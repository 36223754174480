import {IImageDto} from '@models/shared';
import {StandardNavigation} from '@models/entityNavigation/StandardNavigation';

export interface IGetCustomerApplicationHistoryDto {
    id: string;
    number: string;
    customerRequestId: string;
    airwaybillNumber: string;
    createTime: string;
    dateStartPlan?: string;
    dateStartFact?: string;
    dateEndPlan?: string;
    dateEndFact?: string;
    totalPrice: number;
    costPerUnit: number;
    currencyCode: string;
    unitCurrencyCode: string;
    cargoTotalAmount: number;
    cargoTotalWeight: number;
    cargoTotalVolume: number;
    organizationName: string;
    airline: IAirlineDto;
    departureAirport: IAirportDto;
    destinationAirport: IAirportDto;
    cargoes?: ICargoDto[];
    customerRequestNo: string;
}

export interface ICargoDto {
    amount: number;
    weight: number;
    width: number;
    length: number;
    height: number;
}

export interface IAirlineDto {
    id: string;
    logo: IImageDto;
    name: string;
    currencyCode?: string;
    unitCurrencyCode: string;
    totalPrice: string;
}

export interface IAirportDto {
    id: string;
    code: string;
    displayName: string;
    countryName: string;
    cityName: string;
}

export class GetAirlinesForApplicationsByTerm extends StandardNavigation
{
    term: string;
}

export type GetAirlinesForApplicationsByTermDto = {
    id: string;
    name: string;
}