import * as React from "react";

const LoaderOld = (props: {showBackground?: boolean}) => {
    
    const showBackground = props.showBackground != null ? props.showBackground : true;
    
    return showBackground ? <div style={{position: "relative", height: 100}}>
        <div className='loader-bg'>
            <div className='loader'/>
        </div>
    </div> : <div className='loader'/>;
}

export default LoaderOld;