import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterItemDto, SaleFilterDto, SaleStatus } from '@models/customerRequests/customerRequestModels';
import s from './filters.module.scss';
import { useAppDispatch, useAppSelector } from '@root/store';
import { FilterGroup } from '@scenes/customerRequest/searchResults/components/Filter/components';
import { fetchFilteringEntities, fetchSales } from '@store/salesStore';
import { get } from 'lodash';

const createFilter = (noConnections: boolean): SaleFilterDto => {
    return {
        noConnections,
        airlineIds: [],
        productIds: [],
        departureIds: [],
        destinationIds: [],
        statuses: [],
    };
};

function processArray<T>(array: Array<T>, element: T, checked: boolean): void {
    if (array && element) {
        const index = array.indexOf(element);
        if (checked) {
            if (index === -1) {
                array.push(element);
            }
        } else {
            if (index !== -1) {
                array.splice(index, 1);
            }
        }
    }
}

type Group = {
    title: string,
    handler: (id: string, checked: boolean) => void,
    ids: string[],
    items: FilterItemDto[],
}

const FiltersComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const createDefaultFilter = () => createFilter(false);
    const [filter, setFilter] = useState<SaleFilterDto>(createDefaultFilter());
    //const [statuses, setStatuses] = useState<SaleStatus[]>([]);
    const { filteringEntities } = useAppSelector((x) => x.sales);
    const { customerRequest } = useAppSelector((x) => x.customerRequests);
    const { selectedCode: currencyCode } = useAppSelector((x) => x.currencies);

    const salesStatusesChanged = (id: SaleStatus | string, checked: boolean): void => {
        processArray(filter.statuses, id, checked);
        onFilterChanged({
            ...filter,
        });
    };

    const airlinesChanged = (id: string, checked: boolean): void => {
        processArray(filter.airlineIds, id, checked);
        onFilterChanged({
            ...filter,
        });
    };

    const productsChanged = (id: string, checked: boolean): void => {
        processArray(filter.productIds, id, checked);
        onFilterChanged({
            ...filter,
        });
    };

    const depChanged = (id: string, checked: boolean): void => {
        processArray(filter.departureIds, id, checked);
        onFilterChanged({
            ...filter,
        });
    };

    const destChanged = (id: string, checked: boolean): void => {
        processArray(filter.destinationIds, id, checked);
        onFilterChanged({
            ...filter,
        });
    };

    const onFilterChanged = (newFilter: SaleFilterDto): void => {
        setFilter({ ...filter, ...newFilter });
        dispatch(fetchSales({ customerRequestId: customerRequest.id, filter, currencyCode }));
        dispatch(fetchFilteringEntities({ customerRequestId: customerRequest.id, filter }));
    };

    const getSaleStatus = (status: SaleStatus): string => {
        switch (status) {
            case SaleStatus.Available:
                return t('requestSearchResults.available');
            case SaleStatus.UponRequest:
                return t('requestSearchResults.uponRequest');
            case SaleStatus.Embargo:
                return t('requestSearchResults.embargo');
            case SaleStatus.Restrictions:
                return t('requestSearchResults.restrictions');
            case SaleStatus.AdHoc:
                return t('requestSearchResults.adHoc');
            case SaleStatus.Promo:
                return t('requestSearchResults.promo');
            case SaleStatus.NotAvailable:
                return t('requestSearchResults.notAvailable');
            default:
                return t('requestSearchResults.uponRequest');
        }
    };

    const filterGroups: Group[] = [
        {
            title: t('requestSearchResults.airlines'),
            handler: airlinesChanged,
            ids: filter.airlineIds,
            items: filteringEntities.airlines,
        },
        {
            title: t('requestSearchResults.saleStatus'),
            handler: salesStatusesChanged,
            ids: filter.statuses.map(s => s.toString()),
            items: filteringEntities.statuses.map<FilterItemDto>((s) => ({
                id: s.toString(),
                name: getSaleStatus(s),
            })),
        },
        {
            title: t('requestSearchResults.products'),
            handler: productsChanged,
            ids: filter.productIds,
            items: filteringEntities.products,
        },
        {
            title: t('requestSearchResults.departurePoint'),
            handler: depChanged,
            ids: filter.departureIds,
            items: filteringEntities.departures,
        },
        {
            title: t('requestSearchResults.destinationPoint'),
            handler: destChanged,
            ids: filter.destinationIds,
            items: filteringEntities.destinations,
        },
    ];

    return (
        <div className={s.container}>
            <h3 className={s.header}>{t('requestSearchResults.filters')}</h3>
            {filterGroups.map((g, index) =>
                <FilterGroup
                    key={index}
                    groupTitle={g.title}
                    filter={g.ids}
                    checkChanged={g.handler}
                    filterItems={g.items} />,
            )}
        </div>
    );
};

export default FiltersComponent;
