export type TariffDictionaryItem = {
    id: string;
    code: string;
    departureAirportCode?: string;
    transitAirportCode?: string;
    destinationAirportCode?: string;
    currencyCode?: string;
    airlineCode?: string;
    type?: TariffType;
    productName?: string;
};

export enum TariffType {
    None = 0,
    BUY = 663910000,
    SELL = 663910001,
    BUY_SELL = 663910002,
}

export enum UnitOfUse {
    PhysicalWeight = 663910000,
    PaidWeight = 663910001,
}