import React, { useEffect, useMemo, useState } from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import '@assets/reactCalendar.scss';
import LocationSelect from '@components/select/LocationSelect';
import { LocationDto, LocationType } from '@models/locations';
import LocationsService from '@services/LocationsService';
import { Filter } from '@components/Filter';
import { GroupFilter } from '@components/Filter/groupFilter';
import { FilterConnection, FilterOperator, FilterValueCondition } from '@models/entityNavigation/filtering';
import {ReactComponent as Clear} from '@material-design-icons/svg/round/playlist_remove.svg';
import { FieldFilter } from '@components/Filter/fieldFilter';
import { GroupOrFieldFilter } from '@components/Filter/groupOrFieldFilter';
import { useTranslation } from 'react-i18next';
import debounce from 'awesome-debounce-promise';
import styles from './CustomerRequestHistoryFilter.module.scss';
import clsx from 'clsx';
import { Button, PageHeader } from '@root/components';
import cs from '@assets/styles/common.module.scss';
import '@assets/styles/react-dates-custom.css';
import cn from 'classnames';

export interface IDateFilterArgs {
  from?: string;
  to?: string;
}

type Props = {
  onChangeFilter: (filter: FilterConnection) => void;
};

const debounceNumberChange = debounce((updater: () => void) => updater(), 500, {});

const CustomerRequestsHistoryFilter: React.FC<Props> = ({ onChangeFilter }) => {
  const [createDateCalendarFocusedInput, setCreateDateCalendarFocusedInput] = useState<FocusedInputShape>(null);
  const [departureDateCalendarFocusedInput, setDepartureDateCalendarFocusedInput] = useState<FocusedInputShape>(null);
  const [locationsFrom, setLocationsFrom] = useState<LocationDto[]>([]);
  const [locationsTo, setLocationsTo] = useState<LocationDto[]>([]);
  const [createdDateFilter, setCreatedDateFilter] = useState<IDateFilterArgs>({});
  const [departureDateFilter, setDepartureDateFilter] = useState<IDateFilterArgs>({});
  const [requestNumber, setRequestNumber] = useState('');
  const [term, setTerm] = useState('');

  const { t } = useTranslation();

  const filter = useMemo(() => {
    const getLocationsFilter = (locations: LocationDto[], cityFieldName: string, airportFieldName: string): GroupFilter[] => {
      return locations.length == 0
        ? []
        : [{
          type: 'group',
          operator: FilterOperator.Or,
          fields: locations.map(value =>
            ({
              type: 'field',
              name: value.type == LocationType.City ? cityFieldName : airportFieldName,
              condition: FilterValueCondition.Equals,
              value: value.id,
            })),
        }];
    };

    const getDateFilter = (dateFilter: IDateFilterArgs, name: string): GroupFilter[] => {
      const startDate = dateFilter.to || '';
      const getFromFilter = (): FieldFilter[] => {
        return startDate == '' ? [] : [{
          type: 'field',
          name: name,
          condition: FilterValueCondition.LessOrEqualsThan,
          value: startDate,
        }];
      };

      const endDate = dateFilter.from || '';
      return startDate == '' && endDate == ''
        ? []
        : [
          {
            type: 'group',
            operator: FilterOperator.And,
            fields: [
              {
                type: 'field',
                name: name,
                condition: FilterValueCondition.MoreOrEqualsThan,
                value: endDate,
              },
              ...getFromFilter(),
            ],
          },
        ];
    };

    const getNumberFilter = (): FieldFilter[] => {
      return requestNumber == null || requestNumber.trim().length == 0
        ? []
        : [{
          type: 'field',
          condition: FilterValueCondition.Contains,
          value: requestNumber,
          name: 'number',
        }];
    };

    const result: GroupOrFieldFilter = {
      type: 'group',
      operator: FilterOperator.And,
      fields: [
        ...(getLocationsFilter(locationsFrom, 'departureCityId', 'departureAirportId')),
        ...(getLocationsFilter(locationsTo, 'destinationCityId', 'destinationAirportId')),
        ...(getDateFilter(createdDateFilter, 'createTime')),
        ...(getDateFilter(departureDateFilter, 'dateStartPlan')),
        ...getNumberFilter(),
      ],
    };

    return result;
  }, [locationsFrom, locationsTo, createdDateFilter, departureDateFilter, requestNumber]);
  
  useEffect(() => {
    debounceNumberChange(() => setRequestNumber(term));
  }, [term]);
  const minNumberDigitsRequired = 3;

  return (
    <Filter filterFields={filter} onFilter={onChangeFilter}>
      <div className={cn(cs.flexColumn, cs.gap20, cs.card)}>
        <div className={cn(cs.flex, cs.alignCenter)}>
          <PageHeader title={t('customerRequestsHistory.filterOptions')} size='subHeader' />
          {filter.fields.length > 0 &&
            <Button
              type='text' variant='secondary'
              icon={<Clear fill="currentColor" />}
              onClick={() => {
                setLocationsFrom([]);
                setLocationsTo([]);
                setCreatedDateFilter({});
                setDepartureDateFilter({});
                setRequestNumber(null);
              }}
            >
              {t('customerRequestsHistory.clearFilters')}
            </Button>
          }
        </div>
        <div className={cn(cs.flex, cs.gap10)}>
          <div className={cn(cs.flex)}>
            <LocationSelect
              label={t('customerRequestsHistory.source')}
              isClearable={true}
              isMulti={true}
              fetch={(term) => new LocationsService().getKnownLocations(term)}
              onChange={setLocationsFrom}
              loadingMessage={t('options.loadingOptions')}
              noOptionsMessage={t('options.noOptions')}
              placeholder={t('customerRequestsHistory.airportOrCity')}
              selectedOptions={locationsFrom}
              initializeWithEmptyTerm={true}
            />
          </div>
          <div className={cn(cs.flex)}>
            <LocationSelect
              label={t('customerRequestsHistory.target')}
              isClearable={true}
              isMulti={true}
              fetch={(term) => new LocationsService().getKnownLocations(term)}
              onChange={setLocationsTo}
              loadingMessage={t('options.loadingOptions')}
              noOptionsMessage={t('options.noOptions')}
              placeholder={t('customerRequestsHistory.airportOrCity')}
              selectedOptions={locationsTo}
              initializeWithEmptyTerm={true}
            />
          </div>
          <div className={cn(cs.flexColumn)}>
            <span>{t('customerRequestsHistory.selectDateRange')}</span>
            <DateRangePicker
              focusedInput={createDateCalendarFocusedInput}
              isOutsideRange={() => null}
              startDatePlaceholderText={t('customerRequestsHistory.startDate')}
              endDatePlaceholderText={t('customerRequestsHistory.endDate')}
              startDate={
                createdDateFilter?.from
                  ? moment(createdDateFilter.from).startOf('day')
                  : null
              }
              endDate={
                createdDateFilter?.to ? moment(createdDateFilter.to).endOf('day') : null
              }
              startDateId='your_unique_start_date_id'
              endDateId='your_unique_end_date_id'
              displayFormat={() => 'DD/MM/YYYY'}
              customArrowIcon={<i className={`calendarArrowIcon icon-arrow-long`} />}
              inputIconPosition='before'
              showDefaultInputIcon={true}
              customInputIcon={<i className={`calendarInputIcon icon-calendar`} />}
              onDatesChange={({ startDate, endDate }) => {
                const from = startDate?.startOf('day').toISOString();
                const to = endDate?.endOf('day').toISOString();
                setCreatedDateFilter({ from, to });
              }}
              onFocusChange={(inputName) => {
                setCreateDateCalendarFocusedInput(inputName);
              }}
            />
          </div>
          <div className={cn(cs.flexColumn)}>
            <span>{t('customerRequestsHistory.selectDepartureDateRange')}</span>
            <DateRangePicker
              focusedInput={departureDateCalendarFocusedInput}
              isOutsideRange={() => null}
              startDatePlaceholderText={t('customerRequestsHistory.startDate')}
              endDatePlaceholderText={t('customerRequestsHistory.endDate')}
              startDate={
                departureDateFilter?.from
                  ? moment(departureDateFilter.from).startOf('day')
                  : null
              }
              endDate={
                departureDateFilter?.to ? moment(departureDateFilter.to).endOf('day') : null
              }
              startDateId='your_unique_start_date_id'
              endDateId='your_unique_end_date_id'
              displayFormat={() => 'DD/MM/YYYY'}
              customArrowIcon={<i className={`calendarArrowIcon icon-arrow-long`} />}
              inputIconPosition='before'
              showDefaultInputIcon={true}
              customInputIcon={<i className={`calendarInputIcon icon-calendar`} />}
              onDatesChange={({ startDate, endDate }) => {
                const from = startDate?.startOf('day').toISOString();
                const to = endDate?.endOf('day').toISOString();
                setDepartureDateFilter({ from, to });
              }}
              onFocusChange={(inputName) => {
                setDepartureDateCalendarFocusedInput(inputName);
              }}
            />
          </div>
          <div className={cn(cs.flexColumn)}>
								<span>
									{t('customerApplicationHistory.requestNo')}
								</span>
            <input
              className={clsx('form-control', styles.numberFilter)}
              value={term || ''}
              onChange={(x) => {
                const value = x.target.value;
                setTerm(value);
              }}
              placeholder={t('customerApplicationHistory.enterNumber')}
            />
            {term && term.length < minNumberDigitsRequired &&
              <span className='text-danger'>
										{t('customerApplicationHistory.minNumberDigitsRequired')
                      .replace('{value}', minNumberDigitsRequired + '')}
									</span>}
          </div>
        </div>
      </div>
    </Filter>
  );
};

export default CustomerRequestsHistoryFilter;