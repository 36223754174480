import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { Navigation } from '@models/entityNavigation/StandardNavigation';
import { FilterConnection } from '@models/entityNavigation/filtering';
import { ReactComponent as Filter } from '@material-design-icons/svg/round/tune.svg';
import { Collapse } from 'reactstrap';
import nameof from 'ts-nameof.macro';
import SortSelect, { createSortSelectValue } from '@components/select/SortSelect';
import { SortDirection } from '@models/entityNavigation/sorting';
import Paginator from '@components/paginator/Paginator';
import { GetCustomerRequestHistoryItemDto } from '@models/customerRequests/customerRequestHistoryModels';
import { useTranslation } from 'react-i18next';
import CustomerRequestsHistoryItem from '@scenes/customerRequestHistory/components/CustomerRequestsHistoryItem';
import CustomerRequestsHistoryFilter from '@scenes/customerRequestHistory/components/CustomerRequestsHistoryFilter';
import { AppSkeleton, Button, ColumnTitles, Empty, PageHeader } from '@root/components';
import i18n from 'i18next';
import { ItemsPerPage } from '@components/paginator/ItemsPerPage';
import { getRequestHistoryData } from '@store/customerRequests/customerRequestsHistoryStore';
import { updateSettingsAmount, updateSettingsPage } from '@store/localSettings';
import { Settings } from '@root/constants/localSettings';
import cn from 'classnames';
import cs from '@assets/styles/common.module.scss';

const CustomerRequestsHistoryPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const availableSortingProperties = useMemo(
    () => [
      {
        label: t('customerRequestsHistory.date'),
        propertyName: nameof.full<GetCustomerRequestHistoryItemDto>((x) => x.createTime),
      },
    ],
    [i18n.language],
  );

  const [filter, setFilter] = useState<FilterConnection>(null);
  const { isFetching, pagingWrapper } = useAppSelector(x => x.customerRequestsHistory);
  const { requests } = useAppSelector(s => s.localSettings);
  const [isFilterOpen, toggleOpenFilter] = useState(true);
  const [sorting, setSorting] = useState(
    createSortSelectValue(availableSortingProperties[0], SortDirection.Descending),
  );

  const navigationInitial = {
    sorters: [{
      path: sorting?.propertyName,
      sortDirection: sorting?.sortDirection,
    }],
    filters: [],
    pagingFilter: {
      pageNumber: requests.page,
      pageSize: requests.amount,
    },
  };
  const [nav, setNav] = useState<Navigation>(navigationInitial);

  useEffect(() => {
    dispatch(getRequestHistoryData(nav));
  }, [nav]);

  useEffect(() => {
    setNav({
      filters: filter == null ? [] : [filter],
      sorters: nav.sorters,
      pagingFilter: {
        pageNumber: requests.page,
        pageSize: requests.amount,
      },
    });
  }, [filter, sorting, requests.page, requests.amount]);

  const onSetPageSize = (amount: number) => {
    dispatch(updateSettingsAmount({ key: Settings.CustomerRequests, amount: amount }));
    nav.pagingFilter.pageSize = requests.amount;
  };

  const onSetPageNumber = (num: number) => {
    dispatch(updateSettingsPage({ key: Settings.CustomerRequests, page: num }));
    nav.pagingFilter.pageNumber = requests.page;
  };

  return (
    <div className={cn(cs.flexColumn, cs.gap10)}>
      <div className={cn(cs.flex, cs.justifySpaceBetween, cs.alignCenter)}>
        <div className={cn(cs.flex)}>
          <PageHeader
            title={t('customerRequestsHistory.title')}
            additionalInfo={`${t('customerRequestsHistory.totalCount')} ${pagingWrapper?.totalCount}`}
          />
        </div>
        <div className={cn(cs.flex, cs.justifyFlexEnd)}>
          <div className={cn(cs.flex)} style={{ width: '250px' }}>
            <SortSelect
              availableProperties={availableSortingProperties}
              onChange={setSorting}
              value={sorting}
            />
          </div>
          <Button
            type='text'
            variant='primary'
            iconPosition='end'
            icon={<Filter fill='currentColor' />}
            onClick={() => toggleOpenFilter(!isFilterOpen)}
          >
            {t('customerRequestsHistory.filter')}
          </Button>
        </div>
      </div>
      <Collapse isOpen={isFilterOpen}>
        <CustomerRequestsHistoryFilter onChangeFilter={setFilter} />
      </Collapse>
      <div className={cn(cs.flexColumn, cs.gap10)}>
        {isFetching ? (
          <AppSkeleton count={requests.amount} mode='historyItem' />
        ) : (
          <div className={cn(cs.flexColumn, cs.gap20)}>
            {
              pagingWrapper?.items == null || pagingWrapper.items.length === 0 ? (
                  <Empty text={t('customerRequestsHistory.nothingFound')} />
                )
                : (
                  <div className={cn(cs.flexColumn, cs.gap10)}>
                    <ColumnTitles
                      i18tColumnNames={[
                        'request.requestDate',
                        'request.planedFlightDate',
                        'customerRequestsHistory.route',
                        'request.processingCodes',
                        'request.cargoDetails',
                        `customerRequestsHistory.additional`,
                        'actions',
                      ]}
                    />
                    {pagingWrapper.items.map(x => <CustomerRequestsHistoryItem key={x.id} data={x} />)}
                    <div className={cn(cs.flex, cs.gap20, cs.alignCenter)} style={{padding: "20px 0"}}>
                      <Paginator
                        totalResults={pagingWrapper?.totalCount}
                        limitPerPage={requests.amount}
                        currentPage={requests.page + 1}
                        onChangePage={(num) => onSetPageNumber(num)}
                        pageNeighbours={4}
                      />
                      <ItemsPerPage onChange={(amount) => onSetPageSize(amount)} value={requests.amount} />
                    </div>
                  </div>
                )
            }
          </div>
        )}
      </div>

    </div>
  );
};

export default CustomerRequestsHistoryPage;
