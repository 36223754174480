import {
	RegistrationEventType,
	CustomerApplicationEventType,
	CustomerRequestEventType,
	EventGroupType,
	PersonalAreaEventType,
} from '@models/admin/notificationsModels';
import { enumToKeyValuePairArray } from '@helpers/enumHelpers';
import nameof from 'ts-nameof.macro';

export function getEventTypesNameByGroup(eventGroupType: EventGroupType) {
	let eventType = null;
	let eventName = null;
	switch (eventGroupType) {
		case EventGroupType.Registration:
			eventType = RegistrationEventType;
			eventName = nameof(RegistrationEventType);
			break;
		case EventGroupType.PersonalArea:
			eventType = PersonalAreaEventType;
			eventName = nameof(PersonalAreaEventType);
			break;
		case EventGroupType.Requests:
			eventType = CustomerRequestEventType;
			eventName = nameof(CustomerRequestEventType);
			break;
		case EventGroupType.Applications:
			eventType = CustomerApplicationEventType;
			eventName = nameof(CustomerApplicationEventType);
			break;
	}

	if (eventType != null) {
		const name = eventName[0].toLowerCase() + eventName.slice(1, eventName.length);
		return { eventTypeName: name, values: enumToKeyValuePairArray(eventType) };
	}

	return null;
}