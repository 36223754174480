import * as React from 'react';
import { useMemo, useState } from 'react';
import { Alert, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import styles from './Login.module.scss';
import loginStyle from './Login.module.scss';
import { useTranslation } from 'react-i18next';
import { ApplicationPaths } from '@config/ApiAuthorizationConstants';
import SessionManager from '../../SessionManager';
import AccountService from '@services/AccountService';
import { IUser } from '@models/accounts/IUser';
import { useHistory } from 'react-router';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import { LoginToAccount } from '@models/accounts/accountModels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import nameof from 'ts-nameof.macro';
import { object, string } from 'yup';
import i18n from 'i18next';
import ValidationMessage from '@components/ValidationMessage';
import { Button } from '@components/index';
import { PageHeader } from '@root/components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function LoginPage(props) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [passwordShown, setPasswordShown] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  if (SessionManager.isAuthenticated() && !SessionManager.isExpired()) {
    history.push('/request');
  }

  const navigateToReturnUrl = (returnUrl: string) => {
    history.replace(returnUrl);
  };

  const validationSchema = useMemo(
    () =>
      object<Partial<LoginToAccount>>().shape({
        userName: string().nullable().required(t('validation.required')).email(t('validation.email')),
        password: string().nullable().required(t('validation.required')),
      }),
    [i18n.language],
  );

  const hasError = (formikProps: FormikProps<LoginToAccount>, fieldName: string) => {
    return formikProps.errors[fieldName] != null && formikProps.touched[fieldName] != null;
  };

  const togglePasswordVisiblity = (): void => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Formik
      initialValues={{ userName: '', password: '', remeberMe: '' }}
      validationSchema={validationSchema}
      onSubmit={async (values: LoginToAccount, formikHelpers: FormikHelpers<LoginToAccount>) => {
        const accountService = new AccountService();

        try {
          setLoading(true);

          const { data } = await accountService.login(values);

          SessionManager.setUserAsync(data as IUser);

          navigateToReturnUrl('/request');
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError(error.response.data.message);
          formikHelpers.setFieldValue(
            nameof.full<LoginToAccount>((x) => x.password),
            '',
          );
        } finally {
          setLoading(false);
        }
      }}
    >
      {(formikProps: FormikProps<LoginToAccount>) => (
        <div className='simple-layout-content-container'>
          <Form className={`${loginStyle.form} _LoginForm`} name='LoginForm'>
            <PageHeader title={t('login.header')} additionalInfo={t('login.subHeader')} />
            <div>
              <FormGroup row>
                <Field name={nameof.full<LoginToAccount>((x) => x.userName)}>
                  {({ field }) => (
                    <InputGroup>
                      <Input
                        type='email'
                        placeholder={t('login.loginPlaceholder')}
                        {...field}
                        className={'w-100 input-text-primary input-email'}
                        required
                        invalid={hasError(formikProps, field.name)}
                      />
                      <ValidationMessage name={field.name} errors={formikProps.errors} />
                    </InputGroup>
                  )}
                </Field>
              </FormGroup>
              <FormGroup row>
                <Field name={nameof.full<LoginToAccount>((x) => x.password)}>
                  {({ field }) => (
                    <>
                      <InputGroup>
                        <Input
                          type={passwordShown ? 'text' : 'password'}
                          placeholder={t('login.passwordPlaceholder')}
                          name={field.name}
                          {...field}
                          className={'input-text-primary input-password'}
                          required
                          invalid={hasError(formikProps, field.name)}
                        />
                        <InputGroupAddon addonType='append'>
                          <InputGroupText style={{ border: 'none', cursor: 'pointer' }}>
													<span>
														<FontAwesomeIcon icon={passwordShown ? faEyeSlash : faEye}
                                             onClick={togglePasswordVisiblity} />
													</span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <ValidationMessage name={field.name} errors={formikProps.errors} />
                    </>
                  )}
                </Field>
              </FormGroup>
            <FormGroup row className='justify-content-end'>
              <Button
                type='text'
                variant='primary'
                onClick={() => history.push(ApplicationPaths.ResetPasswordRequest)}>
                {t('login.forgotPwd')}
              </Button>
            </FormGroup>
            </div>
            <FormGroup row className='justify-content-between'>
              <Button
                type='fill'
                disabled={isLoading}
                variant='primary'
                onClick={(e) => {
                  e.preventDefault();
                  formikProps.handleSubmit();
                }}>
                {t('login.logIn')}
              </Button>
              <Button
                type='text'
                variant='primary'
                onClick={() => history.push(ApplicationPaths.Register)}>
                {t('login.signUp')}
              </Button>
            </FormGroup>
            <FormGroup row className='justify-content-between'>
              {error != null && (
                <Alert color='danger' className='w-100'>
                  {error}
                </Alert>
              )}
            </FormGroup>
          </Form>
          <div className='d-flex flex-column'>
            <Row className={styles.extLoginLbl}>{t('login.useLoginSrv')}</Row>
            <Row className='mt-2'>
              <a href='api/Account/ExternalMsLogin'>
                <img
                  src='https://aadcdn.msauth.net/ests/2.1/content/images/microsoft_logo_ee5c8d9fb6248c938fd0dc19370e90bd.svg'
                  alt='Microsoft'
                />
              </a>
            </Row>
          </div>
        </div>
      )}
    </Formik>
  );
}