import React from "react";
import Currency from "@components/Currency";
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from "./Price.module.scss";

type Props = WithTranslation & {
    price: number;
    costPerUnit: number;
    priceCurrencyCode: string;
    unitCurrencyCode: string;
};

class Price extends React.PureComponent<Props, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return <>
            <div className={styles.total}>
                <Currency
                    currencyCode={this.props.priceCurrencyCode}
                    amount={this.props.price}
                />
            </div>
            <div className={styles.perUnit}>
                <Currency
                    currencyCode={this.props.unitCurrencyCode}
                    amount={this.props.costPerUnit}
                />{' '}
                | {this.props.t('units.kg')}
            </div>
        </>;
    }
}

export default withTranslation()(Price);