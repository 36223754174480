import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetSaleDto, IFee } from '@models/customerRequests/customerRequestModels';
import { Col, Collapse, Popover, PopoverBody, Row } from 'reactstrap';
import { ReactComponent as Info } from '@assets/svg/legacy/info.svg';
import { ReactComponent as Air } from '@material-design-icons/svg/round/flight.svg';
import { ReactComponent as ArrowRightShort } from '@assets/svg/legacy/arrow_right_short.svg';
import { ReactComponent as Airplane } from '@assets/svg/legacy/airplane.svg';
import SalesStatus from './SalesStatus';
import Currency from '@components/Currency';
import searchStyles from '../Search.module.scss';
import styles from './SaleInfo.module.scss';
import { RouteDto } from '@models/routesModels';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { DateTime } from '@helpers/DateTime';
import _ from 'lodash';
import { useInView } from 'react-intersection-observer';
import { useAppSelector } from '@root/store';
import Button from '@components/Button';
import { RequestBookingModal } from '@root/modals';
import { UnitOfUse } from '@models/tariffs';
import htmlParse from 'html-react-parser';

type Props = {
  selectedDate: string;
  sale: GetSaleDto;
  routes: RouteDto[];
  hasGenCode: boolean;
  isStackable: boolean;
  userCanBook: boolean;
  hasRouteForSelectedDate: boolean;
  hasCustomerApplication?: boolean;
  onSubmit: (isRequestQuotationMode: boolean, route?: RouteDto) => void;
  onAskDiscount: () => void;
};

const getFormattedRouteDate = (dateString: string) => {
  const date = moment(dateString);
  return (
    <>
      <span className={styles.routeDayAndMonth}>{date.format('DD/MM')} </span>
      <span className={styles.routeTimeAndDayOfWeek}>
				{date.format('HH:mm')} {date.format('ddd')}
			</span>
    </>
  );
};

const getFormattedRouteLabel = (route: RouteDto, viaTransit: boolean) => {
  return (
    <>
      {getFormattedRouteDate(route.dateStart)}{' '}
      {viaTransit ? (
        <span>{` via ${route.destinationAirportIataCode || 'transit'} ${route.flightNumber}`}</span>
      ) : (
        <>
          <i className={clsx(styles.routeScheduleArrow, 'icon-arrow-long')} />{' '}
          {getFormattedRouteDate(route.dateEnd)} {route.flightNumber}
        </>
      )}
    </>
  );
};

const SaleInfo = (props: Props): JSX.Element => {
  const {
    sale,
    hasRouteForSelectedDate,
    selectedDate,
    routes,
    hasGenCode,
    isStackable,
    userCanBook,
    hasCustomerApplication,
  } = props;
  const [selectedRoute, setSelectedRoute] = useState<RouteDto>();
  const dateNow = useMemo(() => DateTime.now(false), []);
  const tariffExpirationDate = useMemo(() => new DateTime(sale.expirationDate), [sale?.expirationDate]);
  const isTariffActiveForToday = useMemo(() => tariffExpirationDate > dateNow, [tariffExpirationDate, dateNow]);
  const [showAgreementForm, setShowAgreementForm] = useState(false);

  const isRouteActiveAccordingTariffExpiration = useMemo(() => {
    if (selectedRoute == null) {
      return isTariffActiveForToday;
    }

    const dateEnd = new DateTime(selectedRoute?.dateEnd);

    return dateEnd < tariffExpirationDate;
  }, [tariffExpirationDate, selectedRoute]);

  const requestQuotationMode = sale.total <= 0 || isStackable !== true;

  // TODO: What?
  const { availableCurrencies } = useAppSelector((x) => x.currencies);
  const baseCurrencyCode = availableCurrencies.find((x) => x.isBaseCurrency)?.code;
  const [showDetails, setShowDetails] = useState(false);
  const [showExtraDescription, setShowExtraDescription] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showRestrictions, setShowRestrictions] = useState(true);
  const [showOptionalFees, setShowOptionalFees] = useState(true);
  const [routePopovers, setRoutePopovers] = useState({});
  const [productPopovers, setProductPopovers] = useState({});
  const [showAllRoutes, setShowAllRoutes] = useState(false);

  const maxRoutesBeforeShowMore = 4;

  const toggleShowDetails = (): void => {
    setShowDetails(!showDetails);
  };

  const displayRoutePopover = (id: string, show: boolean): void => {
    setRoutePopovers({
      ...routePopovers,
      [id]: show,
    });
  };

  const displayProductPopover = (id: string, show: boolean): void => {
    setProductPopovers({
      ...productPopovers,
      [id]: show,
    });
  };

  const { t } = useTranslation();

  const normalizedRoutes = useMemo(() => {
    return _.orderBy(
      routes.map((x) => ({ dateStart: new DateTime(x.dateStart), route: x })),
      (x) => x.dateStart,
    );
  }, [routes]);

  useEffect(() => {
    setShowAllRoutes(false);
  }, [normalizedRoutes]);

  const renderFlightDates = () => {
    return (
      <>
        <Row>
          <Col>
            <label
              className={clsx(styles.routeLabel, selectedRoute == null ? styles.routeLabelActive : null)}
            >
              <input
                type='radio'
                onChange={() => setSelectedRoute(null)}
                checked={selectedRoute == null}
              />
              <span className={styles.routeLabelTextWrapper}>
								<span> {t('requestSearchResults.optional')}</span>
							</span>
            </label>
          </Col>
        </Row>
        {(showAllRoutes
            ? normalizedRoutes
            : normalizedRoutes.filter((_, i) => i < maxRoutesBeforeShowMore)
        ).map((x, i) => (
          <Row key={i}>
            <Col>
              <label
                className={clsx(
                  styles.routeLabel,
                  selectedRoute == x.route ? styles.routeLabelActive : null,
                )}
              >
                <input
                  type='radio'
                  onChange={() => setSelectedRoute(x.route)}
                  disabled={x.dateStart > tariffExpirationDate}
                  checked={selectedRoute == x.route}
                />{' '}
                <span className={styles.routeLabelTextWrapper}>
									<span>
										{getFormattedRouteLabel(
                      x.route,
                      sale.destinationPoint?.id !== x.route.destinationAirportId,
                    )}
									</span>
								</span>
              </label>
            </Col>
          </Row>
        ))}

        {normalizedRoutes.length > maxRoutesBeforeShowMore && (
          <Row>
            <Col>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  setShowAllRoutes(!showAllRoutes);
                }}
              >
                {t(showAllRoutes ? 'requestSearchResults.showLess' : 'requestSearchResults.showMore')}
              </a>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const [ref, inView] = useInView({
    threshold: 0,
  });

  const renderFeeRows = (fees: IFee[], showPerUnit = false) => {
    return (
      <>
        {fees.map((x, i) => (
          <Row key={i}>
            {x.cost === 0 && x.comments && <Col className={styles.feeName}>{x.comments}</Col>}
            {x.cost > 0 && (
              <>
                <Col>
                  <b className={styles.feeName}>{x.code}</b>
                  {x.name && (
                    <Tippy content={x.name}>
                      <i className={styles.feeHint}>
                        {' '}
                        <Info />
                      </i>
                    </Tippy>
                  )}
                </Col>
                <Col className={clsx(styles.tariffUnit, 'text-right')}>
                  <Currency amount={x.cost} currencyCode={x.currencyCode} />
                  {showPerUnit && (
                    <>
                      {' ('}
                      <Currency currencyCode={x.currencyCode} amount={x.value} />
                      {' | '}
                      {t((x.unitOfUse === UnitOfUse.PhysicalWeight || x.unitOfUse === UnitOfUse.PaidWeight) ? 'units.kg' : 'units.unit')}
                      {')'}
                    </>
                  )}
                </Col>
              </>
            )}
          </Row>
        ))}
      </>
    );
  };

  //#region Highlight processing.

  const [hightlight, setHighlight] = useState(false);

  useEffect(() => {
    setHighlight(false);
  }, [selectedDate]);

  useEffect(() => {
    if (inView && !hightlight && !hasRouteForSelectedDate) {
      setHighlight(true);
    }
  }, [inView, hightlight]);

  //#endregion

  function showAgreementFormModal() {
    setShowAgreementForm(true);
  }

  return (
    <>
      <div
        ref={ref}
        className={clsx(
          searchStyles.tableRow,
          !hasRouteForSelectedDate && routes.length > 0 ? styles.thisDateRow : null,
          hightlight ? 'highlighted' : null,
          sale?.readonly === true ? styles.readonlyRow : null,
        )}
        onClick={toggleShowDetails}
        style={{ cursor: 'pointer' }}>
        <div className={`${searchStyles.logo} ${searchStyles.cell}`}>
          {!hasRouteForSelectedDate && routes.length > 0 &&
            <div className={styles.alternativeDateLabel}>{t('requestSearchResults.alternativeDate')}</div>}

          {sale.airlineLogo && (
            <div className={searchStyles.image}>
              <img
                src={`data:${sale.airlineLogo.contentType};base64,${sale.airlineLogo.contentBase64}`}
              />
            </div>
          )}

          {!sale.airlineLogo && <div>{sale.airlineName}</div>}
        </div>
        <div className={`${searchStyles.product} ${searchStyles.cell}`}>
          {sale.productName && (
            <div className={searchStyles.productName}>
              <span>{sale.productName}</span>{' '}
              {sale.productDescription && (
                <>
                  <Info
                    id={`product-for-${sale.id}`}
                    onMouseEnter={(): void => displayProductPopover(`product-for-${sale.id}`, true)}
                    onMouseLeave={(): void =>
                      displayProductPopover(`product-for-${sale.id}`, false)
                    }
                  />
                  <Popover
                    hideArrow={true}
                    placement='right'
                    target={`product-for-${sale.id}`}
                    isOpen={productPopovers[`product-for-${sale.id}`] || false}
                  >
                    <PopoverBody className={searchStyles.infoPopover}>
                      {htmlParse(`${sale?.productDescription || '-'}`)}
                    </PopoverBody>
                  </Popover>
                </>
              )}
            </div>
          )}
        </div>
        <div className={`${searchStyles.cell} ${searchStyles.available}`}>
          <SalesStatus saleStatus={sale.saleStatus} />
        </div>
        <div className={`${searchStyles.cell} ${searchStyles.route}`}>
          <div
            data-code={sale.departurePoint.codeIata}
            className={`${searchStyles.from} ${searchStyles.circle}`} />
          <div className={searchStyles.airplaneLine} />

          {!sale.transitPoint && (
            <div>
              <Airplane
                id={`airplane-route-main-${sale.id}`}
                onMouseEnter={(): void => displayRoutePopover(`airplane-route-main-${sale.id}`, true)}
                onMouseLeave={(): void => displayRoutePopover(`airplane-route-main-${sale.id}`, false)}
              />
              <Popover
                placement='bottom'
                target={`airplane-route-main-${sale.id}`}
                isOpen={routePopovers[`airplane-route-main-${sale.id}`]}
                hideArrow={true}
              >
                <PopoverBody className={searchStyles.routePopover}>
                  <div className={searchStyles.header}>
                    <div className={searchStyles.air}>
                      <Airplane />
                    </div>
                    <div className={searchStyles.route}>
                      <span>{sale.departurePoint.codeIata}</span>
                      <ArrowRightShort />
                      <span>{sale.destinationPoint?.codeIata}</span>
                    </div>
                  </div>
                  <div>
                    <div className={`${searchStyles.routeInfo}`}>
                      <div>{t('requestSearchResults.origin')}</div>
                      <div>{sale.departurePoint?.name}</div>
                    </div>
                    <div className={`${searchStyles.routeInfo}`}>
                      <div>{t('requestSearchResults.arrival')}</div>
                      <div>{sale.destinationPoint?.name}</div>
                    </div>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          )}

          {sale.transitPoint && (
            <>
              <div>
                <Airplane
                  id={`airplane-route-main-${sale.id}`}
                  onMouseEnter={(): void => displayRoutePopover(`airplane-route-main-${sale.id}`, true)}
                  onMouseLeave={(): void => displayRoutePopover(`airplane-route-main-${sale.id}`, false)}
                />
              </div>

              <div className={searchStyles.airplaneLine} />

              <div
                data-code={sale.transitPoint.codeIata}
                className={`${searchStyles.from} ${searchStyles.circle}`}
              />

              <div className={searchStyles.airplaneLine} />

              <div>
                <Airplane
                  id={`airplane-route-main-${sale.id}`}
                  onMouseEnter={(): void => displayRoutePopover(`airplane-route-main-${sale.id}`, true)}
                  onMouseLeave={(): void => displayRoutePopover(`airplane-route-main-${sale.id}`, false)}
                />
              </div>

              <Popover
                placement='bottom'
                target={`airplane-route-main-${sale.id}`}
                isOpen={routePopovers[`airplane-route-main-${sale.id}`]}
                hideArrow={true}
              >
                <PopoverBody className={searchStyles.routePopover}>
                  <div className={searchStyles.header}>
                    <div className={searchStyles.air}>
                      <Airplane />
                    </div>
                    <div className={searchStyles.route}>
                      <span>{sale.departurePoint.codeIata}</span>
                      <ArrowRightShort />
                      <span>{sale.destinationPoint?.codeIata}</span>
                    </div>
                  </div>
                  <div>
                    <div className={`${searchStyles.routeInfo}`}>
                      <div>{t('requestSearchResults.origin')}</div>
                      <div>{sale.departurePoint?.name}</div>
                    </div>
                    <div className={`${searchStyles.routeInfo}`}>
                      <div>{t('requestSearchResults.arrival')}</div>
                      <div>{sale.destinationPoint?.name}</div>
                    </div>
                  </div>
                </PopoverBody>
              </Popover>

            </>
          )}

          <div className={searchStyles.airplaneLine} />
          <div
            data-code={sale.destinationPoint?.codeIata}
            className={`${searchStyles.to} ${searchStyles.circle}`} />
        </div>
        <div className={`${searchStyles.cell} ${searchStyles.price}`}>
          <div className={searchStyles.total} data-total>
            {sale.total <= 0 ? (
              'X'
            ) : (
              <>
                <Currency
                  currencyCode={sale.convertedCurrencyCode || sale.currencyCode}
                  amount={sale?.isConverted ? sale.convertedTotal : sale.total}
                />
                {sale.convertedCurrencyCode ? '*' : ''}
              </>
            )}
          </div>
          <div className={searchStyles.perUnit}>
            <Currency
              currencyCode={sale.convertedCurrencyCode || sale.currencyCode}
              amount={sale?.isConverted ? sale.convertedCostPerUnit : sale.costPerUnit} />{' '}
            | {t('units.kg')}
          </div>
        </div>
        <div className={`${searchStyles.cell} ${searchStyles.button}`}>
          <button
            type='button'
            onClick={toggleShowDetails}
            className={clsx(styles.showDetailsBtn, showDetails ? 'rotate-180' : null)}>
            <i className={clsx('icon-chevron-down')} />
          </button>
        </div>
      </div>

      <Collapse isOpen={showDetails}>
        <div className={searchStyles.info}>
          <Row className='px-4 pt-4'>
            <Col>
              <Row className={styles.route}>
                <Col className={styles.h2}>
                  <Row className={styles.routePart}>
                    <Col>
                      <div>{t('requestSearchResults.origin')}</div>
                      <div>{sale.departurePoint?.name || '-'}</div>
                    </Col>
                  </Row>
                  {sale.transitPoint && (
                    <Row className={styles.routePart}>
                      <Col>
                        <div>{t('requestSearchResults.transit')}</div>
                        <div>{sale.transitPoint.name}</div>
                      </Col>
                    </Row>
                  )}
                  <Row className={clsx(styles.routePart, styles.noBorder)}>
                    <Col>
                      <div>{t('requestSearchResults.destination')}</div>
                      <div>{sale.destinationPoint?.name || '-'}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row>
                <Col className={styles.h1}>{t('requestSearchResults.flightDates')}</Col>
              </Row>
              {renderFlightDates()}
            </Col>

            <Col>
              {sale?.isConverted || sale?.currencyCode !== baseCurrencyCode ? (
                <Row>
                  <Col className={styles.exchangeHint}>
                    {sale?.isConverted &&
                      t('requestSearchResults.currencyExchangeComment', {
                        exchangeType: sale.exchangeType,
                        currencyCode: sale.currencyCode,
                        cbPlus: sale.cbPlusPercent,
                      })}
                    {sale?.currencyCode !== baseCurrencyCode &&
                      t('requestSearchResults.paymentTerms', {
                        exchangeType: sale.exchangeType,
                        currencyCode: sale.currencyCode,
                        cbPlus: sale.cbPlusPercent,
                        paymentOn: sale.paymentOn,
                      })}
                    {sale?.isConverted && !(sale?.currencyCode !== baseCurrencyCode) &&
                      t('requestSearchResults.paymentTermsForBaseCurrency')}
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col className={styles.h1}>{t('requestSearchResults.tariffDetails')}</Col>
              </Row>
              <Row>
                <Col>{t('chargeableWeight')}</Col>
                <Col className={clsx(styles.tariffUnit, 'text-right')}>
                  {sale.chargeableWeight} {t('units.kg')}
                </Col>
              </Row>
              <Row>
                <Col>{t('requestSearchResults.airFreightRate')}</Col>
                <Col className={clsx(styles.tariffUnit, 'text-right')}>
                  <Currency amount={sale.tariffRate} currencyCode={sale.currencyCode} />{' '}
                  |{' '}{t(sale.weightCategory === 852250010 ? 'units.min' : 'units.kg')}
                </Col>
              </Row>
              <Row>
                <Col>{t('requestSearchResults.totalAirFreight')}</Col>
                <Col className={clsx(styles.tariffUnit, 'text-right')}>
                  <Currency amount={sale.totalAirFreight} currencyCode={sale.currencyCode} />
                </Col>
              </Row>
              {!isStackable && (
                <Row>
                  <Col
                    className={clsx(styles.stackableHint)}>{t('requestSearchResults.stackableHint')}</Col>
                </Row>
              )}
              {sale.expirationDate && (
                <Row>
                  <Col>{t('requestSearchResults.tariffValidUntil')}</Col>
                  <Col className={clsx(styles.tariffValidUntil, (sale.hasAdHocTariff || sale.hasPromoTariff) && styles.tariffValidUntilPromo)}>
                    <span>{new Date(sale.expirationDate).toLocaleDateString()}</span>
                  </Col>
                </Row>
              )}
              {sale.fees.length > 0 && (
                <>
                  <Row>
                    <Col className={clsx(styles.h1, 'mt-4')}>
                      {t('requestSearchResults.extraFee')}
                    </Col>
                  </Row>
                  {renderFeeRows(sale.fees, true)}
                  <Row>
                    <Col>{t('requestSearchResults.totalSurcharges')}</Col>
                    <Col className={clsx(styles.tariffUnit, 'text-right')}>
                      <Currency amount={sale.surchargesTotal} currencyCode={sale.currencyCode} />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col>
              <Row>
                <Col className={styles.h1}>{t('requestSearchResults.limitationsAndTerms')}</Col>
              </Row>

              {sale.optionalFees?.length > 0 && (
                <Row className={styles.collapsableBlock}>
                  <Col className={styles.multiLineText}>
                    <div
                      className={clsx(styles.descriptionTitle, showOptionalFees ? styles.visible : '')}
                      onClick={() => setShowOptionalFees(!showOptionalFees)}>
                      <div>{t('requestSearchResults.optionalFees')}</div>
                      <button
                        type='button'
                        className={clsx(styles.showDetailsBtn, showOptionalFees ? 'rotate-180' : null)}
                      >
                        <i className={clsx('icon-chevron-down')} />
                      </button>
                    </div>
                    <Collapse isOpen={showOptionalFees}
                              className={showOptionalFees ? styles.descriptionVisible : ''}>
                      <div>{renderFeeRows(sale.optionalFees)}</div>
                    </Collapse>
                  </Col>
                </Row>
              )}

              {sale.airlineDescription2 &&
                (<Row className={styles.collapsableBlock}>
                  <Col className={styles.multiLineText}>
                    <div
                      className={clsx(styles.descriptionTitle, showExtraDescription ? styles.visible : '')}
                      onClick={() => setShowExtraDescription(!showExtraDescription)}>
                      <div>{t('requestSearchResults.airlinesNoteAndConditions')}</div>
                      <button
                        type='button'
                        className={clsx(styles.showDetailsBtn, showExtraDescription ? 'rotate-180' : null)}
                      >
                        <i className={clsx('icon-chevron-down')} />
                      </button>
                    </div>
                    <Collapse isOpen={showExtraDescription}
                              className={showExtraDescription ? styles.descriptionVisible : ''}>
                      <div>{sale.airlineDescription2}</div>
                    </Collapse>
                  </Col>
                </Row>)}

              {sale.airlineDescription && (
                <Row className={styles.collapsableBlock}>
                  <Col className={styles.multiLineText}>
                    <div
                      className={clsx(styles.descriptionTitle, showDescription ? styles.visible : '')}
                      onClick={() => setShowDescription(!showDescription)}>
                      <div>{t('requestSearchResults.generalNotesAndCondition')}</div>
                      <button
                        type='button'
                        className={clsx(styles.showDetailsBtn, showDescription ? 'rotate-180' : null)}
                      >
                        <i className={clsx('icon-chevron-down')} />
                      </button>
                    </div>
                    <Collapse isOpen={showDescription}
                              className={showDescription ? styles.descriptionVisible : ''}>
                      <div>{sale.airlineDescription}</div>
                    </Collapse>
                  </Col>
                </Row>
              )}

              {((sale.dimensionsValidationResult & 8) == 8 || (sale.dimensionsValidationResult & 16) == 16 || (sale.dimensionsValidationResult & 32) == 32
                || (sale.limitations && sale.limitations.length > 0)) && (
                <Row className={styles.collapsableBlock}>
                  <Col className={styles.multiLineText}>
                    <div
                      className={clsx(styles.descriptionTitle, showRestrictions ? styles.visible : '')}
                      onClick={() => setShowRestrictions(!showRestrictions)}>
                      <div>{t('requestSearchResults.embargoAndRestrictions')}</div>
                      <button
                        type='button'
                        className={clsx(styles.showDetailsBtn, showRestrictions ? 'rotate-180' : null)}
                      >
                        <i className={clsx('icon-chevron-down')} />
                      </button>
                    </div>
                    <Collapse isOpen={showRestrictions}
                              className={showRestrictions ? styles.descriptionVisible : ''}>
                      {(sale.dimensionsValidationResult & 8) == 8 && (
                        <Row>
                          <Col className={styles.multiLineText} style={{ color: 'red' }}>
                            {t('requestSearchResults.exceedingWeight')}
                          </Col>
                        </Row>
                      )}
                      {(sale.dimensionsValidationResult & 16) == 16 && (
                        <Row>
                          <Col className={styles.multiLineText} style={{ color: 'red' }}>
                            {t('requestSearchResults.exceedingDimensions')}
                          </Col>
                        </Row>
                      )}
                      {(sale.dimensionsValidationResult & 32) == 32 && (
                        <Row>
                          <Col className={styles.multiLineText} style={{ color: 'red' }}>
                            {t('requestSearchResults.autoDelivery')}
                          </Col>
                        </Row>
                      )}

                      {sale.limitations && sale.limitations.length > 0 && (
                        <Row>
                          <Col className={styles.multiLineText} style={{ color: 'red' }}>
                            {sale.limitations.map((v, i) => (
                              <div key={i}>{v}</div>
                            ))}
                          </Col>
                        </Row>
                      )}
                    </Collapse>
                  </Col>
                </Row>
              )}

            </Col>
          </Row>

          {isTariffActiveForToday && (routes.length > 0 ? isRouteActiveAccordingTariffExpiration : true) && !sale.readonly && (
            <Row>
              <Col>
                <div className={searchStyles.agreement}>
                  <p>{t('requestSearchResults.bookingConditions')}</p>
                  <p>{t(sale.dimensionsValidationResult === 1
                    ? 'requestSearchResults.agreementNotValidated'
                    : 'requestSearchResults.agreementCanBeChanged')}</p>
                  <p>{t('requestSearchResults.bookingRestrictions')}</p>
                  <div className={searchStyles.sendRequestButton}>

                    <Button
                      icon={<Air fill='currentColor' />} type='fill' variant='primary'
                      onClick={showAgreementFormModal}
                      disabled={!userCanBook || sale.isExpired || hasCustomerApplication}
                      title={
                        !userCanBook
                          ? t('exceptions.UnprocessableEntityException.BookingDeniedWithoutContract')
                          : (sale.isExpired
                            ? t('exceptions.UnprocessableEntityException.TariffOrFeeExpired')
                            : hasCustomerApplication ? t('requestSearchResults.hasCustomerApplication') : '')
                      }>
                      {requestQuotationMode ? t('requestSearchResults.requestQuotation') : t('requestSearchResults.sendRequest')}
                    </Button>
                    <Button icon={<Air fill='currentColor' />} type='fill' variant='white'
                            onClick={() => props.onAskDiscount()}>
                      {t('requestSearchResults.askDiscount')}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Collapse>

      <RequestBookingModal
        hasGenCode
        dimensionsValidationResult={sale.dimensionsValidationResult}
        open={showAgreementForm}
        onClose={() => setShowAgreementForm(false)}
        onConfirm={() => props.onSubmit(requestQuotationMode, selectedRoute)}
        confirmText={t(requestQuotationMode ? 'requestSearchResults.requestQuotation' : 'requestSearchResults.agreementConfirmation')} />
    </>
  );
};

export default SaleInfo;