import React from 'react';
import { InputProps } from 'reactstrap';
import {Input as InputField} from 'reactstrap';
import s from "./input.module.scss";
import { ValidationError } from '@components/ValidationError';
import { ColorVariants } from '@root/constants/types';
import cn from 'classnames';
import cs from "../../scenes/common.module.scss"

interface IInput extends InputProps {
	label?: string;
	name?: string;
	labelAsPlaceholder?: boolean;
	validationError?: string;
	indicatorColor?: ColorVariants;
}

const Input: React.FC<IInput> = ({
	label, labelAsPlaceholder, validationError, indicatorColor, ...props
}) => {
	return (
		<div className={s.container}>
			{label || labelAsPlaceholder &&
				<div className={cn(cs.flex, cs.gap_5, cs.flexAlignCenter)}>
					{labelAsPlaceholder ? props.placeholder : label}
						<div className={cn([
							[s.indicator],
							{
								[s.pink]: indicatorColor === 'pink',
								[s.error]: indicatorColor === 'error',
								[s.success]: indicatorColor === 'success',
								[s.warning]: indicatorColor === 'warning',
								[s.primaryDark]: indicatorColor === 'primary',
								[s.primaryLight]: indicatorColor === 'info',
							}]
						)}/>
				</div>
			}
			<InputField {...props} />
			<ValidationError>{validationError}</ValidationError>
		</div>
	);
};

export default Input;
