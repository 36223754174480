import React, { useMemo } from 'react';

import AppModal from '@components/AppModal';
import { FormGroup, Input, Label } from 'reactstrap';
import {
    Field,
    FieldProps,
    Form,
    Formik,
    FormikProps,
} from 'formik';
import { useTranslation } from 'react-i18next';
import SalesService from '@services/SalesService';
import i18n from '@i18n';
import { object, string } from "yup";

type Props = {
    saleId: string;
    isOpened: boolean;
    onClose: () => void;
};

type FormModel = {
    comment: string;
};

const AskDiscountModal = (props: Props): JSX.Element => {
    const initialValues: FormModel = {
        comment: ""
    };

    const { t } = useTranslation();

    const validationSchema = useMemo(
        () =>
            object().shape({
                comment: string().required(t('validation.required')),
            }),
        [i18n.language]
    );

    return (
        <AppModal
            isOpen={props.isOpened}
            onClickCloseButton={props.onClose}
            body={
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (
                        values: FormModel
                    ): Promise<void> => {
                        try {
                            await new SalesService().askDiscount(props.saleId, values.comment);
                        } catch (e) {
                            console.error('Ask discount error', e);
                            alert(t('validation.unknownError'));
                        } finally {
                            props.onClose();
                        }
                    }}
                >
                    {({
                        isSubmitting,
                    }: FormikProps<FormModel>): JSX.Element => (
                        <Form>
                            <div>
                                <FormGroup>
                                    <Field name="comment">
                                        {({ field, form }: FieldProps<string, FormModel>): JSX.Element => (
                                            <>
                                                <Label>{t('requestSearchResults.modal.askDiscountLabel')}</Label>
                                                <Input type="textarea" {...field} rows={8} />
                                                {form.errors && (
                                                    <div style={{ color: "red" }}>
                                                        {form.errors.comment}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </FormGroup>
                                <FormGroup className="text-center">
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="btn btn-primary"
                                    >
                                        {t('requestSearchResults.modal.askDiscountButton')}
                                    </button>
                                </FormGroup>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        />
    );
};

export default AskDiscountModal;
