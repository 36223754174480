import { TenantEnResources } from '@helpers/tenantsHelper';

const common = {
    actions: 'Actions',
    ok: 'OK',
    backToList: 'Back to list',
    cancel: 'Cancel',
    start: 'Start',
    create: 'Create',
    created: 'Created',
    update: 'Update',
    updated: 'Updated',
    imported: 'Imported',
    name: 'Name',
    address: 'Address',
    select: 'Select',
    success: 'Success',
    activate: 'Activate',
    deactivate: 'Deactivate',
    deactivateLabel: 'Deactivate reason',
    activated: 'Activated',
    deactivated: 'Deactivated',
    changesSaved: 'Changes saved',
    draftSaved: 'Draft saved',
    changesReset: 'Changes reset',
    remove: 'Remove',
    removed: 'Removed',
    restore: 'Restore',
    price: 'Price',
    airline: 'Airline',
    number: 'Number',
    yes: 'Yes',
    no: 'No',
    save: 'Save',
    undo: 'Undo',
    harmonizedCommodityCodes: 'HS CODE',
    sameAsLegalAddress: 'Same as legal address',
    areDirectorAndAccountantTheSame: 'Director and responsible for payments are one person',
    chargeableWeight: 'Chargeable weight',
    transportationCost: 'Transportation cost',
    transportationCostPrev: 'Preliminary transportation cost',
    costPerUnit: 'Cost per {unit}',
    unableToChangePermissionsForYourself: 'Unable to change permissions for yourself.',
    changeRequestParams: 'Try changing your request parameters and try again',
    contact: {
        edit: 'Edit employee',
        create: 'Create employee',
        createNew: 'Create new employee',
        activated: 'Activated',
        deactivated: 'Deactivated',
        canLogin: 'Can login',
        canNotLogin: 'Can\'t login',
    },
    contactPermissionTypes: {
        None: 'None',
        All: 'All',
        UpdatePermissions: 'Update permissions',
        UpdateOrganizationInfo: 'Update account info',
        CreateContact: 'Create users',
        UpdateContact: 'Update users info',
    },
    interactionTypes: {
        Commercial: 'Commercial',
        Operational: 'Operational',
        Payments: 'Payments',
        Debts: 'Debts',
        Director: 'Director',
        Documents: 'Documents',
        Administrator: 'Administrator',
        hints: {
            Commercial: 'For discussing commercial issues and sending out rates sheet',
            Operational: 'For operational working, automatic booking confirmations and cargo tracking',
            Payments: 'For mutual settlements and sending accounting documents electronically',
            Debts: 'Specify the employee responsible for debts',
            Director: 'Specily CEO or other person acting on behalf of the company',
            Documents: 'Specify the employee responsible for sending and receiving primary accounting documentation',
            Administrator: 'User and rights management in the system',
        },
    },
    jobPositionTypes: {
        DirectorOrItsDeputy: 'Руководитель',
        FinancialDirector: 'Финансовый директор',
        CommercialDirector: 'Коммерческий директор',
        HeadOfDepartment: 'Руководитель (отдела, филиала)',
        ChiefAccountant: 'Ответственный за взаиморасчеты',
        Accountant: 'Старший бухгалтер / бухгалтер',
        TransportationManager: 'Менеджер по перевозкам',
        LogisticsManager: 'Менеджер по логистике, ВЭД, таможенному оформлению',
        AccountManager: 'Менеджер по работе с клиентами',
        SalesManager: 'Менеджер по бронированию и продаже',
        Ceo: 'CEO',
        Cfo: 'CFO',
        KeyAccountManager: 'Key account manager',
        CustomerService: 'Customer Service',
        TransportCoordinator: 'Transport coordinator',
        Other: 'Прочее',
    },
    waysToGetAccountingDoc: {
        None: '',
        ByEmail: 'По почте',
        ByEdm: 'Посредством ЭДО',
    },
    exceptions: {
        ConflictException: {
            AnotherContactWithSameJobPositionAlreadyExists:
                'Another employee ({1} {2} with Email {3}) already has the same job position.',
            EmailWithSameLanguageAlreadyExists: 'Email with the same language already exist.',
        },
        InvalidLoginException: {
            InvalidLoginOrPassword: 'Invalid login or password.',
            LockoutEnabled: 'Account deactivated.',
        },
        AccountRegistrationException: {
            UserAlreadyExists: 'User already exist.',
            ContactAssignedToAnotherOrganization: 'User is assigned to another organization.',
            UnexpectedError: 'Fail to register. Please contact us.',
            ContactLostRequiredInteractionType:
                'Before deleting a required interaction type, it must be assigned to another contact.',
        },
        UnprocessableEntityException: {
            CouldNotSendEmailMessage:
                'Could not send an Email message to \'{1}\'. It seems that email address is invalid.',
            BookingDeniedWithoutContract:
                'Reservation requires the conclusion of a contract. Please contact sales@ffcargo.com',
            TariffOrFeeExpired:
                'The request is out of date. You need to create a new one.',
        },
    },
    identityCodes: {
        PasswordRequiresNonAlphanumeric: 'Password must have at least one non alphanumeric character.',
        PasswordRequiresLower: 'Password must have a lower case letter (\'a\'-\'z\').',
        PasswordRequiresUpper: 'Password must have an upper case letter (\'A\'-\'Z\').',
        PasswordRequiresDigit: 'Password must have at least one digit (\'0\'-\'9\').',
        PasswordTooShort: 'Password must have at least {0} characters.',
    },
    shc: {
        chooseShc: 'Choose GEN or Special Handling Code(s)',
        shc: 'Special Handling Code',
    },
    accreditation: {
        Granted: 'Thank you for the filling necessary data.\nNow you can start working!',
        GrantedNoBooking: 'Thank you for the filling necessary data.\nNow you can start working!',
        Pending:
            'Thank you for providing information about your company.\nOur employee will contact you shortly to discuss further cooperation.',
        Rejected: 'Thank you for providing information about your company.\nUnfortunately the data was rejected!',
        Blocked:
            'Thank you for providing information about your company.\nWe are sorry but your organization has been blocked!',
        message: 'Fill the form to start working with our services.',
        employeesSubHeader:
            'Choose the employee responsible for each Interaction type. You can also add other employees who can use the portal and receive mailings in your personal account.',
        contacts: {
            create: 'Create',
            interactionTypesText: 'Interaction types',
            employee: 'Employee',
            jobPosition: 'Job Position',
            email: 'Email',
            buttonDeleteRow: 'Delete',
            errors: {
                ShouldContainPaymentsInteractionType: 'Payments responsible person required',
                ShouldContainDirectorInteractionType: 'Director person required',
                ShouldContainCommercialInteractionType: 'Commercial person required',
                ShouldContainOperationalInteractionType: 'Operational person required',
            },
        },
        interactionRequired: 'Responsible person for this block required',
        thanks: 'FF Cargo Service LLC thanks you for the information provided and hopes for a long and fruitful cooperation.',
        timeoutSplash: 'Company information is being updated in the background. {{timeLeft}} seconds left.',
    },
    preferences: 'Preferences',
    changePasswordText: 'Change password',
    add: 'Add',
    accountSettings: 'Account settings',
    personalInfo: 'Personal info',
    organizationText: 'Account',
    employees: 'Users',
    backToEmployees: 'Back to employees',
    employee: 'Employee',
    permissions: 'Permissions',
    firstName: 'First Name',
    lastName: 'Last Name',
    jobPosition: 'Job Position',
    email: 'Email',
    phone: 'Phone',
    mobilePhone: 'Mobile phone',
    sender: 'Sender',
    interactionTypesText: 'Interaction types',
    preferredLanguage: 'Preferred language',
    selectLanguage: 'Select language',
    selectInteractionTypes: 'Select interaction types',
    selectJobPosition: 'Select job position',
    selectWayToGetDocs: 'Select way...',
    legalInfo: 'Legal info',
    contactInfo: 'Contact info',
    financialInfo: 'Financial info',
    additionalInfo: {
        title: 'Additional info',
        employeesCount: 'Number of employees in the organization',
        currentCarriers: 'Which carriers are you currently cooperating with?',
        mainDirections: 'Main directions',
        expectedVolumes: 'Expected volumes within the framework of planned cooperation',
    },
    password: {
        get: 'Get password',
        noPassword: 'You don\'t have password yet. Click on the button to receive email with instructions.',
        sendEmail: 'Send email',
        emailSent: 'Email with instructions in your mailbox.',
        emailSendFail: 'Email send failed, please try again.',

        changePassword: 'Change password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        passwordsMismatch: 'Passwords mismatch',
        passwordChanged: 'Password changed',
    },

    organization: {
        shortName: 'Short name',
        uniqueNumber: 'T.I.C.',
        vatNumber: 'V.A.T.',
        regNumber: 'Reg no',
        primaryContact: 'Main contact',
        fullName: 'Full name',
        checkingAccount: 'Checking account',
        bankName: 'Bank',
        correspondentAccount: 'Correspondent Account',
        wayToGetAccountingDocs: 'Method of obtaining primary documents',
        edmName: 'EDM operator',
        edmId: 'EDM ID',
        bik: 'BIK',
        dateIncorporation: 'Company registration date',
        legalAddress: 'Legal address',
        mailingAddress: 'Mailing address',
        officeAddress: 'Operating office addresses and work phone number',
        websiteUrl: 'Website',
        mainActivity: 'Primary occupation',
        phone: 'Phone',
    },
    upload: {
        maxFileCountReached: 'Maximum file count reached.',
        uploadFiles: 'Upload files',
        attachedFiles: 'Attached files',
        cantUploadFile: 'Can\'t upload file.',
        dropFilesHere: 'Drop files here',
        dragAndDropFileHere: 'Drag and drop your file here or click to select file',
        uploadSuccessful: 'Upload successful',
        uploadingWarning: 'Uploading is active. Please wait...',
    },
    externalLogin: {
        accessDenied: 'Access to Cargo booking is denied',
        userNotExist: 'User with email {email} doesn\'t exist, please, ',
        register: 'register',
    },
    options: {
        loadingOptions: 'Loading options...',
        loadingOptionsTemplate: 'Loading {itemType}...',
        noOptions: 'No options available.',
        noOptionsTemplate: 'No {itemType} available.',
        specifyAtLeast3Chars: 'Type at least 3 chars to search',
    },
    paging: {
        onPage: 'On page:',
        first: 'First',
        last: 'Last',
    },
    sorting: {
        none: 'None',
        ascending: 'Ascending',
        descending: 'Descending',
        sorting: 'Sorting',
        name: 'Name',
        dateCreated: 'Date created',
    },
    validation: {
        agentIataCode: 'Code should be a 7-length number',
        required: 'Field is required',
        invalidAwbNumberChecksum: 'Checksum error.',
        invalidAwbNumberAirlineIata: 'Airline\'s IATA code mismatch.',
        email: 'Provide correct email address',
        organizationUniqueNumber: 'Provide correct T.I.C. 10 or 12 digits.',
        unknownError: 'Unknown error',
        numberType: 'Must be a number',
        positive: 'Must be positive',
        integer: 'Must be an integer',
        contactDuplicateEmail: 'The same email is used for different contacts',
        exactLength: 'Field should have ${length} symbol(-s)',
        invalidFormat: 'Field have invalid format',
        tooShortHSCodes: 'One of rows is too short, it should be at least 6 symbols',
        tooLongHSCodes: 'One of rows is too long, it should be less 19 symbols',
        tooMuchHSCodes: 'Too much rows. Maximum is 12',
        'One or more validation errors occured.': 'One or more validation errors occured.',
        'INN is invalid.': 'Provide correct T.I.C. 10 or 12 digits.',
        lettersExpected: 'Name shouldn\'t contains digits only',
        invalidAddress: 'Invalid address',
        countryRequired: 'Specify country',
        invalidCodeIata: 'Invalid or empty IATA code',
        'Airwaybill number must start with airline IATA prefix.': 'Airwaybill number must start with airline IATA prefix.',
        'Number should be unique.': 'Number should be unique.',
        atLeastOnePhoneRequired: 'At least one phone number required',
        'Поле должно быть заполнено.': 'Field is required',
    },
    units: {
        kg: 'kg',
        cm: 'cm',
        pc: 'pc',
        mc: 'mc',
        mm: 'mm',
        m3: 'm³',
        lb: 'lb',
        min: 'Min.',
        unit: 'unit',
        full: {
            cm: 'Centimeters',
            mm: 'Millimeters',
            m: 'Meters',
        }
    },
    viewMode: {
        title: 'View mode',
        own: 'My only',
        all: 'Show organization\'s entries',
    },
    leftMenu: {
        publicSite: 'Cargobooking365 Public Website',
        homePage: 'Home page',
        sendRequest: 'Search and book your cargo shipment',
        account: 'My company\'s account',
        bookings: 'My bookings',
        requestsHistory: 'My requests',
        awbPanel: 'AWB',
        adminPanel: 'Admin panel',
    },
    login: {
        header: TenantEnResources.loginHeader,
        subHeader: 'Welcome! Please login or sign-up',
        loginPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        rememberMe: 'Remember me',
        forgotPwd: 'Forgot your password?',
        logIn: 'Log in',
        signUp: 'Sign up',
        useLoginSrv: 'Or use 3rd party service to login',
        loginError: 'Login or password is incorrect',
    },
    welcomePage: {
        login: 'log in',
        contactUs: 'Contact us',
        mainHeader: TenantEnResources.welcomePageMainHeader,
        header: TenantEnResources.welcomePageHeader,
        start: 'Get Started',
        policy: 'Privacy policy',
        terms: 'Terms of Service',
        copyright: TenantEnResources.welcomePageCopyright,
    },
    contactUs: {
        mainHeader: TenantEnResources.contactUsMainHeader,
        header: 'Making Air Cargo Booking Instant and Simple',
        selectLbl: 'Select one',
        gssa: 'GSSA',
        forwarder: 'Freight forwarder',
        airport: 'Airport',
        press: 'Press',
        name: 'Full name',
        email: 'Business email',
        phone: 'Business phone',
        company: 'Company',
        message: 'Your message',
        policy: 'By submitting this form, you agree ',
        contactUs: 'Contact us',
        cancel: 'Cancel',
        error: 'Error happened. Please try later or contact administrator',
    },
    register: {
        header: 'User registration',
        language: 'Please choose preferred language',
        languagePlaceholder: 'Select language',
        subHeader: 'Please fill in the details to create your account',
        organizationName: 'Organization name',
        inn: 'T.I.C.',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phone: 'Mobile phone',
        forwarding: 'Forwarding and logistics',
        signUp: 'Sign up',
        other: 'Other',
        activityType: 'Activity type',
        success: 'You are successfully registered. Email with password in your mailbox.',
        successShouldModerated:
            'Thank you for contacting FFCargoServices. A link to create a personal password has been sent to your email.',
        country: 'Select country',
    },
    resetPassword: {
        header: 'Password reset',
        subHeader: 'Please enter new password',
        newPassword: 'New password',
        confirmPassword: 'Confirm new password',
        reset: 'Reset',
    },
    resetPasswordRequest: {
        header: 'Password reset',
        subHeader: 'Please enter your email to get the password recovery link.',
        successResetMsg: 'Password reset email has been successfuly sent.',
        notFountUserMsg: 'User not found.',
        email: 'Email',
        reset: 'Reset',
    },
    changePassword: {
        header: 'Change Password',
        subHeader: 'Please enter old password and new password',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmPassword: 'Confirm new password',
        update: 'Change',
    },
    loginErrors: {
        changePasswordNotMatch: 'Passwords does not matched.',
        somethingWrong: 'Something went wrong. Please contact the administrator.',
        notFoundUserMsg: 'User not found.',
    },
    home: {
        header: 'Welcome to AirCargoBooking365',
    },
    request: {
        headers: {
            dimensions: "Detailed dimensions and weight"
        },
        parseExample: "Example:<br/>120*80*80cm 1 pc 200kg<br/>200kg 1 pcs 120*80*80cm<br/>1 pcs 200kg 120*80*80cm",
        minLettersRequired: 'Min. 3 letters required',
        header: TenantEnResources.requestHeader,
        subHeader: 'Choose the best option and book',
        linkText: ' send the request right away',
        departure: 'Departure point',
        destination: 'Destination',
        from: 'City or Airport in Russia',
        to: 'City or Airport',
        nearBy: 'Airports near by',
        countries: 'Countries',
        airports: 'Airports',
        airCarriers: 'Air carriers',
        cargoDetails: 'Cargo details',
        cargoType: 'Cargo type',
        cargoTypePlaceholder: 'Choose cargo type',
        cargoWeight: 'Cargo weight',
        cargoWeightTotal: 'Weight (total)',
        calculateTotalWeightFromDims: 'Calculate total weight from dimensions',
        natureOfCargo: 'Nature of Cargo',
        requestDate: 'Request date',
        planedFlightDate: 'Planned flight date',
        processingCodes: 'Processing codes',
        natureOfCargo_placeholder: 'Choose the appropriate nature of cargo',
        goodsDescription: 'Description of the cargo (no more than 50 characters)',
        goodsDescription_placeholder: 'Goods description shown in AWB',
        cargoVolume: 'Volume',
        packagePlaces: 'Pieces',
        dimOfEach: 'Dimensions of each piece of cargo individually',
        isTurnable: 'Turnable',
        isCargoStacked: 'Stackable',
        items: 'Items',
        orderNumber: "№",
        itemLength: 'Item length (cm)',
        itemWidth: 'Item width (cm)',
        itemHeight: 'Item height (cm)',
        itemWeight: 'Item weight (kg)',
        weightType: 'Calculate type',
        generalWeightType: "General",
        perPlaceWeightType: "Per place",
        itemAdd: 'Add',
        total: 'Total',
        placesNo: 'Number of package spaces',
        getRoutes: 'Get routes and quotes',
        excel: 'Load from Excel',
        excelTemplate: 'Download a template',
        parseDims: 'Parse from text',
        tableHeader: 'A detailed indication of the weight and dimensional characteristics of the load',
        tableHeaderAddText: 'will help the system to more accurately identify the available options',
        alertMismatchData: 'The weight in the detailing does not match the total weight',
        alertMismatchVolume: 'The dimensions in the detailing do not match the total cargo volume',
        parse: 'Parse',
        plannedDepartDate: 'Expected shipping date',
        useTotalWeight: 'Total weight',
        useWeightPerPiece: 'Weight per piece',
        withoutDimensions: 'Without dimensions',
        separateDimensions: 'Separate dimensions',
        isMillimetersUsed: 'Dimensions are in millimeters',
        serviceModeActive: 'The portal is currently unavailable due to ongoing technical maintenance. Normal service on the portal will resume in the near future. We apologize for any inconvenience caused.',
        dimensions: {
            changeTotalWeight: 'Change the total weight of the cargo manually',
            changeSeparatly: 'Enter the weight of the cargo separately',
            enterTotalWeight: 'If you do not know the weight of each cargo separately, enter the total weight of the cargo in the "Cargo Information" section',
            enterWeightSeparatly: 'To enter the weight of each dimension manually, delete the total weight of the cargo in the "Cargo Information" section'
        }
    },
    requestSearchResults: {
        departurePoint: 'Departure point',
        destinationPoint: 'Destination point',
        modal: {
            success: 'Email sended to {email} successfully',
            header: 'Send results to email (PDF)',
            header2: 'Send results to email',
            button: 'Send results',
            buttonSending: 'Sending...',
            askDiscountLabel:
                'Ask your question or provide additional information about the shipment: special conditions, competitive rate, etc.',
            askDiscountButton: 'Request special conditions',
        },
        currencyExchangeComment:
            'Comparative analysis was made at the ' +
            '{exchangeType, select, 557050001 {rate of the Central Bank of the Russian Federation + {cbPlus}%} 557050002 {airline’s fixed rate of exchange} other {rate of the Central Bank of the Russian Federation}} ' +
            'as of the current date. The carrier\'s tariffs are published in {currencyCode}. ',
        paymentTerms:
            'Payment is made in Russian Rubles at the ' +
            '{exchangeType, select, 557050001 {rate of the Central Bank of the Russian Federation + {cbPlus}%} 557050002 {airline’s fixed rate of exchange} other {rate of the Central Bank of the Russian Federation}} ' +
            'for one {currencyCode} on the {paymentOn, select, 557050000 {date of payment} 557050002 {date of AWB} other {date of departure of the cargo from the Russian Federation}}. ',
        paymentTermsForBaseCurrency: 'Payment is made in Russian Rubles at the carrier\'s tariffs.',
        PaymentIsMadeBasedOnTheIssuedInvoice: 'Payment is made based on the issued invoice',
        sendSalesDocument: 'Send results to my email (PDF)',
        sendSalesDocument2: 'Send results to my email',
        downloadSalesDocument: 'Download results (PDF)',
        copySalesHtml: 'Copy results to clipboard',
        sendFile: 'With PDF attached',
        sendEmail: 'As email',
        sendHeader: 'Send results',
        sendSchedule: 'Send tariff schedule',
        downloadFile: 'Download',
        copySales: 'Copy',
        products: 'Products',
        searchText: 'The systems is searching for the best pricing & routes for your cargo',
        filter: 'Filter',
        sort: 'Sort by',
        noConnections: 'no connections',
        sendRequest: 'Send booking request',
        searchResults: 'Search results',
        found: 'found',
        carrier: 'Carrier',
        product: 'Product',
        status: 'Status',
        limitationsAndTerms: 'Limitations and terms',
        optionalFees: 'Optional fees',
        route: 'Route',
        feeTableHeader: 'Fee',
        extraFee: 'Surcharge',
        airFreightRate: 'Air freight rate',
        totalAirFreight: 'Total air freight',
        tariffValidUntil: 'Valid until',
        totalSurcharges: 'Total surcharges',
        totalTariff: 'Total tariff',
        transit: 'Transit',
        departure: 'Departure',
        arrival: 'Arrival',
        origin: 'Origin',
        destination: 'Destination',
        limitations: 'Limitations',
        terms: 'Terms',
        nothingFound:
            'The automatic search did not return any results.\nPlease contact the operator on our e-mail <0 href="mailto:{{email}}">{{email}}</0> for individual processing of your request.',
        filters: 'Filters',
        airlines: 'Airlines',
        plannedDepartDate: 'Expected shipping date',
        requestNumber: 'Request number',
        available: 'Available',
        uponRequest: 'Upon request',
        embargo: 'Embargo',
        restrictions: 'Restrictions',
        adHoc: 'Ad hoc',
        promo: 'PROMO',
        notAvailable: 'Not available',
        saleStatus: 'Status',
        flightDates: 'Flight dates',
        optional: 'Optional',
        showMore: 'Show more routes...',
        showLess: 'Show less routes...',
        tariffDetails: 'Tariff details',
        results: 'Result(s)',
        searchDetails: {
            header: 'Search details',
            change: 'Change',
            seatsNumber: 'Number of package spaces',
            weight: 'Cargo weight',
            volume: 'Cargo volume',
            cargoType: 'Cargo type',
            cargoDimensions: 'Cargo dimensions separately',
            showMore: 'Show more',
            hide: 'Hide',
        },
        agreementTitle: 'Agreement',
        bookingConditions: 'Space availability as per the published fares is not guaranteed. The carrier has the right to change fares depending on the request. The final confirmation of the fare and its application is made at the time of booking air carriage.',
        bookingRestrictions: 'This shipment should not contain: valuable goods, dangerous goods, live animals, goods requiring temperature control, human remains, military ammunition and other types of weapons, including sports and hunting, as well as other goods requiring special handling',
        agreementConfirmation: 'Confirm',
        agreement:
            'I am aware that space availability as per the published fares is not guaranteed. The carrier has the right to change fares depending on the request. The final confirmation of the fare and its application is made at the time of booking air carriage.',
        agreementNotValidated:
            'The rate is provided without checking the aircraft type and does not guarantee shipping',
        agreementCanBeChanged:
            'The rate is provided in accordance with the loading possibilities, but the Carrier may change the aircraft type. The shipping is guaranteed only after the booking confirmation',
        agreementGen:
            'I hereby confirm that this shipment does not contain: valuable goods, dangerous goods, live animals, goods requiring temperature control, human remains, military ammunition and other types of weapons, including sports and hunting, as well as other goods requiring special handling',
        requestQuotation: 'Request quotation',
        askDiscount: 'Request special conditions',
        exceedingWeight: 'Exceeding the permissible load weight',
        exceedingDimensions: 'Exceeding the permissible cargo dimensions',
        autoDelivery: 'The rate is available on request using autodelivery',
        stackableHint: 'The rate is valid if the pieces are stackable. Otherwise, request a quotation',
        airlinesNoteAndConditions: 'Airlines note and conditions',
        generalNotesAndCondition: 'General notes and condition',
        embargoAndRestrictions: 'Embargo and restrictions',
        hasCustomerApplication: 'This request booked already',
        alternativeDate: 'Alternative date',
    },
    navMenu: {
        profile: 'Profile',
        settings: 'Settings',
        changeCurrency: 'Change currency',
        changeLang: 'Change language',
        changePassword: 'Change Password',
        signOut: 'Sign out',
    },
    admin: {
        backToPanel: 'Back to panel',
        panel: {
            title: 'Admin panel',
            logs: 'Logs',
            logsDescription: 'Logged data',
            referenceData: 'Reference data',
            referenceDataDescription: 'Dictionaries',
            modules: 'Configuring modules',
            modulesDescription: 'Modules and their settings',
            serviceMode: 'Service Mode',
            serviceModeDescription: 'Enable or disable service mode',
            noServiceMode: 'Not specified',
            serviceModePeriod: 'Period: {start} - {end}',
            serviceModeExpired: 'Expired',
            serviceModeCancel: 'Cancel service mode',
        },
        dictionaries: {
            goBack: 'Back to previous',
            title: 'Reference data',
            total: '{0} total',
            aircrafts: 'Aircrafts',
            airlines: 'Airlines',
            airplanes: 'Airplanes',
            airportGroups: 'Airport groups',
            airports: 'Airports',
            cargoTypes: 'Cargo types',
            cities: 'Cities',
            countries: 'Countries',
            tariffs: 'Tariffs',
            users: 'Users',
            organizations: 'Organizations',
        },
        organizations: {
            search: 'Search...',
            name: 'Name',
            phone: 'Phone',
            email: 'Email',
            uniqueNumber: 'T.I.C.',
            vatNumber: 'V.A.T.',
            regNumber: 'Reg no',
            hierarchyLevel: 'Hierarchy level',
            branch: 'Branch',
            office: 'Office',
            parent: 'Parent',
            related: 'Related',
            subsidiary: 'Subsidiary',
            goToUsersTable: 'See users',
        },
        users: {
            search: 'Search...',
            userName: 'Login',
            email: 'Email',
            organization: 'Organization',
            organizations: 'Organizations',
            contactId: 'Contact ID',
        },
        tariffs: {
            search: 'Search...',
            code: 'Code',
            type: 'Type',
            departureAirportCode: 'Departure airport code',
            destinationAirportCode: 'Destination airport code',
            airlineCode: 'Airline code',
            currencyCode: 'Currency code',
            product: 'Product',
            buy: 'BUY',
            sell: 'SELL',
            buySell: 'BUY/SELL',
            none: 'NONE',
        },
        airlines: {
            airlines: 'Airlines',
            search: 'Search...',
            uploadLogo: 'Upload logo',
            excludeFromCalc: 'Exclude from calc',
            includeToCalc: 'Include to calc',
            noImage: 'No image',
            logo: 'Logo',
            name: 'Name',
            iataPrefix: 'IATA prefix',
            iso2CountryCode: 'ISO2 country code',
            manage: 'Manage',
            uploadLogoModalTitle: 'Upload logo for airline',
            fwbVersion: 'FWB Version',
            fwb16Version: 'FWB Version 16',
            fwb17Version: 'FWB Version 17',
            eAwb: 'eAwb Code',
            security: 'Security Code',
            notSpecified: 'Not specified',
            dataSaving: 'Data saving...',
        },
        settings: {
            settings: 'Configuring modules',
            key: 'Key',
            value: 'Value',
            save: 'Save',
            edit: 'Edit',
            cancel: 'Cancel',
            delete: 'Delete',
            language: 'Language',
            category: 'Category',
            selectLanguage: 'Language...',
            deleteModalMessage: 'You are going to delete the \'{0}\' key of the settings.',
            areYouSure: 'Are you sure?',
            yes: 'Yes',
            no: 'No',
            userInterface: 'User interface',
            email: 'Email',
            erase: 'Erase',
            uniqueErrorMsg: 'Key and language should be unique.',
            isPublic: 'Public',
        },
    },
    customerApplication: {
        booking: 'Booking',
        modal: {
            successApplicationTitle:
                'Your booking request has been accepted and will be processed as soon as possible.',
            successQuatationTitle: 'Your request has been accepted and will be processed as soon as possible.',
            followNextSteps: 'Thanks for your request.\nPlease use the number below to track your request:',
            homePage: 'Home page',
            activeRequests: 'Active requests',
        },
        form: {
            awbNumber: 'Airwaybill number (if any)',
            date: 'Flight / Date',
            cargoDetails: 'Nature of Goods',
            stacked: 'Stackable',
            stackedHint: 'it is allowed to place this cargo on other heavier places',
            turnover: 'Turnable',
            turnoverHint:
                'it is allowed to carry cargo on the side surface and / or tilt the cargo when loading into the aircraft',
            packingWithOtherGoods: 'Top load',
            packingWithOtherGoodsHint: 'lighter loads can be placed on top ',
            carriageSpecialTerms: 'Special terms of Carriage',
            hazardClass: 'Hazard class',
            isCargoDangerous: 'Dangerous cargo',
            cargoUnNumber: 'UN',
            cargoPackagedForPlaneType: 'Packed for',
            cargoPlane: 'Cargo airplane',
            passengerPlane: 'Passenger plane',
            senderAddress: 'Shipper\'s Name and Address (including phone number)',
            recipientAddress: 'Consignee\'s Name and Address (including phone number and zip code)',
            specialPrice: 'Special rates',
            refNumber: 'REF number',
            awbProvidedBy: 'Airwaybill issued by',
            fromSender: 'Sender',
            fromProvider: 'Provider',
            sendRequest: 'Send request',
            saveRequest: 'Save',
            back: 'Back without save',
            cargoPlaces: 'Pieces count',
            cargoVolume: 'Cargo volume',
            meters: 'm',
            cargoWeight: 'Cargo weight',
            cargoType: 'Cargo type',
            where: 'Where',
            to: 'To',
            cancel: 'Cancel',
            yes: 'Yes',
            no: 'No',
            senderStateProvince: 'Sender\'s State/Province',
            senderCity: 'Sender\'s city',
            recipientStateProvince: 'Recipient\'s State/Province',
            recipientCity: 'Recipient\'s city',
            startTypingHint: 'type 3 letters to see options',
            contactInformation: 'Contact information',
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email',
            additionalEmail: 'Additional email',
            phoneNumber: 'Phone number',
            aggrementTxt:
                'I hereby certify that I have provided complete and correct information about the shipment. I confirm that the shipment does not contain any items prohibited for carriage or items that have been mistakenly accepted.',
        },
        traceSubscription: 'Data by subscription',
    },
    customerApplicationHistory: {
        airportNameOrIata: 'Airport name/IATA code',
        minNumberDigitsRequired: '{value} digits min. required',
        title: 'Booking history',
        totalCount: 'Total',
        nothingFound: 'Nothing found.',
        filter: 'Filter',
        filterOptions: 'Filter options',
        clearFilters: 'Clear filter',
        enterNumber: 'Enter number...',
        selectAirlines: 'Select airlines...',
        selectDateRange: 'Select a date range',
        awbNumber: 'Air Waybill',
        dateCreated: 'Created date and time',
        startDate: 'Start date',
        endDate: 'End date',
        date: 'Date',
        number: 'AWB Number',
        route: 'Route',
        totalFee: 'Total fee',
        expectedShippingDate: 'Expected shipping date',
        departure: 'Departure',
        destination: 'Destination',
        minimize: 'Minimize',
        general: 'General',
        actualShippingDate: 'Actual shipping date',
        expectedArrivalDate: 'Expected arrival date',
        actualArrivalDate: 'Actual arrival date',
        totalItems: 'Total items',
        totalWeight: 'Total weight',
        totalVolume: 'Total volume',
        dimensionsOfEachPackage: 'Dimensions of each package',
        items: 'Items',
        itemLength: 'Item length',
        itemWidth: 'Item width',
        itemHeight: 'Item height',
        itemWeight: 'Item weight',
        organizations: 'Organizations',
        searchOrganizations: 'Search for organizations...',
        organizationName: 'Organization',
        repeatSearch: 'Repeat search',
        openBookingForm: 'Open booking',
        requestNo: 'Request No',
    },
    customerRequestsHistory: {
        additional: "Places, weight, volume",
        title: 'Request history',
        totalCount: 'Total',
        nothingFound: 'Nothing found.',
        date: 'Date',
        route: 'Route',
        cargoTypeName: 'Cargo type',
        statusCode: 'Status',
        filter: 'Filter',
        filterOptions: 'Filter options',
        clearFilters: 'Clear',
        source: 'Source',
        target: 'Target',
        minimize: 'Minimize',
        expectedShippingDate: 'Expected shipping date',
        general: 'General',
        searchDate: 'Search date',
        totalItems: 'Total items',
        totalWeight: 'Total weight',
        totalVolume: 'Total volume',
        dimensionsOfEachPackage: 'Dimensions of each package',
        items: 'Items',
        itemLength: 'Item length',
        itemWidth: 'Item width',
        itemHeight: 'Item height',
        itemWeight: 'Item weight',
        loadingOptions: 'Loading options...',
        noOptions: 'No options available.',
        selectDateRange: 'Select a create date range',
        selectDepartureDateRange: 'Select a departure date range',
        airportOrCity: 'IATA/Airport/City',
        startDate: 'Start date',
        endDate: 'End date',
        cargoTypes: 'Cargo types',
        selectCargoTypes: 'Select cargo types...',
        repeatSearch: 'Repeat search',
        viewSearchResults: 'View results',
        numberUpdating: 'Number is being updated...',
        status: {
            '852250014': 'In progress',
            '979540002': 'In progress', // 'Требует ручной обработки',
            '852250013': 'In progress', // 'Запрос доп. информации у клиента',
            '852250012': 'In progress', // 'Запрос в авиакомпанию',
            '852250001': 'Completed', // 'Заключение выгодной сделки',
            '852250004': 'Waiting for feedback', // 'Ожидание решения Заказчика'
            '852250000': 'Waiting for feedback', // 'Предложение с расценками',
            '852250006': 'Waiting for feedback', // 'Предварительное подтверждение',
            '852250009': 'Waiting for feedback', // 'Тендер',
            '1': 'Waiting for feedback', // Обратная связь 1
            '852250003': 'Waiting for feedback', // Обратная связь 2
            '852250005': 'Completed', // 'Первый груз забронирован',
            '852250007': 'Completed', // 'Регулярно летает - с нами',
            '852250008': 'Waiting for feedback', // 'Регулярно летает - не с нами',
            '852250010': 'Waiting for feedback', // 'Холодный/сетевой запрос',
            '852250011': 'Waiting for feedback', // 'Изучение рынка',
            '852250015': 'Completed', // 'Улетел с конкурентами',
            '979540000': 'Completed', // 'Улетел с ФФ',
            '979540001': 'Completed', // 'Улетел с АСР',
            '979540003': 'Completed', // 'Улетел с AGS',
            '979540004': 'No options',
            '979540005': 'Auto rating',
        },
    },
    tracing: {
        title: 'Status',
        request: 'Request',
        booking: 'Booking',
        execution: 'Execution',
        closing: 'Closing',
        arrived: 'Arrived',
        shipping: 'Shipment',
    },
    generalData: {
        title: 'Cargo data',
        data: 'Data',
        edit: 'Edit',
    },
    comments: {
        title: 'Comments',
        enterComment: 'Enter comment',
        send: 'Send',
    },
    payment: {
        title: 'Payment',
    },
    bookingConfirmation: {
        detailsWillBeSoon: 'Your booking details will be available once your booking has been confirmed',
        title: 'Booking Confirmation',
        flightNo: 'Flight No',
        originalAirport: 'ORG',
        destination: 'DEST',
        departureTime: 'STD',
        arrivalTime: 'STA',
        dimensions: 'Pieces/Weight(GW)/Volume',
        bookingStatus: 'Booking Status',
        flightDetails: 'Scheduled flight information',
        allIn: 'all-in',
        kg: '/kg',
        min: 'Min',
        normal: 'N',
        tariffHeader: 'Tariff',
        tariff: 'Tariff and additional fees',
        conditions: 'Conditions of air transportation',
        transportationCost: 'Preliminary transportation cost including additional fees',
        fees: 'Additional charges',
        optionalFees: 'Terminal processing and related services',
        limits: 'Additional conditions of carriage and restrictions',
        kk: 'Confirmed',
        nn: 'Requesting space allocation',
        ll: 'Wait list',
        cn: 'Cancellation noted',
    },
    printAwbToPdf: {
        printToPdf: 'Print to PDF',
        original1ForIssuingCarrier: 'Original 1 (for Issuing Carrier)',
        original2ForConsignee: 'Original 2 (for Consignee)',
        original3ForShipper: 'Original 3 (for Shipper)',
        copy4DeliveryReceipt: 'Copy 4 (Delivery Receipt)',
        copy5ExtraCopy: 'Copy 5 (Extra Copy)',
        copy6ExtraCopy: 'Copy 6 (Extra Copy)',
        copy7ExtraCopy: 'Copy 7 (Extra Copy)',
        copy8ForAgent: 'Copy 8 (for Agent)',
        extraCopies: 'Extra copies',
        downloadPdf: 'Download PDF',
    },
    awb: {
        awbListHeader: 'AirWaybill list',
        cargoInfo: 'Cargo info',
        invalid: 'Invalid',
        unableToManageHawbDueToAwbInDraft:
            'Unable to manage House AirWaybills because of the Master AirWaybill draft status.',
        hawbNumber: 'Number',
        agentName: 'Agent Name',
        agentCity: 'Agent city',
        awb: 'AirWaybill',
        draft: 'Requires validation',
        hawb: 'House AirWaybill',
        fwb: 'FWB',
        fhl: 'FHL',
        dateCreated: 'Created',
        lastUpdate: 'Last update',
        backToView: 'Back to view',
        createNewEmptyAwb: 'Create new empty AWB',
        createNewEmptyHawb: 'Create new empty House AWB',
        recognizeAwbFromFile: 'Recognize AWB from file',
        recognizeHawbFromFile: 'Recognize House AWB from file',
        importFromFwb: 'Import from FWB',
        importFromFhl: 'Import from FHL',
        sendFhl: 'Send FHL',
        sendFwb: 'Send FWB',
        createNewAwb: 'Create new Air Waybill',
        createNewHawb: 'Create new House Air Waybill',
        recognizeNewHawb: 'Recognize new House Air Waybill from file',
        editAwb: 'Edit Air Waybill',
        editHawb: 'Edit House Air Waybill',
        save: 'Save',
        uploadFileWithAwb: 'Upload file with AirWaybill',
        cantUploadFile: 'Can\'t upload file. File should have pdf, jpg or png extension.',
        recognizing: 'Recognizing...',
        recognizingSuccessful: 'Recognizing successful!',
        preview: 'Preview',
        download: 'Print to PDF',
        edit: 'Edit',
        showFwb: 'Show FWB',
        showFhl: 'Show FHL',
        copyCode: 'Copy code',
        copied: 'Copied to clipboard',
        send: 'Send',
        shipper: 'Shipper\'s Name and Address',
        consignmentDetails: 'AWB consigment details',
        hawbDetails: 'HAWB details',
        consignee: 'Consignee\'s Name and Address',
        awbNumber: 'AWB number',
        name: 'Name',
        address: 'Address',
        postCode: 'Postal code',
        contact: 'Contact',
        rawData: 'Raw address',
        nameAndAddress: 'Name and address',
        departure: 'Departure',
        issuer: 'Issuer',
        issuedBy: 'Issued by',
        accountingInformation: 'Accounting information',
        details: 'Details',
        issuingCarriersAgent: 'Issuing Carrier\'s Agent Name and City',
        nameAndCity: 'Name and city',
        iataCode: 'Agent\'s IATA Code',
        accountNo: 'Account №',
        routingAndFlightBookings: 'Airport of Departure (Address of First Carrier) and Requested Routing',
        shipmentReferenceInformation: 'Optional shipping information',
        referenceNumber: 'Reference number',
        route: 'Route',
        to: 'To',
        carrier: 'Carrier',
        byFirstCarrier: 'By first carrier',
        by: 'By',
        destinationAirport: 'Destination airport',
        flightDate: 'Flight/Date',
        chargesDeclaration: 'Declared Value& Currency',
        currency: 'Currency',
        valueForCarriage: 'Declared Value for Carriage (number or NVD)',
        valueForCustoms: 'Declared Value for Customs (number or NCV)',
        amountOfInsurance: 'Amount of Insurance (number or XXX)',
        other: 'Other',
        handlingInformation: 'SCI',
        requirements: 'Requirements',
        rateDescription: 'Rate description',
        pieces: 'Pieces',
        grossWeight: 'Gross w.',
        kl: 'K/L',
        rc: 'RC',
        itemNo: 'Item no.',
        rateToCharge: 'Rate/Charge',
        total: 'Total',
        natureAndQuantity: 'Nature and quantity',
        prepaid: 'Prepaid',
        collect: 'Collect',
        totalPrepaid: 'Total Prepaid',
        totalCollect: 'Total Collect',
        chargesSummary: 'Charges summary',
        valuationCharge: 'Valuation charge',
        chargeableWeight: 'Weight Charge',
        tax: 'Tax',
        otherChargesDueAgent: 'Other charges due agent',
        otherChargesDueCarrier: 'Other charges due carrier',
        otherCharges: 'Other charges',
        description: 'Description',
        carriersExecution: 'Carrier\'s execution',
        date: 'Date',
        place: 'Place',
        signature: 'Signature',
        create: 'Create',
        cancel: 'Cancel',
        apply: 'Apply',
        parsed: "Parsed",
        importFromFwbCode: 'Import',
        stateProvince: 'State/Province',
        oci: 'OCI',
        country: 'Country',
        info: 'Info',
        customCode: 'Custom',
        note: 'Note',
        fwbSent: 'FWB sent',
        fhlSent: 'FHL sent',
        replaceField: 'Replace',
        skipField: 'Skip',
        confirmReplace: 'Do you want to replace text to {{text}}?',
        list: {
            hasFMA: 'FMA',
            number: 'Air Waybill number',
            physicalWeight: 'Physical Weight',
            paidWeight: 'Paid Weight',
            departureDate: 'Departure Date',
            awbDate: 'AWB Date',
            currency: 'Currency',
            departureAirport: 'Departure',
            arrivalAirport: 'Arrival',
            departureAirportSearch: 'Departure airport',
            arrivalAirportSearch: 'Arrival airport',
            airline: 'Airline',
        },
        'custom': 'Custom',
        'unknown': 'Unknown',
        'accountNumber': 'Account number',
        'tokenType': {
            'Unknown': 'Skip',
            'Address': 'Address',
            'Street': 'Address',
            'House': 'Address',
            'Office': 'Address',
            'Place': 'Place',
            'PostalCode': 'Postal Code',
            'Region': 'Address',
            'Country': 'Address',
            'Name': 'Name',
            'AccountNumber': 'Account Number',
            'Phone': 'Contact',
        },
        alsoNotify: 'Notify',
        parsing: 'Parsing address. Wait, please.',
        findAddress: 'Found recognized addressed for fields:',
        confirmAddressReplacing: 'Do you want to replace fields\' values with found? Don\'t forget to save form after replacing.',
        replace: 'Replace',
        findSavedData: 'Saved data found',
        hasErrors: 'Form has error(s)',
        delete: 'Delete',
        confirmDelete: 'Are you sure to delete this house airWaybill?',
        deletedTemplate: 'The house airWaybill "{{awbNumber}}" was deleted successfully',
        setUnknownCity: 'Use "{{city}}" value as city.',
        itemType: 'cities',
        extraInfo: 'Additional information',
        dueAgent: 'Due agent',
        dueCode: 'Code',
        dueAmount: 'Amount',
        mergeWarning: 'Position will be merged with existing one',
        fwbVersion: 'FWB version:',
        weightValue: 'Weight/Value',
        recipientAccountTitle: 'TIC',
        recipientAllowEmptyAccountNumber: 'No TIC',
        attachOrganizationDocuments: 'Attach your organization\'s documents',
        shcDetails: 'Special handling details',
        eAwb: 'eAwb',
        security: 'Security',
        notSpecified: 'Not specified',
        chgs: 'Available values: СС, PP',
        savingChanges: 'Saving changes...',
    },
    customerApplicationEventLog: {
        title: 'Event log',
        timestamp: 'Date',
        userDisplayName: 'User',
        organizationName: 'Organization',
        entity: 'Item',
        event: 'Event',
        noEventsFound: 'No events found.',
        customerApplication: 'Booking request',
        masterAirwaybill: 'Master AirWaybill',
        houseAirwaybill: 'House AirWaybill',
        importedFromImage: 'Uploaded from image',
        importedFromCode: 'Imported from code',
        fwbSend: 'Send FWB',
    },
    notifications: {
        texts: {
            plural: 'Notifications',
        },
        settingsDescription: 'Configure notifications',
    },
    eventGroups: 'Event groups',
    eventGroupType: {
        Registration: 'Registration',
        PersonalArea: 'Personal area',
        Requests: 'Requests',
        Applications: 'Applications',
    },
    eventTypes: 'Event types',
    languages: 'Languages',
    personalAreaEventType: {
        OrganizationUpdated: 'Organization updated',
        ContactCreated: 'Created',
        ContactUpdated: 'Updated',
        CanLoginFlagChanged: 'Can login flag changed',
        ActivationFlagChanged: 'Activation/deactivation',
    },
    customerApplicationEventType: {
        Created: 'Created',
        Updated: 'Updated',
        AwbCreated: 'MAWB/HAWB created',
        AwbRemoved: 'MAWB/HAWB removed',
        AwbUpdated: 'MAWB/HAWB updated',
        AwbImportedFromFile: 'MAWB/HAWB imported from image',
        AwbImportedFromCode: 'MAWB/HAWB imported from code',
        FwbSended: 'FWB sended',
        BookingRequested: 'Booking requested',
    },
    customerRequestEventType: {
        Created: 'Created',
        QuotationRequested: 'Quotation requested',
        DiscountRequested: 'Discount requested',
        ErrorDuringRequest: 'Error during request',
    },
    registrationEventType: {
        Registered: 'Registered',
        AccreditationInfoUpdated: 'Updated accreditation info',
    },
    isAccreditationListFilled: 'Accred. list filled',
    userAgreement: {
        header: 'Пользовательское соглашение',
        checkBoxText: 'I have read and agree to the terms of the user agreement',
        buttonText: 'Ok',
    },
    pageNotFound: '404 page not found',
    location: 'City/Airport',
    SingleDatePickerPhrases: {
        calendarLabel: 'Date',
        hours: 'hours',
        minutes: 'minutes',
    },
    trackHistory: {
        dataNotAvailable: 'Data is unavailable',
        youCanTrackOnlyCargoBooking365: 'You can only track shipments made on CargoBooking365.com',
        youCanTrackOnlyOrgCargoes: 'You can only track shipments made by employees of your organization',
        noData: 'No traking data',
        trackDataWillBeLater: 'Your booking tracking information will be available at a later date.',
        externalServiceError: 'No data from external service. Please try again later',
        requestInfo: 'Request information',
        searchExecuting: 'Search in progress',
        enterAwbNumber: 'Enter air waybill number',
        dataResponseError: 'Error receiving data',
        resetSearch: 'Reset',
        currentStatus: 'Current status',
        title: 'Tracking',
        summary: 'Summary',
        routes: 'Routes',
        details: 'Details',
        latestInfo: 'Latest information',
        origin: 'Origin',
        destination: 'Destination',
        flightNo: 'Flight number',
        flightDate: 'Flight date',
        segment: 'Segment',
        confirmed: 'Confirmed',
        pieces: '{{segment.pieces}} piece(s)',
        segmentWeight: '{{segment.weight}} kg',
        weight: 'Weight',
        codes: {
            ACC: 'Accepted',
            AST: 'Assigned to another flight',
            ARR: 'Arrived',
            ARE: 'Arrival estimated',
            DLV: 'Delivered',
            DDL: 'Documents Delivered',
            RCV: 'Received',
            DEP: 'Departed',
            MAN: 'Manifested',
            BKC: 'Booking Confirmed',
            BKD: 'Booked',
            BKG: 'Booking Generated',
            RCS: 'Received from Shipper',
            RCF: 'Received from Flight',
            NFD: 'Consignee Notified',
            FOH: 'Freight on hand',
            AWD: 'Documentation Delivered',
            CLD: 'Cargo Loaded',
            PRE: 'Shipment Prepared',
            ARV: 'Arrived',
            FWB: 'Electronic AWB',
            TRA: 'In Transit',
            AWR: 'Documents Received',
            TFD: 'Transferred',
            RCT: 'Received from other airline',
            SCW: 'Shipment Checked Into Warehouse',
            CLC: 'Cleared by Customs',
            TPL: 'Temperature Log',
            PIC: 'Available for pickup',
            SOH: 'Shipment on hold',
            PDD: 'Pre-declaration is done',
            MCC: 'Matching cancelled',
            DCD: 'Documentation check is done',
        },
    },
    zohoBooking: {
        Order: 'Book a call',
        Back: 'Back to select a timeslot',
        Messages: {
            'Invalid phone_number': 'Invalid phone number',
            'Invalid email': 'Invalid email address',
            'invalid_time_zone': 'Invalid time zone',
            'general_error': 'Invalid form. Check data please',
            'slot not available': 'Slot not available',
        },
    },
    oci: {
        emptyForm: 'Enter at least one of the fields: Country, Info or Custom'
    },
    inputPhone: {
        extLabel: 'ext.'
    }
};

export default common;