import React, { useEffect, useState } from 'react';
import { components, OptionProps } from 'react-select';
import styles from './Select.module.scss';
import Async from 'react-select/async';
import SelectStyles from '@components/select/components/SelectStyles';
import DropdownIndicator from '@components/select/components/DropdownIndicator';
import { LocationDto, LocationType } from '@models/locations';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { useDebouncedCallback } from "use-debounce";
import Tippy from "@tippyjs/react";
import commonStyles from "@assets/styles/common.module.scss";
import cn from 'classnames';

export type Props = {
	onChange(locations: LocationDto[]): void;
	selectedOptions?: LocationDto[];
	loadingMessage?: string;
	noOptionsMessage?: string;
	placeholder?: string;
	fetch: (term: string) => Promise<AxiosResponse<LocationDto[]>>;
	isMulti: boolean;
	initializeWithEmptyTerm?: boolean;
	name?: string | string[];
	isClearable: boolean;
	onTermChanged?: (term: string) => void;
	onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
	required?: boolean;
	disabled?: boolean;
	label?: string;
    isOptionDisabled?: (option: LocationOption) => boolean;
}

export type State = {
	isFetching: boolean;
	availableOptions: LocationDto[];
}

const getLocationLabel = (location: LocationOption): JSX.Element => {

	if (!location) {
		return null;
	}

	const text = [];

	let selectIconClassName = `${styles.selectIcon}`;
	if (location.type == LocationType.Airport) {
		selectIconClassName += ' icon-airport';
	} else {
		selectIconClassName += ' icon-globe';
	}

	//TODO: Warning: Each child in a list should have a unique "key" prop. Check the render method of `SingleValue`. See https://fb.me/react-warning-keys for more information. in i (at LocationSelect.tsx:50)
	// City or airport.
	let index = 0;
	text.push(<i key={index ++} className={styles.selectCaption}>{location.codeIata}</i>);
	text.push(<b key={index ++}>{location.name}</b>);

	text.push(', ');

	// City or country.
	if (location?.parent?.name != null) {
		text.push(location?.parent?.name);
	}

	// Country.
	if (location?.parent?.parent?.name != null) {
		text.push(', ');
		text.push(location?.parent?.parent?.name);
	}

	return <span className={clsx(styles.selectLabel)}><i className={selectIconClassName}/><span
		className={styles.selectText}>{text}</span></span>;
};

const MultiValue = props => {
	return (
		components.MultiValue && (
			<components.MultiValue {...props}>
				{getLocationLabel(props.data)}
			</components.MultiValue>
		)
	);
};

const SingleValue = props => {
	return (
		components.SingleValue && (
			<components.SingleValue {...props}>
				{getLocationLabel(props.data)}
			</components.SingleValue>
		)
	);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Option = ({...props}: OptionProps<LocationOption>) => {

	const className = clsx(
		props.className,
		props.data.isChildLabel ? styles.childLabel : null,
		props.data.isParentLabel ? styles.parentLabel : null,
	);

	return (
		components.Option && (
			<components.Option {...props} className={className}>
				{getLocationLabel(props.data)}
			</components.Option>
		)
	);
};

/* Normalize value for output. */
const nOutput = (val: LocationDto[] | LocationDto): LocationDto[] => {

	if (val == null) {
		val = [];
	}

	if (!Array.isArray(val)) {
		val = [ val ];
	}

	return val;
};


/* Normalize value for input. */
const nInput = (isMulti: boolean, val: LocationDto[] | LocationDto): LocationDto[] | LocationDto => {

	if (isMulti) {

		if (val == null) {
			return [];
		}

		if (!Array.isArray(val)) {
			val = [ val ];
		}

		return val;
	}

	if (Array.isArray(val)) {
		if (val.length > 0) {
			return val[0];
		} else {
			return [];
		}
	}

	if (val == null) {
		return [];
	}

	return val;
};

export type LocationOption = LocationDto & { isChildLabel?: boolean; isParentLabel?: boolean };

const LocationSelect = (props: Props) => {

	const [ isFetching, setFetching ] = useState(props.initializeWithEmptyTerm);
	const [ availableOptions, setAvailableOptions ] = useState<LocationOption[]>([]);

	let names: string[] = [];

	if (props.name) {
		if (Array.isArray(props.name)) {
			names = props.name;
		} else {
			names = [ props.name ];
		}
	}

	const search = async (term?: string): Promise<LocationOption[]> => {

		setFetching(true);

		const {data} = await props.fetch(term || '');

		const locations: LocationDto[] = data || [];

		setFetching(false);

		const result: LocationOption[] = [];

		locations.forEach(x => {
			if (x.children && x.children.length > 0) {
				result.push({...x, isParentLabel: true});
				const children = x.children.map(x => {
					return {...x, isChildLabel: true};
				});
				result.push(...children);
			} else {
				result.push(x);
			}
		});

		return result;
	};

	const searchDebounced = useDebouncedCallback((term, callback) => {
		search(term).then(x => {
			setAvailableOptions(x);
			callback(x);
		})
	}, 500);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const getInputValue = (val) => {
		return JSON.stringify(nInput(props.isMulti, props.selectedOptions));
	};

	useEffect(() => {
		if (props.initializeWithEmptyTerm) {
			search();
		}
	}, []);

	return <Tippy
		content={props.placeholder}
		placement={'left'}
		trigger={'focusin'}
	>
		<div className={cn(commonStyles.flexColumn)}>
			{props.label && <span>{props.label}</span>}
			<Async
                isDisabled={props.disabled}
				className={props.required == true ? 'select-required' : null}
				isClearable={props.isClearable}
				components={{DropdownIndicator, Option, MultiValue, SingleValue}}
				styles={{...SelectStyles, singleValue: p => ({...p, paddingLeft: 0})}}
				isLoading={isFetching}
				options={availableOptions}
				value={nInput(props.isMulti, props.selectedOptions)}
				isMulti={props.isMulti}
				getOptionValue={option => option.id}
                placeholder={props.placeholder}
				loadOptions={(term, cb) => {
					if (props.onTermChanged) {
						props.onTermChanged(term);
					}

					if (term != null && term.length >= 3) {
						searchDebounced(term, cb);
					} else {
						//cb([]);
					}

				}}
				defaultOptions={availableOptions || []}
				loadingMessage={() => props.loadingMessage}
				noOptionsMessage={() => props.noOptionsMessage}
				onChange={selectedOptions => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const val = nOutput(selectedOptions as any);
					props.onChange(val);
					if (names?.length > 0) {
						const inputs = Array
							.from(document.querySelectorAll("." + names.join(".")));
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						inputs.forEach(x => (x as any).click());
					}
				}}
				onBlur={e => props?.onBlur && props?.onBlur(e)}
				onFocus={e => props?.onFocus && props?.onFocus(e)}
                isOptionDisabled={props.isOptionDisabled}
			/>
			{
				props.name ?
					names.map((x, i) => <input
						key={i}
						className={clsx('invisible', ...names)}
						defaultValue={getInputValue(props.selectedOptions) || ""}
						name={x}
					/>)
					: null
			}
		</div>
	</Tippy>;

};

export default LocationSelect;