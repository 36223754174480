import clsx from 'clsx';
import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import nameof from 'ts-nameof.macro';
import { AirwaybillDto, OciModel } from '@models/awbs/awbsModels';
import { useTranslation } from 'react-i18next';
import CargoInfoEditModal from '@scenes/customerApplication/awb/components/CargoInfoEditModal';
import { useAppDispatch, useAppSelector } from '@root/store';
import { fetchCountries } from '@store/countryStore';
import { TableWithForm } from '@scenes/customerApplication/awb/components/TableWithForm';
import { CountrySelect } from '@scenes/customerApplication/awb/components/CountrySelect';

interface Props {
    disabled?: boolean;
}

export const QuantityTable = (props: Props) => {
    const [isCargoEditorOpen, setIsCargoEditorOpen] = useState(false);
    const { allCountries } = useAppSelector(x => x.allCountries);

    const [field] = useField<OciModel[]>(nameof<AirwaybillDto>(x => x.ociList));

    const {
        values,
        setFieldValue
    } = useFormikContext<AirwaybillDto>();

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (allCountries?.length == 0) {
            dispatch(fetchCountries());
        }
    }, []);

    return <>
        <table className={clsx('table-app', styles.tableApp)}>
            <thead>
            <tr>
                <th>{t('awb.natureAndQuantity')}</th>
                <th>{t('awb.oci')}</th>
            </tr>
            </thead>
            <colgroup>
                <col width={'50%'} />
                <col />
            </colgroup>
            <tbody>
            <tr>
                <td style={{ verticalAlign: 'top' }}>
                    <div
                        id={'cargoDescription'}
                        className={styles.cargoDescription}
                    >
                        <div
                            className={clsx(styles.dummyTextarea, styles.textareaWithData)}
                            {...{ 'data-tippy-content': t('awb.natureAndQuantity') }}
                        >
                                      {values?.cargoDescription}
                                          {values?.cargoDimensions?.map(x => `\n${x.length}x${x.width}x${x.height}CM/${x.amount} ${x.weight > 0 ? x.weight + 'K' : ''}`)}
                                          {values?.harmonizedCommodityCodes?.length > 0 ? '\n\nHCC ' + values?.harmonizedCommodityCodes?.join(' ') : null}
                        </div>
                        <div className={styles.buttonContainer}>
                            <button
                                disabled={props.disabled}
                                className={clsx('btn btn-primary', styles.buttonChange)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsCargoEditorOpen(true);
                                }}>{t((values?.cargoDescription?.length || values?.cargoDimensions?.length || values?.harmonizedCommodityCodes?.length ? 'awb.edit' : 'add'))}</button>
                        </div>

                    </div>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                    <div>
                        <TableWithForm
                            disabled={props.disabled}
                            formTitle={t('awb.oci')}
                            fieldName={nameof<AirwaybillDto>(x => x.ociList)}
                            columns={[{
                                title: t('awb.country'),
                                getValue: (x) => {
                                    return allCountries.find(c => c.id == x.countryId)?.codeIso2;
                                },
                                formField: (value, setValue) =>
                                    <CountrySelect countryId={value?.countryId} onChange={country => {
                                        setValue({ ...value, countryId: country?.id });
                                    }} />
                            },
                                {
                                    title: t('awb.info'),
                                    getValue: (x) => x.infoCode,
                                    formField: (value, setValue) => {
                                        if (value == null) {
                                            return null;
                                        }

                                        const showInfoCode = value.infoCode != 'SHP' && value.infoCode != 'CNE' && value.infoCode != 'NFY';

                                        return <div style={{ display: 'flex', gap: '10px' }}>
                                            <select
                                                className={'form-control'}
                                                value={showInfoCode ? '' : value.infoCode}
                                                onChange={e => {
                                                    setValue({ ...value, infoCode: e.target.value });
                                                }}>
                                                <option value="SHP">SHP - Shipper</option>
                                                <option value="CNE">CNE - Consignee</option>
                                                <option value="NFY">NFY - Also notify</option>
                                                <option value="">Other (specify)</option>
                                            </select>
                                            {showInfoCode &&
                                                <input className={'form-control'} type={'text'} value={value.infoCode}
                                                       onChange={(e) => {
                                                           setValue({ ...value, infoCode: e.target.value });
                                                       }} />}
                                        </div>;
                                    }
                                },
                                {
                                    title: t('awb.custom'),
                                    getValue: (x) => x.customCode,
                                    formField: (value, setValue) => {
                                        if (value == null) {
                                            return null;
                                        }

                                        const showCustomCode = value.customCode != 'T' && value.customCode != 'CP' && value.customCode != 'CT';

                                        return <div style={{ display: 'flex', gap: '10px' }}>
                                            <select
                                                className={'form-control'}
                                                value={showCustomCode ? '' : value.customCode}
                                                onChange={e => {
                                                    setValue({ ...value, customCode: e.target.value });
                                                }}>
                                                <option value="T">T - Trader ID (tax ID)</option>
                                                <option value="CP">CP - Contact person</option>
                                                <option value="CT">CT - Contact telephone</option>
                                                <option value="">Other (specify)</option>
                                            </select>
                                            {showCustomCode &&
                                                <input className={'form-control'} type={'text'} value={value.customCode}
                                                       onChange={(e) => {
                                                           setValue({ ...value, customCode: e.target.value });
                                                       }} />}
                                        </div>;
                                    }
                                },
                                {
                                    title: t('awb.note'),
                                    getValue: (x) => x.note,
                                    formField: (value, setValue) => <input className={'form-control'} type={'text'}
                                                                           value={value?.note || ''} onChange={(e) => {
                                        setValue({ ...value, note: e.target.value });
                                    }} maxLength={35} />
                                }
                            ]}

                            emptyModel={({ customCode: '', infoCode: '', note: '', countryId: '' })}

                            onDelete={valueIndex => setFieldValue(nameof<AirwaybillDto>(x => x.ociList),
                                (field.value || []).filter((v, index) => index != valueIndex))}

                            onSave={(selectedIndex, value) => {
                                let values = (field.value || []);
                                if (selectedIndex < 0) {
                                    values = [...values, value];
                                } else {
                                    values = values.map((x, idx) => idx == selectedIndex ? value : x);
                                }

                                setFieldValue(nameof<AirwaybillDto>(x => x.ociList), values);
                            }} />
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <CargoInfoEditModal
            model={{
                cargoDescription: values?.cargoDescription,
                cargoDimensions: values?.cargoDimensions,
                harmonizedCommodityCodes: values?.harmonizedCommodityCodes,
                cargoVolume: values?.cargoVolume
            }}
            onSave={(values) => {
                setFieldValue(nameof<AirwaybillDto>(x => x.cargoDimensions), values.cargoDimensions);
                setFieldValue(nameof<AirwaybillDto>(x => x.cargoDescription), values.cargoDescription);
                setFieldValue(nameof<AirwaybillDto>(x => x.harmonizedCommodityCodes), values.harmonizedCommodityCodes);
                setFieldValue(nameof<AirwaybillDto>(x => x.cargoVolume), values.cargoVolume);
                setIsCargoEditorOpen(false);
            }}
            onCancel={() => {
                setIsCargoEditorOpen(false);
            }}
            isOpen={isCargoEditorOpen}
        />
    </>;
};