import AppModal from '@components/AppModal';
import styles from './index.module.scss';
import { Button } from 'reactstrap';
import React, { useState } from 'react';
import { GetSpecialHandlingCodeDto } from '@models/cargoes';
import { useTranslation } from 'react-i18next';

interface IProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    otherShCodes: GetSpecialHandlingCodeDto[];
    selectedShcIds: string[];
    onChange: (selectedIds: string[]) => void;
}

export const OtherShcModal = ({isOpen, setIsOpen, otherShCodes, selectedShcIds, onChange}: IProps) => {
    const [selectedIds, setSelectedIds] = useState<string[]>(selectedShcIds || []);
    const {t} = useTranslation();

    const toggleStaticCode = (shcId: string, isSelected: boolean) => {
        const shcCodes = selectedIds.filter((id: string) => shcId != id) || [];
        if (isSelected) {
            shcCodes.push(shcId);
        }

        setSelectedIds(shcCodes);
    };

    return <AppModal
        isOpen={isOpen}
        onClickCloseButton={() => setIsOpen(false)}
        body={
            <div>
                <table>
                    <thead>
                    <tr>
                        <th />
                        <th>Код</th>
                        <th>Описание</th>
                    </tr>
                    </thead>
                </table>
                <div className={styles.otherCodesTable}>
                    <table>
                        <tbody>
                        {otherShCodes
                            .map(shc => <tr
                                onClick={e => toggleStaticCode(shc.id, !selectedIds.includes(shc.id))}
                                key={shc.code}>
                                <td>
                                    <label className={styles.checkboxContainer}>
                                        <input
                                            type={'checkbox'}
                                            checked={selectedIds.includes(shc.id)}
                                            readOnly
                                        />
                                        <div className={styles.checkboxMarker} />
                                    </label>
                                </td>
                                <td>{shc.code}</td>
                                <td>{shc.name}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        }
        footer={<div className={styles.footer}>
            <Button
                onClick={() => setIsOpen(false)}
            >
                {t('cancel')}
            </Button>
            <Button
                onClick={() => {
                    setIsOpen(false);
                    onChange(selectedIds);
                }}
            >
                {t('select')}
            </Button>
        </div>
        } />;

};