import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Port } from "../models/Port";
import { Plane } from "../models/Plane";
import { Util } from "../models/utility";

const AirplaneAnimation = (): JSX.Element => {

    const cw = 1000;
    const ch = 500;
    const dimAvg = (cw + ch ) / 4;
    const opt = {
        portCount: 12,
        planeCount: 60,
        portSpacingDist: dimAvg / 15,
        holdingDist: 5,
        approachDist: 80,
        planeDist: 20,
        pathSpacing: 25,
        pathCount: 40,
        avoidRadius: 20,
        avoidMult: 0.025
    };

    const [ timeState, setTimeState ] = useState({
        lt: Date.now(),
        dt: 1,
        et: 0,
        tick: 0
    });

    const [ ports, setPorts ] = useState([] as Port[]);
    const [ planes, setPlanes ] = useState([] as Plane[]);

    // eslint-disable-next-line prefer-const
    let canvasRef = useRef(null);

    const draw = () => {
        // requestAnimFrame( $.step );

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        // clear
        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillStyle = 'hsla(0, 0%, 0%, 1)';
        ctx.fillRect( 0, 0, cw, ch );
        ctx.globalCompositeOperation = 'lighter';

        // collections
        let i: number;
        i = ports.length; while( i-- ) { ports[ i ].update( i, planes ); }
        i = planes.length; while( i-- ) { planes[ i ].update( i, timeState.tick, opt, timeState.dt, cw, ch, ports ); }
        i = ports.length; while( i-- ) { ports[ i ].render( i, timeState.et, ctx ); }
        i = planes.length; while( i-- ) { planes[ i ].render( i, opt, ctx, timeState.tick ); }

        // delta
        const now = Date.now();
        setTimeState(prev => {
            const _dt = Util.clamp( ( now - prev.lt ) / ( 1000 / 60 ), 0.001, 10 );

            return {
                lt: now,
                dt: _dt,
                et: prev.et + _dt,
                tick: prev.tick + 1
            };
        });
    };

    useEffect(() => {
        const _ports: Port[] = Array(opt.portCount).fill(undefined)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(_ => new Port(cw, ch));

        // _ports.forEach(p => p.init(cw, ch, _ports, opt.portSpacingDist));

        const _planes: Plane[] = Array(opt.planeCount).fill(undefined)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(_ => new Plane(_ports));

        setPorts(_ports);
        setPlanes(_planes);
    }, []);

    useEffect(() => {
        const animationFrameId: number = window.requestAnimationFrame(draw);

        return () => {
            window.cancelAnimationFrame(animationFrameId);
        };
    }, [draw]);

    return (
        <canvas ref={canvasRef} width={cw} height={ch} />
    );
};

export default connect()(AirplaneAnimation);
