import {IUser} from '@models/accounts/IUser';
import { ContactPermissionType } from '@models/contacts/ContactPermissionType';

export default class User implements IUser {

    public id: string = null;
    public userName: string = null;
    public refreshToken: string = null;
    public token: string = null;
    /* Token expiration UTC date. */
    public expiration: string;
    public email: string = null;
    public roles: string[] = [];
	public contactId: string;
	public organizationId: string;
	public permissions: ContactPermissionType[];

    public get initials(): string {

        if (!this.userName) {
            return null;
        }

        return this.userName
            .split(' ')
            .map((name) => name[0])
            .join('');
    }

    public hasAnyRole(...roles: string[]): boolean {
        if(roles == null || roles.length === 0){
            return false;
        }
        for (let i = 0; i < roles.length; i++){

            if(this.roles.indexOf(roles[i]) > -1){
                return true;
            }
        }
        return false;
    }

	public hasPermissions(...permissions: ContactPermissionType[]): boolean {
		if(permissions == null || permissions.length === 0){
			return false;
		}

		if(this.permissions?.includes(ContactPermissionType.All)){
			return true;
		}

		for (let i = 0; i < permissions.length; i++){
			if(this.permissions?.indexOf(permissions[i]) > -1){
				return true;
			}
		}

		return false;
	}

    public static create(json: IUser): User {
        return Object.assign(new User(), json);
    }
}