export enum BookingStatus {
  NN = 557050000,
  KK = 557050001,
  LL = 557050002,
  CN = 557050003,
}

export enum SendingStatus
{
    None = 0,
    BKD = 557050000,
    HLD = 557050001,
    WAT = 557050002,
    XXX = 557050003,
    FBL = 557050004,
    DEP = 557050005,
    RCF = 557050006,
    DLV = 557050007,
    ARR = 557050008,
    MAN = 557050009,
    RCS = 557050010,
    NFD = 557050011,
    FOH = 557050012,
    AWD = 557050013,
    PRE = 557050014,
    AWR = 557050015
}

export enum Codes {
    BKD = 'BKD',
    CLD = 'CLD',
    DEP = 'DEP',
    TRA = 'TRA',
    RCT = 'RCT',
    ARR = 'ARR',
    ARV = 'ARV',
    ARE = 'ARE',
    DLV = 'DLV',
    RCV = 'RCV'
}