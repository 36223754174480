import axios, { AxiosRequestConfig } from 'axios';
import i18n from '../i18n';
import SessionManager from '@root/SessionManager';
import { RefreshTokenDto } from '@models/accounts/accountModels';
import AccountService from '@services/AccountService';
import { IUser } from '@models/accounts/IUser';
import { LOGIN_REDIRECT_URL } from '@config/ApiAuthorizationConstants';
import { tryGetExceptionWithCode } from '@helpers/stringHelpers';

const AuthorizedHttpClient = axios.create();

const refreshTokenAndUpdateUser = async (): Promise<RefreshTokenDto> => {
	if (!SessionManager.user?.token == null || !SessionManager.user?.refreshToken == null) {
		return null;
	}

	try {
		const { data } = await new AccountService().refreshToken(
			SessionManager.user.token,
			SessionManager.user.refreshToken
		);

		const u = { ...SessionManager.user };

		u.token = data.token;
		u.refreshToken = data.refreshToken;
		u.expiration = data.expiration;
		u.roles = data.roles;
		u.permissions = data.permissions;

		SessionManager.setUserAsync(u as IUser);

		return data;
	} catch (error) {
		SessionManager.clearUser();
		return null;
	}
};

const setHeaders = (config: AxiosRequestConfig, token: string): void => {
	config.headers['Authorization'] = 'Bearer ' + token;
	config.headers['Content-Type'] = 'application/json';
	config.headers['Accept-Language'] = i18n.language;
};

let syncContext: Promise<RefreshTokenDto> = null;

// Sync timeout.
setInterval(() => {
	syncContext = null;
}, 10000);

AuthorizedHttpClient.interceptors.request.use(
	(config) => {
		if (!SessionManager.isAuthenticated()) {
			window.location.replace(LOGIN_REDIRECT_URL);
			return config;
		}

		if (SessionManager.isExpired()) {
			return (syncContext || (syncContext = refreshTokenAndUpdateUser())).then((x) => {
				if (x == null) {
					window.location.replace(LOGIN_REDIRECT_URL);
					return config;
				}

				setHeaders(config, x.token);
				return config;
			});
		}

		setHeaders(config, SessionManager.user.token);
		return config;
	},
	(error) => {
		const exceptionWithCode = tryGetExceptionWithCode(error?.response?.data?.message);
		if (exceptionWithCode) {
			const e = { ...error };
			e.response.data.message = i18n.t(exceptionWithCode.i18nPath, exceptionWithCode.args);
			return Promise.reject(e);
		}
		return Promise.reject(error);
	}
);

AuthorizedHttpClient.interceptors.response.use(
	(response) => response,
	(error) => {

		const originalRequest = error.config;

		if (
			(error?.response != null && error.response.status === 401) ||
			(error.response.status === 403 && error.response.headers['token-expired'])
		) {
			originalRequest._retry = true;

			if (SessionManager.user?.token == null) {
				return Promise.reject(error);
			}

			return (syncContext || (syncContext = refreshTokenAndUpdateUser())).then((x) => {
				if (x == null) {
					window.location.replace(LOGIN_REDIRECT_URL);
					return Promise.reject(error);
				}

				return AuthorizedHttpClient(originalRequest);
			});
		}

		const e = { ...error };

		if(typeof e.response.data == "string"){
			e.response.data = { message: e.response.data, isError: true };
		}else{
			e.response.data = { ...(e.response.data || {}), isError: true };
		}

		const exceptionWithCode = tryGetExceptionWithCode(error.response.data?.message);
		if (exceptionWithCode) {
			e.response.data.message = i18n.t(exceptionWithCode.i18nPath, exceptionWithCode.args);
		}

		return Promise.reject(e.response.data);
	}
);

export const apiHandleErrors = (response) => {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
};

export default AuthorizedHttpClient;