export enum ContactPermissionType {
	None = 1 << 0,
	All = 1 << 1,
	UpdateOrganizationInfo = 1 << 2,
	CreateContact = 1 << 3,
	UpdateContact = 1 << 4
}

export type ContactPermissionTypeDto = {
	key: string;
	value: number;
}