import React from 'react';
import s from '../../index.module.scss';
import classNames from 'classnames';
import cn from 'classnames';
import { stepsToUse } from '@scenes/welcome/FFCargoWelcomePage/types';
import { ReactComponent as IconArrow } from '@material-design-icons/svg/round/arrow_forward.svg';
import { welcomeImages } from '@helpers/tenantsHelper';
import { Badge, Button, PageHeader } from '@root/components';
import { useHistory } from 'react-router';

export const HowStart = () => {
  const history = useHistory();

  return (
    <div className={s.howToUse}>
      <div className={s.column}>
        <h2 className={classNames(s['t-l'], s['t-center'])}>Как начать использование
          платформы</h2>
        <p className={classNames(s['text-l'], s['t-center'])}>Настоятельно рекомендуем
          связаться с нашим менеджером для
          представления функционирования платформы и демонстрации ее возможностей
        </p>
      </div>
      <div className={s.steps}>
        {stepsToUse.map((step, idx) => (
          <div key={idx} className={cn(s.step, s.cardSteps)}>
            <Badge label={`Шаг ${idx + 1}`} color='blue' />
            <div className={s['step-image']}>
              <div className={s['step-icon']}>
                <img src={welcomeImages[`step${idx + 1}`]} alt={`Шаг ${idx + 1}`} />
              </div>
            </div>
            <p className={s['text-l']}><strong>{step.highlight}</strong></p>
            {step.addSoonToken && <Badge label='Скоро' color='blue' />}
            {step.text &&
              <p className={classNames(s['step-info'], s['m-0'])}>{step.text}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};