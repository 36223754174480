import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Alert, Col, FormGroup, Label, Row } from 'reactstrap';
import Button from '@components/Button';
import { ReactComponent as IconPlane } from '@material-design-icons/svg/round/flight.svg';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import MoonIcon from '@components/MoonIcon';
import { toDDMMYYYYformat } from '@helpers/dateHelpers';
import { FormikProps } from 'formik';
import { CreateCustomerRequestModel } from '@models/customerRequests/formModels';
import Tippy from '@tippyjs/react';
import { ReactComponent as Info } from '@assets/svg/legacy/info.svg';
import { splitCode } from '@helpers/specialHandlingCodesHelpers';
import { PageHeader } from '@root/components';
import { getTotalVolume, getTotalWeight } from '@scenes/request/helpers';

type Props = {
    formikProps: FormikProps<CreateCustomerRequestModel>;
    specialHandlingCodeNames: string[];
    natureOfCargoNames: string[];
};

const RequestSummary = (props: Props): JSX.Element => {

    const { formikProps, specialHandlingCodeNames, natureOfCargoNames } = props;

    const { values } = formikProps;

    const { t } = useTranslation();
    const [isAlert, setIsAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');

    useEffect(() => {
        if (formikProps?.values?.cargoDimensions.length > 0 && (formikProps?.values?.totalWeight || formikProps?.values?.totalVolume)) {
            if (getTotalWeight(formikProps.values.cargoDimensions) !== +formikProps.values.totalWeight) {
                setIsAlert(true);
                setAlertMessage(t('request.alertMismatchData'));
            }
            if (getTotalVolume(formikProps.values.cargoDimensions) !== +formikProps.values.totalVolume) {
                setIsAlert(true);
                setAlertMessage(t('request.alertMismatchDataVolume'));
            }
        } else {
            setIsAlert(false);
            setAlertMessage('');
        }
    }, [formikProps?.values?.cargoDimensions, formikProps?.values?.totalWeight, formikProps?.values?.totalVolume]);

    useEffect(() => {
        formikProps.validateForm();
    }, [formikProps.values]);

    const specialHandlingCodes = useMemo(() => {

        if (specialHandlingCodeNames == null ||
            specialHandlingCodeNames.length == 0) {
            return [];
        }

        return specialHandlingCodeNames.map((x, i) => {
            const c = splitCode(x);
            return <span key={i} className={styles.shc}>
				{c.code} <Tippy content={c.name}>
				<i style={{ cursor: 'pointer' }}>
					{' '}<Info />
				</i>
			</Tippy>
			</span>;
        });

    }, [specialHandlingCodeNames]);

    return (
        <div className={styles.summaryContainer}>
            <Row form className='mb-3'>
                <Col md={3}>
                    <PageHeader title={t('request.total')} size='subHeader' />
                </Col>
                <Col className={styles.routeDirection}>
                    {values.fromCodeIata}
                    {(values.fromCodeIata && values.toCodeIata) && (
                        <MoonIcon
                            icon='icon-arrow-right'
                            className='moon-1x ml-2 mr-2'
                        />
                    )}
                    {values.toCodeIata}
                </Col>
            </Row>
            <FormGroup>
                <Label for='packagePlaces' className={styles.label}>
                    {t('request.placesNo')}
                </Label>
                <Label id='packagePlaces' className={styles.value}>
                    {values.totalPlaces || '-'}
                </Label>
            </FormGroup>
            <Row form className='mb-3'>
                <Col md={5}>
                    <Label
                        for='cargoWeight'
                        className={`${styles.label} ${styles.avoidBreakLine}`}
                    >
                        {t('request.cargoWeight')}
                    </Label>
                    <Label id='cargoWeight' className={styles.value}>
                        {values.totalWeight} {t('units.kg')}
                    </Label>
                </Col>
                <Col>
                    <Label
                        for='cargoVolume'
                        className={`${styles.label} ${styles.avoidBreakLine}`}
                    >
                        {t('request.cargoVolume')}
                    </Label>
                    <Label id='cargoVolume' className={styles.value}>
                        {values.totalVolume} {t('units.m3')}
                    </Label>
                </Col>
            </Row>
            <FormGroup>
                <Label for='natureOfCargo' className={styles.label}>
                    {t('request.natureOfCargo')}
                </Label>
                <Label id='natureOfCargo' className={styles.value}>
                    {natureOfCargoNames.length > 0 ? natureOfCargoNames[0] : '-'}
                </Label>
            </FormGroup>
            <FormGroup>
                <Label for='cargoType' className={styles.label}>
                    {t('shc.shc')}
                </Label>
                <Label id='cargoType' className={styles.value}>
                    {specialHandlingCodes.length > 0 ? specialHandlingCodes : '-'}
                </Label>
            </FormGroup>
            <FormGroup>
                <Label for='plannedDepartDate' className={styles.label}>
                    {t('request.plannedDepartDate')}
                </Label>
                <Label id='plannedDepartDate' className={styles.value}>
                    {values.dateStartPlan != null ? toDDMMYYYYformat(new Date(values.dateStartPlan)) : '-'}
                </Label>
            </FormGroup>
            {isAlert &&
                <Alert color='danger' className={styles.alert}>
                    {alertMessage}
                </Alert>
            }
            <FormGroup>
                <Button
                    icon={<IconPlane fill='currentColor' />}
                    variant='primary'
                    onClick={formikProps.handleSubmit}
                    type='fill'
                >
                    {t('request.getRoutes')}
                </Button>
            </FormGroup>
        </div>
    );
};

export default RequestSummary;
