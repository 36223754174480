import { ServiceDto } from '@models/demoBooking/ServiceDto';
import { createAppThunk } from '@helpers/reduxHelpers';
import DemoBookingService from '@services/DemoBookingService';
import { createSlice } from '@reduxjs/toolkit';

export type State = {
	services: ServiceDto[];
	isFetching: boolean;
	isFetched: boolean;
};

export const getServices = createAppThunk('demo-booking-services/get', async () => {
	const { data } = await new DemoBookingService().getServices();
	return data;
});

const slice = createSlice({
	name: 'demo-booking-services',
	initialState: {
		services: [],
		isFetching: false,
		isFetched: false
	} as State,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getServices.pending, state => {
			state.isFetching = true;
			state.isFetched = false;
			state.services = [];
		});

		builder.addCase(getServices.fulfilled, (state, action) => {
			state.isFetching = false;
			state.isFetched = true;
			state.services = action.payload;
		});

		builder.addCase(getServices.rejected, state => {
			state.isFetching = false;
			state.isFetched = false;
			state.services = [];
		});
	}
});

export const { reducer } = slice;
export const { actions } = slice;