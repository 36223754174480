import { SettingCategory, SettingsEntryDto } from '@models/admin/settingsModels';
import React, { useEffect, useState } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import Tippy from '@tippyjs/react';
import nameof from 'ts-nameof.macro';
import { useTranslation } from 'react-i18next';
import { LanguageDto } from '@models/languagesModels';
import LanguageSelect from '@components/select/LanguageSelect';
import { object, string } from "yup";
//import CodeEditor from '@uiw/react-textarea-code-editor';

type Model = SettingsEntryDto;

type Props = {
	key: string | number;
	isNew: boolean;
	model: Model;
	availableLanguages: LanguageDto[];
	onSave: (model: Model) => Promise<boolean>;
	onDelete?: (model: Model) => void;
	otherEntries: Model[];
};

enum Mode {
	New,
	Edit,
	Read,
}

const SettingsField = (props: { isBase64Image?: boolean; isEmail?: boolean; name: string; isEditable: boolean; isBigText: boolean }) => {

	const renderOtherFields = () => {
		return props.isEditable ? (
			<Field name={props.name}>
				{(fieldProps) => (
					<>
						{props.isBigText ? (
							<textarea
								className="form-control"
								rows={6}
								{...fieldProps.field}
								value={fieldProps.field.value || ''}
							/>

							//<CodeEditor
							//	value={fieldProps.field.value || ''}
							//	language="Razor"
							//	placeholder="Razor code"
							//	onChange={(e) => fieldProps.field.onChange({
							//		target: {
							//			name: fieldProps.field.name,
							//			value: e.target.value,
							//		},
							//	})}
							//	padding={15}
							//	style={{
							//		fontSize: 12,
							//		backgroundColor: "white",
							//		fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
							//	}}
							///>

						) : (
							<input
								className="form-control"
								type={'text'}
								{...fieldProps.field}
								value={fieldProps.field.value || ''}
							/>
						)}
						{fieldProps.form.errors[props.name] ? (
							<span className="validationMessage">{fieldProps.form.errors[props.name]}</span>
						) : null}
					</>
				)}
			</Field>
		) : (
			<Field name={props.name}>
				{(fieldProps) => fieldProps.field.value?.length > 300 && props.isEmail ? <i>BIG TEXT</i> : <span dangerouslySetInnerHTML={{ __html: fieldProps.field.value }} />}
			</Field>
		)
	};

	const renderSvgField = () => {
		return props.isEditable ? (
			<Field name={props.name}>
				{(fieldProps) => (
					<textarea
						className="form-control"
						rows={6}
						{...fieldProps.field}
						value={fieldProps.field.value || ''}
					/>
				)}
			</Field>
		) : (
			<Field name={props.name}>
				{(fieldProps) => <img style={{maxWidth: '220px'}} src={fieldProps.field.value} />}
			</Field>
		)
	};

	return props.isBase64Image == true ? renderSvgField() : renderOtherFields();
};

const LanguageField = (props: { name: string; availableLanguages: LanguageDto[]; isEditable: boolean }) => {
	const { t } = useTranslation();

	return (
		<Field name={props.name}>
			{(fieldProps) => {
				const language = props.availableLanguages.find((x) => x.id == fieldProps.field.value);

				return !props.isEditable ? (
					<>{language?.name || '-'}</>
				) : (
					<LanguageSelect
						availableOptions={props.availableLanguages}
						selectedOption={language}
						onChange={(x) => fieldProps.form.setFieldValue(props.name, x?.id)}
						placeholder={t('admin.settings.selectLanguage')}
						isClearable={true}
					/>
				);
			}}
		</Field>
	);
};

const SettingsEntry = ({ isNew, model, availableLanguages, onSave, onDelete, otherEntries }: Props) => {
	const [data, setData] = useState(model);
	const [mode, setMode] = useState(isNew ? Mode.New : Mode.Read);
	const [globalErrors, setGlobalErrors] = useState<string[]>([]);

	const validateUnique = (m: Model): boolean => {
		const key = m.key?.trim().toLowerCase();
		const hasSameEntry = otherEntries.find(
			(x) => x.id != m.id && x.key.toLowerCase() == key && x.languageId == m.languageId
		);
		return hasSameEntry == null;
	};

	const onClickSave = async (changedModel: SettingsEntryDto) => {
		if (validateUnique(changedModel)) {
			setGlobalErrors([]);
			const isSuccess = await onSave(changedModel);
			if (isSuccess) {
				if (!isNew) {
					setMode(Mode.Read);
					setGlobalErrors([]);
				}
			}
			return isSuccess;
		} else {
			setGlobalErrors([t('admin.settings.uniqueErrorMsg')]);
		}

		return false;
	};

	const { t } = useTranslation();

	const validationSchema = object<Partial<Model>>().shape({
		key: string().required(t('validation.required')),
		value: string().required(t('validation.required')),
	});

	const renderButtons = (formikProps: FormikProps<Model>) => {
		switch (mode) {
			case Mode.New:
				return (
					<>
						<Tippy content={t('admin.settings.save')}>
							<a
								className="btn btn-control-success"
								onClick={(e) => {
									e.preventDefault();
									formikProps.handleSubmit();
								}}
							>
								<i className="icon-save" />
							</a>
						</Tippy>
						{'  '}
						<Tippy content={t('admin.settings.erase')}>
							<a
								className="btn btn-control-danger"
								onClick={(e) => {
									e.preventDefault();
									setData({});
									formikProps.resetForm({});
									setGlobalErrors([]);
								}}
							>
								<i className="icon-minus" />
							</a>
						</Tippy>
					</>
				);
			case Mode.Edit:
				return (
					<>
						<Tippy content={t('admin.settings.save')}>
							<a
								className="btn btn-control-success"
								onClick={(e) => {
									e.preventDefault();
									formikProps.handleSubmit();
								}}
							>
								<i className="icon-save" />
							</a>
						</Tippy>
						{'  '}
						<Tippy content={t('admin.settings.cancel')}>
							<a
								className="btn btn-control-danger"
								onClick={(e) => {
									e.preventDefault();
									setMode(Mode.Read);
									setGlobalErrors([]);
								}}
							>
								<i className="icon-close" />
							</a>
						</Tippy>
					</>
				);
			case Mode.Read:
				return (
					<>
						<Tippy content={t('admin.settings.edit')}>
							<a
								className="btn btn-control-primary"
								onClick={(e) => {
									e.preventDefault();
									setMode(Mode.Edit);
								}}
							>
								<i className="icon-edit" />
							</a>
						</Tippy>
						{'  '}
						<Tippy content={t('remove')}>
							<a
								className="btn btn-control-danger"
								onClick={(e) => {
									e.preventDefault();
									onDelete(model);
								}}
							>
								<i className="icon-trash" />
							</a>
						</Tippy>
					</>
				);
		}
	};

	useEffect(() => {
		setData(model);
	}, [model]);

	return (
		<Formik
			initialValues={data}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, formikHelpers) => {
				formikHelpers.setFieldTouched(
					nameof.full<Model>((x) => x.key),
					false
				);
				formikHelpers.setFieldTouched(
					nameof.full<Model>((x) => x.languageId),
					false
				);
				const isSuccess = await onClickSave(values);
				if (isNew && isSuccess) {
					setData({});
					formikHelpers.resetForm({});
				}
			}}
		>
			{(formikProps) => {
				const globalValidatorTouched =
					formikProps.touched[nameof.full<Model>((x) => x.key)] == true ||
					formikProps.touched[nameof.full<Model>((x) => x.languageId)] == true;

				return (
					<>
						<tr key={model.key}>

							<td key={'isPublic'} style={{background: 'none'}} className='text-center'>
								<Field name={nameof.full<Model>((x) => x.isPublic)}>
									{({ field }) => ((mode == Mode.Edit || mode == Mode.New) ?
										<div className="checkbox m-0 p-0 d-inline" style={{width: 18, height: 18}}>
											<input
												type="checkbox"
												checked={field.value}
												onChange={({ target }) => {
													field.onChange({
														target: {
															name: field.name,
															value: target.checked,
														},
													});
												}}
												style={{width: 18, height: 18}}
											/>
										</div> :
										(field.value == true ? t('admin.settings.yes') : t('admin.settings.no'))
									)}
								</Field>
							</td>

							<td key={'key'}>
								<SettingsField
									name={nameof.full<Model>((x) => x.key)}
									isBigText={false}
									isEditable={mode == Mode.Edit || mode == Mode.New}
								/>
							</td>
							<td key={'value'}>
								<SettingsField
									isBase64Image={data.key?.indexOf('Base64') > -1}
									name={nameof.full<Model>((x) => x.value)}
									isBigText={(model.category == SettingCategory.Email || model.category == SettingCategory.UserInterface)}
									isEmail={model.category == SettingCategory.Email}
									isEditable={mode == Mode.Edit || mode == Mode.New}
								/>
							</td>
							<td key={'language'}>
								<LanguageField
									name={nameof.full<Model>((x) => x.languageId)}
									availableLanguages={availableLanguages}
									isEditable={mode == Mode.Edit || mode == Mode.New}
								/>
							</td>
							<td key={'category'}>
								{mode == Mode.Edit || mode == Mode.New ? (
									<Field name={nameof.full<Model>((x) => x.category)}>
										{(fieldProps) => {
											return (
												<select
													className="form-control"
													value={fieldProps.field.value?.toString()}
													onChange={(e) => {
														fieldProps.form.setFieldValue(
															nameof.full<Model>((x) => x.category),
															parseInt(e.target.value)
														);
													}}
												>
													<option value={SettingCategory.UserInterface}>
														{t('admin.settings.userInterface')}
													</option>
													<option value={SettingCategory.Email}>
														{t('admin.settings.email')}
													</option>
												</select>
											);
										}}
									</Field>
								) : model.category == SettingCategory.UserInterface ? (
									t('admin.settings.userInterface')
								) : (
									t('admin.settings.email')
								)}
							</td>

							<td key={'buttons'}>{renderButtons(formikProps)}</td>
						</tr>
						{!globalValidatorTouched && globalErrors.length > 0 && (
							<tr className="error-row">
								<td colSpan={5}>{globalErrors.join('\n')}</td>
							</tr>
						)}
					</>
				);
			}}
		</Formik>
	);
};

export default SettingsEntry;