import s from '../../index.module.scss';
import classNames from 'classnames';
import cn from 'classnames';
import { welcomeImages } from '@helpers/tenantsHelper';
import React, { useEffect, useRef, useState } from 'react';

export const MapAnimated: React.FC = () => {
  const lastAnimation = useRef<HTMLImageElement>(null);
  const [isMapAnimated, setMapAnimated] = useState(true);

  useEffect(() => {
    if (!isMapAnimated) {
      setMapAnimated(true);
    }
  }, [isMapAnimated]);

  useEffect(() => {
    const restartAnimation = (): void => setMapAnimated(false);

    lastAnimation.current.addEventListener('animationend', restartAnimation);
    return (): void => lastAnimation.current.removeEventListener('animationend', restartAnimation);
  });

  return (
    <div className={s['map-animation']}>
        <img src={welcomeImages.mapImages[0]} className={classNames(s['map-0'])} alt='map' />
      <div className={classNames(s['map-images'], cn({ [s['map-animated']]: isMapAnimated }))}>
        <img src={welcomeImages.mapImages[1]} className={classNames(s['map-1'], s['map-img'])}
             alt='map' />
        <img src={welcomeImages.mapImages[2]} className={classNames(s['map-2'], s['map-img'])}
             alt='map' />
        <img src={welcomeImages.mapImages[3]} className={classNames(s['map-3'], s['map-img'])}
             alt='map' />
        <img src={welcomeImages.mapImages[4]} className={classNames(s['map-4'], s['map-img'])}
             alt='map' />
        <img src={welcomeImages.mapImages[5]} className={classNames(s['map-5'], s['map-img'])}
             alt='map' ref={lastAnimation} />
      </div>
    </div>
  );
};