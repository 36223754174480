import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { AirwaybillDto, CreateHouseAirwaybill, UpdateHouseAirwaybill, FhlDto } from '@models/awbs/awbsModels';
import { HttpError, UploadProgress } from '@models/shared';

export default class HouseAirwaybillsService {

    public recognizeImage = (
        parentAirwaybillId: string,
        file: File,
        onUploadProgress: UploadProgress
    ) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('parentAirwaybillId', parentAirwaybillId);

        return AuthorizedHttpClient.post<string & HttpError>(
            `api/houseAirwaybills/file`,
            formData,
            {
                onUploadProgress
            }
        );
    }

    public create = (model: CreateHouseAirwaybill) => {
        return AuthorizedHttpClient.post<string & HttpError>(
            `api/houseAirwaybills`,
            model
        );
    }

    public update = (model: UpdateHouseAirwaybill) => {
        return AuthorizedHttpClient.put<HttpError>(
            `api/houseAirwaybills`,
            model
        );
    }

    public getPdfFileUrl = (houseairwaybillId: string) => {
        return `api/houseAirwaybills/${houseairwaybillId}/pdf`;
    }

    public get = (houseairwaybillId: string) => {
        return AuthorizedHttpClient.get<AirwaybillDto>(`api/houseAirwaybills/${houseairwaybillId}`);
    }

    public getFhlCode = (houseairwaybillId: string) => {
        return AuthorizedHttpClient.get<string>(`api/houseAirwaybills/${houseairwaybillId}/fhlCode`);
    }

    public remove = (houseairwaybillId: string) => {
        return AuthorizedHttpClient.delete<HttpError>(`api/houseAirwaybills/${houseairwaybillId}`);
    }

    public importFromFhl = (model: FhlDto) => {
        return AuthorizedHttpClient.post<string & HttpError>(
            `api/houseAirwaybills/fhl`,
            model
        );
    }
}
