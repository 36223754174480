import * as React from 'react';
import { Input, Label } from 'reactstrap';
import styles from './InputNumber.module.scss';
import { Button } from '@components/index';
import { ReactComponent as IconMinus } from '@material-design-icons/svg/round/remove.svg';
import { ReactComponent as IconPlus } from '@material-design-icons/svg/round/add.svg';

interface InputNumberProps {
    id: string;
    name: string;
    label?: string;
    step?: number;
    value: number | string;
    hasError?: boolean;
    onChange?: (field: string, value: number) => void;
    onBlur?: (field: string, isTouched: boolean) => void;
}

const InputNumber = (props: InputNumberProps): JSX.Element => {
    const { step, id, value, label, name, onChange, onBlur } = props;
    const updateValue = (newValue: number): void => {
        onChange(name, Math.max(1, newValue || 0));
    };

    const handleBlur = (): void => {
        onBlur(name, true);
    };

    return (
        <div className={styles.inputControl}>
            {props.label &&
                <Label for={id}>{label}</Label>
            }
            <div className={styles.inputNumber}>
                <Button
                    className={styles.inputBtn}
                    type='outline'
                    variant='primary'
                    onClick={() => updateValue(+(value || 0) - (step || 1))}
                    icon={<IconMinus fill='currentColor' />}
                />
                <Input
                    style={{ height: 'auto' }}
                    type='number'
                    value={value}
                    id={id}
                    name={name}
                    className={styles.inputNumber}
                    onBlur={handleBlur}
                    onChange={(e): void =>
                        updateValue(parseInt(e.target.value, 10))
                    }
                />
                <Button
                    className={styles.inputBtn}
                    type='outline'
                    variant='primary'
                    onClick={() => updateValue(+(value || 0) + (step || 1))}
                    icon={<IconPlus fill='currentColor' />}
                />
            </div>
        </div>
    );
};

export default InputNumber;
