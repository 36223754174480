import * as React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { CSSProperties, useCallback } from 'react';
import { UploadedFileDto } from '@models/filesModels';
import { useTranslation } from 'react-i18next';
import { formatBytes, getExtensionFromFileName } from '@helpers/stringHelpers';
import clsx from 'clsx';
import styles from './FileUploader.module.scss';
import { downloadBlob } from '@helpers/filesHelpers';

type Props = {
	maxFileCount: number;
	value: AttachedFile[];
	onChange: (files: AttachedFile[]) => void;
	style?: CSSProperties;
	downloadPreviewBlob: (file: UploadedFileDto) => Promise<Blob>;
	disabled?: boolean;
};

export class AttachedFile {
	uploadedFile?: UploadedFileDto;
	blob?: File;
	isUploaded: boolean;
	isDeleted: boolean;

	fileName: string;
	extension: string;
	sizeInBytes: number;
	hashCode: string;

	constructor() {
		this.isDeleted = false;
		this.isUploaded = false;
	}

	public static fromUploadedFile(uploadedFile: UploadedFileDto) {
		const f = new AttachedFile();
		f.uploadedFile = uploadedFile;
		f.isUploaded = true;

		f.fileName = uploadedFile.name;
		f.extension = uploadedFile.extension;
		f.sizeInBytes = uploadedFile.sizeInBytes;
		f.hashCode = f.getHashCode();

		return f;
	}

	public static fromBlob(blob: File) {
		const f = new AttachedFile();

		f.blob = blob;
		f.isUploaded = false;

		f.extension = getExtensionFromFileName(blob.name);

		if (f.extension) {
			const fNameSplitted = blob.name.split(`.${f.extension}`);
			f.fileName = fNameSplitted.filter((_, i) => i < fNameSplitted.length - 1).join('');
		} else {
			f.fileName = blob.name;
		}

		f.sizeInBytes = blob.size;
		f.hashCode = f.getHashCode();

		return f;
	}

	public setDeleted = (isDeleted: boolean) => {
		this.isDeleted = isDeleted;
	};

	private getHashCode = () => {
		return `${this.fileName}:${this.extension}:${this.sizeInBytes}:${this.isUploaded}`;
	};
}

export default function FileUploader(props: Props) {
	const { t } = useTranslation();

	const isDisabled = props.disabled == true;

	let _inputRef: React.RefObject<HTMLInputElement> = null;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onDrop = useCallback((acceptedFiles) => {
		// Do something with the files
		if (_inputRef?.current) {
			_inputRef.current.value = null;
		}
	}, []);

	const dropzoneOpts: DropzoneOptions = {
		onDrop,
		multiple: true,
		onDropAccepted: (files: File[]) => {
			if (isDisabled) {
				return;
			}

			const filesToAttach = files
				.map((x) => AttachedFile.fromBlob(x))
				// Remove duplicates.
				.filter((x) => props.value.find((y) => y.hashCode == x.hashCode) == null);

			const attachedFileCount = props.value?.filter((x) => !x.isDeleted)?.length || 0;
			if (attachedFileCount == props.maxFileCount) {
				return;
			}

			const fileCountToAttach = props.maxFileCount - attachedFileCount;

			props.onChange([...props.value, ...filesToAttach.filter((_, i) => i < fileCountToAttach)]);
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onDropRejected: (fileRejections) => {
			alert(t('upload.cantUploadFile'));
		},
		noClick: true,
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { getRootProps, getInputProps, isDragActive, inputRef, isDragAccept } = useDropzone(dropzoneOpts);

	_inputRef = inputRef;

	const onToggleDelete = (e: React.MouseEvent<HTMLAnchorElement>, hashCode: string, isDeleted: boolean) => {
		e.preventDefault();

		let files = [...props.value];

		const file = files.find((x) => x.hashCode == hashCode);

		if (file.isUploaded) {
			file.setDeleted(isDeleted);
		} else {
			files = files.filter((x) => x.hashCode != hashCode);
		}

		props.onChange(files);
	};

	const openUploadedFile = async (uploadedFile: UploadedFileDto) => {
		const fileName = `${uploadedFile.name}.${uploadedFile.extension}`;
		const blob = await props.downloadPreviewBlob(uploadedFile);
		downloadBlob(blob, fileName);
	};

	const maxFileCountReached = props.value?.filter((x) => !x.isDeleted)?.length == props.maxFileCount;

	return (
		<>
			<div {...getRootProps()} className={clsx(styles.wrapper)} style={props.style || {}}>
				{!isDisabled && isDragActive ? (
					<div className={clsx(styles.dragActive, maxFileCountReached ? styles.dragNotAllowed : null)}>
						<span>{maxFileCountReached && <>{t('upload.maxFileCountReached')}</>}</span>
					</div>
				) : null}

				<input {...getInputProps()} />

				<div className={styles.innerContainer}>
					{props.value?.map((file, i) => {
						return (
							<div key={i}>
								<div className={styles.fileIcon}>
									<i className="icon-document" />
								</div>
								<a
									href="#"
									className={clsx(
										!file.isUploaded ? styles.notUploadedFileName : null,
										file.isDeleted ? styles.deletedFileName : null
									)}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										file.isUploaded
											? openUploadedFile(file.uploadedFile)
											: downloadBlob(file.blob, `${file.fileName}.${file.extension}`);
									}}
								>
									<div className={styles.fileName}>{`${file.fileName}.${file.extension}`}</div>
								</a>
								, {formatBytes(file.sizeInBytes)}
								{isDisabled || (file.isDeleted && maxFileCountReached) ? null : (
									<>
										{' '}
										|{' '}
										<a href="#" onClick={(e) => onToggleDelete(e, file.hashCode, !file.isDeleted)}>
											{!file.isDeleted ? t('remove') : t('restore')}
										</a>
									</>
								)}
							</div>
						);
					})}

					{!isDisabled && props.value?.length > 0 && <br />}

					{!isDisabled && !maxFileCountReached && (
						<a
							className="btn btn-big btn-success"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								inputRef.current.click();
							}}
						>
							{t('upload.uploadFiles')}
						</a>
					)}
				</div>
			</div>
		</>
	);
}