import React from 'react';
import { connect } from 'react-redux';
import styles from './Stats.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
    countriesCount: number;
    airportsCount: number;
    airCarriersCount: number;
};

const StatsComponent = (props: Props) => {
    const { countriesCount, airportsCount, airCarriersCount } = props;
    const { t } = useTranslation();

    return (
        <div className={styles.statsContainer}>
            <div className={styles.block}>
                <div className={styles.count}>{countriesCount}+</div>
                <div className={styles.text}>{t('request.countries')}</div>
            </div>
            <div className={styles.block}>
                <div className={styles.count}>{airportsCount}+</div>
                <div className={styles.text}>{t('request.airports')}</div>
            </div>
            <div className={styles.block}>
                <div className={styles.count}>{airCarriersCount}+</div>
                <div className={styles.text}>{t('request.airCarriers')}</div>
            </div>
        </div>
    );
};

export default connect()(StatsComponent);
