import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { ApplicationPaths } from '@config/ApiAuthorizationConstants';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import ExLoginCallback from './ExLoginCallback';
import ExternalLoginFailed from './ExternalLoginFailed';
import ResetPassword from './ResetPassword';
import ResetPasswordRequest from './ResetPasswordRequest';
import ChangePassword from './ChangePassword';

export default class ApiAuthorizationRoutes extends Component {
	render() {
		return (
			<Fragment>
				<Route path={ApplicationPaths.Login} component={LoginPage} />
				<Route path={ApplicationPaths.Register} component={RegisterPage} />
				<Route path={ApplicationPaths.LoginCallback} component={ExLoginCallback} />
				<Route path={ApplicationPaths.LoginFailed} component={ExternalLoginFailed} />
				<Route path={ApplicationPaths.ResetPassword} component={ResetPassword} />
				<Route path={ApplicationPaths.ResetPasswordRequest} component={ResetPasswordRequest} />
				<Route path={ApplicationPaths.ChangePassword} component={ChangePassword} />
			</Fragment>
		);
	}
}