import { routerMiddleware } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit';
import { ApplicationState } from '@store/index';

const initializeStore = (rootReducer, browserHistory, preloadedState?: Partial<ApplicationState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware()
        .prepend(
          routerMiddleware(browserHistory),
        ),
    preloadedState: preloadedState || {},
    devTools: true,
  });
};

export default initializeStore;