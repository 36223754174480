import { HttpError, ValidationError } from '@models/shared';

export function handleValidationErrors(request: XMLHttpRequest, removeFirstPropertyName = false): ValidationError[] {
    
    if(request.status != 400 || request.response?.data?.fields == null){
        return [];
    }
    
    return getValidationErrors(request.response.data, removeFirstPropertyName);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValidationErrors(error: HttpError | any, removeFirstPropertyName = false): ValidationError[]{
    const fields : ValidationError[] = error.fields;
    if(fields == null || fields.length == 0){
        return [];
    }
    
    return fields.map(x => {

        const splitted = x.name.split('.');
        
        const propPath = [];
        
        const hasExtra = splitted.length > 1;
        
        for (let i = 0; i < splitted.length; i++){

            const pathItem = splitted[i];

            if(hasExtra && removeFirstPropertyName && i == 0){
                continue;
            }
            
            // Lowercase first letter.
            
            const strArr = [];
            
            for (let ci = 0; ci < pathItem.length; ci ++){
                if(ci == 0){
                    strArr.push(pathItem[ci].toLowerCase());
                }else{
                    strArr.push(pathItem[ci]);
                }
            }

            propPath.push(strArr.join(''));
        }
        
        return { name: propPath.join('.'), messages: x.messages };
    });    
}