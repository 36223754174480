import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import Tippy from '@tippyjs/react';
import nameof from 'ts-nameof.macro';
import { useTranslation } from 'react-i18next';
import { LanguageDto } from '@models/languagesModels';
import { object, number, string, array } from 'yup';
import styles from '@scenes/customerApplication/CustomerApplicationPage.module.scss';
import { EventGroupType, NotificationSettingsEntryDto } from '@models/admin/notificationsModels';
import StaticSelect from '@components/select/StaticSelect';
import ValidationMessage from '@components/ValidationMessage';
import { LanguageType } from '@models/LanguageType';
import { KeyValuePair } from '@core/models';
import { getEventTypesNameByGroup } from '@helpers/eventTypeHelpers';
import i18n from 'i18next';
import { submitFormAndShowErrorsAsync } from '@helpers/FormHelper';
import { HttpError } from '@models/shared';
import AirportSelect from '@scenes/customerRequest/searchForm/components/AirportSelect';
import { getIconSymbol } from '@helpers/CssHelpers';
import { LocationDto } from '@models/locations';
import LocationsService from '@services/LocationsService';
import AirlineSelect from '@components/select/AirlineSelect';
import { useAppSelector } from "@root/store";
import { SenderEmailModelDto } from "@models/settings/SenderEmailModel";
import Select from "react-select";

type Model = NotificationSettingsEntryDto;

type Props = {
	key: string | number;
	isNew: boolean;
	model: Model;
	eventGroupTypes: KeyValuePair<number>[];
	availableLanguages: LanguageDto[];
	sendRequest: (model: Model) => Promise<HttpError | null>;
	onDelete?: (model: Model) => void;
	otherEntries: Model[];
	onError: (msg: string) => void;
};

enum Mode {
	New,
	Edit,
	Read,
}

const SettingsField = (props: { name: string; isEditable: boolean; isBigText: boolean }) => {
	return props.isEditable ? (
		<Field name={props.name}>
			{(fieldProps) => (
				<>
					{props.isBigText ? (
						<textarea
							className="form-control"
							rows={6}
							{...fieldProps.field}
							value={fieldProps.field.value || ''}
						/>
					) : (
						<input
							className="form-control"
							type={'text'}
							{...fieldProps.field}
							value={fieldProps.field.value || ''}
						/>
					)}
					{fieldProps.form.errors[props.name] ? (
						<span className="validationMessage">{fieldProps.form.errors[props.name]}</span>
					) : null}
				</>
			)}
		</Field>
	) : (
		<Field name={props.name}>
			{(fieldProps) => <span dangerouslySetInnerHTML={{__html: fieldProps.field.value}}/>}
		</Field>
	);
};

const MultipleSelectField = function <TObject, TFieldValue>(props: {
	name: string;
	required: boolean;
	isEditMode: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formikProps: FormikProps<any>;
	availableObjects: TObject[];
	fieldValueSelector: (obj: TObject) => TFieldValue;
	placeholder: string;
	labelSelector: (selectedObject: TObject) => JSX.Element;
	onChange?: (selectedObjects: TObject[]) => void;
}) {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getSelectedObjects = (formikFieldValue: any): TObject[] => {
		if (formikFieldValue == null) {
			return [];
		}

		const selectedObj: TObject[] = [];

		if (Array.isArray(formikFieldValue)) {
			props.availableObjects.forEach((obj) => {
				const fieldValue = props.fieldValueSelector(obj);
				if (formikFieldValue.includes(fieldValue)) {
					selectedObj.push(obj);
				}
			});
		}

		return selectedObj;
	};

	return (
		<Field name={props.name}>
			{({field}) => {
				return (
					<>
						{props.isEditMode ? (
							<>
								<StaticSelect<TObject>
									isMulti
									value={getSelectedObjects(field.value)}
									checkboxes={true}
									availableOptions={props.availableObjects}
									onChange={(x) => {
										props.formikProps.setFieldValue(
											props.name,
											x.map((c) => props.fieldValueSelector(c))
										);
										props.formikProps.setFieldTouched(props.name, true);
										if (props.onChange) {
											props.onChange(x);
										}
									}}
									optionLabel={props.labelSelector}
									selectedLabel={props.labelSelector}
									isSearchable={false}
									placeholder={props.placeholder}
								/>
								<ValidationMessage name={field.name} errors={props.formikProps.errors}/>
							</>
						) : (
							<div className={styles.formText}>
								{field.value?.length > 0
									? props.availableObjects
										.filter((x) => field.value.includes(props.fieldValueSelector(x)))
										.map((x, i) => {
											if (i < field.value.length - 1) {
												return <>{props.labelSelector(x)}, </>;
											}
											return props.labelSelector(x);
										})
									: ' - '}
							</div>
						)}
					</>
				);
			}}
		</Field>
	);
};

const NotificationSettingsEntry = ({
									   isNew,
									   model,
									   eventGroupTypes,
									   availableLanguages,
									   sendRequest,
									   onError,
									   onDelete,
									   otherEntries
								   }: Props) => {
	const {t} = useTranslation();

	const createEmptyEntry = (): NotificationSettingsEntryDto => {
		return {
			languageTypes: [],
			eventGroupTypes: [],
			eventTypeValues: [],
		} as NotificationSettingsEntryDto;
	};

	const [ data, setData ] = useState<NotificationSettingsEntryDto>(model);

	const [ mode, setMode ] = useState(isNew ? Mode.New : Mode.Read);
	const [ globalErrors, setGlobalErrors ] = useState<string[]>([]);

	const [ eventTypeObject, setEventTypeObject ] = useState<{
		eventGroupType: EventGroupType;
		eventName: string;
		eventValues: KeyValuePair<number>[];
	}>(null);

	const [ selectedEventTypeValues, setSelectedEventTypeValues ] = useState<number[]>([]);
	const airlineSelectRef = useRef<AirlineSelect>();
	const [ selectedAirlineId, setSelectedAirlineId ] = useState<string>('');
	const [ locationFrom, setLocationFrom ] = useState<LocationDto>(null);
	const {emails} = useAppSelector(app => app.senderEmails);
	const [ currentSenderEmail, setCurrentSenderEmail ] = useState<SenderEmailModelDto>(null);

	useEffect(() => {
		setData(model);
		if (model.locationId != null) {
			new LocationsService()
				.getLocationById(model.locationId)
				.then(data => setLocationFrom(data.data));
		} else {
			setLocationFrom(null);
		}

		setSelectedAirlineId(model.airlineId);
		setCurrentSenderEmail(emails.find(e => e.id == model.senderEmailId));

	}, [ model ]);

	useEffect(() => {
		if (mode == Mode.Read) {
			return;
		}

		airlineSelectRef.current.setValue([ selectedAirlineId ]);
	}, [ mode, selectedAirlineId ]);

	const availableEventTypes = useMemo(() => {
		if (eventTypeObject == null) {
			return null;
		}
		return eventTypeObject.eventValues.map((x) => ({
			name: t(`${eventTypeObject.eventName}.${x.key}`),
			value: x.value,
		}));
	}, [ eventTypeObject, i18n.language ]);

	const validateUnique = (m: Model): boolean => {
		const email = m.email?.trim().toLowerCase();
		const hasSameEntry = otherEntries.find(
			(x) => x.id != m.id && x.email.toLowerCase() == email && x.languageTypes == m.languageTypes
			// &&
			// x.eventGroupTypes == m.eventGroupTypes
		);
		return hasSameEntry == null;
	};

	const validationSchema = object<Partial<Model>>().shape({
		email: string().email(t('validation.email')).required(t('validation.required')),
		languageTypes: array().of(number()),
	});

	const renderButtons = (formikProps: FormikProps<Model>) => {
		switch (mode) {
			case Mode.New:
				return (
					<>
						<Tippy content={t('admin.settings.save')}>
							<a
								className="btn btn-control-success"
								onClick={(e) => {
									e.preventDefault();
									formikProps.handleSubmit();
								}}
							>
								<i className="icon-save"/>
							</a>
						</Tippy>
						{'  '}
						<Tippy content={t('admin.settings.erase')}>
							<a
								className="btn btn-control-danger"
								onClick={(e) => {
									e.preventDefault();
									setData(createEmptyEntry());
									setEventTypeObject(null);
									setSelectedEventTypeValues([]);
									formikProps.resetForm({});
									setGlobalErrors([]);
								}}
							>
								<i className="icon-minus"/>
							</a>
						</Tippy>
					</>
				);
			case Mode.Edit:
				return (
					<>
						<Tippy content={t('admin.settings.save')}>
							<a
								className="btn btn-control-success"
								onClick={(e) => {
									e.preventDefault();
									formikProps.handleSubmit();
								}}
							>
								<i className="icon-save"/>
							</a>
						</Tippy>
						{'  '}
						<Tippy content={t('admin.settings.cancel')}>
							<a
								className="btn btn-control-danger"
								onClick={(e) => {
									e.preventDefault();
									setMode(Mode.Read);
									setGlobalErrors([]);
								}}
							>
								<i className="icon-close"/>
							</a>
						</Tippy>
					</>
				);
			case Mode.Read:
				return (
					<>
						<Tippy content={t('admin.settings.edit')}>
							<a
								className="btn btn-control-primary"
								onClick={(e) => {
									e.preventDefault();
									setMode(Mode.Edit);
								}}
							>
								<i className="icon-edit"/>
							</a>
						</Tippy>
						{'  '}
						<Tippy content={t('remove')}>
							<a
								className="btn btn-control-danger"
								onClick={(e) => {
									e.preventDefault();
									onDelete(model);
								}}
							>
								<i className="icon-trash"/>
							</a>
						</Tippy>
					</>
				);
		}
	};

	const getEventTypeObject = (eventGroupType: EventGroupType) => {
		const {eventTypeName, values} = getEventTypesNameByGroup(eventGroupType);

		return {
			eventGroupType,
			eventName: eventTypeName,
			eventValues: values,
		};
	};

	useEffect(() => {
		if (data == null || data.eventGroupTypes == null) {
			setEventTypeObject(null);
			setSelectedEventTypeValues([]);
			return;
		}

		if (data.eventGroupTypes.length == 1) {
			const eventGroupType = data.eventGroupTypes[0];

			setEventTypeObject(getEventTypeObject(eventGroupType));
			if (data.eventTypeValues == null || data.eventTypeValues.length == 0) {
				setSelectedEventTypeValues([]);
			} else {
				setSelectedEventTypeValues(data.eventTypeValues);
			}

			return;
		} else {
			//setEventGroupType(null);
			setSelectedEventTypeValues([]);
			setEventTypeObject(null);
		}
	}, [ data ]);

	return (
		<Formik
			initialValues={data}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, formikHelpers) => {
				formikHelpers.setFieldTouched(
					nameof.full<Model>((x) => x.email),
					false
				);
				formikHelpers.setFieldTouched(
					nameof.full<Model>((x) => x.languageTypes),
					false
				);

				values.eventTypeName = selectedEventTypeValues?.length > 0 ? eventTypeObject?.eventName : null;
				values.eventTypeValues = selectedEventTypeValues;

				if (validateUnique(values)) {
					setGlobalErrors([]);
					const result = await submitFormAndShowErrorsAsync(values, sendRequest);

					if (!result.isError) {
						if (!isNew) {
							setMode(Mode.Read);
							setGlobalErrors([]);
						} else {
							setData(createEmptyEntry());
							formikHelpers.resetForm({});
						}
					} else {
						onError(result.message);
					}
				} else {
					setGlobalErrors([ t('admin.settings.uniqueErrorMsg') ]);
				}
			}}
		>
			{(formikProps) => {
				const globalValidatorTouched =
					formikProps.touched[nameof.full<Model>((x) => x.email)] == true ||
					formikProps.touched[nameof.full<Model>((x) => x.languageTypes)] == true;

				return (
					<>
						<tr>
							<td key={'sender'}>
								{mode == Mode.Read
									? currentSenderEmail?.email
									: <Select
										isClearable
										onChange={e => formikProps.setFieldValue(nameof.full<Model>(x => x.senderEmailId), e.id)}
										options={emails}
										getOptionValue={dto => dto.id}
										getOptionLabel={dto => dto.email}
										value={currentSenderEmail}/>
								}
							</td>
							<td key={'email'}>
								<SettingsField
									name={nameof.full<Model>((x) => x.email)}
									isBigText={false}
									isEditable={mode == Mode.Edit || mode == Mode.New}
								/>
							</td>

							<td key={'languageTypes'}>
								<MultipleSelectField<LanguageDto, LanguageType>
									placeholder={t('languages')}
									formikProps={formikProps}
									name={nameof.full<Model>((x) => x.languageTypes)}
									availableObjects={availableLanguages}
									fieldValueSelector={(x) => x.type}
									labelSelector={(x) => <>{x.name}</>}
									isEditMode={mode == Mode.Edit || mode == Mode.New}
									required={false}
								/>
							</td>

							<td key={'eventGroupTypes'}>
								<MultipleSelectField<KeyValuePair<number>, EventGroupType>
									placeholder={t('eventGroups')}
									formikProps={formikProps}
									name={nameof.full<Model>((x) => x.eventGroupTypes)}
									availableObjects={eventGroupTypes}
									fieldValueSelector={(x) => x.value}
									labelSelector={(x) => <>{t(`eventGroupType.${x.key}`)}</>}
									isEditMode={mode == Mode.Edit || mode == Mode.New}
									required={false}
									onChange={(obj) => {

										setEventTypeObject(null);
										setSelectedEventTypeValues([]);

										if (obj?.length == 1) {
											const eventGroupType = obj[0].value;
											setEventTypeObject(getEventTypeObject(eventGroupType));
										}
									}}
								/>
							</td>

							<td key={'eventTypes'}>
								<>
									{(mode == Mode.Edit || mode == Mode.New) && eventTypeObject != null ? (
										<StaticSelect<{ name: string; value: number }>
											isMulti
											value={availableEventTypes.filter((x) =>
												selectedEventTypeValues.includes(x.value)
											)}
											checkboxes={true}
											availableOptions={availableEventTypes}
											onChange={(x) => {
												setSelectedEventTypeValues(x.map((y) => y.value));
											}}
											optionLabel={(x) => <>{x.name}</>}
											isSearchable={false}
											placeholder={t('eventTypes')}
										/>
									) : (
										<div className={styles.formText}>
											{availableEventTypes?.length > 0
												? availableEventTypes
													.filter((x) => selectedEventTypeValues.includes(x.value))
													.map((x, i, target) => {
														if (i < target.length - 1) {
															return <>{x.name}, </>;
														}
														return x.name;
													})
												: ' - '}
										</div>
									)}
								</>
							</td>

							<td>
								{mode == Mode.Read
									? <div className={styles.formText}>{model.airlineName}</div>
									:
									<AirlineSelect
										ref={airlineSelectRef}
										isMulti={false}
										onChange={(x) => {
											const airlineId = x[0];
											setSelectedAirlineId(airlineId);
											formikProps.setFieldValue(
												nameof.full<Model>((x) => x.airlineId),
												airlineId
											);
											//this.props.onChangeAirlineFilter ? this.props.onChangeAirlineFilter(x) : null
										}}
										loadingMessage={t('options.loadingOptions')}
										noOptionsMessage={t('options.noOptions')}
										placeholder={t('customerApplicationHistory.selectAirlines')}
									/>
								}
							</td>
							<td>
								{mode == Mode.Read
									? <div className={styles.formText}>{model.locationName}</div>
									:
									<AirportSelect
										hideWarning={true}
										icon={getIconSymbol('icon-start-way')}
										placeholder={t('request.from')}
										onChange={(val) => {
											setLocationFrom(val);
											formikProps.setFieldValue(
												nameof.full<Model>((x) => x.locationId),
												val?.id
											);
										}}
										value={locationFrom}
									/>
								}
							</td>

							<td key={'buttons'}>{renderButtons(formikProps)}</td>
						</tr>
						{!globalValidatorTouched && globalErrors.length > 0 && (
							<tr className="error-row">
								<td colSpan={5}>{globalErrors.join('\n')}</td>
							</tr>
						)}
					</>
				);
			}}
		</Formik>
	);
};

export default NotificationSettingsEntry;