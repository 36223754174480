import * as React from 'react';
import { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Loader } from '@components/index';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import Paginator from '@components/paginator/Paginator';
import dictionaryStyles from '../../../admin/components/DictionaryStyles.module.scss';
import { getAwbList } from '@store/airwaybills/airwaybillsStore';
import debounce from 'awesome-debounce-promise';
import { AwbListQuery } from '@models/awbs/awbsModels';
import LocationSelect from '@components/select/LocationSelect';
import LocationsService from '@services/LocationsService';
import { AirwaybillRow } from '@scenes/awb/components/list/AirwaybillRow';
import { RecognizeControl } from '@scenes/awb/components/edit/recognizeControl';
import AirlineSelect from '@components/select/AirlineSelect';
import { Button, InfoCard, PageHeader } from '@root/components';
import s from './AirwaybillRow.module.scss';

const AwbAllItemsPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const airlineSelectRef = useRef<AirlineSelect>();
    const { isFetching, airwaybills } = useAppSelector((x) => x.airwaybills);
    const [query, setQuery] = useState<AwbListQuery>({ awbNumber: '', pageNumber: 0, pageSize: 10 });
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(getAwbList(query));
    }, [query]);

    const onChangePage = (pageNumber: number): void => {
        setQuery({ ...query, pageNumber });
    };
    const goToAwbForm = (awbUId: string): void => {
        history.push(`/awb/edit/${awbUId}`);
    };
    const debouncedTermChanged = debounce((term: string) => {
        setQuery({ ...query, awbNumber: term, pageNumber: 0 });
    }, 800);
    const onSearchByTerm = (term: string): void => {
        debouncedTermChanged(term?.trim() ?? '');
    };

    return (
        <div className={s.container}>
            <div className={s.buttons}>
                <Button
                    type='fill'
                    variant='primary'
                    onClick={_ => history.push(`/awb/create`)}
                >
                    {t('awb.createNewEmptyAwb')}
                </Button>
                <RecognizeControl onRecognize={id => history.push(`/awb/edit/${id}`)} />
            </div>
            <div className={s.flexContainer}>
                <div className={s.filterCard}>
                    <Row>
                        <Col md={3}>
                            <input
                                type='text'
                                className={clsx('form-control', dictionaryStyles.termFilter)}
                                placeholder={t('awb.awbNumber')}
                                onKeyUp={(e): void => onSearchByTerm(e.currentTarget.value)}
                            />
                        </Col>
                        <Col md={3}>
                            <LocationSelect
                                placeholder={t('awb.list.departureAirportSearch')}
                                selectedOptions={query.departure || null}
                                onChange={(x) => setQuery({ ...query, departure: x, pageNumber: 0 })}
                                fetch={term => new LocationsService().getLocations(term)}
                                isMulti={true}
                                isClearable={true}
                            />
                        </Col>
                        <Col md={3}>
                            <LocationSelect
                                placeholder={t('awb.list.arrivalAirportSearch')}
                                selectedOptions={query.arrival || null}
                                onChange={(x) => setQuery({ ...query, arrival: x, pageNumber: 0 })}
                                fetch={term => new LocationsService().getLocations(term)}
                                isMulti={true}
                                isClearable={true}
                            />
                        </Col>
                        <Col md={3}>
                            <AirlineSelect
                                mode="awb only"
                                ref={airlineSelectRef}
                                onChange={(x) => setQuery({ ...query, airlineIds: x, pageNumber: 0 })}
                                loadingMessage={t('options.loadingOptions')}
                                noOptionsMessage={t('options.noOptions')}
                                placeholder={t('awb.list.airline')}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={s.flexContainer}>
                <InfoCard title={t('awb.awbListHeader')}>
                    {isFetching ? (
                        <Loader />
                    ) : (
                        <>
                            <Row>
                                <Col>
                                    <table
                                        className={clsx('table table-hover table-responsive-sm')}>
                                        <thead>
                                        <tr className={s.tableHeader}>
                                            <th />
                                            <th>{t('awb.list.hasFMA')}</th>
                                            <th>{t('awb.list.number')}</th>
                                            <th>{t('awb.list.departureAirport')}</th>
                                            <th>{t('awb.list.arrivalAirport')}</th>
                                            <th>{t('awb.list.physicalWeight')}</th>
                                            <th>{t('awb.list.paidWeight')}</th>
                                            <th>{t('awb.list.departureDate')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {airwaybills?.items.map((a, key) => <AirwaybillRow key={key} item={a}
                                                                                           onClick={goToAwbForm} />)}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Paginator
                                        totalResults={airwaybills?.count || 0}
                                        limitPerPage={query.pageSize}
                                        currentPage={query.pageNumber + 1}
                                        onChangePage={(pageNumber) => onChangePage(pageNumber)}
                                        pageNeighbours={4}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </InfoCard>
            </div>
        </div>
    );
};

export default AwbAllItemsPage;