import { ReviewDto } from '@models/reviewDto';

export default class ReviewService {
	public getLatestReviewsAsync(): Promise<ReviewDto[]> {
		return new Promise<ReviewDto[]>(resolve => {
			const data: ReviewDto[] = [
				{
					author: 'Максим Семенов',
					authorPost: 'Заместитель руководителя по работе с клиентами FFCargo Services',
					reviewBody: 'Автоматизация бронирования грузовых перевозок — это неотъемлемая функция современной компании. Использование Cargobooking365.com позволило ускорить обработку запросов и обслуживание заказов клиентов FFCargo Services на 30%. На платформе есть сервисы оптимизации основных бизнес-процессов компании, платформа динамично расширяет имеющийся функционал, внедряет новые современные решения цифровизации грузовой логистики.',
					highlightText: 'Ускорили обработку запросов и обслуживание заказов клиентов FFCargo Services на 30%',
					logoId: 'FFCargo'
				},
				{
					author: 'Алексей Каналин',
					authorPost: 'Руководитель департамента авиаперевозок ООО «ТРАСКО»',
					reviewBody: 'Хотим отметить удобство пользования при обработке запросов. С переходом на новую платформу мы сократили время обработки запроса с нескольких часов до 1-2 минут и получили более полную информацию по возможностям доставки грузов.',
					highlightText: 'Сократили время обработки запроса и получили более полную информацию',
					logoId: 'Trasko'
				},
				{
					author: 'Евгений Джурко',
					authorPost: 'Руководитель операционного отдела ООО «Абипа»',
					reviewBody: 'На платформе реализован мгновенный поиск тарифа на большинство наших запросов. Интуитивно понятный интерфейс позволяет с легкостью начать пользоваться системой сразу же после регистрации.\n' +
						'Удобно делиться ссылками на запрос с коллегами.',
					highlightText: 'Мгновенный поиск тарифа, интуитивно понятный интерфейс',
					logoId: 'Abipa'
				},
				{
					author: 'Екатерина Игнатьева',
					authorPost: 'Заместитель директора ООО «АТА Интернешенл»',
					reviewBody: 'Очень удобная система, экономит время при заполнении заявок на бронирование, выдает актуальный тариф (что особенно важно!), быстро обрабатывает запросы и предлагает подходящие маршруты. Есть подсказки по технической возможности перевозки на разных воздушных судах, дополнительные сборы.',
					highlightText: 'Удобная система, экономит время при заполнении заявок на бронирование, выдает актуальный тариф',
					logoId: 'Ata'
				},
				{
					author: 'Наталья Слипченко',
					authorPost: 'Начальник отдела международной логистики ООО "еКаргоУорлд"',
					reviewBody: 'Использование платформы позволяет получать актуальные тарифы по нашим запросам практически мгновенно. Сервис доступен круглосуточно, и это важно тогда, когда время на предоставление клиенту ставки крайне ограничено. Также удобна функция сохранения запросов в истории.',
					highlightText: 'Сервис доступен круглосуточно, удобная функция сохранения запросов в истории',
					logoId: 'CargoWorld'
				},
				{
					author: 'Алексей Ермолаев',
					authorPost: 'Генеральный директор ООО «Скай Карго Сервис»',
					reviewBody: 'Работать с обработкой заявок на перевозку стало намного быстрее и удобнее благодаря вам и вашей системе. Планируем и дальше с удовольствием пользоваться предоставленными услугами!',
					highlightText: 'Работать с обработкой заявок на перевозку стало намного быстрее и удобнее',
					logoId: 'SkyCargo'
				}
			];

			resolve(data);
		});
	}
}