import React from 'react';
import s from '@scenes/welcome/FFCargoWelcomePage/index.module.scss';
import { tr } from 'date-fns/locale';

type Step = {
  highlight: string;
  text: string;
  addSoonToken: boolean;
}

export type AdvantageListItem = {
  text: string;
  isStrong: boolean;
  isSoon: boolean;
}

export type TimeslotDto = {
  id: string;
  name: string;
};

export enum DisplayMode {
  booking,
  contactForm,
  result
}

export const stepsToUse: Step[] = [
  {
    highlight: 'Выбрать в верхней части страницы ссылку «Зарегистрироваться»',
    text: 'и заполнить необходимые сведения об организации и пользователе',
    addSoonToken: false,
  },
  {
    highlight: 'Дождаться обратной связи от нашего специалиста',
    text: 'по телефону или\n' +
      'по электронной почте',
    addSoonToken: false,
  },
  {
    highlight: 'Заполнить аккредитационный лист пользователя',
    text: '',
    addSoonToken: true,
  },
  {
    highlight: 'Можно начинать поиск рейсов',
    text: 'и подачу заявок на бронирование',
    addSoonToken: false,
  },
];


export const listFirst: AdvantageListItem[] = [
  {
    text: 'Специальные грузы (special handling коды)',
    isStrong: true,
    isSoon: false
  },
  {
    text: 'Импорт данных в систему из Excel',
    isStrong: false,
    isSoon: false
  },
  {
    text: 'Подбор вариантов ближайших аэропортов',
    isStrong: false,
    isSoon: false
  }
];

export const listSecond: AdvantageListItem[] = [

  {
    text: 'Наличие провозных емкостей по рейсам',
    isStrong: true,
    isSoon: false
  },
  {
    text: 'Маршруты и расписание',
    isStrong: false,
    isSoon: false
  },
  {
    text: 'Тарифы и дополнительные сборы',
    isStrong: false,
    isSoon: false
  }
];

export const listThird: AdvantageListItem[] = [
  {
    text: 'Создание авианакладной',
    isStrong: true,
    isSoon: false
  },
  {
    text: 'Подача заявок на бронирование',
    isStrong: false,
    isSoon: false
  },
  {
    text: 'Отправка телеграмм CARGO-IMP',
    isStrong: false,
    isSoon: true
  }
];