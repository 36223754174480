import * as React from 'react';
import { useEffect, useState } from 'react';
import { ReactComponent as IconInfo } from '@material-design-icons/svg/round/info.svg';
import { ReactComponent as IconGrid } from '@material-design-icons/svg/round/grid_view.svg';
import { ReactComponent as IconWeight } from '@material-design-icons/svg/round/scale.svg';
import { ReactComponent as IconFrom } from '@material-design-icons/svg/round/flight_takeoff.svg';
import { ReactComponent as IconTo } from '@material-design-icons/svg/round/flight_land.svg';
import { ReactComponent as IconRefresh } from '@material-design-icons/svg/round/refresh.svg';
import s from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AirportDictionaryItemDto } from '@models/airports/AirportDictionaryItemDto';
import { Badge, Button, Loader, ParamCard } from '@root/components';
import i18n from '@i18n';
import getDate from '@helpers/dateTime/getDate';
import { useAppDispatch, useAppSelector } from '@root/store';
import { findByCodeIata, getTrackAndTraceInfoAsync, resetTrack } from '@store/trackAndTrace';

interface ITraceHistory {
    airWaybillNumber: string;
}

const TraceHistory: React.FC<ITraceHistory> = ({ airWaybillNumber }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { traceData, locations, traceLoading } = useAppSelector((state) => state.tracking);
    const [locationsArray, setLocations] = useState<string[]>([]);
    const [locationDtos, setLocationDtos] = useState<Record<string, AirportDictionaryItemDto>>({});

    useEffect(() => {
        dispatch(getTrackAndTraceInfoAsync({ number: airWaybillNumber, force: false }));
        return () => {
            dispatch(resetTrack());
        };
    }, [airWaybillNumber]);

    useEffect(() => {
        if (traceData == null || !traceData.isSuccess) {
            setLocations([]);
            setLocationDtos({});
            return;
        }

        const locationCodes = Object.keys(traceData?.data?.traceInfo?.eventsByLocations);
        setLocations(locationCodes);

        const endpoints = [traceData?.data?.traceInfo?.trackAndTrace?.origin, traceData?.data?.traceInfo?.trackAndTrace?.destination];
        dispatch(findByCodeIata([...endpoints, ...locationCodes.filter(location => endpoints.indexOf(location) < 0)]));

        const dto: Record<string, AirportDictionaryItemDto> = {};
        locations?.forEach(location => dto[location.codeIata] = location);
        setLocationDtos(dto);
    }, [traceData?.data]);


    const onReFetchHandler = () => {
        dispatch(getTrackAndTraceInfoAsync({ number: airWaybillNumber, force: true }));
    };

    const columnTitles = React.useMemo(() => {
        const i18tColumnNames = [
            'trackHistory.flightNo',
            'trackHistory.flightDate',
            'trackHistory.segment',
            'awb.pieces',
            'trackHistory.weight',
            'request.cargoVolume',
        ];

        return <>{i18tColumnNames.map((x, i) => <span className={s.tableBodyRowCol} key={i}>{t(x)}</span>)}</>;

    }, [i18n.language]);

    return (
        <>
            {traceLoading ?
                (
                    <Loader />
                ) : (
                    traceData?.data ? (
                        <div className={s.traceData}>
                            <div className={s.chapter}>
                                <h5>{t('trackHistory.summary')}</h5>
                                <div className={s.cards}>
                                    <ParamCard paramName={t('trackHistory.latestInfo')} icon={<IconInfo />}>
                                        {getDate(traceData?.data?.traceInfo?.latestEvent?.eventDate)}
                                        {traceData?.data?.traceInfo?.trackAndTrace?.eventLocation && `- ${traceData?.data?.traceInfo?.trackAndTrace?.eventLocation}`}
                                    </ParamCard>

                                    <ParamCard paramName={'Количество мест'} icon={<IconGrid />}>
                                        <Trans t={t} i18nKey='trackHistory.pieces'
                                               values={{ segment: traceData?.data?.traceInfo?.latestEvent }} />
                                    </ParamCard>

                                    <ParamCard paramName={'Вес'} icon={<IconWeight />}>
                                        <Trans t={t} i18nKey='trackHistory.segmentWeight'
                                               values={{ segment: traceData?.data?.traceInfo?.latestEvent }} />
                                    </ParamCard>

                                    <ParamCard paramName={t('trackHistory.origin')} icon={<IconFrom />}>
                                        <Badge
                                            label={`${traceData?.data?.traceInfo?.trackAndTrace?.origin}`}
                                            color='blue' />
                                    </ParamCard>

                                    <ParamCard paramName={t('trackHistory.destination')} icon={<IconTo />}>
                                        <Badge
                                            label={`${traceData?.data?.traceInfo?.trackAndTrace?.destination}`}
                                            color='blue' />
                                    </ParamCard>
                                </div>
                            </div>
                            {traceData?.data?.traceInfo?.flights && traceData?.data?.traceInfo?.flights?.length > 0 &&
                                <div className={s.chapter}>
                                    <h5>{t('trackHistory.routes')}</h5>
                                    <div className={s.routesTable}>
                                        <div className={s.table}>
                                            <div className={s.tableHead}>{columnTitles}</div>
                                            <div className={s.tableBody}>
                                                {traceData?.data?.traceInfo?.flights?.map(flight =>
                                                        <div key={flight.number} className={s.tableBodyRow}>
                          <span className={s.tableBodyRowCol}>
                            <Badge label={flight.number} color='blue' />
                          </span>
                                                            <span className={s.tableBodyRowCol}>
                            {getDate(flight.actualDeparture)}
                          </span>
                                                            <span className={s.tableBodyRowCol}>
                            <Badge label={flight.origin} color='white' />
                            <Badge label={flight.destination} color='white' />
                          </span>
                                                            <span
                                                                className={s.tableBodyRowCol}>{traceData?.data?.traceInfo?.eventsByLocations[flight.origin][0].pieces}</span>
                                                            <span
                                                                className={s.tableBodyRowCol}>{traceData?.data?.traceInfo?.eventsByLocations[flight.origin][0].weight.toFixed(2)}</span>
                                                            <span
                                                                className={s.tableBodyRowCol}>{traceData?.data?.traceInfo?.eventsByLocations[flight.origin][0].volume}</span>
                                                        </div>,
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {locations && locations?.length > 0 &&
                                <div className={s.chapter}>
                                    <h5>{t('trackHistory.details')}</h5>
                                    <div className={s.flightInfo}>
                                        {locationsArray.map(location =>
                                            <div key={location} className={s.flightCard}>
                                                <div className={s.flightTitle}>
                                                    <span>{location}</span>
                                                </div>
                                                <div className={s.flightDetails}>
                                                    {traceData?.data?.traceInfo?.eventsByLocations[location].map((flightEvent, index) =>
                                                            <div key={index} className={s.flightDetail}>
                            <span
                                className={clsx(s.flightCode, s[flightEvent.code], [traceData?.data?.traceInfo?.trackAndTrace.origin, traceData?.data?.traceInfo?.trackAndTrace.destination].indexOf(flightEvent.eventLocation) > -1 ? s.endpoint : null)}>
                              {flightEvent.code}
                            </span>
                                                                <div className={s.flightDetailInfo}>
                            <span>
                              {getDate(flightEvent.eventDate)}
                            </span>
                                                                    <span className={s.flightDetailInfoBadges}>
                              <Badge
                                  label={<Trans t={t} i18nKey='trackHistory.pieces'
                                                values={{ segment: flightEvent }} />}
                                  color='white' />
                              <Badge
                                  label={`${flightEvent.weight.toFixed(2)} ${t('units.kg')}`}
                                  color='white'
                              />
                            </span>
                                                                </div>
                                                            </div>,
                                                    )}
                                                </div>
                                            </div>)}
                                    </div>
                                </div>
                            }
                            <div className={s.chapter}>
                                <div className={s.legend}>
                                    {traceData?.data?.traceInfo?.trackAndTrace?.events?.map(e => e.code)
                                        .filter((value, index, self) => self.indexOf(value) === index)
                                        .map(code => <div key={code} className={s.flightLegend}>
                                            <div className={clsx(s.flightCode, s[code])} />
                                            <span className={s.flightDescription}>
                    {code} - {t(`trackHistory.codes.${code}`)}
                  </span>
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={s.alert}>
                            <div>
                                {traceData?.message ? (
                                    traceData.message === 'Авиакомпания не поддерживается.'
                                        ? (<span>{t('trackHistory.trackDataWillBeLater')}</span>)
                                        : (
                                            <div>
                                                <span>{t('trackHistory.externalServiceError')}</span>
                                                <Button
                                                    icon={<IconRefresh fill='currentColor' />}
                                                    type='fill'
                                                    variant='secondary'
                                                    onClick={onReFetchHandler}>
                                                    Обновить
                                                </Button>
                                            </div>)
                                ) : (
                                    <div>
                                        <span>{t('trackHistory.trackDataWillBeLater')}</span>
                                        <Button
                                            icon={<IconRefresh fill='currentColor' />}
                                            type='fill'
                                            variant='secondary'
                                            onClick={onReFetchHandler}>
                                            Обновить
                                        </Button>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    )


                )
            }
        </>
    );
};

export default TraceHistory;