import {createSlice} from '@reduxjs/toolkit';
import {createAppThunk} from '@helpers/reduxHelpers';
import { LanguageDto } from '@models/languagesModels';
import LanguagesService from '@services/LanguagesService';

export type State = {
    languages: LanguageDto[];
	isFetching: boolean;
};

export const fetchLanguages = createAppThunk(
    'fetchLanguages',
    async () => {
        const {data} = await new LanguagesService().get();
        return data;
    });

const slice = createSlice({
    name: 'languages',
    initialState: {
        languages: [],
		isFetching: false
    } as State,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchLanguages.fulfilled, (state, action) => {
            state.languages = action.payload;
			state.isFetching = false;
        });
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchLanguages.pending, (state, action) => {
			state.isFetching = true;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchLanguages.rejected, (state, action) => {
			state.isFetching = false;
		});
	}
});

export const {reducer} = slice;
export const {actions} = slice;