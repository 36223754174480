import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import s from './layout.module.scss';
import { background, logo } from '@helpers/tenantsHelper';
import useAccreditationListRedirection from '@helpers/routingHelpers';
import { Link } from 'react-router-dom';

type LayoutRouteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path?: string | string[];
  exact?: boolean;
};

type LayoutProps = {
  children?: React.ReactNode;
};

const Layout = (props: LayoutProps): JSX.Element => {
  useAccreditationListRedirection();

  return (
    <Container fluid={true} className={s.simpleLayout} style={{ backgroundImage: `url(${background})` }}>
      <Navbar className='justify-content-start'>
        <NavbarBrand tag={Link} to='/'>
          <img style={{ height: '80px' }} src={logo} />
        </NavbarBrand>
      </Navbar>
      <Container className='container-xs pb-1'>
        {props.children}
      </Container>
    </Container>
  );
};

const GuestLayout = ({ component, ...rest }: LayoutRouteProps) => (
  <Route
    {...rest}
    render={(matchProps: RouteComponentProps) => <Layout>{React.createElement(component, matchProps)}</Layout>}
  />
);

export default GuestLayout;