import * as React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useTranslation } from 'react-i18next';
import { Loader } from '@components/index';
import listStyles from '@assets/list.module.scss';
import s from './eventLog.module.scss';
import Paginator from '@components/paginator/Paginator';
import { GetCustomerApplicationEventLog } from '@models/customerApplications/customerApplicationEventsModels';
import { fetchEvents } from '@store/customerApplications/customerApplicationEventLogStore';
import debounce from 'awesome-debounce-promise';
import { PropertySorter } from '@models/entityNavigation/sorting';
import Tippy from '@tippyjs/react';
import { ApplicationEntityType } from '@models/ApplicationEntityType';
import i18n from 'i18next';
import { CustomerApplicationEventType } from '@models/admin/notificationsModels';
import { Badge } from '@root/components';
import getDate from '@helpers/dateTime/getDate';

type Props = {
  customerApplicationId: string;
};

const createNavState = (customerApplicationId: string) => {
  const nav = new GetCustomerApplicationEventLog(customerApplicationId);
  const sorter = new PropertySorter();
  nav.sorters.push(sorter);
  return { nav, sorter };
};

let navState: ReturnType<typeof createNavState> = null;

const EventLogPage = (props: Props) => {
  const { pagingWrapper, isFetching } = useAppSelector((x) => x.customerApplicationEventLog);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tableWrapperRef = useRef<HTMLDivElement>();

  const getDebounced = debounce((nav: GetCustomerApplicationEventLog, resetPaging: boolean) => {
    if (resetPaging) {
      nav.pagingFilter.pageNumber = 0;
    }
    dispatch(fetchEvents(nav));
  }, 800);

  useEffect(() => {

    if (navState == null) {
      navState = createNavState(props.customerApplicationId);
      dispatch(fetchEvents(navState.nav));
    }

  }, [navState?.nav]);

  useEffect(() => {
    return () => {
      navState = null;
    };
  }, []);

  const onChangePage = (pageNum: number) => {
    navState.nav.pagingFilter.pageNumber = pageNum;
    getDebounced(navState.nav, false);
  };
  useMemo(() => {
    const i18tColumnNames = [
      'customerApplicationEventLog.timestamp',
      'customerApplicationEventLog.userDisplayName',
      'customerApplicationEventLog.entity',
      'customerApplicationEventLog.event',
    ];
    return (
      <div className={s.header}>
        {i18tColumnNames.map((x, i) =>
          <span className={s.column} key={i}>{t(x)}</span>)
        }
      </div>
    );
  }, [i18n.language]);
  const entityNames = useMemo(() => {
    return [
      {
        type: ApplicationEntityType.CustomerApplication,
        name: t('customerApplicationEventLog.customerApplication'),
      },
      {
        type: ApplicationEntityType.MasterAirwaybill,
        name: t('customerApplicationEventLog.masterAirwaybill'),
      },
      {
        type: ApplicationEntityType.HouseAirwaybill,
        name: t('customerApplicationEventLog.houseAirwaybill'),
      },
    ];
  }, [i18n.language]);

  const eventNames: {
    type: CustomerApplicationEventType;
    name: string;
  }[] = useMemo(() => {
    return [
      {
        type: CustomerApplicationEventType.Created,
        name: t('created'),
      },
      {
        type: CustomerApplicationEventType.Updated,
        name: t('updated'),
      },
      {
        type: CustomerApplicationEventType.AwbCreated,
        name: t('created'),
      },
      {
        type: CustomerApplicationEventType.AwbRemoved,
        name: t('removed'),
      },
      {
        type: CustomerApplicationEventType.AwbUpdated,
        name: t('updated'),
      },
      {
        type: CustomerApplicationEventType.AwbImportedFromFile,
        name: t('customerApplicationEventLog.importedFromCode'),
      },
      {
        type: CustomerApplicationEventType.AwbImportedFromCode,
        name: t('customerApplicationEventLog.importedFromImage'),
      },
      {
        type: CustomerApplicationEventType.FwbSended,
        name: t('customerApplicationEventLog.fwbSend'),
      },
    ];
  }, [i18n.language]);

  return (
    <div>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <div>
            <div ref={tableWrapperRef}>
              {pagingWrapper?.items?.length > 0 ? (
                <div className={s.logContainer}>
                  {
                    pagingWrapper.items.map((item, i) => {
                      const eventName = eventNames.find(x => x.type == item.eventType)?.name;
                      const entityName = entityNames.find(x => x.type == item.entityType)?.name;

                      return (
                        <div key={i} className={s.row}>
                          <div className={s.data}>
                            <span className={s.header}>{t('customerApplicationEventLog.timestamp')}</span>
                            <span className={s.column}>{getDate(item.timestamp)}</span>
                          </div>
                          <div className={s.data}>
                            <span className={s.header}>{t('customerApplicationEventLog.userDisplayName')}</span>
                            <span className={s.column}>
                              <Tippy content={item.organizationName}>
                                <Badge label={item.userDisplayName} color='white' />
                              </Tippy>
                            </span>
                          </div>
                          <div className={s.data}>
                            <span className={s.header}>{t('customerApplicationEventLog.entity')}</span>
                            <span className={s.column}>
                              <Badge label={entityName} color='blue' />
                            </span>
                          </div>
                          <div className={s.data}>
                            <span className={s.header}>{t('customerApplicationEventLog.event')}</span>
                            <span className={s.column}>
                              <Badge label={eventName} color='gray' />
                            </span>

                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              ) : (
                <div className={listStyles.box}>{t('customerApplicationEventLog.noEventsFound')}</div>
              )}
            </div>
          </div>

          <div className={listStyles.paginator}>
            <Paginator
              totalResults={pagingWrapper?.totalCount}
              limitPerPage={navState?.nav.pagingFilter.pageSize || 0}
              currentPage={(navState?.nav.pagingFilter.pageNumber || 0) + 1}
              onChangePage={(p) => onChangePage(p)}
              pageNeighbours={4}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EventLogPage;