import { HttpError } from '@models/shared';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { GetRoutesSchedules, RoutesScheduleDto } from '@models/routesModels';

export default class RoutesService {

    public get = async (m: GetRoutesSchedules) => {
        return await AuthorizedHttpClient.post<RoutesScheduleDto[] & HttpError>('/api/routes/schedules', m);
    };

}