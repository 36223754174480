import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from '../../SearchCalendar.module.scss';
import clsx from 'clsx';
import {DateTime} from '@helpers/DateTime';
import {scrollParentToChildInCenter} from '@helpers/scrollHelpers';
import {TileStatus} from "../types";
import Tile from "../Tile"

type Props = {
    value: string;
    data: CalendarData[];
    onChange: (date: string) => void;
    /*
    * Count of the next days which will be rendered in calendar.
    * */
    daysToRenderNext: number;
    disabledFromDate: DateTime;
};

export type CalendarData = { date: string, resultCount: number };

type CalendarTileData = { tileIndex: number, date: string, resultCount: number, status: TileStatus };


export const SearchCalendar = (props: Props) => {

    const [activeTileIndex, setActiveTileIndex] = useState(0);

    const today: DateTime =
        useMemo(() => DateTime.now(true), []);

    let currentValue: DateTime = null;
    if (props.value == null) {
        currentValue = today;
    } else {

        currentValue = new DateTime(props.value);
        currentValue.resetTime();

        if (currentValue < today) {
            currentValue = today;
        }
    }

    const datesToRender = useMemo(() => {

        let d = currentValue.clone();

        let daysBack = 5;
        if (d < today) {
            d = today;
        } else {
            const daysBackFromSelectedTillToday = d.diffDaysCount(today);
            if (daysBackFromSelectedTillToday > daysBack) {
                daysBack = daysBackFromSelectedTillToday + daysBack;
            }
        }

        d.addDays(-daysBack);

        const daysToLoad = daysBack + 1 + props.daysToRenderNext;

        return d.arrangeDates(daysToLoad).map(x => x.toISOString());

    }, []);

    const tileRefs = useRef<HTMLDivElement[]>([]);

    const calendarWrapperRef = useRef<HTMLDivElement>();

    const [calendarData, setCalendarData] = useState<CalendarData[]>([]);
    const [tileData, setTileData] = useState<CalendarTileData[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onResizeWindow = (ev: UIEvent) => {
        if (calendarWrapperRef.current) {
            const tile = calendarWrapperRef.current.querySelector('.active');
            if (tile) {
                scrollParentToChildInCenter(calendarWrapperRef.current, tile as HTMLElement);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', onResizeWindow);
        return () => {
            window.removeEventListener('resize', onResizeWindow);
        };
    }, []);


    useEffect(() => {

        const newData = props.data.map(x => {
            const d = new DateTime(x.date);
            d.resetTime();
            return {
                date: d.toISOString(),
                resultCount: x.resultCount,
            };
        });
        setCalendarData(newData);
    }, [props.data]);

    useEffect(() => {

        const entries: CalendarTileData[] = [];

        for (let i = 0; i < datesToRender.length; i++) {

            const dateToRender = datesToRender[i];

            const dateToRenderMoment = new DateTime(datesToRender[i]);

            const resultCountForTile =
                calendarData
                    .find(x => x.date == dateToRender)?.resultCount;

            let status = TileStatus.Enabled;
            if (dateToRender == currentValue.toISOString()) {
                status = TileStatus.Active;
                setActiveTileIndex(i);
            } else if (
                dateToRenderMoment < today ||
                props.disabledFromDate <= dateToRenderMoment
            ) {
                status = TileStatus.Disabled;
            } else if (resultCountForTile == null) {
                status = TileStatus.Loading;
            }

            entries.push({
                resultCount: resultCountForTile,
                date: dateToRender,
                status: status,
                tileIndex: i,
            });
        }

        setTileData(entries);

    }, [props.value, props.disabledFromDate, calendarData, datesToRender]);

    const getActiveTile = () => {
        return tileRefs.current[activeTileIndex];
    };

    const getNearbyEnabledTile = (isNext: boolean): CalendarTileData => {
        let i = activeTileIndex;
        if (isNext && activeTileIndex == tileData.length - 1) {
            return null;
        }
        if (!isNext && activeTileIndex == 0) {
            return null;
        }
        while (isNext ? i < tileData.length - 1 : i > 0) {
            if (isNext) {
                i++;
            } else {
                i--;
            }
            const tile = tileData[i];
            if (tile.status == TileStatus.Enabled) {
                return tile;
            }
        }
        return null;
    };

    const isNavButtonActive = (isNext: boolean) => {
        return getNearbyEnabledTile(isNext) != null;
    };

    const onClickTile = (newDate: string) => {
        scrollToActiveTile();
        props.onChange(newDate);
    };

    const scrollToActiveTile = () => {
        const activeTile = getActiveTile();
        if (activeTile) {
            scrollParentToChildInCenter(calendarWrapperRef.current, activeTile);
        }
    };

    useEffect(() => {

        setTimeout(() => {
            scrollToActiveTile();
        });

    }, [tileData]);

    const onClickNav = (isNext: boolean) => {
        const tile = getNearbyEnabledTile(isNext);
        if (tile != null) {
            onClickTile(tile.date);
        }
    };

    return <div className={styles.calendarWrapper}>
        <div
            className={clsx(styles.btn, styles.btnLeft, isNavButtonActive(false) ? null : styles.btnDisabled)}
            onClick={() => onClickNav(false)}
        >
            <i className='icon-arrow-right'/>
        </div>
        <div ref={calendarWrapperRef} className={styles.tilesWrapper}
             style={{gridTemplateColumns: `repeat(${datesToRender.length}, minmax(100px, 1fr))`}}>

            {
                tileData.map((x, i) => <Tile
                    key={i}
                    ref={x => tileRefs.current[i] = x}
                    date={x.date}
                    resultCount={x.resultCount}
                    status={x.status}
                    onClick={() => {
                        scrollParentToChildInCenter(calendarWrapperRef.current, tileRefs.current[i]);
                        onClickTile(x.date);
                    }}
                />)
            }

        </div>
        <div
            className={clsx(styles.btn, styles.btnRight, isNavButtonActive(true) ? null : styles.btnDisabled)}
            onClick={() => onClickNav(true)}
        >
            <i className='icon-arrow-right'/>
        </div>
    </div>;
};