/* eslint-disable @typescript-eslint/no-explicit-any */
import {LocationDto, LocationType} from '@models/locations';
import qs from 'qs';
import moment from 'moment';
import {isString} from 'formik';
import { GetSpecialHandlingCodeDto } from '@models/cargoes';

export interface IAirport {
    id: string;
    name: string;
    codeIata: string;
    cityName: string;
    cityCode: string;
    cityId: string;
    countryName: string;
}

export type CargoTypeDto = {
    id: string;
    name: string;
};

export type CargoDimensionDto = {
    amount: number;
    length: number;
    width: number;
    height: number;
    weight: number;
    isTotalWeight: boolean;
};

export interface IFee {
    code: string;
    currencyCode: string;
    name: string | null;
    comments: string | null;
    cost: number;
    unitOfUse: number | null;
    value: number | null;
}

export interface AirlineDto {
    id: string;
    name: string;
    defaultFwbVersion: number;
    defaultEAwbShcId?: string;
    defaultSecurityShcId?: string;
}

export interface FilterItemDto {
    id: string;
    name: string;
}

export interface IImage {
    contentBase64: string;
    contentType: string;
}

export enum SaleStatus {
    Available = 1,
    UponRequest = 2,
    Embargo = 3,
    Restrictions = 4,
    AdHoc = 5,
    NotAvailable = 6,
    Promo = 7
}

export type SalesFilteringEntities = {
    airlines: FilterItemDto[];
    products: FilterItemDto[];
    departures: FilterItemDto[];
    destinations: FilterItemDto[];
    statuses: SaleStatus[];
};

export type SaleFilterDto = {
    noConnections: boolean;
    airlineIds: string[];
    productIds: string[];
    departureIds: string[];
    destinationIds: string[];
    statuses: SaleStatus[];
};


export interface GetSaleDto {
    id?: string | undefined;
    total?: number | undefined;
    convertedTotal?: number | undefined;
    costPerUnit?: number | undefined;
    convertedCostPerUnit?: number | undefined;
    isConverted?: boolean;
    airlineLogo?: IImage | undefined;
    airlineName?: string | undefined;
    airlineCodeIata?: string | undefined;
    airlineIataPrefix?: string | undefined;
    productId?: string | undefined;
    productName?: string | undefined;
    productDescription?: string | undefined;
    airlineId?: string | undefined;
    saleStatus?: SaleStatus | undefined;
    surchargesTotal?: number | undefined;
    totalAirFreight?: number | undefined;
    tariffRate?: number | undefined;
    currencyCode?: string | undefined;
    convertedCurrencyCode?: string | undefined;
    chargeableWeight?: number | undefined;
    expirationDate?: string | undefined;
    exchangeType?: number | undefined;
    paymentOn?: number | undefined;
    cbPlusPercent?: number | undefined;
    destinationPoint?: SaleLocationDto | undefined;
    transitPoint?: SaleLocationDto | undefined;
    departurePoint?: SaleLocationDto | undefined;
    limitations?: string[] | undefined;
    fees?: IFee[] | undefined;
    optionalFees?: IFee[] | undefined;
    dimensionsValidationResult: number;
    showSpecialConditions: boolean;
    hasAdHocTariff: boolean;
    hasPromoTariff: boolean;
    weightCategory: number;
    readonly: boolean;
    airlineDescription: string;
    airlineDescription2: string;
    isExpired: boolean
}

export type SaleLocationDto = {
    id: string;
    name: string;
    codeIata: string;
    parent: SaleLocationDto;
    type: LocationType;
};

export type GetCustomerRequestDto = {
    id: string;
    searchNearBy: boolean;
    number: string;
    totalPlaces: number;
    totalWeight: number;
    totalVolume: number;
    departureLocation: LocationDto;
    destinationLocation: LocationDto;
    //cargoType: CargoTypeDto;
    cargoDimensions: CargoDimensionDto[];
    specialHandlingCodes: GetSpecialHandlingCodeDto[];
    natureOfCargo: string;
    natureOfCargoTitle: string;
	goodsDescription: string;
    dateStartPlan: string;
    isTurnable: boolean;
    isCargoStacked?: boolean;
    // ---
    airwaybillNumber?: string;
    cargoDetails?: string;
    isCargoTurnover?: boolean;
    allowPackingWithOtherGoods?: boolean;
    isCargoDangerous?: boolean;
    cargoDangerClass?: string;
    cargoUnNumber?: string;
    cargoPackagedForPlaneType?: number;
    senderAddress?: string;
    senderStateProvince?: string;
    senderCityId?: string;
    recipientAddress?: string;
    recipientStateProvince?: string;
    recipientCityId?: string;
    referenceNumber?: string;
    awbProvidedBy?: number;
    firstName?: string;
    lastName?: string;
    additionalEmail?: string;
    hasCustomerApplication?: boolean;
    // ---
};

export type CreateCustomerRequest = {
    id?: string | undefined;
    searchNearBy?: boolean | undefined;
    totalWeight?: number | undefined;
    totalVolume?: number | undefined;
    totalPlaces?: number | undefined;
    cargoDimensions?: CargoDimensionDto[] | undefined;
    //cargoTypeId?: string | undefined;
    dateStartPlan?: string | undefined;
    fromCodeIata?: string | undefined;
    fromLocationType?: LocationType | undefined;
    toCodeIata?: string | undefined;
    toLocationType?: LocationType | undefined;
    specialHandlingCodeIds: string[];
    natureOfCargo: string;
	goodsDescription: string;
    useTotalWeight: boolean | undefined;
    isTurnable: boolean | undefined;
    isCargoStacked: boolean | undefined;
};

export class CreateCustomerRequestQs {
    /*
     * Request ID.
     */
    public id?: string | undefined;
    /*
     * Search nearby.
     */
    public nearby?: boolean | undefined;
    /*
     * Total weight.
     */
    public w?: number | undefined;
    /*
     * Total volume.
     */
    public v?: number | undefined;
    /*
     * Total places.
     */
    public p?: number | undefined;
    /*
     * Cargo dimensions.
     */
    public dims?: CargoDimensionDto[] | undefined;
    /*
     * Special handling code ids.
     */
    public shcIds: string[];
    /*
     * Nature of Cargo.
     */
    public noc: string;
    /*
     * Goods description.
     */
    public gd: string;
    /*
     * Cargo type.
     */
    public ct?: string | undefined;
    /*
     * Date start plan.
     */
    public d?: string | undefined;
    /*
     * From location IATA code.
     */
    public from?: string | undefined;
    /*
     * From location type.
     */
    public fromType?: LocationType | undefined;
    /*
     * To location IATA code.
     */
    public to?: string | undefined;
    /*
     * To location type.
     */
    public toType?: LocationType | undefined;

    public useTotalWeight: boolean | undefined;

    public isTurnable: boolean | undefined;

    public isCargoStacked: boolean | undefined;

    public static fromModel(m: CreateCustomerRequest): CreateCustomerRequestQs {
        return Object.assign(new CreateCustomerRequestQs(), {
            dims: m.cargoDimensions,
            //ct: m.cargoTypeId,
            d: m.dateStartPlan,
            from: m.fromCodeIata,
            fromType: m.fromLocationType,
            nearby: m.searchNearBy,
            to: m.toCodeIata,
            toType: m.toLocationType,
            p: m.totalPlaces,
            v: isString(m.totalVolume) ? (m.totalVolume as string).replace(/,/, ".") : m.totalVolume,
            w: isString(m.totalWeight) ? (m.totalWeight as string).replace(/,/, ".") : m.totalWeight,
            shcIds: m.specialHandlingCodeIds,
            noc: m.natureOfCargo,
            gd: m.goodsDescription,
            useTotalWeight: m.useTotalWeight,
            isTurnable: m.isTurnable,
            isCargoStacked: m.isCargoStacked
        } as CreateCustomerRequestQs);
    }

    public static fromQueryString(qsData: string): CreateCustomerRequestQs {
        const json = qs.parse(qsData) as unknown as CreateCustomerRequestQs;
        json.w = parseFloat(json.w as any);
        json.v = parseFloat(json.v as any);
        json.p = parseInt(json.p as any);
        json.nearby = (json.nearby as any) == 'true';
        json.isTurnable = (json.isTurnable as any) == 'true';
        json.isCargoStacked = (json.isCargoStacked as any) == 'true';
        json.dims =
            (json.dims?.map((x) => ({
                amount: parseInt(x.amount as any),
                length: parseFloat(x.length as any),
                height: parseFloat(x.height as any),
                weight: parseFloat(x.weight as any),
                width: parseFloat(x.width as any),
            })) as CargoDimensionDto[]) || null;
        json.fromType = parseInt(json.fromType as any);
        json.toType = parseInt(json.toType as any);
        return Object.assign(new CreateCustomerRequestQs(), json);
    }

    public toQueryString(): string {
        const obj = {...this};
        obj.d = moment(obj.d).format('YYYY-MM-DD');
        if (obj.dims == null || obj.dims.length == 0) {
            delete obj.dims;
        }
        if (obj.nearby == false) {
            delete obj.nearby;
        }
        delete obj.id;
        return qs.stringify(obj);
    }

    public toModel(): CreateCustomerRequest {
        return {
            id: this.id,
            cargoDimensions: this.dims || [],
            dateStartPlan: this.d
                ? moment(this.d).endOf('day').utc().startOf('day').toISOString()
                : moment().endOf('day').utc().startOf('day').toISOString(),
            fromCodeIata: this.from,
            fromLocationType: this.fromType,
            searchNearBy: this.nearby,
            toCodeIata: this.to,
            toLocationType: this.toType,
            totalPlaces: this.p,
            totalVolume: this.v,
            totalWeight: this.w,
            specialHandlingCodeIds: this.shcIds,
            natureOfCargo: this.noc,
            goodsDescription: this.gd,
            useTotalWeight: this.useTotalWeight,
            isTurnable: this.isTurnable,
            isCargoStacked: this.isCargoStacked
        };
    }
}