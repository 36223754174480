export type GetSpecialHandlingCodeDto = {
	id: string;
    code: string;
	name: string;
    visibility?: VisibilityType;
}

export type GetNatureOfCargoDto = {
	id: string;
	name: string;
	description: string;
	shcId: string;
}

export enum VisibilityType {
    None,
    InSearch = 1,
    InAirwayBill = 2
}