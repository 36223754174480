import { FilterConnection, PagingFilter } from '@models/entityNavigation/filtering';
import { PropertySorter, PropSorter } from '@models/entityNavigation/sorting';
import { VisibilityType } from '@models/cargoes';

export class StandardNavigation {

    filters: FilterConnection[] = [];
    sorters: PropertySorter[] = [];
    pagingFilter: PagingFilter = new PagingFilter(0, 5);
}

export type Navigation = {
    filters: FilterConnection[];
    sorters: PropSorter[];
    pagingFilter: PagingFilter;
}

export class ShcNavigation extends StandardNavigation {
    visibility?: VisibilityType;
}