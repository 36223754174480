import { WayToGetAccountingDoc } from "./WayToGetAccountingDoc";

export type OrganizationDictionaryItem = {
	id: string;
	name: string;
	email: string;
	phone: string;
	uniqueNumber: string;
	regNumber: string;
	vatNumber: string;
	hierarchyLevel: OrganizationHierarchyLevel;
	accessLevel: OrganizationAccessLevel;
};

export enum OrganizationHierarchyLevel {
	Office = 852250003,
	ParentOrganization = 852250000,
	SubsidiaryOrganization = 852250001,
	Branch = 852250002,
	Related = 852250004,
}

export enum OrganizationAccessLevel {
	NotProvided = 557050000,
	Pending = 557050001,
	Granted = 557050002,
	Rejected = 557050003,
	Blocked = 557050004,
	GrantedNoBooking = 557050005
}

export type OrganizationDto = {
	id: string;
	name: string;
	uniqueNumber: string;
	vatNumber: string;
	email: string;
	fullName: string;
	regNumber: string;
	checkingAccount: string;
	bankName: string;
	correspondentAccount: string;
	bic: string;
	dateIncorporation: string;
	legalAddress: string;
	mailingAddress: string;
	officeAddress: string;
	webSiteUrl: string;
	mainActivity: string;
	areDirectorAndAccountantTheSame: boolean;
	accessLevel: OrganizationAccessLevel;
	wayToGetAccountingDocs: WayToGetAccountingDoc;
	edmName?: string;
	edmId?: string;
	phone: string;
	hasDynamicsRegistration?: boolean;
	employeesCount?: number;
	currentCarriers?: string;
	mainDirections?: string;
	expectedVolumes?: string;
    isSynchronized?: boolean;
};

export type UpdateOrganizationInfo = {
	id: string;
	name: string;
	email: string;
	regNumber: string;
	vatNumber: string;
	fullName: string;
	checkingAccount: string;
	bankName: string;
	correspondentAccount: string;
	bic: string;
	legalAddress: string;
	mailingAddress: string;
	officeAddress: string;
	webSiteUrl: string;
	mainActivity: string;
	dateIncorporation: string;
	areDirectorAndAccountantTheSame: boolean;

	wayToGetAccountingDocs: WayToGetAccountingDoc;
	edmName?: string;
	edmId?: string;
	phone: string;
};