import React, { useMemo, useState } from 'react';

import AppModal from '@components/AppModal';
import { FormGroup, Label } from 'reactstrap';
import {
    Form,
    Formik,
    FormikProps,
} from 'formik';
import nameof from 'ts-nameof.macro';
import { useTranslation } from 'react-i18next';
import i18n from '@i18n';
import { object, string, array } from "yup";
import { useAppDispatch, useAppSelector } from '@store/index';
import { fetchColleaguesByInteractionType } from '@store/contactsStore';
import { InteractionType } from '@models/contacts/InteractionType';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import CustomerRequestsService from '@services/CustomerRequestsService';
import { SendEmailToClientModel } from '@models/customerRequests/sendEmailToClientModel';

type Props = {
    isOpened: boolean;
    onClose: () => void;
	model: SendEmailToClientModel;
};

type FormModel = {
    emails: string[];
};

const SendEmailToClientFromDynamicsModal = (props: Props): JSX.Element => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const validationSchema = useMemo(
        () =>
            object().shape({
                emails: array()
					.nullable()
                    .min(1, t('validation.required'))
                    .of(string().email(({ value }) => t('validation.email')))
            }),
        [i18n.language]
    );

    const [state, setState] = useState<{ submitted: boolean; error?: string }>({
        submitted: false,
        error: null,
    });

    const { colleagueEmailsWithOperationalInteractionType } = useAppSelector(appState => appState.contacts); 
    React.useEffect(() => {
        if (colleagueEmailsWithOperationalInteractionType.length == 0) {
            dispatch(fetchColleaguesByInteractionType({iType: InteractionType.Operational}));
        }
    }, []);

    return (
        <AppModal
            isOpen={props.isOpened}
            onClickCloseButton={() => {
                setState({ submitted: false, error: null });
                props.onClose();
            }}
            body={
                <Formik<FormModel>
                    initialValues={{emails: colleagueEmailsWithOperationalInteractionType.concat(props.model.MyEmail)}}
                    validationSchema={validationSchema}
                    onSubmit={async (
                        values: FormModel,
						formikHelpers
                    ): Promise<void> => {
                        setState({ submitted: false, error: null });

                        try {
							formikHelpers.setFieldTouched(nameof<FormModel>(x => x.emails), false);

                            await new CustomerRequestsService()
                                .sendClientOffer(props.model.RequestId, props.model.WithTariffSchedule, props.model.SaleIDs, values.emails);

                            setState({ submitted: true, error: null });

                        } catch (e) {
                            setState({ submitted: true, error: t('validation.unknownError') });
                        }

                        formikHelpers.setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting,
						getFieldMeta,
						values,
                        setFieldTouched, touched, errors, setFieldValue
                    }: FormikProps<FormModel>): JSX.Element => (
                        <Form>
                            <div>
                                <FormGroup>
                                    <h4>
                                        {t('requestSearchResults.modal.header2')}
                                    </h4>
                                </FormGroup>
                                <FormGroup>
                                    <>
                                        <Label>Emails</Label>
                                        {/* <CreatableSelect placeholder="Emails" isMulti
                                            name={nameof.full<FormModel>((x) => x.emails)}
                                            value={values.emails.map(x => ({label: x, value: x}))}
                                            options={values.emails.map(x => ({label: x, value: x}))}
                                            onCreateOption={(newValue: any) => {
                                                setFieldTouched('emails', true);
                                                setFieldValue('emails', values.emails.concat(newValue));
                                            }}
                                            onChange={(inputValue: any) => {
                                                setFieldTouched('emails', true);
                                                setFieldValue('emails', (inputValue || []).map(x => x.value));
                                            }} /> */}
                                        <Select
                                            name={nameof.full<FormModel>((x) => x.emails)}
                                            isMulti
                                            value={values.emails.map(x => ({label: x, value: x}))}
                                            options={values.emails.map(x => ({label: x, value: x}))}
                                            onChange={(inputValue: any) => {
                                                setFieldTouched('emails', true);
                                                setFieldValue('emails', (inputValue || []).map(x => x.value));
                                            }}
                                        />
                                        {touched['email'] && errors['email'] && (
                                            <div style={{ color: 'red' }}>{errors['email']}</div>
                                        )}
                                    </>
                                </FormGroup>
                                <FormGroup className="text-center">
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="btn btn-primary"
                                    >
                                        {isSubmitting
                                            ? t('requestSearchResults.modal.buttonSending')
                                            : t('requestSearchResults.modal.button')}
                                    </button>
                                </FormGroup>
                                {state.submitted && !getFieldMeta(nameof<FormModel>(x => x.emails)).touched && (
                                    <FormGroup>
                                        {!!state.error ? (
                                            <div className="alert alert-danger">
                                                {state.error}
                                            </div>
                                        ) : (
                                            <div className="alert alert-success">
                                                {t(
                                                    'requestSearchResults.modal.success'
                                                ).replace('{email}', values.emails.join(';'))}
                                            </div>
                                        )}
                                    </FormGroup>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        />
    );
};

export default SendEmailToClientFromDynamicsModal;